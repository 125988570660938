import React from 'react';

const Notifications = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <g>
        <path
          d="M10 18.333c.917 0 1.667-.75 1.667-1.666H8.333c0 .916.75 1.666 1.667 1.666zm5-5V9.167c0-2.559-1.358-4.7-3.75-5.267v-.567c0-.691-.558-1.25-1.25-1.25s-1.25.559-1.25 1.25V3.9C6.367 4.467 5 6.6 5 9.167v4.166L3.333 15v.833h13.334V15L15 13.333zm-1.667.834H6.667v-5c0-2.067 1.258-3.75 3.333-3.75s3.333 1.683 3.333 3.75v5z"
          id="b"
        />
      </g>
    </svg>
  );
};

export default Notifications;
