export const getSiteEndpoint = () => {
  const environment = document.querySelector('meta[name="environment_name"]').content;

  const endpoints = {
    dev: process.env.REACT_APP_SITE_ENDPOINT_DEV,
    test: process.env.REACT_APP_SITE_ENDPOINT_TEST,
    staging: process.env.REACT_APP_SITE_ENDPOINT_STAGING,
    prod: process.env.REACT_APP_SITE_ENDPOINT_PROD,
    DevLocal:process.env.REACT_APP_SITE_ENDPOINT_DEVLOCAL
  };

  return endpoints[environment];
};
