import styled from 'styled-components';
import { Icon } from 'components/_icons';
import NavigationLink from 'components/_common/navigation-link';

export const LinkSection = styled.div`
  width: 100%;
  height: 100%;
`;

export const LinkHeader = styled.h3`
  ${({
    theme: {
      fonts: { NotoSansRegular, NotoSansBold },
    },
    activeTab,
    bold,
  }) => `
    position: relative;
    padding: 3.3rem 4rem 1rem 1.4rem;
    margin: 0;
    color: ${activeTab ? 'rgb(0, 0, 0)' : 'rgb(106, 106, 106)'};
    ${
      activeTab
        ? `
      font-family: ${NotoSansBold.value};
      font-weight: ${NotoSansBold.fontWeight};
      font-style: ${NotoSansBold.fontStyle};
      `
        : `
      font-family: ${NotoSansRegular.value};
      font-weight: ${NotoSansRegular.fontWeight};
      font-style: ${NotoSansRegular.fontStyle};
      `
    }

    ${
      bold
        ? `
      font-family: ${NotoSansBold.value};
      font-weight: ${NotoSansBold.fontWeight};
      font-style: ${NotoSansBold.fontStyle};
      color: rgb(0, 0, 0);
      `
        : ''
    }
    font-size: 16px;
    line-height: 24px;
    min-width: 21.5rem;
    cursor: pointer;
  `}
`;

export const LinkContainer = styled.div`
  ${({ open }) => `
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow-y: hidden;
    // Hack to allow for auto dimensions
    max-height:  ${open ? `1000rem` : '0'};
    transition: max-height 400ms ease-in-out;
  `}
`;

export const Link = styled(NavigationLink)`
  ${({
    theme: {
      fonts: { NotoSansRegular, NotoSansBold },
    },
    mainHeader,
    bold,
  }) => `
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    color: rgb(106, 106, 106);
    font-size: ${mainHeader ? '16px' : '14px'};
    font-weight: normal;
    line-height: 24px;
    text-decoration: underline;
    padding: ${mainHeader ? '' : '0 1rem 1rem 1.8rem'};

    span {
      ${
        bold
          ? `
      font-family: ${NotoSansBold.value};
      font-weight: ${NotoSansBold.fontWeight};
      font-style: ${NotoSansBold.fontStyle};
      color: rgb(0, 0, 0);
      `
          : ''
      }
    }

    &:first-child {
      margin-top: 1.8rem;
    }

    &:last-child {
      margin-bottom: 1rem;
    }
  `}
`;

export const HeadingIcon = styled(Icon)`
  ${({ activeTab, hideChevron }) => `
    ${hideChevron ? 'display: none;' : ''}
    ${activeTab ? ' transform: rotate(-90deg);' : 'transform: rotate(0deg);'}
    position: absolute;
    top: 4rem;
    right: 2rem;
    fill: black;
    width: 1.2rem;
    height: 1.2rem;
    transition: transform 400ms ease-in-out;
  `}
`;
