import React from 'react';

const Download = ({ className }) => {
  return (
    <svg className={className} xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 18 22">
      <g fillRule="evenodd" transform="translate(-6 -3)">
        <path d="M23.75 11.25h-5v-7.5h-7.5v7.5h-5L15 20l8.75-8.75zM6.25 22.5V25h17.5v-2.5H6.25z" id="a" />
      </g>
    </svg>
  );
};

export default Download;
