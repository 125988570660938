import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormContext } from 'components/context/FormContext';
import { Auth } from 'components/context/AuthContext';
import { SignupComponent, Title, Description, ButtonsContainer, LinkCTA } from './Signup.styles.js';
import Modal from 'components/_common/modal/Modal.js';
import ModalContent from 'components/_common/modal-content/index.js';
import Cookies from 'js-cookie';

const Signup = ({
  header,
  body,
  primaryCta,
  secondaryCta,
  modalError,
  modalSuccess,
  labels = {},
  moreLinks,
  ...rest
}) => {
  const {
    formDispatch,
    formState: { isOpenFormSuccessModal, isOpenFormErrorModal },
  } = useContext(FormContext);

  const { handleLogin } = useContext(Auth);

  useEffect(() => {
    Cookies.set('dashboard', labels?.dashboard_url, { expires: 365 });
  }, [labels.dashboard_url]);

  return (
    <SignupComponent>
      <Title>{header}</Title>
      <Description>{body}</Description>
      {primaryCta || secondaryCta || moreLinks?.length > 0 ? (
        <ButtonsContainer>
          {primaryCta ? (
            <span onClick={() => handleLogin()}>
              <LinkCTA linkText={primaryCta.title} dark={true} iconName="user" />
            </span>
          ) : null}
          {secondaryCta ? (
            <LinkCTA
              linkHref={secondaryCta.url}
              linkText={secondaryCta.title}
              target={secondaryCta.target}
              isExternal={secondaryCta.isExternal}
              dark={false}
              iconName="lock"
            />
          ) : null}
          {moreLinks?.length > 0
            ? moreLinks.map(item => (
                <LinkCTA
                  linkHref={item.url}
                  linkText={item.title}
                  target={item.target}
                  isExternal={item.isExternal}
                  dark={false}
                  iconName={item.icon ? item.icon : null}
                  iconSrc={item.iconImage ? item.iconImage : null}
                />
              ))
            : null}
        </ButtonsContainer>
      ) : null}
      {isOpenFormErrorModal ? (
        <Modal onClose={() => formDispatch({ type: 'REQUEST_ACCESS_TOGGLE_ERROR_MODAL' })}>
          <ModalContent
            icon={modalError.modalIcon.src}
            title={modalError.modalHeader}
            buttonText={modalError.modalCtaLabel}
            buttonAction={() => formDispatch({ type: 'REQUEST_ACCESS_TOGGLE_ERROR_MODAL' })}
          >
            {modalError.modalBody}
          </ModalContent>
        </Modal>
      ) : null}
      {isOpenFormSuccessModal ? (
        <Modal onClose={() => formDispatch({ type: 'REQUEST_ACCESS_TOGGLE_SUCCESS_MODAL' })}>
          <ModalContent
            icon={modalSuccess.modalIcon.src}
            title={modalSuccess.modalHeader}
            buttonText={modalSuccess.modalCtaLabel}
            buttonAction={() => formDispatch({ type: 'REQUEST_ACCESS_TOGGLE_SUCCESS_MODAL' })}
          >
            {modalSuccess.modalBody}
          </ModalContent>
        </Modal>
      ) : null}
    </SignupComponent>
  );
};

Signup.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  ctas: PropTypes.shape({
    cta1: PropTypes.shape({
      linkHref: PropTypes.string,
      linkText: PropTypes.string,
    }),
    cta2: PropTypes.shape({
      linkHref: PropTypes.string,
      linkText: PropTypes.string,
    }),
  }),
};

export default Signup;
