import React from 'react';

const BespokeMachine = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M20.906 5c.809 0 1.476.619 1.566 1.412a2.082 2.082 0 011.12-.325h5.906c.277 0 .502.227.502.508 0 .28-.225.508-.502.508h-.112l-1.391 8.448a1.577 1.577 0 01-1.555 1.333h-2.883v1.803c.527.712.857 1.396.992 2.054.086.418.086 1.034 0 1.85-.356 1.704-1.854 2.986-3.643 2.986-1.49 0-2.778-.89-3.373-2.173h-9.06c-.235 1.236-1.312 2.173-2.601 2.173-1.461 0-2.65-1.203-2.65-2.681a2.69 2.69 0 011.074-2.155V16.92c0-.58.466-1.052 1.039-1.052h.034v-5.469c0-.58.467-1.051 1.04-1.051h4.33V8.19H3.932L2.468 9.671a.498.498 0 01-.71 0l-1.61-1.63a.513.513 0 010-.719l1.518-1.537A2.617 2.617 0 013.54 5zM5.872 21.23c-.907 0-1.646.748-1.646 1.666 0 .918.739 1.665 1.646 1.665.907 0 1.645-.747 1.645-1.665S6.78 21.23 5.872 21.23zm15.034-2.173c-1.5 0-2.719 1.235-2.719 2.752 0 1.517 1.22 2.752 2.72 2.752 1.499 0 2.719-1.235 2.719-2.752 0-1.517-1.22-2.752-2.72-2.752zm0-13.04h-9.162v3.33h7.015c.277 0 .502.228.502.508 0 .281-.225.509-.502.509H6.409a.035.035 0 00-.035.035l-.001.157a.414.414 0 01.3-.13h2.682c.23 0 .418.19.418.423a.42.42 0 01-.418.423H6.674a.414.414 0 01-.301-.13v1.222a.414.414 0 01.3-.13h2.682c.23 0 .418.19.418.424a.42.42 0 01-.418.423H6.674a.414.414 0 01-.301-.13l.001 2.916h.572c.277 0 .502.228.502.509 0 .28-.225.508-.502.508H5.335a.035.035 0 00-.035.035v2.89a.43.43 0 010 .167v.302c.185-.042.376-.064.572-.064 1.29 0 2.366.937 2.602 2.173h8.752a3.82 3.82 0 01-.044-.578c0-2.078 1.671-3.768 3.724-3.768.59 0 1.15.14 1.646.388v-1.545H
9.094a.505.505 0 01-.503-.509c0-.28.225-.508.503-.508h12.384v-5.503h-.572a.505.505 0 01-.502-.509c0-.28.225-.508.502-.508h.572V6.595a.576.576 0 00-.572-.578zM12.93 17.66c.231 0 .419.19.419.423a.42.42 0 01-.419.423H7.567a.42.42 0 01-.418-.423.42.42 0 01.418-.423zM28.368 7.103H23.59c-.611 0-1.108.504-1.108 1.122v7.642h3.957c.28 0 .518-.203.564-.483l.11-.674h-2.449c-.573 0-1.04-.471-1.04-1.051v-4.89c0-.281.226-.509.503-.509h4.05l.19-1.157zm-.358 2.174h-3.38v4.382c0 .019.016.035.035.035h2.617l.728-4.417zm-17.27-3.26h-7.2c-.44 0-.853.173-1.164.487L1.213 7.682l.9.911 1.256-1.27a.5.5 0 01.355-.15h7.015V6.017z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default BespokeMachine;
