import React from 'react';

const Minus = ({ className }) => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className}>
      <path d="M6 10.985h12v2.016h-12v-2.016z"></path>
    </svg>
  );
};

export default Minus;
