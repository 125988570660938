import { GlobalContext } from 'components/context/GlobalContext';
import React, { Fragment, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Chevron,
  ChevronBack,
  ContainerChevronBack,
  ContainerSubSideNav,
  TooltipText,
} from './SubSideNav.styles';
import ReactHtmlParser from 'react-html-parser';
import CloseButton from './CloseButton';

export default function SubSideNav(props) {
  const { open, parentLinks, getActiveSubLink, setGetActiveSubLink, setActiveTabIndex, goBackMenu } = props;
  const { globalDispatch, globalState } = useContext(GlobalContext);
  return (
    <ContainerSubSideNav open={open} getActiveSubLink={getActiveSubLink} parentLinks={parentLinks}>
      {!getActiveSubLink ? (
        <Fragment>
          {/* {open && (
            <CloseButton
              onClick={() => {
                globalDispatch({
                  type: 'CLOSE_SIDEBAR',
                });
                setActiveTabIndex(null);
              }}
            >
              X
            </CloseButton>
          )} */}
          <h1>
            <Link to={parentLinks?.url}>{ReactHtmlParser(parentLinks?.title)}</Link>
          </h1>
          <ul>
            {parentLinks?.childLinks?.map(item => {
              return (
                <li key={item.title}>
                  {item.isExternal ? (
                    <a
                      href={item.url}
                      target={item.target}
                      onClick={() => {
                        globalDispatch({
                          type: 'OPEN_SIDEBAR',
                        });
                      }}
                    >
                      {ReactHtmlParser(item.title)}
                    </a>
                  ) : (
                    <Link
                      to={item.url}
                      target={item.target}
                      onClick={() => {
                        globalDispatch({
                          type: 'OPEN_SIDEBAR',
                        });
                      }}
                    >
                      {ReactHtmlParser(item.title)}
                    </Link>
                  )}
                  {item.childSubLinks.length > 0 && (
                    <div
                      onClick={() => {
                        setGetActiveSubLink(item.title);
                      }}
                    >
                      <Chevron name="chevron" />
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </Fragment>
      ) : (
        <ContainerChevronBack
          onClick={() => {
            goBackMenu();
          }}
        >
          <ChevronBack name="chevron" />
          <TooltipText>Back</TooltipText>
        </ContainerChevronBack>
      )}
    </ContainerSubSideNav>
  );
}
