import React, { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {
  FormWrapper,
  FormInner,
  Label,
  Field,
  SubmitButton,
  Error,
  Row,
  Title,
} from 'components/form/Form.styles';
import Modal from 'components/_common/modal/Modal.js';
import ModalContent from 'components/_common/modal-content/index.js';
import { getSiteEndpoint } from 'helpers/api';
import Dropdown from 'components/_common/dropdown';
import { Auth } from 'components/context/AuthContext';
import ReactHtmlParser from 'react-html-parser';
const Form = ({
  header,
  comments,
  subject,
  ctaLink,
  emailAddress,
  firstname,
  lastname,
  mobilePhone,
  labels = {},
  modal,
  country,
  formType,
}) => {
  const { userToken, accounts } = useContext(Auth);
  const { modalBody, modalCtaLabel, modalHeader, modalIcon } = modal || {};

  const { src: iconSrc } = modalIcon || {};

  const { label: questionsLabel, requiredMessage: commentsRequiredMessage } = comments;
  const { title: submitButtonText } = ctaLink;

  const {
    label: emailLabel,
    errorMessage: emailValidationMessage,
    requiredMessage: emailRequiredMessage,
  } = emailAddress;

  const { label: firstNameLabel, requiredMessage: firstNameRequiredMessage } = firstname;
  const { label: lastNameLabel, requiredMessage: lastNameRequiredMessage } = lastname;
  const { label: mobilePhoneLabel, requiredMessage: mobilePhoneRequiredMessage } = mobilePhone;
  const { label: subjectLabel, requiredMessage: subjectRequiredMessage } = subject;
  const {
    label: countryLabel,
    requiredMessage: countryRequiredMessage,
    placeholder: countryplaceholder,
  } = country;

  const isRequiredAsterix = required => (required === 'yes' ? '*' : '');

  const tokenClaims = accounts.length > 0 ? accounts[0]['idTokenClaims'] : null;

  const schema = Yup.object().shape({
    firstName:
      firstname.isRequired === 'yes' ? Yup.string().required(firstNameRequiredMessage) : Yup.string(),
    lastName: lastname.isRequired === 'yes' ? Yup.string().required(lastNameRequiredMessage) : Yup.string(),
    mobilePhone:
      mobilePhone.isRequired === 'yes' ? Yup.number().required(mobilePhoneRequiredMessage) : Yup.number(),
    country: country.isRequired === 'yes' ? Yup.string().required(countryRequiredMessage) : Yup.string(),
    emailAddress:
      emailAddress.isRequired === 'yes'
        ? Yup.string()
            .email(emailValidationMessage)
            .required(emailRequiredMessage)
        : Yup.string().email(emailValidationMessage),
    subject: subject.isRequired === 'yes' ? Yup.string().required(subjectRequiredMessage) : Yup.string(),
    comments: comments.isRequired === 'yes' ? Yup.string().required(commentsRequiredMessage) : Yup.string(),
  });

  const [formSuccess, setFormSuccess] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [loadingForm, setLoadingForm] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [locationValues, setLocationValues] = useState([]);

  useEffect(() => {
    if (formValues) {
      setLoadingForm(true);
      axios({
        method: 'post',
        url: `${getSiteEndpoint()}/form/contact`,
        data: formValues,
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${userToken}` },
      })
        .then(function(response) {
          setFormSuccess(true);
          setLoadingForm(false);
          setModalOpen(true);
        })
        .catch(function(error) {
          setFormSuccess(false);
          setLoadingForm(false);
        });
    }
  }, [formType, formValues, userToken]);

  useEffect(() => {
    axios({
      method: 'get',
      url: `${getSiteEndpoint()}/CountryList`,
      headers: { Authorization: `Bearer ${userToken}` },
    })
      .then(function(response) {
        setLocationValues(response?.data.filter(item => item.country).map(item => item.country));
      })
      .catch(function(error) {});
  }, [userToken]);
  return (
    <FormWrapper>
      <Formik
        initialValues={{
          firstName: tokenClaims != null ? tokenClaims['given_name'] : '',
          lastName: tokenClaims != null ? tokenClaims['family_name'] : '',
          mobilePhone: '',
          emailAddress: tokenClaims != null ? tokenClaims['preferred_username'] : '',
          country: '',
          subject: '',
          comments: '',
        }}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting, setErrors, setStatus, resetForm }) => {
          const formData = new FormData();

          Object.keys(values).forEach(key => formData.append(key, values[key]));
          formData.append('formType', formType);
          setFormValues(formData);
          resetForm({});
          setSelectedLocation('');
        }}
      >
        {({ values, errors, touched, setFieldValue, handleChange }) => (
          <FormInner>
            <Title>{ReactHtmlParser(header)}</Title>
            <Row>
              <Label>
                {`${ReactHtmlParser(firstNameLabel)}${isRequiredAsterix(firstname.isRequired)}`}
                <Field name="firstName" minLength={2} />
                {errors?.firstName && touched?.firstName ? (
                  <Error>{ReactHtmlParser(errors?.firstName)}</Error>
                ) : null}
              </Label>

              <Label>
                {`${ReactHtmlParser(lastNameLabel)}${isRequiredAsterix(lastname.isRequired)}`}
                <Field name="lastName" minLength={2} />
                {errors?.lastName && touched?.lastName ? (
                  <Error>{ReactHtmlParser(errors?.lastName)}</Error>
                ) : null}
              </Label>
            </Row>
            <Label>
              {`${ReactHtmlParser(emailLabel)}${isRequiredAsterix(emailAddress.isRequired)}`}
              <Field name="emailAddress" />
              {errors?.emailAddress && touched?.emailAddress ? (
                <Error>{ReactHtmlParser(errors?.emailAddress)}</Error>
              ) : null}
            </Label>
            <Row>
              <Label>
                {`${ReactHtmlParser(countryLabel)}${isRequiredAsterix(country.isRequired)}`}
                <Dropdown
                  selectedValue={selectedLocation}
                  handleChange={e => {
                    const {
                      target: { value },
                    } = e;
                    setSelectedLocation(value);
                    setFieldValue('country', value);
                  }}
                  selectOptions={locationValues}
                  placeholder={`${ReactHtmlParser(countryplaceholder)}`}
                />
                {errors?.country && touched?.country ? (
                  <Error>{ReactHtmlParser(errors?.country)}</Error>
                ) : null}
              </Label>
              <Label>
                {`${ReactHtmlParser(mobilePhoneLabel)}${isRequiredAsterix(mobilePhone.isRequired)}`}
                <Field name="mobilePhone" maxLength={16} />
                {errors?.mobilePhone && touched?.mobilePhone ? (
                  <Error>{ReactHtmlParser(errors?.mobilePhone)}</Error>
                ) : null}
              </Label>
            </Row>
            <Label>
              {`${ReactHtmlParser(subjectLabel)}${isRequiredAsterix(subject.isRequired)}`}
              <Field name="subject" minLength={2} />
              {errors?.subject && touched?.subject ? <Error>{ReactHtmlParser(errors?.subject)}</Error> : null}
            </Label>
            <Label>
              {`${ReactHtmlParser(questionsLabel)}${isRequiredAsterix(comments.isRequired)}`}
              <Field name="comments" component="textarea" textarea />
              {errors?.comments && touched?.comments ? (
                <Error>{ReactHtmlParser(errors?.comments)}</Error>
              ) : null}
            </Label>

            <SubmitButton
              type="submit"
              linkText={submitButtonText ? ReactHtmlParser(submitButtonText) : 'Submit'}
              loading={loadingForm}
              loadingText={labels?.loading_label || 'loading...'}
              dark
            />
          </FormInner>
        )}
      </Formik>
      {modalOpen ? (
        <Modal onClose={() => setModalOpen(false)}>
          <ModalContent
            icon={iconSrc}
            title={ReactHtmlParser(modalHeader)}
            buttonText={ReactHtmlParser(modalCtaLabel)}
            buttonAction={() => setModalOpen(false)}
          >
            {ReactHtmlParser(modalBody)}
          </ModalContent>
        </Modal>
      ) : null}
    </FormWrapper>
  );
};

export default Form;
