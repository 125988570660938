import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'components/context/AuthContext';

import { RichTextComponent, RichTextContent } from './RichText.styles.js';
import ReactHtmlParser from 'react-html-parser';

import axios from 'axios';
import { getSiteEndpoint } from 'helpers/api';

var fileDownload = require('js-file-download');

const RichText = ({ value, align }) => {
  
  const { userToken } = useContext(Auth);
  
  const downloadLocalAsset = e => {
    e.preventDefault();

      var file = e.target.href.split(new RegExp('local-assets(.*?)/')).pop();
      if(file)
      {
        axios
       .get(`${getSiteEndpoint()}/Files/key/${file}`, {
         headers: {
           Authorization: `Bearer ${userToken}`,
         },
         //responseType: 'blob',
       })
       .then(function(response) {
        window.open(`${getSiteEndpoint()}/Files/View/${response.data}`);
        //  //fileDownload(response.data, `${file}`);
        //  var contentType =  response.headers["content-type"];
      

        //  var blob = new Blob([response.data], { type: contentType });
        //  blob.title = file;
        //  blob.name = file;
        //  var url = window.URL.createObjectURL(blob, { oneTimeOnly: true, title: file });
    
        //  window.open(url, "_blank");
       })
       .catch(function(error) {
         console.log(error);
       });
      }
  };

  const unescapeHTML = html => {
    var escapeEl = document.createElement('div');
    escapeEl.innerHTML = html;
    return escapeEl.textContent;
  };

  useEffect(() => {
    const getElementbyClass = Array.from(document.getElementsByClassName('download-link'));
    getElementbyClass.forEach(el => {
      var a = el.querySelector('a');
      if (a) {
        if(a.href.indexOf('local-assets') != -1){
          a.onclick = downloadLocalAsset;
        }
        else{
          a.setAttribute('download', '');
        }
      }
    });
  }, []);
  return (
    <RichTextComponent align={align}>
      <RichTextContent>{ReactHtmlParser(unescapeHTML(value))}</RichTextContent>
    </RichTextComponent>
  );
};

RichText.propTypes = {};

export default RichText;
