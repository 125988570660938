import React from 'react';
import PropTypes from 'prop-types';
import {
  ModalContentComponent,
  ModalHeader,
  Line,
  Icon,
  Heading,
  Body,
  ButtonCTA,
} from './ModalContent.styles.js';
import ReactHtmlParser from 'react-html-parser';

const ModalContent = ({ icon, title, children, buttonText, buttonAction = () => {}, brand }) => {
  return (
    <ModalContentComponent>
      <ModalHeader>
        <Line></Line>
        <Icon src={icon} />
        <Line></Line>
      </ModalHeader>
      <Heading>{ReactHtmlParser(title)}</Heading>
      {children ? <Body>{children}</Body> : null}
      {buttonText ? (
        <ButtonCTA onClick={buttonAction} dark brand={brand}>
          {ReactHtmlParser(buttonText)}
        </ButtonCTA>
      ) : null}
    </ModalContentComponent>
  );
};

ModalContent.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  buttonText: PropTypes.string,
  buttonAction: PropTypes.func,
};

export default ModalContent;
