import React from 'react';

const MerchandiseStore = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M17.756.312a.478.478 0 01.612-.283l3.633 1.289a.478.478 0 01.158.095l5.022 4.432a.472.472 0 010 .688L23.914 9.81l2.871 1.093c.212.081.353.285.353.512v13.69c0 .227-.14.43-.353.512l-11.5 4.38a.548.548 0 01-.39 0l-11.499-4.38a.548.548 0 01-.352-.512v-13.69c0-.227.14-.43.352-.512L6.373 9.77 3.149 6.533a.471.471 0 01-.01-.009l-.008-.009a.472.472 0 01.018-.67l4.988-4.418a.478.478 0 01.158-.095L11.923.03a.476.476 0 01.635.422c.005.104.158 2.507 2.592 2.545C17.57 2.958 17.728.555 17.728.45a.471.471 0 01.028-.138zM4.14 12.21v12.518l10.404 3.964V16.174L4.139 12.21zm21.903 0l-10.404 3.964v12.517l10.405-3.963-.001-12.518zM4.725 22.829a.548.548 0 01.71-.308l5.466 2.163a.548.548 0 01-.403 1.019L5.033 23.54a.548.548 0 01-.308-.711zm12.63-4.368l1.26.376-.313 1.05-.209-.063-.673 2.268 4.607 1.37.674-2.265-.463-.139.315-1.049 1.509.453-1.298 4.361-6.706-1.993 1.298-4.369zm-7.59.092l1.39 1.94a.548.548 0 01-.89.639l-.103-.145v1.38a.548.548 0 01-.458.541l-.09.008a.548.548 0 01-.547-.548v-2.11l-.257.157a.548.548 0 01-.694-.103l-.059-.08a.548.548 0 01.184-.752l1.523-.927zm-3.28-1.296l1.39 1.939a.548.548 0 01-.89.638l-.109-.151v1.59a.548.548 0 01-.458.54l-.089.007a.548.548 0 01-.547-.547L5.78 18.96l-.258.157a.548.548 0 01-.693-.105l-.059-.08a.548.548 0 01.185-.752l1.529-.924zm17.796-2.632l1.317 3.862-5.876 2.014-1.318-3.862 5.877-2.014zm-.682 1.391l-3.805 1.304.61 1.79 3.805-1.305-.61-1.789zM18.607 1.11a3.506 3.506 0 01-2.784 2.765c-1.907.367-3.754-.87-4.124-2.765L8.71 2.181 4.165 6.22l2.936 2.953V6.969c0-.262.214-.474.478-.474s.477.212.477.474v5.035l7.035 2.594 7.149-2.594V6.969c0-.262.214-.474.477-.474.264 0 .478.212.478.474v2.2l2.93-2.95-4.53-4.038zM7.12 10.658l-1.99.758 1.99.758v-1.516zm16.088.056v1.404l1.843-.702-1.843-.702z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default MerchandiseStore;
