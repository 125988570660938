import { Auth } from 'components/context/AuthContext';
import { DeliveryStatus } from 'components/_icons/Icon.stories.js';
import { logoutApi } from 'helpers/utilities.js';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { NavIcon } from '../../global-nav/GlobalNav.styles.js';

export default function HoverStyle({ links, MyProfile }) {
  const { handleLogout, editProfile, editPassword, editPhoneNumber, userToken } = useContext(Auth);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = e => {
    if (window.screen.width < 1200) {
      if (!isOpen) {
        setIsOpen(true);
        document.getElementById('hoverStylesDiv').style.display = 'block';
      } else {
        setIsOpen(false);
        document.getElementById('hoverStylesDiv').style.display = 'none';
      }
    }
  };

  const handleLogoutClick = async () => {
    await logoutApi(userToken);
    handleLogout();
  };
  return (
    <div id="hoverStyle">
      {userToken && (
        <HoverStyleWrapper>
          <span onClick={handleClick}>{ReactHtmlParser(MyProfile)}</span>

          <div id="hoverStylesDiv" style={{ display: 'none' }}>
            <ul>
              {links?.map(item => {
                if (item.url === '#b2c-log-out') {
                  return (
                    <li>
                      <Link to={item.url} onClick={handleLogoutClick} target={item.target} alt={ReactHtmlParser(item.alt)}>
                        {ReactHtmlParser(item.title)}
                      </Link>
                      <NavIcon name={item.icon} />
                    </li>
                  );
                } else if (item.url === '#b2c-edit-profile') {
                  return (
                    <li>
                      <a
                        href={item.url}
                        target={item.target}
                        alt={ReactHtmlParser(item.alt)}
                        onClick={e => {
                          e.preventDefault();
                          editProfile();
                        }}
                      >
                        {ReactHtmlParser(item.title)}
                      </a>
                      <NavIcon name={item.icon} />
                    </li>
                  );
                } else if (item.url === '#b2c-edit_profile_edit_phone') {
                  return (
                    <li>
                      <a
                        href={item.url}
                        target={item.target}
                        alt={ReactHtmlParser(item.alt)}
                        onClick={e => {
                          e.preventDefault();
                          editPhoneNumber();
                        }}
                      >
                        {ReactHtmlParser(item.title)}
                      </a>
                      <NavIcon name={item.icon} />
                    </li>
                  );
                } else if (item.url === '#b2c-edit_password') {
                  return (
                    <li>
                      <a
                        href={item.url}
                        target={item.target}
                        alt={ReactHtmlParser(item.alt)}
                        onClick={e => {
                          e.preventDefault();
                          editPassword();
                        }}
                      >
                        {ReactHtmlParser(item.title)}
                      </a>
                      <NavIcon name={item.icon} />
                    </li>
                  );
                } else if (item.isExternal) {
                  return (
                    <li>
                      <a href={item.url} target={item.target} alt={ReactHtmlParser(item.alt)}>
                        {ReactHtmlParser(item.title)}
                      </a>
                      <NavIcon name={item.icon} />
                    </li>
                  );
                } else {
                  return (
                    <li>
                      <Link to={item.url} target={item.target} alt={ReactHtmlParser(item.alt)}>
                        {ReactHtmlParser(item.title)}
                      </Link>
                      <NavIcon name={item.icon} />
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </HoverStyleWrapper>
      )}
    </div>
  );
}

const HoverStyleWrapper = styled.div`
${({
  theme: {
    breakpoints: { tablet, tabletLarge, mobileLarge, mobileMax },
  },
}) => `
    position: relative;
    z-index: 1;
    padding: 12px;
    
    &: hover {
      background-color: #f2f2f2;
    }
    
    span {
      color: #333;
      text-transform: capitalize;
      font-size: 16px;
      // line-height: 26px;
      font-weight: bold;
      cursor: pointer;
    }

    @media (max-width: ${mobileMax}px){
      font-size: 14px;
      padding: 5px;
    }

    > div {
      background-color: #fff;
      position: absolute;
      top: 50px;
      right: 0;
      box-shadow: 0 0 10px #00000029;
      ul {
        padding: 0;
        margin: 0;
        li {
          a {
            white-space: nowrap;
          }
        }
      }

      @media (min-width: 1200px) {
        display: none !important;
      }
    }

    &:hover > div {
      @media (min-width: 1200px) {
        display: block !important;
      }
    }

    &:hover > div::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 30px;
      top: -30px;
    }

    > div ul li {
      list-style-type: none;

      a {
        display: block;
        padding: 15px 10px;
        text-decoration: none;
        color: rgb(106, 106, 106);
        font-size: 1.5rem;
        position: relative;
        letter-spacing: 1px;
        transition: all 0.2s;

        :hover {
          background-color: rgb(228, 225, 225);
          text-decoration: none;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid #ddd;
      }
    }
  `}
`;
