import React from 'react';

const ExitToApp = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <g>
        <path
          id="b"
          d="M8.408 12.992l1.175 1.175L13.75 10 9.583 5.833 8.408 7.008l2.15 2.159H2.5v1.666h8.058l-2.15 2.159zM15.833 2.5H4.167c-.925 0-1.667.75-1.667 1.667V7.5h1.667V4.167h11.666v11.666H4.167V12.5H2.5v3.333c0 .917.742 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V4.167c0-.917-.75-1.667-1.667-1.667z"
        />
      </g>
    </svg>
  );
};

export default ExitToApp;
