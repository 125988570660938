import React from 'react';

const LockSheild = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M16.005.248l.028.038c.49.665 2.465 1.846 4.852 2.882l.077.033.152.065c2.457 1.045 4.824 1.722 6.218 1.735h.062c.337.011.606.287.606.626V18.13c0 1.245-.678 2.594-1.911 4.049-1.012 1.194-2.386 2.442-4.023 3.703l-.146.112c-2.407 1.834-5.61 3.812-6.259 3.985a.625.625 0 01-.322 0l-.022-.006c-.3-.091-1.186-.559-2.195-1.16l-.214-.128A41.35 41.35 0 018.93 25.96c-1.644-1.28-3.021-2.585-4.036-3.885C3.671 20.506 3 18.978 3 17.505V5.627c0-.34.27-.615.606-.625h.059c1.306-.015 3.748-.729 6.28-1.803l.15-.065.147-.063c2.36-1.025 4.271-2.152 4.74-2.786l.027-.037c.25-.33.747-.33.996 0zm-.503 1.283l-.063.054C13.32 3.37 7.335 5.882 4.25 6.215v11.29c0 2.201 2.04 4.815 5.45 7.47a40.112 40.112 0 003.854 2.64l.133.08c.699.418 1.372.785 1.727.964l.076.037.053-.026c.08-.042.176-.092.282-.15l.118-.065.093-.053c.365-.205.801-.463 1.269-.753l.137-.085a48.651 48.651 0 003.862-2.673c3.392-2.612 5.446-5.036 5.446-6.76V6.218c-3.204-.316-9.082-2.813-11.185-4.633l-.063-.055zm.24 4.969c2.386 0 3.536 1.847 3.478 5.276h.209a1.5 1.5 0 011.5 1.5v6.23a1.5 1.5 0 01-1.5 1.5H12a1.5 1.5 0 01-1.5-1.5v-6.23a1.5 1.5 0 011.5-1.5h.293c-.08-3.428 1.06-5.276 3.448-5.276zm3.687 6.277H12a.5.5 0 00-.5.5v6.229a.5.5 0 00.5.5h7.429a.5.5 0 00.5-.5v-6.23a.5.5 0 00-.5-.5zm-4.426-9.405a.625.625 0 011.006.741c-.976 1.375-6.435 3.644-9.259 3.974v8.793a.625.625 0 11-1.25 0V7.502c0-.345.28-.625.625-.625h.063c.996-.02 2.815-.512 4.7-1.252l.124-.049.12-.048c1.945-.783 3.523-1.667 3.859-2.14zm.752 11.797a.5.5 0 01.5.5v1a.5.5 0 11-1 0v-1a.5.5 0 01.5-.5zm-1.077-1.976a.5.5 0 010 1H13.42a.5.5 0 00-.5.5v1.346a.5.5 0 11-1 0v-1.346a1.5 1.5 0 011.5-1.5zM15.741 7.5c-1.703 0-2.529 1.336-2.449 4.275h4.928c.06-2.938-.775-4.275-2.479-4.275z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default LockSheild;
