import styled from 'styled-components';
import Link from 'components/_common/Link';

export const FullWidthCTAComponent = styled.section`
  ${({
    theme: {
      colours: { lighterGrey },
      breakpoints: { tablet },
    },
  }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${lighterGrey};
    padding: 3rem 6.25rem;

    @media (min-width: ${tablet}px) {
      flex-direction: row;
      padding: 6.35rem 18.6rem 5rem;
    }
  `}
`;

export const Text = styled.p`
  ${({
    theme: {
      fonts: { NotoSansBold },
      colours: { darkGrey },
      breakpoints: { tablet },
    },
  }) => `
    font-family: ${NotoSansBold.value};
    font-weight: ${NotoSansBold.fontWeight};
    font-style: ${NotoSansBold.fontStyle};
    color: ${darkGrey};
    font-size: 1.2rem;
    line-height: 2.4rem;
    margin: 0 0 1rem 0;
    text-align: center;

    @media (min-width: ${tablet}px) {
      font-size: 2.4rem;
      line-height: 3.3rem;
      margin: 0 3rem 0 0;
      text-align: left;
    }
  `}
`;
export const LinkCTA = styled(Link)`
  margin-bottom: 0;
  max-width: 18.1rem;
  width: 100%;
  font-size: 1.4rem;
  padding: 1.5rem 4rem;
`;
