import React from 'react';

const DistributionInfo = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M28.588 8.88A14.993 14.993 0 014.31 25.57l-.97-.97v2.62H1.978v-4.77h4.77v1.363H4.483l.79.79a13.63 13.63 0 0017.031 1.74l.286-.19A13.63 13.63 0 0027.34 9.428zM14.896 5.029l9.296 3.541c.171.066.285.23.285.414V20.05a.443.443 0 01-.285.414l-9.296 3.541a.443.443 0 01-.315 0l-9.296-3.541A.443.443 0 015 20.05V8.984c0-.184.113-.348.285-.414l9.296-3.541a.443.443 0 01.315 0zM5.885 9.626v10.12l8.41 3.202V12.829l-8.41-3.203zm17.706 0l-8.41 3.203v10.119l8.41-3.203V9.626zM6.499 2.639a14.994 14.994 0 0119.05 1.706l.963.962v-2.62h1.363v4.77h-4.77V6.094h2.266l-.783-.783a13.63 13.63 0 00-17.03-1.74l-.286.191A13.63 13.63 0 002.52 20.486l-1.247.549A14.993 14.993 0 016.499 2.639zm-.14 15.57a.443.443 0 01.575-.248l4.417 1.748a.443.443 0 11-.326.824l-4.417-1.749a.443.443 0 01-.249-.574zm10.21-3.53l1.018.304-.253.848-.168-.05-.545 1.833 3.724 1.107.545-1.83-.374-.113.255-.848 1.22.366-1.05 3.526-5.42-1.612 1.048-3.531zm-6.136.074l1.124 1.569a.443.443 0 01-.72.516l-.083-.117v1.116a.443.443 0 01-.363.436l-.08.007a.443.443 0 01-.442-.443v-1.706l-.208.128a.443.443 0 01-.554-.077l-.054-.072a.443.443 0 01.148-.608l1.232-.749zm-2.651-1.048l1.123 1.568a.443.443 0 11-.72.516l-.087-.122v1.285a.443.443 0 01-.363.435l-.08.007a.443.443 0 01-.442-.442v-1.869l-.209.127a.443.443 0 01-.554-.078l-.054-.072a.443.443 0 01.15-.607l1.236-.748zm14.386-2.127l1.064 3.122-4.75 1.628-1.065-3.122 4.75-1.628zm-.552 1.125l-3.076 1.054.494 1.446 3.075-1.054-.493-1.446zM9.964 7.734L6.685 8.983l8.053 3.068 3.279-1.249-8.053-3.068zm4.774-1.818l-3.531 1.345 8.053 3.067 3.531-1.345-8.053-3.067z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default DistributionInfo;
