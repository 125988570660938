import React from 'react';

const HandBook = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M15.586 4.381a3.6 3.6 0 013.225 0l9.706 4.853a1.8 1.8 0 01.002 3.222l.078-.045v4.063l.332-.165a.6.6 0 01.754.183l.051.084a.6.6 0 01-.267.805L15.6 24.336c-.505.25-1.098.25-1.606-.003l-2.195-1.112v2.515a.6.6 0 01-1.057.389L9.16 24.263l-1.468.23A.6.6 0 017 23.9l-.001-3.085-4.342-2.168a3 3 0 01-1.65-2.485l-.007-.2v-2.434a3 3 0 011.658-2.685zm-7.387 14.5v4.318l1.107-.173a.6.6 0 01.55.204l.743.874v-4L8.2 18.88zM18.274 5.453a2.4 2.4 0 00-2.151 0L3.194 11.917a1.8 1.8 0 00-.994 1.611v2.436a1.8 1.8 0 00.994 1.611l3.805 1.9v-1.573a.6.6 0 01.873-.535l3.6 1.836a.6.6 0 01.327.535v2.139l2.733 1.385a.6.6 0 00.429.04l.103-.039 12.333-6.186v-4.059l-11.814 5.921a1.8 1.8 0 01-1.402.074l-.167-.071L4 14.195v1.912a.6.6 0 11-1.2 0v-1.904a1.2 1.2 0 011.715-1.092l10.018 4.748c.166.08.36.08.519.003l12.93-6.48a.6.6 0 000-1.074zM15.472 9.76a.6.6 0 01.814-.24l1.184.646a.602.602 0 01.265.267l-.045-.076a.598.598 0 01.017.026l.028.05a.6.6 0 01-.01.555l.025-.05a.603.603 0 01-.283.3l.075-.045a.6.6 0 01-.096.055l-3.756 1.878.187.097a.6.6 0 01.292.72l-.038.09a.6.6 0 01-.81.254l-2.4-1.254a.6.6 0 11.556-1.064l.901.471 3.524-1.762-.19-.104a.6.6 0 01-.28-.724zm4.126-1.458a.6.6 0 010 1.2h-1.2a.6.6 0 010-1.2z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default HandBook;
