import styled from 'styled-components';

export const ContactCardsComponent = styled.section`
  ${({
    theme: {
      breakpoints: { tabletLarge },
    },
  }) => `
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 122.8rem;
    margin: 0 auto;
    padding: 0 2rem 3rem;

    @media(min-width: ${tabletLarge}px) {
      flex-direction: row;
      padding: 0 8.1rem 5rem;
    }
  `}
`;
