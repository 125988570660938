import styled from 'styled-components';

export const FileDownloadListComponent = styled.section`
  /* component */
`;
export const FileDownloadLists = styled.div`
  margin: 0 auto;
`;
export const FilesComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.h2`
  ${({
    theme: {
      fonts: { NotoSansBold },
      colours: { darkGrey },
      breakpoints: { tablet },
    },
  }) => `
    font-family: ${NotoSansBold.value};
    font-weight: ${NotoSansBold.fontWeight};
    font-style: ${NotoSansBold.fontStyle};
    font-size: 2.4rem;
    color: ${darkGrey};
    padding: 2.5rem 1rem 1rem;
    @media (min-width: ${tablet}px) {
      padding: 5rem 2rem 2rem;
    }
  `}
`;
