import React from 'react';
import PropTypes from 'prop-types';
import { EventsListWrapper } from './EventList.styles.js';
import Event from 'components/event';
const EventList = ({ events }) => {
  return (
    <EventsListWrapper>
      {events?.map((event, index) => (
        <Event key={index} {...event} />
      ))}
    </EventsListWrapper>
  );
};
EventList.propTypes = {
  events: PropTypes.array,
};
export default EventList;
