import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import axios from 'axios';
import { getSiteEndpoint } from 'helpers/api';
import { Auth } from 'components/context/AuthContext';
import LoadingSpinner from 'components/_common/loading-spinner';


import {
     Container,
     HeaderComponent,
     TableWrapper, Header, TableContent, Row, Column
} from './view-profile.styles';


const ViewProfile = ({ header, align, fields, brand, langFolder, pageID }) => {
    
    const [loading, setLoading] = useState(true);
    const { userToken } = useContext(Auth);
    const [activeContact, setActiveContact] = useState([]);

        
    const getActiveContact = (setActiveContact, token) => {
        return new Promise((resolve, reject) => {
            axios.get(`${getSiteEndpoint()}/user/ActiveContact`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(response => {
                if (response.status === 200 && response?.data) {

                    let arr = [];
                    arr.push(response?.data)

                    if(setActiveContact) { setActiveContact(arr); }
                    resolve(arr);
                    setLoading(false);
                }
            })
            .catch(error => {
                if (error) { console.log(error); }
                setLoading(false);
            });
        });
    };

    useEffect(() => {
        getActiveContact(setActiveContact, userToken);
    }, [setActiveContact, userToken]);
    

    Object.byString = function(o, s) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}
    return (
        <Container>
            <>
                {loading ? <LoadingSpinner /> : <HeaderComponent align={align}>{ReactHtmlParser(header)}</HeaderComponent>}
            </>
            <>
            {activeContact.map(item => {
                return (
                    <TableWrapper>
                        <TableContent>
                            {fields.map(field => {
                                return (
                                    <Row>
                                        <Column paleBackground boldText>{field.value}</Column>
                                        <Column lightText>{ReactHtmlParser(Object.byString(item, field.key))}</Column>
                                    </Row>
                                )
                            }
                            )}
                        </TableContent>
                    </TableWrapper>
                )})
            }
            </>
        </Container>
    );
};

export default ViewProfile;
