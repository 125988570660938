import React from 'react';

const ProductMarketing = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M16.087 9.044v.618c0 1.89-1.04 3.094-3.093 3.094H9.9v15.469H8.662V11.519h4.332c1.083 0 1.635-.41 1.801-1.238l-11.21.001c-1.789.031-2.348.738-2.348 3.093v4.331c0 1.202.43 1.856 1.238 1.856v-6.187h1.237v14.85H2.475V20.8C.849 20.8 0 19.51 0 17.706v-4.331c0-3.054 1.092-4.331 3.712-4.331h12.375zm-9.28 9.28v9.9H5.568v-9.9h1.237zM29.7 2.238v22.894H11.137V13.994h1.238v9.9h16.087V3.473H12.375v4.332h-1.238V2.237H29.7zm-9.281 3.164l4.21 6.736h-3.592v1.237h3.713l-.001 2.475h2.476v6.188h-6.188V15.85l2.475-.001v-1.237h-6.187v1.3a3.095 3.095 0 11-1.238 0v-2.537l3.712-.001v-1.237h-3.59l4.21-6.736zm-3.713 11.686a1.856 1.856 0 100 3.713 1.856 1.856 0 000-3.713zm9.281 0h-3.712V20.8h3.712v-3.713zM20.42 7.736L18.44 10.9h3.955l-1.977-3.164zM6.202 1a3.156 3.156 0 013.08 3.273l-.001.85a3.156 3.156 0 01-.845 2.299 3.17 3.17 0 01-2.263 1.003 3.156 3.156 0 01-3.08-3.273l.001-.85A3.156 3.156 0 016.173 1h.029zm-.015 1.238a1.918 1.918 0 00-1.856 2.035v.907c-.025.516.16 1.02.513 1.398.352.378.842.597 1.329.61a1.918 1.918 0 001.87-2.036l.001-.908a1.918 1.918 0 00-1.857-2.006z"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default ProductMarketing;
