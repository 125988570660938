import React from 'react';

const Marketing = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M24.286 3.571c.789 0 1.428.64 1.428 1.429v16.429h-1.428V5H1.429l-.001 4.285h21.43v1.43l-21.43-.001V30H0V5c0-.789.64-1.429 1.429-1.429zm-2.542 9.85v4.699h-1.342v-3.356H5.637v11.41h14.765v-2.685h1.342v4.026H4.294V13.422h17.45zM10.691 16.88l5.369 3.356c.42.263.42.875 0 1.138l-5.37 3.356a.671.671 0 01-1.026-.57v-6.71c0-.528.58-.85 1.027-.57zm15.023 5.978v1.429h-1.428v-1.429h1.428zM11.006 18.66v4.29l3.432-2.145-3.432-2.145zM29.286 20v1.429h-1.429V20h1.429zm0-2.857v1.428h-1.429v-1.428h1.429zM27.857 0c.79 0 1.429.64 1.429 1.429v14.285h-1.429V1.43H5v1.428H3.571V1.43C3.571.639 4.211 0 5 0zM4.286 6.429v1.428H2.857V6.43h1.429zm2.857 0v1.428H5.714V6.43h1.429zm2.857 0v1.428H8.571V6.43H10z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Marketing;
