import React from 'react';

const BespokeTraining = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M11.925 4.48l1.22 1.132-11.864.149v2.562h11.864l-.424 1.282H1.281v18.581h19.222v-14.35l1.282.094v15.538H0V4.479h11.925zm7.297 8.97v13.455H2.562V13.449h16.66zm-1.282 1.28H3.844v10.893H17.94V14.731zM8.67 16.75l5.126 3.204a.64.64 0 010 1.087l-5.126 3.203a.64.64 0 01-.98-.543v-6.407a.64.64 0 01.98-.544zm.301 1.7v4.095l3.276-2.048L8.97 18.45zm14.86-5.95c-.462 1.376-3.103 1.378-6.031.405-2.938-.977-5.05-2.556-4.59-3.936l1.073-3.251-2.999-2.205a1.375 1.375 0 01-.413-1.61l.062-.122c.229-.45.684-.742 1.213-.761h9.098l.135-.015c.405-.03.817.073 1.206.317l6.794 5.133a1.62 1.62 0 01.446 2.011l-.447.883H27.7V8.068h.888l.092-.182a.337.337 0 00-.084-.415l-6.738-5.093a.64.64 0 00-.452-.087l-.113.01h-9.126c-.04.001-.075.024-.108.088-.024.041-.014.093.003.107l7.382 5.432c.164.099.353.147.568.14l4.675-.001-4.072-2.96.754-1.037 4.845 3.522.048.055c.572.659.859 1.519.798 2.343v5.126h-1.282l.002-5.172a1.998 1.998 0 00-.046-.595h-.859zm-8.462-5.984l-.943 2.856c-.116.347 1.51 1.563 3.778 2.317 2.261.752 4.296.75 4.412.406l.911-2.746H19.89a2.266 2.266 0 01-.964-.25l-.19-.105-3.368-2.478zm-3.835 4.37v1.282h-8.97v-1.281h8.97zM3.844 6.402v1.282H2.563V6.4h1.281zm2.563 0v1.282H5.126V6.4h1.281z"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default BespokeTraining;
