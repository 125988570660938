import React from 'react';

const LinkedIn = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 30 30"
      className={className}
    >
      <g fillRule="evenodd">
        <path
          d="M7.491 30H1.48V10.639H7.49V30zM4.481 7.998C2.56 7.998 1 6.405 1 4.483A3.483 3.483 0 014.482 1a3.483 3.483 0 013.482 3.483c0 1.922-1.56 3.515-3.482 3.515zM29.995 30h-6v-9.425c0-2.246-.045-5.127-3.126-5.127-3.126 0-3.604 2.44-3.604 4.965V30h-6.006V10.639h5.766v2.64h.084c.803-1.52 2.764-3.126 5.689-3.126 6.083 0 7.203 4.007 7.203 9.212V30h-.006z"
          id="a"
        />
      </g>
    </svg>
  );
};

export default LinkedIn;
