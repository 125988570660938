import styled, { css } from 'styled-components';

export const HeaderTitle = styled.h1`
  ${({
    theme: {
      fonts: { NotoSansBold },
      breakpoints: { tabletLarge },
      colours: { darkGrey, offWhite },
    },
    align,
  }) => css`
    color: ${darkGrey};
    font-family: ${NotoSansBold.value};
    font-style: ${NotoSansBold.fontStyle};
    font-weight: ${NotoSansBold.fontWeight};
    font-size: 2.4rem;
    line-height: 2.75rem;
    padding: 40px;
    margin: 0;
    width: 100%;
    background-color: ${offWhite};
    margin-bottom: 0;
    text-align: ${align ? align : 'center'};
    letter-spacing: -1.08px;
    @media (min-width: ${tabletLarge}px) {
      padding: 60px 50px;
      font-size: 3.3rem;
      //line-height: 1.4375rem;
    }
  `}
`;
