import React from 'react';
import PropTypes from 'prop-types';
import { ImageComponent } from './Image.styles.js';
import theme from 'theme.js';

const Image = ({ mobile = {}, tablet = {}, desktop = {}, className, ...props }) => {
  return (
    <ImageComponent>
      {mobile ? (
        <source
          srcSet={mobile.src}
          alt={mobile.alt}
          media={`(max-width: ${theme.breakpoints.tablet - 1}px)`}
        />
      ) : null}
      {tablet ? (
        <source
          srcSet={mobile.src}
          alt={mobile.alt}
          media={`(max-width: ${theme.breakpoints.tabletLarge - 1}px)`}
        />
      ) : null}
      <img src={desktop.src} alt={desktop.alt} className={className} />
    </ImageComponent>
  );
};

Image.propTypes = {
  mobile: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }),
  tablet: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }),
  desktop: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }),
};

export default Image;
