import React from 'react';

const Twitter = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 30 30"
      className={className}
    >
      <g fillRule="evenodd">
        <path
          d="M26.916 9.23c.02.274.02.547.02.82 0 8.34-6.187 17.95-17.494 17.95-3.484 0-6.72-1.035-9.442-2.832.495.059.97.078 1.485.078 2.874 0 5.52-.996 7.633-2.695-2.703-.059-4.968-1.875-5.749-4.375.381.058.762.097 1.161.097.553 0 1.105-.078 1.619-.214-2.818-.586-4.93-3.125-4.93-6.192v-.078c.818.469 1.77.762 2.778.8a6.348 6.348 0 01-2.74-5.253c0-1.172.304-2.246.837-3.184C5.12 7.98 9.67 10.48 14.772 10.754a7.3 7.3 0 01-.153-1.445C14.62 5.832 17.36 3 20.768 3c1.77 0 3.369.762 4.492 1.992a11.92 11.92 0 003.902-1.523 6.265 6.265 0 01-2.703 3.476A12.08 12.08 0 0030 5.97a13.41 13.41 0 01-3.084 3.261z"
          id="a"
        />
      </g>
    </svg>
  );
};

export default Twitter;
