import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import axios from 'axios';
import { getSiteEndpoint } from 'helpers/api';
import { Auth } from 'components/context/AuthContext';
import Tiles from 'components/tiles/Tiles.js';
import LoadingSpinner from 'components/_common/loading-spinner';

import {
     ContainerFavoriteTiles,
     HeaderComponent,
     NoTiles
} from './favorite-tiles.styles';


const FavoriteTiles = ({ header, align, brand, langFolder, pageID }) => {
    
    const [loading, setLoading] = useState(true);
    const { userToken } = useContext(Auth);
    const [tiles, setTiles] = useState([]);

        
    const getFavoriteTiles = (setFavoriteTiles, token) => {
        return new Promise((resolve, reject) => {
            axios.get(`${getSiteEndpoint()}/user/GetTiles`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(response => {
                if (response.status === 200 && response?.data) {
                    var tiles = response.data.map(o=> {
                        let obj = JSON.parse(o.tileData);
                        obj.favoriteId = o.id;
                        return obj;
                    });
                    if(setFavoriteTiles) { setFavoriteTiles(tiles); }
                    resolve(tiles);
                    setLoading(false);
                }
            })
            .catch(error => {
                if (error) { console.log(error); }
                setLoading(false);
            });
        });
    };

    useEffect(() => {
        getFavoriteTiles(setTiles, userToken);
    }, [setTiles, userToken]);

    return (
        <> 
            <ContainerFavoriteTiles>
                
                {tiles && tiles.length > 0?
                    <Tiles {...{ tiles: tiles }} brand={brand} langFolder={langFolder} pageID={pageID}></Tiles>
                :
                <>
                    {loading ? <LoadingSpinner /> : <HeaderComponent align={align}>{ReactHtmlParser(header)}</HeaderComponent>}
                </>
                }
            </ContainerFavoriteTiles>
            
        </>
    );
};

export default FavoriteTiles;
