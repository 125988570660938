import React from 'react';
import {
  SelectWrapper,
  SelectMultiple,
  Select, 
  Option,
  Chevron,
  ChevronWrapper,
} from 'components/_common/dropdown/Dropdown.styles';
import ReactHtmlParser from 'react-html-parser';

const Dropdown = ({ selectedValue, handleChange, selectOptions, placeholder, ismultiple, name }) => (
  ismultiple?
  <SelectWrapper>
    <SelectMultiple>
      <div className="placeholder-option">
        {ReactHtmlParser(placeholder)}
      </div>

      {selectOptions?.map((item, index) => {
        const { value, key } = item;
        return (
          <label for={`${name}_${index}`}>
            {ReactHtmlParser(value)}

            <input type="checkbox" name={name} value={key} id={`${name}_${index}`} onChange={handleChange} />
            <span class="checkmark"></span>
          </label>
        )
      })}
    </SelectMultiple>

  </SelectWrapper>

  :<SelectWrapper>
  <Select value={selectedValue} onChange={handleChange}>
    <Option className="placeholder-option" value="" disabled selected>
      {ReactHtmlParser(placeholder)}
    </Option>
    {selectOptions?.map((item, index) => {
      const { value, key } = item;

      return (
        <Option key={`${value}-${index}`} value={key}>
          {ReactHtmlParser(value)}
        </Option>
      );
    })}
  </Select>
  <ChevronWrapper tabIndex="0">
    <Chevron name="chevron" />
  </ChevronWrapper>
</SelectWrapper>
);

export default Dropdown;
