import styled from 'styled-components';

export const LoadingSpinnerComponent = styled.section`
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  top: ${props => (props.place === 'notification-sidebar' ? '30%' : '50vh')};
  left: ${props => (props.place === 'notification-sidebar' ? '50%' : '50vw')};
  transform: translate(-50%);
  position: absolute;
  z-index: 99;
  border-top: 0.2em solid rgba(51, 51, 51, 1);
  border-right: 0.2em solid rgba(51, 51, 51, 1);
  border-bottom: 0.2em solid rgba(51, 51, 51, 1);
  border-left: 0.2em solid rgba(0, 0, 0, 0);
  animation: rotateCircleLoader 1.1s infinite linear;

  @keyframes rotateCircleLoader {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  + div {
    opacity: 0.7;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 98;
    background-color: #fff;
  }
`;
