import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { GetIcon, Icon } from 'components/_icons';


export const Container = styled.div`
  ${({ theme }) => css`
    
    margin-bottom: 50px;
    @media (min-width: ${theme.breakpoints.tablet}px) {
      
    }
    @media (min-width: ${theme.breakpoints.desktop}px) {
      
    }
  `}
`;

export const HeaderComponent = styled.h1`
  ${({
    theme: {
      fonts: { NotoSansRegularItalic },
      colours: { primary },
    },
    align,
  }) => css`
    font-family: ${NotoSansRegularItalic.value};
    font-style: normal;
    font-weight: bold;
    color: ${primary};
    width: 100%;
    padding: 50px 0px;
    text-align: ${align ? align : 'left'};
  `}
`;



export const TableWrapper = styled.section`
  ${({
    theme: {
      breakpoints: { tablet },
    },
  }) => `
    max-width: 90%;
    margin-left: 5%;

    @media (min-width: ${tablet}px) {
      
    }
  `}
`;
export const Header = styled.h2`
  ${({
    theme: {
      colours: { primary, black, white },
      fonts: { NotoSansBold },
      breakpoints: { tablet },
    },
  }) => `
    font-family: ${NotoSansBold.value};
    font-weight: ${NotoSansBold.fontWeight};
    font-style: ${NotoSansBold.fontStyle};
    background: ${primary};
    color: ${white};
    border-radius: 0;
    padding: 12px;
    font-size: 1.8rem;
    margin: 0;

    
    margin-top: 10px;
    @media (min-width:${tablet}px) {
      
    }
  `}
`;
export const TableContent = styled.div`
  ${({
    theme: {
      breakpoints: { tablet },
    },
    numberOfRows,
  }) => `
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
  `}
`;


export const Row = styled.div`
  ${({
    theme: {
      breakpoints: { tablet },
    }
  }) => `
  display: flex;
  flex-direction: row;

  > div:first-child {
    width: 50%;

    @media (min-width: ${tablet}px) {
     width: 24%;
    }
  }
  `}
`;

export const Column = styled.div`
  ${({
    theme: {
      colours: { black, offWhiteTable, white, lightGrey },
      fonts: { NotoSansBold, NotoSansRegular },
      breakpoints: { tablet },
    },
    paleBackground,
    boldText,
    lightText,
  }) => `
    display: flex;
    flex-direction: column;
    background-color: ${paleBackground ? offWhiteTable : white};
    padding: 10px;
    border-top: 1px solid #dfdfdf;
    border-left: 1px solid #dfdfdf;
    font-size: 1.2rem;
    line-height: 2rem;
    width: 100%;

    & > *{
      margin: 0;
    }

    ${
      boldText
        ? `
      font-family: ${NotoSansBold.value};
      font-weight: ${NotoSansBold.fontWeight};
      font-style: ${NotoSansBold.fontStyle};
      color: ${black};
    `
        : ''
    }

    ${
      lightText
        ? `
      font-family: ${NotoSansRegular.value};
      font-weight: ${NotoSansRegular.fontWeight};
      font-style: ${NotoSansRegular.fontStyle};
      color: ${lightGrey};
    `
        : ''
    }

    @media (min-width: ${tablet}px) {
      font-size: 1.6rem;
    }
  `}
`;