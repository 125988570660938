import React from 'react';

const Brochures = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <g fillRule="evenodd">
        <path opacity=".503" d="M10.053 20.609h3.342l.34-2.609h3.855l.557 2.67H21v4.547H10z" />
        <path
          d="M8.237 3.125v1.25H5.816A.615.615 0 005.21 5v23.125c0 .345.27.625.605.625h19.368c.334 0 .605-.28.605-.625V5a.615.615 0 00-.605-.625h-2.42v-1.25h2.42C26.187 3.125 27 3.965 27 5v23.125C27 29.161 26.187 30 25.184 30H5.816C4.813 30 4 29.16 4 28.125V5c0-1.036.813-1.875 1.816-1.875h2.42zm0 2.5v1.25h-.605V26.25h15.736V6.875h-.605v-1.25h1.816V27.5H6.42V5.625h1.816zM18.147 18v2.669h3.395v4.672H10v-5.339h3.394V18h4.753zm-4.752 3.337h-2.037v2.67h2.037v-2.67zm6.789.667h-2.037v2.002h2.037v-2.002zm-3.395-2.67h-2.037v4.672h2.037v-4.671zm-2.715-4.995v1.334H10V14.34h4.074zm2.036-2.67v1.335H10v-1.335h6.11zm0-2.669v1.335H10V9h6.11zm-.61-9c1.464 0 2.685 1.074 2.966 2.5h1.27c1.338 0 2.422 1.12 2.422 2.5v2.5H8.842V5c0-1.38 1.084-2.5 2.421-2.5h1.271l.03-.133C12.89 1.007 14.081 0 15.5 0zm0 1.25c-1.003 0-1.816.84-1.816 1.875v.625h-2.42c-.67 0-1.211.56-1.211 1.25v1.25h10.894V5c0-.69-.542-1.25-1.21-1.25h-2.421v-.625c0-1.036-.813-1.875-1.816-1.875zm.605 1.25v1.25h-1.21V2.5h1.21z"
          fill="#000"
        />
      </g>
    </svg>
  );
};

export default Brochures;
