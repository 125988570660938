import styled from 'styled-components';
import Link from 'components/_common/Link';

export const EditProfileComponent = styled.section`
  ${({ theme }) => `
    background-color: white;
    padding: 0 0 30px;

    @media (min-width: ${theme.breakpoints.tablet}px) {
      padding: 40px 140px 100px;
    }
  `}
`;

export const Rows = styled.div`
  /* Rows */
`;

export const Row = styled.div`
  ${({ theme }) => `
    border-top: 1px solid ${theme.colours.hairlineGrey};
    padding: 20px;
    display: flex;
    max-width: 550px;
    font-size: 16px;

    &:last-child {
      border-bottom: 1px solid ${theme.colours.hairlineGrey};
      margin-bottom: 30px;

      @media (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: 50px;
      }
    }
  `}
`;

export const FieldName = styled.div`
  ${({ theme }) => `
    color: ${theme.colours.lightGrey};
    margin-right: 30px;
    min-width: 100px;

    @media (min-width: ${theme.breakpoints.tablet}px) {
      min-width: 180px;
    }
  `}
`;

export const FieldValue = styled.div`
  ${({ theme }) => `
    color: ${theme.colours.black};
    flex: 1;
    word-break: break-word;
  `}
`;

export const EditProfileLink = styled(Link)`
  ${({
    theme: {
      breakpoints: { mobileMax },
    },
  }) => `
    max-width: 20rem;
    margin: 0 auto;
    @media (min-width: ${mobileMax}px) {
      margin-right: auto;
      margin-left: 2rem;
    }
  `}
`;
