import styled from 'styled-components';
import { Icon } from 'components/_icons';
import NavigationLink from 'components/_common/navigation-link';

export const Wrapper = styled.div`
  /* component */
`;

export const Header = styled.div`
  ${({
    theme: {
      fonts: { NotoSansRegular, NotoSansBold },
    },
    bold,
  }) => `
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    color: rgb(106, 106, 106);
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
    text-decoration: underline;
    padding: 0 4rem 1rem 1.8rem;
    position: relative;

    ${
      bold
        ? `
      font-family: ${NotoSansBold.value};
      font-weight: ${NotoSansBold.fontWeight};
      font-style: ${NotoSansBold.fontStyle};
      color: rgb(0, 0, 0);
      `
        : ''
    }
  `}
`;

export const HeadingIcon = styled(Icon)`
  ${({ activeTab }) => `
    ${activeTab ? ' transform: rotate(-90deg);' : 'transform: rotate(0deg);'}
    position: absolute;
    top: 0.5rem;
    right: 2rem;
    fill: black;
    width: 1.2rem;
    height: 1.2rem;
    transition: transform 400ms ease-in-out;
  `}
`;

export const LinksContainer = styled.div`
  ${({ open }) => `
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow-y: hidden;
    // Hack to allow for auto dimensions
    max-height:  ${open ? `1000rem` : '0'};
    transition: max-height 400ms ease-in-out;
  `}
`;

export const Link = styled(NavigationLink)`
  ${({
    theme: {
      fonts: { NotoSansRegular },
    },
  }) => `
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    color: rgb(106, 106, 106);
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
    text-decoration: underline;
    padding: 0 1rem 1rem 3.8rem;
  `}
`;
