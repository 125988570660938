import React from 'react';

const Kubota = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 197 66">
      <g fill="#00A8AB" fillRule="nonzero" transform="translate(-120 -25)">
        <path d="M236.557 45.005c-4.66 0-8.442 3.776-8.442 8.437 0 .53.053 1.042.153 1.543h4.254a4.306 4.306 0 01-.289-1.543 4.322 4.322 0 014.325-4.319 4.318 4.318 0 014.324 4.319 4.32 4.32 0 01-5.856 4.041v4.254a8.183 8.183 0 001.532.147c1.796 0 3.21-.648 4.489-2.003v1.944H245v-8.383c0-4.66-3.782-8.437-8.442-8.437m-30.448-.17c-4.707 0-8.519 3.817-8.519 8.524 0 .53.047 1.055.141 1.555h4.301a4.392 4.392 0 01-.282-1.555 4.362 4.362 0 014.36-4.366c2.409 0 4.37 1.95 4.37 4.366a4.372 4.372 0 01-4.37 4.366 4.329 4.329 0 01-1.544-.289v4.3c.5.095 1.019.148 1.543.148 4.708 0 8.531-3.818 8.531-8.525 0-4.707-3.823-8.525-8.53-8.525m-45.147 8.525v-8.525h-4.16v8.525c0 .53.047 1.055.147 1.555h4.301a4.266 4.266 0 01-.288-1.555m8.153-8.525v8.525a4.367 4.367 0 01-4.365 4.366 4.361 4.361 0 01-1.55-.289v4.3c.501.095 1.02.148 1.55.148a8.526 8.526 0 008.525-8.525v-8.525h-4.16zm17.168 0c-1.579 0-3.052.43-4.318 1.178V38h-4.254v16.914h4.495a4.36 4.36 0 014.077-5.92c2.41 0 4.366 1.95 4.366 4.365a4.367 4.367 0 01-4.366 4.366 4.36 4.36 0 01-1.55-.289v4.3c.502.095 1.014.148 1.55.148 4.708 0 8.52-3.818 8.52-8.525 0-4.707-3.812-8.525-8.52-8.525M156.822 38h-5.408l-10.829 11.942 10.829 11.936h5.408L146.03 49.942 156.822 38zm69.673 6.834h-4.183V38h-4.248v23.825h4.248V49.088h4.183v-4.254zm-87.253 10.104H135V38h4.242v16.938z" />
      </g>
    </svg>
  );
};

export default Kubota;
