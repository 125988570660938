import styled, { css } from 'styled-components';

export const CM_CENTER_CENTER = Symbol('CM_CENTER_CENTER');
export const CM_TOP_LEFT = Symbol('CM_TOP_LEFT');
export const CM_TOP_CENTER = Symbol('CM_TOP_CENTER');
export const CM_TOP_RIGHT = Symbol('CM_TOP_RIGHT');
// Modal background layer - visible or invisible, but always floating above client's elements
export const Model = styled.div`
    z-index: auto;
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    z-index:999;
    background-color: rgba(22,22,22,0.5); 
    visibility:${({ show }) => (show ? 'block' : 'none')};
    display:${({ show }) => (show ? 'block' : 'none')};
`;

// Rendered popup - a positional demo confirmation box
export const Container = styled.div`
position: absolute;
margin: 0 auto;
top: 40%;
left: 20%;
background: white;
width: 60%;
height: auto;
padding: 20px;
`;
export const SubContainer = styled.div`
display:flex;
align-items: center;
justify-content: space-between;
background-color:white
`;

export const Img = styled.img`
width:15%;
`;
export const DetilsContainer = styled.div`
font-family: Noto Sans,Verdana,Helvetica Neue,sans-serif;
font-weight: normal;
margin-right: 15px;

`;
export const Head = styled.h1`
font-weight: bold;
`;
export const Message = styled.div`
font-size: 20px;
font-weight: 500;
`;

export const ButtonsContainer = styled.div`
display:flex;
`;


export const Button = styled.button`
    background-color: ${({ primary }) => (primary ? 'green' : 'black')};
    color: white;
    width: 100px;
    padding: 7px 20px;
    margin: 0.2rem;
    display:${({ show }) => (show ? 'block' : 'none')};
    font-size: x-large;
    border: solid 1px ${({ primary }) => (primary ? 'green' : 'black')};
    font-weight:bold;
    margin-right: 15px;
    cursor: pointer;
`;

// export const Header = styled.div`
//     font-size: 18px;
//     color: #000 !important;
//     padding:5px 0 !important;
//     font-weight: bold;
// `;

// export const HBar = styled.div`
//     width: 100%;
//     height: 1px;
//     border: solid 1px #000;
// `;

// export const ButtonBar = styled.div`
//     display: flex;
//     flex-direction: row;
//     flex: 1 0 auto;
//     justify-content: flex-end;
//     padding: 15px 0 !important;
// `;

// export const Slot = styled.div`
// font-family: Noto Sans,Verdana,Helvetica Neue,sans-serif;
// font-weight: normal;
// font-style: italic;
// color: rgba(51,51,51,1.0);
// padding: 5px;
// width: 100%;
// font-size: 18px;
// text-align: center;
// padding-top: 10px;
// `;