import React from 'react';

const Lock = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" className={className}>
      <g fillRule="evenodd">
        <path d="M15 21.25c1.375 0 2.5-1.125 2.5-2.5s-1.125-2.5-2.5-2.5a2.507 2.507 0 00-2.5 2.5c0 1.375 1.125 2.5 2.5 2.5zM22.5 10h-1.25V7.5c0-3.45-2.8-6.25-6.25-6.25S8.75 4.05 8.75 7.5V10H7.5A2.507 2.507 0 005 12.5V25c0 1.375 1.125 2.5 2.5 2.5h15c1.375 0 2.5-1.125 2.5-2.5V12.5c0-1.375-1.125-2.5-2.5-2.5zM11.125 7.5A3.878 3.878 0 0115 3.625 3.878 3.878 0 0118.875 7.5V10h-7.75V7.5zM22.5 25h-15V12.5h15V25z" />
      </g>
    </svg>
  );
};

export default Lock;
