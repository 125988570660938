import React from 'react';

const WarrantyPolicy = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <g fillRule="evenodd">
        <path
          d="M12 28.694v1.347H3v-1.347h9zM13.798 26v1.347H3V26h10.798zM9 23v1.347H3V23h6zM15.838.207l.023.03c.408.555 2.054 1.54 4.043 2.403l.065.027.126.055c2.047.87 4.02 1.434 5.182 1.446h.052a.52.52 0 01.504.52v10.42c0 1.037-.565 2.162-1.592 3.374-.844.996-1.989 2.036-3.353 3.086l-.122.093c-2.005 1.529-4.674 3.177-5.215 3.321a.52.52 0 01-.268 0l-.019-.005c-.249-.076-.988-.465-1.829-.966l-.179-.107a34.458 34.458 0 01-3.313-2.27c-1.37-1.066-2.518-2.154-3.364-3.238C5.56 17.09 5 15.815 5 14.588v-9.9a.52.52 0 01.505-.52h.049c1.088-.012 3.124-.607 5.233-1.502l.126-.054.122-.053c1.966-.854 3.56-1.793 3.95-2.322l.022-.03a.52.52 0 01.83 0zm-.42 1.069l-.052.044C13.6 2.81 8.612 4.902 6.042 5.18v9.408c0 1.834 1.699 4.012 4.54 6.224a33.427 33.427 0 003.213 2.2l.111.067a25.01 25.01 0 001.44.803l.063.032.043-.022c.068-.035.147-.077.2
35-.126l.099-.054.078-.043c.304-.171.667-.387 1.057-.628l.114-.07a40.543 40.543 0 003.218-2.229c2.827-2.176 4.539-4.197 4.539-5.634V5.182c-2.67-.263-7.568-2.343-9.321-3.86l-.053-.046zm.2 4.14c1.989 0 2.947 1.54 2.898 4.398h.174c.69 0 1.25.56 1.25 1.25v5.19c0 .691-.56 1.25-1.25 1.25H12.5c-.69 0-1.25-.559-1.25-1.25v-5.19c0-.69.56-1.25 1.25-1.25h.244c-.066-2.857.884-4.397 2.874-4.397zm3.072 5.231H12.5a.417.417 0 00-.417.417v5.19c0 .231.187.418.417.418h6.19c.23 0 .417-.187.417-.417v-5.191a.417.417 0 00-.417-.417zM15.003 2.81a.52.52 0 01.838.618c-.814 1.145-5.362 3.037-7.716 3.311v7.328a.52.52 0 11-1.042 0V6.252a.52.52 0 01.521-.521h.052c.83-.018 2.347-.427 3.918-1.044l.103-.04.1-.04c1.62-.653 2.935-1.39 3.215-1.783zm.626 9.83c.23 0 .417.187.417.417v.834a.417.417 0 01-.833 0v-.834c0-.23.186-.416.416-.416zm-.897-1.646a.417.417 0 110 .834h-1.049a.417.417 0 00-.416.416v1.122a.417.417 0 01-.834 0v-1.122c0-.69.56-1.25 1.25-1.25zm.886-4.744c-1.42 0-2.108 1.114-2.041 3.563h4.106c.051-2.448-.645-3.563-2.065-3.563z"
        />
      </g>
    </svg>
  );
};
export default WarrantyPolicy;
