import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from 'components/_common/loading-spinner';
import { ButtonComponent, ButtonIcon } from './Button.styles.js';

const Button = ({
  linkText,
  iconName = '',
  dark = false,
  className,
  onClick = () => {},
  loading = false,
  loadingText,
  children,
  brand,
}) => (
  <ButtonComponent dark={dark} className={className} onClick={onClick} brand={brand}>
    {loading ? <LoadingSpinner /> : <ButtonIcon name={iconName} dark={dark} />}
    {loading ? loadingText : linkText || children}
  </ButtonComponent>
);

Button.propTypes = {
  linkText: PropTypes.string,
  iconName: PropTypes.string,
  dark: PropTypes.bool,
  className: PropTypes.string,
};

export default Button;
