import styled from 'styled-components';
import { Field as _Field, Form as _Form } from 'formik';
import Button from 'components/_common/button';
import { ButtonIcon } from 'components/_common/button/Button.styles';

export const FormWrapper = styled.div`
  ${({
    theme: {
      breakpoints: { tablet },
    },
  }) => `
    width: 100%;
    padding: 0 2.5rem 4.1rem;
    background-color: rgb(249, 250, 251);

    @media (min-width: ${tablet}px) {
      padding: 3.6rem 4.5rem 5rem;
    }
  `}
`;

export const FormInner = styled(_Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 89.8rem;
  margin: 0 auto;
`;

export const Label = styled.label`
  ${({
    theme: {
      fonts: { NotoSansSemiBold },
      colours: { darkGrey },
      breakpoints: { tablet },
    },
  }) => `
    display: flex;
    flex-direction: column;
    color: ${darkGrey};
    font-family: ${NotoSansSemiBold.value};
    font-weight: ${NotoSansSemiBold.fontWeight};
    font-style: ${NotoSansSemiBold.fontStyle};
    font-size: 1.4rem;
    margin-bottom: 2rem;
    width: 100%;

    @media (min-width: ${tablet}) {
      font-size: 1.6rem;
    }
  `}
`;

export const Field = styled(_Field)`
  ${({
    theme: {
      fonts: { NotoSansRegular },
      colours: { borderGrey, lightGrey, darkGrey },
      breakpoints: { tablet },
    },
    textarea,
  }) => `
    display: flex;
    justify-content: flex-start;
    border: 1px solid ${borderGrey};
    padding: 1.3rem 2rem;
    color: ${darkGrey};
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin-top: 1rem;
    ${textarea ? 'height: 20.2rem; overflow-y: auto;' : ''}

    &:placeholder {
      color: ${lightGrey};
    }

    @media (min-width: ${tablet}px) {
      font-size: 1.6rem;
      padding: 1.8rem 2rem;
      ${textarea ? 'height: 21.9rem; overflow-y: auto;' : ''}
    }
    `}
`;

export const SubmitButton = styled(Button)`
  ${({
    theme: {
      breakpoints: { tablet },
    },
  }) => `
    width: 100%;
    padding: 2rem 4rem;
    cursor: pointer;
    ${ButtonIcon} {
      width: 3rem;
      height: 3rem;
    }

    @media (min-width: ${tablet}px) {
      max-width: 21rem;
    }
  `}
`;

export const Error = styled.p`
  ${({
    theme: {
      colours: { red },
      fonts: { NotoSansRegular },
    },
  }) => `
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    color: ${red};
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-bottom: 0;
  `}
`;

export const Row = styled.div`
  ${({
    theme: {
      breakpoints: { tablet },
    },
  }) => `
    display: flex;
    flex-direction: column;

    @media (min-width: ${tablet}px) {
      flex-direction: row;
      ${Label} {
        &:first-child {
          margin-right: 2.5rem;
        }
        &:last-child {
          margin-left: 2.5rem;
        }
      }
    }
  `}
`;

export const Title = styled.h2`
  ${({
    theme: {
      colours: { darkGrey },
      fonts: { NotoSansBold },
    },
  }) => `
    color: ${darkGrey};
    font-family: ${NotoSansBold.value};
    font-weight: ${NotoSansBold.fontWeight};
    font-style: ${NotoSansBold.fontStyle};
    font-size: 2.4rem;
    text-align: center;
    margin-bottom: 5rem;
    margin-top: 5rem;
  `}
`;
