import React from 'react';
import PropTypes from 'prop-types';
import { FullWidthCTAComponent, Text, LinkCTA } from './FullWidthCTA.styles.js';

const FullWidthCTA = ({ link, ctaLabel, title }) => {
  const { url, target, isExternal } = link;
  return (
    <FullWidthCTAComponent>
      <Text>{title}</Text>
      <LinkCTA linkText={ctaLabel} linkHref={url} target={target} isExternal={isExternal} dark />
    </FullWidthCTAComponent>
  );
};

FullWidthCTA.propTypes = {
  text: PropTypes.object,
  CTA: PropTypes.object,
};

export default FullWidthCTA;
