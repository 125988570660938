import React from 'react';

const Instagram = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 22 22"
      className={className}
    >
      <g fill-rule="nonzero" fill="none">
        <path
          d="M21.28 1a3.421 3.421 0 013.412 3.412v16.176A3.421 3.421 0 0121.28 24H5.104a3.421 3.421 0 01-3.412-3.412V4.412A3.421 3.421 0 015.104 1H21.28z"
          fill="#FFF"
        />
        <path
          d="M21.28 2.917H5.104c-.826 0-1.495.669-1.495 1.495v16.176c0 .826.669 1.495 1.495 1.495H21.28c.826 0 1.495-.669 1.495-1.495V4.412c0-.826-.669-1.495-1.495-1.495z"
          fill="#333"
        />
        <path d="M13.192 6.75a5.75 5.75 0 110 11.5 5.75 5.75 0 010-11.5z" fill="#FFF" />
        <path d="M13.192 8.667a3.833 3.833 0 100 7.666 3.833 3.833 0 000-7.666z" fill="#333" />
        <path d="M19.421 4.833a1.438 1.438 0 110 2.875 1.438 1.438 0 010-2.875z" fill="#FFF" />
      </g>
    </svg>
  );
};

export default Instagram;
