const mapPageJSON = json => {
  const { header, sidebar, main, footer, pageLayout, brand, langFolder, id } = json;

  let sidebarComponent =
    sidebar?.components?.filter(component => component?.templateLabel === 'Sidebar Nav Template')[0] || {};
  let notificationsSideBar =
    sidebar?.components?.filter(
      component => component?.templateLabel === 'Notifications Sidebar Template'
    )[0] || {};

  return {
    header,
    sidebar: sidebarComponent,
    notificationsSideBar,
    main,
    footer,
    pageLayout,
    brand,
    langFolder,
    pageID: id
  };
};

export default mapPageJSON;
