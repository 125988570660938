import styled, { css } from 'styled-components';
import Link from 'components/_common/Link';
import { Icon } from 'components/_icons';

export const FileDownloadComponent = styled.section`
  ${({
    theme: {
      breakpoints: { tablet },
      colours: { fileDownloadBorder },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    max-width: 103.5rem;
    width: 100%;
    border: 1px solid ${fileDownloadBorder};
    margin: 0 auto;
    margin-bottom: 1.8rem;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    &:first-child {
      margin-top: 1.8rem;
    }
    @media (min-width: ${tablet}px) {
      flex-direction: row;
    }
  `}
`;

export const FileDownloadContents = styled.div`
  ${({
    theme: {
      breakpoints: { tablet },
      colours: { fileDownloadBorder },
    },
  }) => `
    display: flex;
    flex-direction: column;
    flex: 0.81;
    border: 1px solid ${fileDownloadBorder};
    word-break: break-word;

    @media(min-width: ${tablet}px) {
      flex-direction: row;
    }
  `}
`;

export const DocumentName = styled.div`
  ${({
    theme: {
      fonts: { NotoSansBold },
      colours: { offWhite },
      breakpoints: { tablet },
    },
  }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0.50;
    background-color: ${offWhite};
    padding: 2.1rem 2.1rem 0 2.1rem;
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: center;
    font-family: ${NotoSansBold.value};
    font-weight: ${NotoSansBold.fontWeight};
    font-style: ${NotoSansBold.fontStyle};

    @media(min-width: ${tablet}px) {
      justify-content: flex-start;
      font-size: 1.6rem;
      padding: 0 2rem 0 2rem;
      text-align: left;
    }
  `}
`;

export const LastUpdated = styled.div`
  ${({
    theme: {
      fonts: { NotoSansRegular },
      colours: { darkerOffWhite, offWhite, fileDownloadBorder, lightGrey },
      breakpoints: { tablet },
    },
  }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0.5;
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    border-left: 1px solid ${fileDownloadBorder};
    background-color: ${offWhite};
    padding: 2.1rem;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: ${lightGrey};

    @media(min-width: ${tablet}px) {
      background-color: ${darkerOffWhite};

    }
  `}
`;

export const ViewOnline = styled.a`
  ${({
    theme: {
      fonts: { NotoSansBold },
      colours: { darkerOffWhite, fileDownloadBorder },
      breakpoints: { tablet },
    },
  }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0.2;
    font-family: ${NotoSansBold.value};
    font-weight: ${NotoSansBold.fontWeight};
    font-style: ${NotoSansBold.fontStyle};
    background-color: ${darkerOffWhite};
    padding: 2.1rem;
    border-top: 1px solid ${fileDownloadBorder};
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-decoration: none;

    @media(min-width: ${tablet}px) {
      border-left: 1px solid ${fileDownloadBorder};
      border-top: none;
    }
  `}
`;

export const DownloadButton = styled(Link)`
  flex: 0.19;
  margin-bottom: 0;
  padding: 2.1rem;
  outline: none;
  border: none;
`;

export const Bold = styled.div`
  ${({
    theme: {
      fonts: { NotoSansBold },
      colours: { black },
    },
  }) => `
  color: ${black};
  font-family: ${NotoSansBold.value};
  font-weight: ${NotoSansBold.fontWeight};
  font-style: ${NotoSansBold.fontStyle};
  font-size: 1.4rem;
  line-height: 2.4rem;
  margin-left: 1rem;
  `}
`;

export const PdfIcon = styled(Icon)`
  ${({
    theme: {
      fonts: { NotoSansBold },
      colours: { black },
    },
  }) => `
  width: 2.1rem;
  height: 3rem;
  margin-right: 1rem;
	path:nth-child(odd) {
		fill: ${black}
	}
	`}
`;
