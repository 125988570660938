import styled from 'styled-components';
export const EventsListWrapper = styled.div`
  ${({
    theme: {
      breakpoints: { tabletLarge },
      containerWidth,
    },
  }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    max-width: ${containerWidth};
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    @media (min-width: ${tabletLarge}px) {
      margin-left: 11rem;
      margin-right: 11rem;
    }
  `}
`;
