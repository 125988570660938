import React from 'react';

const Building = ({ className }) => {
  return (
    <svg version="1.1" x="0" y="0" viewBox="0 0 426.667 426.667" className={className}>
      <g>
        <g xmlns="http://www.w3.org/2000/svg">
          <g>
            <path
              d="M362.667,384V0H64v384H21.333v42.667h384V384H362.667z M192,42.667h42.667v42.667H192V42.667z M192,128h42.667v42.667H192    V128z M192,213.333h42.667V256H192V213.333z M149.333,256h-42.667v-42.667h42.667V256z M149.333,170.667h-42.667V128h42.667    V170.667z M149.333,85.333h-42.667V42.667h42.667V85.333z M256,384h-85.333v-42.667c0-23.467,19.2-42.667,42.667-42.667    S256,317.867,256,341.333V384z M320,256h-42.667v-42.667H320V256z M320,170.667h-42.667V128H320V170.667z M320,85.333h-42.667    V42.667H320V85.333z"
              fill="#000000"
              data-original="#000000"
            ></path>
          </g>
        </g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
      </g>
    </svg>
  );
};

export default Building;
