import React from 'react';

const Repair = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M2.423.632a.628.628 0 01.792-.079l5.33 3.548c.14.092.236.236.269.4l.402 2.036 9.641 9.636.888-.887a.628.628 0 01.643-.152l1.331.446c.19.064.337.213.398.401l.019.072.405 2.026 5.187 5.193c.979.98.979 2.569 0 3.55l-.886.885a2.51 2.51 0 01-3.548.001l-5.19-5.153-2.038-.409a.628.628 0 01-.444-.347l-.027-.069-.264-.796-.175-.553-.025-.105c-.028-.016-.036-.021.03-.194l.145-.358.887-.887-9.636-9.636-2.036-.409a.628.628 0 01-.4-.267L.569 3.201a.628.628 0 01.079-.792zm7.805 13.328l.887.89-9.492 9.466a1.256 1.256 0 001.774 1.774l9.342-9.317.887.889-9.342 9.317c-.98.979-2.569.979-3.55 0l-.122-.132a2.512 2.512 0 01.123-3.42l9.493-9.467zm10.13 2.488l-3.898 3.898.21.64 1.866.375c.12.024.231.083.319.17l5.325 5.287c.49.49 1.284.49 1.774 0l.885-.884c.49-.49.49-1.285 0-1.775l-5.317-5.324.085-.085-.274-.21c-.02-.017-.024-.023-.019-.022l.04.015-.007-.031-.372-1.847-.617-.207zm-16.961 6.98l.887.888-1.33 1.331-.888-.888 1.33-1.33zm16.784-4.156l5.33 5.33-.888.888-5.33-5.33.888-.888zM2.948 1.884l-1.05 1.049 3.124 4.68 1.968.394a.628.628 0 01.32.172l9.771 9.77.889-.889-9.777-9.77a.628.628 0 01-.147-.233l-.025-.09-.389-1.966-4.684-3.117zm14.427-.445C19.044-.21 21.782-.418 24.073.693a.628.628 0 01.17 1.01l-2.87 2.864 1.773 1.774 2.877-2.877a.628.628 0 011.011.173c1.096 2.298.888 5.032-.757 6.704l-1.048 1.018c-1.706 1.607-3.851 1.804-6.155.805l-1.392 1.391-.888-.887 1.702-1.702c.19-.19.48-.238.72-.12 2.077 1.02 3.854.9 5.27-.514l.906-.882c1.044-1.06 1.32-2.784.837-4.417l-2.639 2.64a.628.628 0 01-.888 0L20.04 5.01a.628.628 0 010-.889l2.637-2.63-.175-.05c-1.575-.42-3.214-.127-4.237.883l-.868.887c-1.388 1.408-1.507 3.181-.509 5.272a.628.628 0 01-.122.714l-1.884 1.884-.888-.888 1.577-1.577c-1.002-2.373-.77-4.56.93-6.285z"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default Repair;
