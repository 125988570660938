import React from 'react';
import PropTypes from 'prop-types';
import { TableWrapper, Header, TableContent, Row, FirstColumn, SecondColumn } from './Table.styles.js';

const Table = ({ rows, heading }) => {
  const renderMultipleRows = content => {
    return (
      <>
        {content.split('\n').map((item, i) => (
          <p className="table-row" key={i}>
            {item}
          </p>
        ))}
      </>
    );
  };
  return (
    <TableWrapper>
      <Header>{heading}</Header>
      <TableContent numberOfRows={rows.length}>
        {rows.map((row, index) => {
          const { rowHeader, rowContent } = row;
          const singleRow = rowContent.split('\n').length === 1;
          return (
            <Row key={index}>
              <FirstColumn paleBackground boldText>
                {rowHeader}
              </FirstColumn>
              <SecondColumn singleRow={singleRow} lightText>
                {renderMultipleRows(rowContent)}
              </SecondColumn>
            </Row>
          );
        })}
      </TableContent>
    </TableWrapper>
  );
};

Table.propTypes = {
  heading: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      rowHeader: PropTypes.string,
      rowContent: PropTypes.string,
    })
  ),
};

export default Table;
