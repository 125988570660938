import React from 'react';

const FavoritesAdd = ({ className }) => {
  return (
    <svg className={className} 
    xmlns="http://www.w3.org/2000/svg"
    version="1"
    viewBox="0 0 50 50"
  >
    <path
      d="M142 357C75 290 20 230 20 225c0-13 192-205 205-205 5 0 21 12 36 26l27 26 21-21c39-39 102-40 141-1s38 102-1 141l-21 21 26 27c24 25 26 33 26 122 0 61-4 99-12 107s-47 12-108 12h-95L142 357zm318 10c0-89-1-94-28-120-15-15-38-27-51-27-54 0-101-48-101-104 0-12-14-35-30-51l-31-29-92 92-92 92 120 120 120 120h185v-93zm-25-192c50-49 15-135-55-135-41 0-80 39-80 80s39 80 80 80c19 0 40-9 55-25z"
      transform="matrix(.1 0 0 -.1 0 50)"
    ></path>
    <path
      d="M360 415c-26-32 13-81 48-59 22 14 27 41 12 59-16 19-44 19-60 0zm46-16c10-17-13-36-27-22-12 12-4 33 11 33 5 0 12-5 16-11zM370 150c0-13-7-20-20-20-11 0-20-4-20-10 0-5 9-10 20-10 13 0 20-7 20-20 0-11 5-20 10-20 6 0 10 9 10 20 0 13 7 20 20 20 11 0 20 5 20 10 0 6-9 10-20 10-13 0-20 7-20 20 0 11-4 20-10 20-5 0-10-9-10-20z"
      transform="matrix(.1 0 0 -.1 0 50)"
    ></path>
  </svg>
  );
};

export default FavoritesAdd;