import styled, { css } from 'styled-components';
import { Icon } from 'components/_icons';
import Link from 'components/_common/Link';
import { lighten } from 'polished';

export const SideNavComponent = styled.section`
  ${({
    theme: {
      breakpoints: { mobileLarge },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;

    @media (min-width: ${mobileLarge}px) {
      flex-direction: row;
    }
  `}
`;

export const SideBar = styled.div`
  ${({
    openNotificationsSideBar,
    theme: {
      colours: { white },
      breakpoints: { mobileMax },
    },
  }) => css`
    display: none;
    position: relative;
    width: 8rem;
    min-height: 100%;
    transition: all 400ms ease-in-out;
    margin-left: auto;
    box-shadow: -2px 0px 2px -1px rgba(0, 0, 0, 0.15);
    background-color: ${white};

    @media (min-width: ${mobileMax}px) {
      display: block;
    }
  `}
`;

export const ChevronIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HideChevron = styled(Icon)`
  width: 1.8rem;
  height: 1.8rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  transform: rotate(-180deg);
`;

export const NotificationsIconWrapper = styled.div`
  ${({ notificationCount }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    ${notificationCount > 0
      ? css`
    &:before {
      content: '${notificationCount}';
      display: flex;
      justify-content: center;
      align-content: center;
      position: absolute;
      border-radius: 50%;
      height: 2rem;
      width: 2rem;
      line-height: 2rem;
      background-color: red;
      color: white;
      top: -13px;
      right: 16px;
    }`
      : ''}
  `}
`;

export const Notifications = styled(Icon)`
  position: relative;
  width: 1.7rem;
  height: 1.7rem;
  margin-top: 0.5rem;
`;

export const SideBarContent = styled.div`
  ${({
    openNotificationsSideBar,
    theme: {
      colours: { darkerOffWhite, white },
      breakpoints: { mobileMax },
    },
    mobile,
  }) => css`
    position: relative;
    max-width: ${openNotificationsSideBar ? '37rem' : '0'};
    width: 100%;
    margin-left: auto;
    background-color: ${white};
    border-left: 2px solid ${darkerOffWhite};
    height: 100%;
    max-height: ${openNotificationsSideBar ? '300rem' : '0'};
    opacity: ${openNotificationsSideBar ? '1' : '0'};
    overflow-y: auto;
    transition: max-height 150ms ease-in-out;
    padding-top: ${mobile ? '0' : '3rem'};

    ${
      mobile
        ? css`
            max-width: 100%;
          `
        : ''
    }

    @media (min-width: ${mobileMax}px) {
      position: absolute;
      top: 0;
      right: 0;
      padding-bottom: 2.5rem;
      width: ${openNotificationsSideBar ? '100%' : '0'};
      z-index: ${openNotificationsSideBar ? '1' : '0'};
      transition: all 200ms ease-in-out;
    }
  `}
`;

export const HideText = styled.p`
  ${({
    theme: {
      breakpoints: { mobileMax },
      fonts: { NotoSansExtraBold },
    },
  }) => css`
    display: none;
    align-items: center;
    color: rgb(51, 51, 51);
    font-family: ${NotoSansExtraBold.value};
    font-weight: ${NotoSansExtraBold.fontWeight};
    font-style: ${NotoSansExtraBold.fontStyle};
    font-size: 1.4rem;
    line-height: 1.9rem;
    letter-spacing: 0.02px;
    text-transform: uppercase;
    margin: 0 2rem 3.5rem 3rem;

    @media (min-width: ${mobileMax}px) {
      display: flex;
    }
  `}
`;

export const HideChevronContentWrapper = styled.div`
  /* empty */
`;

export const HideChevronContent = styled(Icon)`
  ${({
    theme: {
      colours: { primary },
    },
  }) => css`
    /* fill: ${primary}; */
    width: 1.7rem;
    height: 1.7rem;
    margin-top: 0.5rem;
  `}
`;

export const NotificationsWrapper = styled.div`
  ${({
    read,
    theme: {
      colours: { darkerOffWhite, white, unReadNotificationBackground },
      breakpoints: { mobileMax },
    },
  }) => css`
    margin: 0;
    width: 100%;
    display: inline-block;
  `}
`;
export const BtnLoadMore = styled.div`
  ${({
    theme: {
      breakpoints: { mobileLarge },
      colours,
      fonts: { NotoSansExtraBold },
    },
    dark,
    brand,
  }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${NotoSansExtraBold.value};
    font-style: ${NotoSansExtraBold.fontStyle};
    font-weight: ${NotoSansExtraBold.fontWeight};
    font-size: 1.4rem;
    background-color: ${dark ? colours.primary : colours.white};
    border: 1px solid ${colours.primary};
    color: ${dark ? colours.white : colours.primary};
    padding: 2.1rem 4rem 2rem;
    letter-spacing: 0.02px;
    text-transform: uppercase;
    margin-bottom: 2rem;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    margin: 4.7rem auto 2.5rem;
    max-width: 20.4rem;
    text-align: center;
    @media (min-width: ${mobileLarge}px) {
      display: block;
    }

    &:disabled {
      cursor: default;
    }

    svg {
      path {
        transition: fill 0.2s ease-in-out;
      }
    }

    &:hover,
    &:focus {
      background-color: ${lighten(dark ? 0.1 : 0.2, dark ? colours.primary : colours.white)};
      border: 1px solid ${lighten(dark ? 0.1 : 0.2, colours.primary)};
      color: ${lighten(dark ? 0.1 : 0.2, dark ? colours.white : colours.primary)};

      svg {
        path {
          fill: ${lighten(dark ? 0.1 : 0.2, dark ? colours.white : colours.primary)};
        }
      }
    }
  `}
`;
export const NotificationsCTA = styled(Link)`
  ${({
    theme: {
      breakpoints: { mobileLarge },
    },
  }) => css`
    display: none;
    margin: 4.7rem auto 2.5rem;
    max-width: 20.4rem;
    text-align: center;
    @media (min-width: ${mobileLarge}px) {
      display: block;
    }
  `}
`;

export const BellIcon = styled(Icon)`
  ${({
    theme: {
      colours: { darkGrey },
    },
  }) => css`
    width: 3rem;
    height: 3rem;
    min-width: 3rem;
    fill: ${darkGrey};
    margin-right: 1rem;
  `}
`;

export const Header = styled.h2`
  ${({
    theme: {
      fonts: { NotoSansBold },
      colours: { darkGrey },
      breakpoints: { mobileMax },
    },
  }) => css`
    display: none;
    align-items: center;
    font-family: ${NotoSansBold.value};
    font-weight: ${NotoSansBold.fontWeight};
    font-style: ${NotoSansBold.fontStyle};
    font-size: 16px;
    line-height: 24px;
    color: ${darkGrey};
    margin: 1.4rem 0 4.3rem 3rem;
    text-transform: capitalize;

    @media (min-width: ${mobileMax}px) {
      display: flex;
    }
  `}
`;

export const ContentNotificationsIconWrapper = styled.div`
  ${({ notificationCount }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 3.046rem;

    ${notificationCount > 0
      ? css`
    &:before {
      content: '${notificationCount}';
      display: flex;
      justify-content: center;
      align-content: center;
      position: absolute;
      border-radius: 50%;
      height: 2rem;
      width: 2rem;
      line-height: 2rem;
      background-color: red;
      color: white;
      top: -1.3rem;
      font-size: 0.9rem;
      right: -16px;
    }`
      : ''}
  `}
`;
