import React from 'react';

const Licensing = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M28.184 5c.916 0 1.658.742 1.658 1.658v17.684c0 .916-.742 1.658-1.658 1.658H1.658A1.658 1.658 0 010 24.342V6.658C0 5.742.742 5 1.658 5zm0 1.105H1.658a.553.553 0 00-.553.553v17.684c0 .305.248.553.553.553h26.526a.553.553 0 00.553-.553V6.658a.553.553 0 00-.553-.553zm-5.526 9.723v1.74H14.92v-1.74h7.737zM10.024 7.29a1 1 0 011 1v7.912a1 1 0 01-1 1H3.218a1 1 0 01-1-1V8.29a1 1 0 011-1zM9.92 8.394H3.322v7.702l.546-.001v-.816c0-.848 1.366-1.695 2.554-1.764l.154-.004c1.22 0 2.708.883 2.708 1.768v.816h.635v-7.7zm-3.343 6c-.786 0-1.805.606-1.805.885v.816h3.61v-.816c0-.263-.902-.814-1.664-.878zm20.503-2.046v1.74H14.92v-1.74H27.08zM6.672 8.868c.923.008 1.712.74 1.834 1.728l.005.068v1.03l-.004.06c-.114 1.035-.957 1.798-1.93 1.755-.965.044-1.804-.704-1.93-1.726l-.005-.068v-1.03l.003-.06c.111-1 .902-1.747 1.834-1.756l.097.001.096-.002zM6.476 9.9c-.435.012-.803.356-.867.82v.958c.075.482.479.83.94.801l.127.002c.435-.012.804-.356.867-.82v-.959l-.015-.079c-.104-.44-.489-.748-.924-.722h-.128zM27.08 8.87v1.74H14.92v-1.74H27.08z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Licensing;
