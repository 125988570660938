import React from 'react';
import styled from 'styled-components';

import OrderOnline from './OrderOnline';
import PriceTag from './PriceTag';
import Brochures from './Brochures';
import Charts from './Charts';
import Contact from './Contact';
import Machine from './Machine';
import Minus from './Minus';
import Plus from './Plus';
import Training from './Training';
import Truck from './Truck';
import MyOrders from './MyOrders';

import Youtube from './brands/Youtube';
import LinkedIn from './brands/LinkedIn';
import Twitter from './brands/Twitter';
import Fcaebook from './brands/Facebook';

import BrandGuideLines from './bespoke/BrandGuideLines';
import Brochure from './bespoke/Brochure';
import BusinessSupport from './bespoke/BusinessSupport';
import BespokeCharts from './bespoke/BespokeCharts';

import Clock from './bespoke/Clock';
import BespokeContact from './bespoke/BespokeContact';
import DeliveryStatus from './bespoke/DeliveryStatus';
import DigitalService from './bespoke/DigitalService';

import DistributionInfo from './bespoke/DistributionInfo';
import FarmCentre from './bespoke/FarmCentre';
import FindFinancialSolution from './bespoke/FindFinancialSolution';
import HandBook from './bespoke/HandBook';

import Info from './bespoke/Info';
import ITService from './bespoke/ITService';
import LicensingShop from './bespoke/LicensingShop';
import Licensing from './bespoke/Licensing';

import LockShield from './bespoke/LockShield';
import BespokeMachine from './bespoke/BespokeMachine';
import Mail from './bespoke/Mail';
import Marketing from './bespoke/Marketing';

import MerchandiseStore from './bespoke/MerchandiseStore';
import BespokeMyOrders from './bespoke/BespokeMyOrders';
import MyPartsOrders from './bespoke/MyPartsOrders';
import OnlineOrdering from './bespoke/OnlineOrdering';

import OnlineTraining from './bespoke/OnlineTraining';
import BespokeOrderOnline from './bespoke/BespokeOrderOnline';
import PartsAvailability from './bespoke/PartsAvailability';
import PartsCatalogue from './bespoke/PartsCatalogue';

import PartsCalims from './bespoke/PartsClaims';
import PartsReplacementList from './bespoke/PartsReplacementList';
import PartsTraining from './bespoke/PartsTraining';
import Parts from './bespoke/Parts';

import Pen from './bespoke/Pen';
import PhysicalTraining from './bespoke/PhysicalTraining';
import BespokePriceTag from './bespoke/BespokePriceTag';
import ProductMarketing from './bespoke/ProductMarketing';

import ProductService from './bespoke/ProductService';
import ProductUpdates from './bespoke/ProductUpdates';
import PromotionalListing from './bespoke/PromotionalListing';
import Repair from './bespoke/Repair';

import Returns from './bespoke/Returns';
import SafetyRegulations from './bespoke/SafetyRegulations';
import SalesAndOrders from './bespoke/SalesAndOrders';
import ServiceTraining from './bespoke/ServiceTraining';

import Service from './bespoke/Service';
import Speech from './bespoke/Speech';
import StockRecommendationList from './bespoke/StockRecommendationList';
import SupportTicket from './bespoke/SupportTicket';

import TrainingManual from './bespoke/TrainingManual';
import BespokeTraining from './bespoke/BespokeTraining';
import BespokeTruck from './bespoke/BespokeTruck';
import BespokeUser from './bespoke/User';
import WarrantyPolicy from './bespoke/WarrantyPolicy';

import Vicon from './company-logos/Vicon';
import Kverneland from './company-logos/Kverneland';
import KVEGroup from './company-logos/KVEGroup';
import Kubota from './company-logos/Kubota';

import User from './User';
import Notifications from './Notifications';
import ExitToApp from './ExitToApp';
import Chevron from './Chevron';
import Lock from './Lock';
import DisableLock from './DisableLock';
import Launch from './Launch';

import KVEGroupNoFill from './company-logos/KVEGroupNoFill';
import Phone from './Phone';
import Printer from './Printer';
import Download from './Download';
import Pdf from './Pdf';

import Pin from './Pin';
import Calendar from './Calendar';
import Warning from './Warning';
import Dashboard from './Dashboard';
import Instagram from './brands/Instagram';
import ChevronLeft from './ChevronLeft';

import PowerPoint from './download-icons/PowerPoint';
import Excel from './download-icons/Excel';
import Word from './download-icons/Word';
import Zip from './download-icons/Zip';
import CircleArrow from './CircleArrow';
import Delete from './Delete';
import Building from './Building';
import FavoritesAdd from './FavoritesAdd';
import FavoritesRemove from './FavoritesRemove';


export const GetIcon = ({ name, className }) => {
  const getIcon = iconName => {
    switch (name) {
      case 'orderOnline':
        return OrderOnline;
      case 'priceTag':
        return PriceTag;
      case 'brochures':
        return Brochures;
      case 'charts':
        return Charts;
      case 'contact':
        return Contact;
      case 'machine':
        return Machine;
      case 'training':
        return Training;
      case 'truck':
        return Truck;
      case 'myOrders':
        return MyOrders;
      case 'youtube':
        return Youtube;
      case 'linkedin':
        return LinkedIn;
      case 'twitter':
        return Twitter;
      case 'facebook':
        return Fcaebook;
      case 'brandGuideLines':
        return BrandGuideLines;
      case 'brochure':
        return Brochure;
      case 'businessSupport':
        return BusinessSupport;
      case 'bespokeCharts':
        return BespokeCharts;
      case 'clock':
        return Clock;
      case 'bespokeContact':
        return BespokeContact;
      case 'deliveryStatus':
        return DeliveryStatus;
      case 'digitalService':
        return DigitalService;
      case 'distributionInfo':
        return DistributionInfo;
      case 'farmCentre':
        return FarmCentre;
      case 'findFinancialSolution':
        return FindFinancialSolution;
      case 'handBook':
        return HandBook;
      case 'info':
        return Info;
      case 'iTService':
        return ITService;
      case 'launch':
        return Launch;
      case 'licensingShop':
        return LicensingShop;
      case 'licensing':
        return Licensing;
      case 'lockShield':
        return LockShield;
      case 'bespokeMachine':
        return BespokeMachine;
      case 'mail':
        return Mail;
      case 'marketing':
        return Marketing;
      case 'merchandiseStore':
        return MerchandiseStore;
      case 'minus':
        return Minus;
      case 'bespokeMyOrders':
        return BespokeMyOrders;
      case 'myPartsOrders':
        return MyPartsOrders;
      case 'onlineOrdering':
        return OnlineOrdering;
      case 'onlineTraining':
        return OnlineTraining;
      case 'bespokeOrderOnline':
        return BespokeOrderOnline;
      case 'partsAvailability':
        return PartsAvailability;
      case 'partsCatalogue':
        return PartsCatalogue;
      case 'partsCalims':
        return PartsCalims;
      case 'partsReplacementList':
        return PartsReplacementList;
      case 'partsTrianing':
        return PartsTraining;
      case 'parts':
        return Parts;
      case 'pen':
        return Pen;
      case 'physicalTraining':
        return PhysicalTraining;
      case 'plus':
        return Plus;
      case 'bespokePriceTag':
        return BespokePriceTag;
      case 'productMarketing':
        return ProductMarketing;
      case 'productService':
        return ProductService;
      case 'productUpdates':
        return ProductUpdates;
      case 'promotionalListing':
        return PromotionalListing;
      case 'repair':
        return Repair;
      case 'returns':
        return Returns;
      case 'safetyRegulations':
        return SafetyRegulations;
      case 'salesAndOrders':
        return SalesAndOrders;
      case 'serviceTraining':
        return ServiceTraining;
      case 'service':
        return Service;
      case 'speech':
        return Speech;
      case 'stockRecommendationList':
        return StockRecommendationList;
      case 'supportTicket':
        return SupportTicket;
      case 'trainingManual':
        return TrainingManual;
      case 'bespokeTraining':
        return BespokeTraining;
      case 'bespokeTruck':
        return BespokeTruck;
      case 'bespokeUser':
        return BespokeUser;
      case 'warrantyPolicy':
        return WarrantyPolicy;
      case 'vicon':
        return Vicon;
      case 'kverneland':
        return Kverneland;
      case 'KVEGroup':
        return KVEGroup;
      case 'kubota':
        return Kubota;
      case 'user':
        return User;
      case 'notifications':
        return Notifications;
      case 'exitToApp':
        return ExitToApp;
      case 'KVEGroupNoFill':
        return KVEGroupNoFill;
      case 'lock':
        return Lock;
      case 'disablelock':
        return DisableLock;
      case 'left_chevron':
        return Chevron;
      case 'chevron':
        return Chevron;
      case 'pin':
        return Pin;
      case 'calendar':
        return Calendar;
      case 'phone':
        return Phone;
      case 'printer':
        return Printer;
      case 'download':
        return Download;
      case 'pdf':
        return Pdf;
      case 'warning':
        return Warning;
      case 'dashboard':
        return Dashboard;
      case 'instagram':
        return Instagram;
      case 'chevronLeft':
        return ChevronLeft;
      case 'powerPoint':
        return PowerPoint;
      case 'excel':
        return Excel;
      case 'word':
        return Word;
      case 'zip':
        return Zip;
      case 'CircleArrow':
        return CircleArrow;
      case 'Delete':
        return Delete;
      case 'Building':
        return Building;
      
      case 'FavoritesAdd':
        return FavoritesAdd;

        case 'FavoritesRemove':
          return FavoritesRemove;
  
      default:
        return '';
    }
  };

  const IconComponent = getIcon(name);

  return IconComponent && <IconComponent className={className} />;
};

export const Icon = styled(GetIcon)`
  fill: black;
`;
