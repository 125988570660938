import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { GetIcon } from 'components/_icons';

export const TileComponent = styled.div`
  ${({ theme }) => css`
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    border-left: 5px solid ${theme.colours.primary};

    margin: 5px;
    min-height: 110px;

    text-align: center;
    text-decoration: none;
    color: ${theme.colours.black};
    transition: all 0.2s ease-in-out;
    background-color: ${theme.colours.white};
    position: relative;

    width: calc(50% - 10px);
    @media (max-width: ${theme.breakpoints.tablet *1.3}px) {
      width: calc(100% - 10px);
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;

      *{
        cursor: not-allowed;
      }
    }
  `}
`;


export const TileComponentClick = styled(Link)`
  ${({ theme }) => css`
  padding: 2% 8%;
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    
    flex-direction: row;
    align-items: center;
    text-align: left;
  }

  @media (max-width: ${theme.breakpoints.tablet - 1}px) {
    align-items: center;
  }

    &:hover {
      color: ${theme.colours.primary};
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      svg {
        path {
          fill: ${theme.colours.primary};
        }
      }
    }

  `}
`;

export const IconWrap = styled.div`
  ${({ theme, className }) => css`
    position: relative;
    margin: 0 0 10px;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    width: ${ className == 'max'? '75%' : '25%' };

    @media (min-width: ${theme.breakpoints.mobileLarge}px) {
      margin-top: 5%;
    }

    @media (min-width: ${theme.breakpoints.tablet}px) {
      // margin: 0 20px 0 0;
    }
  `}
`;

export const Icon = styled.img`
  ${({ theme }) => css`
    width: auto;
    height: 40px;
    max-width: 100%;

    path {
      transition: fill 0.2s ease-in-out;
    }

    @media (min-width: ${theme.breakpoints.mobileLarge}px) {
      height: 50px;
    }
  `}
`;

export const Icon2 = styled.img`
  ${({ theme }) => css`
    width: auto;
    height: 50px;
    max-width: 100%;
    
    @media (max-width: 767px) {
      height: 30px;
    }
    path {
      transition: fill 0.2s ease-in-out;
    }
  `}
`;

export const Text = styled.h4`
  ${({ theme }) => css`
    font-size: 14px;
    flex: 1;
    margin: 0;
    // word-break: break-all;
    word-break: break-word;

    @media (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 18px;
      margin-right: 20px;
    }
  `}
`;


export const TextLink = styled.a`
  ${({ theme }) => css`
  span.tooltip {
      font-size: 12px;
      position: absolute;
      top: 80%;
      
      left: 8%;
      text-align: left;

      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover + span.tooltip-text {
        visibility: visible;
      }
    }
    span.tooltip-text {
      visibility: hidden;
      width: 80%;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 10px;

      position: absolute;
      left: 8%;
      z-index: 1;
    }

    svg{
      height: 20px;
      width: 20px;
      position: absolute;
      bottom: 2px;
      left: 2%;
    }
    @media (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 14px;
    }
  `}
`;


export const ActionIcon = styled(GetIcon)`
  ${({ theme, small }) => css`
    display: none;
    width: 10% !important;

    @media (min-width: ${theme.breakpoints.tablet}px) {
      display: block;
      width: ${small ? '20px' : '30px'};
      height: ${small ? '20px' : '30px'};

      path {
        transition: fill 0.2s ease-in-out;
      }
    }
  `}
`;

export const FavButton = styled(Link)`
  ${({ theme }) => css`
  position: absolute;
  display: block;
  cursor: pointer;
  
  top: 15%;
  left: 2%;
  z-index:1;
  svg{
    height: 30px;
    pointer-events: none;
  }

  &.active {
    svg{
      fill: ${theme.colours.primary};
    }
  }
  `}
`;


export const FavButtonIcon = styled(GetIcon)`
  ${({ theme, small }) => css`
  `}
`;

export const Count = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: -2px;
    right: -17px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    font-size: 10px;
    font-weight: bold;
    background-color: ${theme.colours.red};
    color: ${theme.colours.white};
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;
export const Holder = styled.div`
${({ theme }) => css`
    width: 100%;
    display: contents;
`}
`;

export const HeaderDiv = styled.div`
  ${({ theme }) => css`
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    
    width: 65%;
    -webkit-line-clamp: 4;
    overflow: hidden;


    @media (max-width: ${theme.breakpoints.tablet - 1}px) {
      display: flex;
      align-items: center;
    }
  `}
`;