import React from 'react';

const Warning = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 168 150">
      <path
        d="M84.07 0c6.083.255 11.548 3.783 14.179 9.036l66.79 115.911c3.32 5.094 3.64 11.575.7 17.209-3.23 5.17-9.034 8.166-14.926 7.816l-133.604-.006c-6.102.366-11.914-2.635-15.26-8.026-2.858-5.399-2.536-11.923.725-16.83L69.5 9.217A16.716 16.716 0 0183.782 0zm-.293 6.97l.143.01-.515.039c-3.274.356-6.17 2.357-7.744 5.494L8.62 128.772c-1.965 2.966-2.153 6.766-.61 9.7 1.87 3.003 5.24 4.743 8.988 4.525l134.018.006c3.54.206 6.911-1.534 8.65-4.297 1.644-3.165 1.457-6.968-.592-10.12L92.091 12.336a9.757 9.757 0 00-8.314-5.366zm3.538 97.764c5.699 0 10.318 4.62 10.318 10.318v6.878c0 5.699-4.62 10.319-10.318 10.319h-6.879c-5.698 0-10.318-4.62-10.318-10.319v-6.878c0-5.699 4.62-10.318 10.318-10.318zm0 6.878h-6.879a3.44 3.44 0 00-3.439 3.44v6.878c0 1.9 1.54 3.44 3.44 3.44h6.878c1.9 0 3.44-1.54 3.44-3.44v-6.878c0-1.9-1.54-3.44-3.44-3.44zm1.591-73.443a8.413 8.413 0 016.076 2.365 8.717 8.717 0 012.651 6.047l-.008.351-3.43 47.25c0 3.867-3.08 7.001-6.88 7.001h-6.879c-3.8 0-6.88-3.134-6.87-6.744l-3.44-47.507-.008-.352c.126-4.73 3.964-8.478 8.564-8.412zm.19 7H78.634c-.885-.013-1.615.7-1.639 1.6l.008-.095 3.433 47.508h6.88l.009-.258 3.42-47.25-.029-.227a1.66 1.66 0 00-.256-.584l-.208-.245a1.6 1.6 0 00-1.156-.45z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Warning;
