import React from 'react';

const Launch = ({ className }) => (
  <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 768 768">
    <g fillRule="evenodd">
      <path
        fill="#000"
        d="M448.5 96h223.5v223.5h-64.5v-114l-313.5 313.5-45-45 313.5-313.5h-114v-64.5zM607.5 607.5v-223.5h64.5v223.5q0 25.5-19.5 45t-45 19.5h-447q-27 0-45.75-18.75t-18.75-45.75v-447q0-27 18.75-45.75t45.75-18.75h223.5v64.5h-223.5v447h447z"
      ></path>
    </g>
  </svg>
);

export default Launch;
