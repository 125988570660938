import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import ReactJson from 'react-json-view';
import Cookies from 'js-cookie';
import qs from 'qs';

import mapPageJSON from '../page-json-mapper/pageJSONMapper';
import PageLayout from '../page-layout/PageLayout';
import { getSiteEndpoint } from 'helpers/api';
import getAuthVariables from 'helpers/authVariables';

import { getNotification, getUnReadNotificationCount } from 'helpers/utilities';
import { Auth } from 'components/context/AuthContext';
import { ThemeProvider } from 'styled-components';
import theme, { brands } from '../../../theme.js';
import GlobalStyle from 'components/_utility/global-styles/GlobalStyles.js';
import LoadingSpinner from 'components/_common/loading-spinner';

const Div = styled.div`
  line-height: 1.5rem;
  font-size: 2rem;
`;

const Page = props => {
  const envVariables = getAuthVariables();
  const { 0: slug } = useParams();
  const debug = useLocation().search;
  const url = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  const { setSlugValue, page, loading, userToken } = useContext(Auth);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSlugValue(slug);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, url.brand, page]);

  if (!page) {
    return <LoadingSpinner />;
  }
  const debugRegex = /debug=serverjson/g;
  if (debugRegex.test(debug) && (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
    return (
      <Div>
        <ReactJson src={page} />
      </Div>
    );
  }

  // dynamically adding in colours for theming of
  // different brands. This brand comes from BE
  // which comes from UMS.

  const brand = page?.brand || 'default';

  const newColours = { ...theme.colours, ...brands[brand] };

  theme.colours = newColours;

  return (
    <ThemeProvider theme={theme}>
      {loading ? <LoadingSpinner /> : null}
      <GlobalStyle />
      <PageLayout {...mapPageJSON(page)} />
    </ThemeProvider>
  );
};

export default Page;
