import React from 'react';
import PropTypes from 'prop-types';
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import moment from 'moment';
import {
  EventsListWrapper,
  EventName,
  Date,
  Location,
  Country,
  Flag,
  CountryName,
  MoreInformation,
  MoreInformationText,
  Chevron,
  PlaceWrapper,
  CalendarIcon,
  PinIcon,
  Dropdown,
  DetailsWrapper,
} from './Event.styles.js';

// TODO: Change the flag image to be from CMS
// when BE is ready.
const Event = ({
  eventName,
  startDate,
  endDate,
  location,
  countryName,
  flag,
  dropdown,
  moreInfo,
  description = 'KVG Event',
}) => {
  const { target, title: moreInfoLabel, url, isExternal } = moreInfo || {};
  const showMoreInfoCTA = url && moreInfoLabel;
  const formattedDate = moment(startDate).format('DD/MM/YYYY');
  const startTime = moment(startDate);
  const endTime = moment(endDate);
  const duration = moment.duration(endTime.diff(startTime)).asHours();

  const event = {
    startDatetime: startTime.format('YYYYMMDDTHHmmss'),
    endDatetime: endTime.format('YYYYMMDDTHHmmss'),
    duration,
    location: location,
    title: eventName,
    description,
  };

  const AddToCalendarDropdown = AddToCalendarHOC(EventsListWrapper, Dropdown);

  return (
    <EventsListWrapper>
      <DetailsWrapper>
        <EventName>{eventName}</EventName>
        <Date>
          <AddToCalendarDropdown
            className="dropdown-container"
            event={event}
            buttonText={
              <>
                <CalendarIcon name="calendar" />
                {formattedDate}
              </>
            }
          />
        </Date>
        <PlaceWrapper>
          <Location>
            <PinIcon name="pin" />
            {location}
          </Location>
          <Country>
            <Flag src={flag} />
            <CountryName>{countryName}</CountryName>
          </Country>
        </PlaceWrapper>
      </DetailsWrapper>

      <MoreInformation as="a" target={target} href={url} noCTA={!showMoreInfoCTA} isExternal={isExternal}>
        {showMoreInfoCTA && (
          <>
            <MoreInformationText>{moreInfoLabel}</MoreInformationText>
            <Chevron name="chevron" />
          </>
        )}
      </MoreInformation>
    </EventsListWrapper>
  );
};

Event.propTypes = {
  eventName: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  location: PropTypes.string,
  country: PropTypes.string,
  flag: PropTypes.string,
  moreInfo: PropTypes.shape({
    moreInfoLabel: PropTypes.string,
    target: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default Event;
