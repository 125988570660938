import React from 'react';
import PropTypes from 'prop-types';
import { GatewayComponent, ImagePane, BackgroundImage, ContentPane } from './Gateway.styles.js';
import ComponentSwitch from 'components/_utility/component-switch';
import Wrapper from 'components/_utility/page-layout/wrapper';

const Gateway = ({ header, sidebar, main: { components }, footer }) => {
  const gatewayComponents = [
    'Login Template',
    'Back Template',
    'Request Access Template',
    'Request Accessnew Template',
    'Header With Text Template',
  ];
  const image = components.find(component => component?.templateLabel === 'Image Template') || {
    mobile: { src: '', alt: '' },
    tablet: { src: '', alt: '' },
    desktop: { src: '', alt: '' },
  };

  return (
    <Wrapper header={header} footer={footer}>
      <GatewayComponent>
        <ImagePane>
          <BackgroundImage
            mobile={image.mobile}
            tablet={image.tablet}
            desktop={image.desktop}
          ></BackgroundImage>
        </ImagePane>
        <ContentPane>
          {components.map((component, index) => {
            if (gatewayComponents.includes(component?.templateLabel)) {
              return (
                <ComponentSwitch
                  key={index}
                  component={component}
                  gatewayHeaderText={gatewayComponents.includes('Header With Text Template')}
                ></ComponentSwitch>
              );
            }
            return null;
          })}
        </ContentPane>
      </GatewayComponent>
    </Wrapper>
  );
};

Gateway.propTypes = {
  image: PropTypes.shape({}),
  component: PropTypes.shape({}),
};

export default Gateway;
