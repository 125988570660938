export const appendModalToDom = (className, options = {}) => {
  let modalEl = document.querySelector(`.${className}`);

  if (!modalEl) {
    modalEl = document.createElement('div');
    document.body.appendChild(modalEl);
    modalEl.classList.add(className);
    modalEl.style.display = 'none';
    modalEl.style.zIndex = options.zIndex || 10000;
    modalEl.style.position = 'fixed';
    modalEl.style.top = 0;
    modalEl.style.left = 0;
    modalEl.style.height = '100%';
    modalEl.style.width = '100%';
  }

  return modalEl;
};
