import React from 'react';

const ChevronLeft = ({ className }) => {
  return (
    <svg viewBox="0 0 10 16" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M8.238 15.5L10 13.738 4.276 8 10 2.262 8.238.5.738 8z" />
    </svg>
  );
};

export default ChevronLeft;
