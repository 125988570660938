import styled from 'styled-components';
import Link from 'components/_common/Link';

export const NotificationPreviewListComponent = styled.section`
  display: flex;
  flex-direction: column;
`;

export const BackToDashboardLink = styled(Link)`
  ${({
    theme: {
      breakpoints: { mobileMax, tablet },
    },
  }) => `
    margin: 4rem auto 4rem;

    @media (min-width: ${mobileMax}px) {
      max-width: 28.3rem;
      margin-left: 5.2rem;
    }

    @media (min-width: ${tablet}px) {
      margin-left: 14.2rem;
    }
  `}
`;
