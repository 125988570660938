import React from 'react';

const Info = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M15.305 2C22.653 2 28.61 7.957 28.61 15.305S22.653 28.61 15.305 28.61 2 22.653 2 15.305 7.957 2 15.305 2zm0 2.661c-5.878 0-10.644 4.766-10.644 10.644 0 5.879 4.766 10.644 10.644 10.644 5.879 0 10.644-4.765 10.644-10.644 0-5.878-4.765-10.644-10.644-10.644zm1.33 6.653v7.983h2.662v2.66h-5.322v-7.982h-1.33v-2.661h3.99zm0-3.992v2.661h-2.66v-2.66h2.66z"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default Info;
