import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  SideNavComponent,
  SideBar,
  ChevronIconWrapper,
  HideChevron,
  NotificationsIconWrapper,
  Notifications,
  SideBarContent,
  HideText,
  HideChevronContentWrapper,
  HideChevronContent,
  NotificationsWrapper,
  NotificationsCTA,
  ContentNotificationsIconWrapper,
  Header,
  BtnLoadMore,
} from './NotificationsSideBar.styles.js';
import Notification from 'components/notifications-side-bar/Notification';
import { GlobalContext } from 'components/context/GlobalContext';
import { getNotification, getUnReadNotificationCount } from 'helpers/utilities';
import { Auth } from 'components/context/AuthContext';
import getAuthVariables from 'helpers/authVariables.js';
import LoadingSpinner from 'components/_common/loading-spinner/LoadingSpinner.js';
import ReactHtmlParser from 'react-html-parser';

const NotificationsSideBar = ({ labels, cta, header, mobile = false, userId = 1234 }) => {
  const [listOfNotificationId, setListOfNotificationId] = useState([]);

  const envVariables = getAuthVariables();

  const {
    notifications,
    setNotifications,
    userToken,
    setNotifLoading,
    notificationCount,
    notificationPageSize,
    setNotificationCount,
    setPageNotification,
    pageNotification,
    notifLoading,
    isRead,
  } = useContext(Auth);

  const {
    globalDispatch,
    globalState: { isOpenNotificationSideBar },
  } = useContext(GlobalContext);

  const notificationsSideBarHeader = notificationCount + ' ' + header;

  useEffect(() => {
    globalDispatch({
      type: 'NOTIFICATIONS_HEADER',
      payload: {
        notificationsSideBarHeader,
      },
    });
  }, [globalDispatch, header, notificationsSideBarHeader]);

  const { notifications_sidebar_hide_label: hideText } = labels || {};
  const { target, url: linkHref, title: linkText, isExternal } = cta || {};

  const toggleSidebar = value => {
    globalDispatch({
      type: 'TOGGLE_NOTIFICATION_SIDEBAR',
    });
  };
  useEffect(() => {
    if (notifications) {
      const notificationsIdArray = notifications.map(id => id.notificationId);
      setListOfNotificationId(notificationsIdArray);
    }
  }, [notifications]);

  const loadNewNOtification = () => {
    if (notifications.length === notificationPageSize || notifications.length === 0) {
      getNotification(
        setNotifications,
        userToken,
        setNotifLoading,
        pageNotification,
        notificationPageSize,
        setPageNotification
      );
    }
  };
  return (
    <SideNavComponent open={isOpenNotificationSideBar}>
      <SideBarContent openNotificationsSideBar={isOpenNotificationSideBar} mobile={mobile}>
        <HideText mobile={mobile}>
          {hideText}
          <HideChevronContentWrapper onClick={() => toggleSidebar()}>
            <HideChevronContent name="chevron" noMargin />
          </HideChevronContentWrapper>
        </HideText>

        {notifLoading ? (
          <LoadingSpinner place="notification-sidebar" />
        ) : (
          <>
            <Header>
              <ContentNotificationsIconWrapper notificationCount={notificationCount}>
                <Notifications name="notifications" />
              </ContentNotificationsIconWrapper>
              {` ${ReactHtmlParser(notificationsSideBarHeader)}`}
            </Header>
            <NotificationsWrapper read={isRead}>
              {notifications?.map((notification, index) => (
                <Notification key={index} {...notification} closeSideBar={() => toggleSidebar()} />
              ))}
            </NotificationsWrapper>
            {/* <BtnLoadMore dark onClick={loadNewNOtification}>
              Load More
            </BtnLoadMore> */}
            <NotificationsCTA
              linkText={linkText}
              linkHref={
                listOfNotificationId[0]
                  ? `/dashboard-pages/notification-list?notificationId=${listOfNotificationId[0]}`
                  : `/dashboard-pages/notification-list`
              }
              target={target}
              isExternal={isExternal}
              onClick={() => toggleSidebar()}
              dark
            />
          </>
        )}
      </SideBarContent>
      <SideBar openNotificationsSideBar={isOpenNotificationSideBar} mobile={mobile}>
        <ChevronIconWrapper onClick={() => toggleSidebar()} center>
          <HideChevron open={isOpenNotificationSideBar} name="chevron" />
        </ChevronIconWrapper>
        <NotificationsIconWrapper notificationCount={notificationCount}>
          <Notifications name="notifications" />
        </NotificationsIconWrapper>
      </SideBar>
    </SideNavComponent>
  );
};

NotificationsSideBar.propTypes = {
  labels: PropTypes.object,
  notifications: PropTypes.array,
  cta: PropTypes.shape({
    target: PropTypes.string,
    url: PropTypes.string,
    title: PropTypes.string,
    isExternal: PropTypes.bool,
  }),
  header: PropTypes.string,
  mobile: PropTypes.bool,
  userId: PropTypes.number,
};

export default NotificationsSideBar;
