import React from 'react';

const PriceTag = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <g fillRule="evenodd">
        <path
          d="M16 15.678l.478-.36L17 15h.956l.32.318.526.313.197 1.037c-.2.473-.365.771-.498.896-.351.33-.996.359-1.228.415-.362.089-.787-.107-1.274-.588v-1.713zM10 10.678l.478-.36L11 10h.956l.32.318.526.313.197 1.037c-.2.473-.365.771-.498.896-.351.33-.996.359-1.228.415-.362.089-.787-.107-1.274-.588v-1.713z"
          fill="#333"
          opacity=".503"
        />
        <path
          d="M14.535 2l.203.011h7.007l.924.778-.8.97-.577-.485h-6.585l-.184-.01a1.27 1.27 0 00-.819.268L1.657 15.661a1.378 1.378 0 00.005 1.963l8.594 8.696a1.399 1.399 0 001.99 0l12.1-12.209a1.44 1.44 0 00.404-1.023v-6.6l-.723-.652.833-.943L26 5.922v4.772c.302-.062.594-.163.87-.302 1.327-.66 2.05-2.069 1.784-3.476-.267-1.407-1.46-2.484-2.946-2.658-1.291-.151-2.541.411-3.25 1.417L24 7.216 23.216 8 21 5.784l.092-.093L21 5.652c.816-1.732 2.678-2.781 4.646-2.64l.219.022c2.047.239 3.692 1.724 4.06 3.663.367 1.938-.63 3.88-2.456 4.787-.46.233-.956.39-1.468.464L26 13.08a2.711 2.711 0 01-.765 1.92L13.13 27.212a2.642 2.642 0 01-1.88.788 2.642 2.642 0 01-1.879-.788l-8.588-8.69A2.647 2.647 0 010 16.64c0-.708.282-1.386.779-1.878L12.863 2.6a2.538 2.538 0 011.875-.589zm2.75 13c.947 0 1.715.768 1.715 1.714v.572c0 .946-.768 1.714-1.714 1.714h-.572A1.714 1.714 0 0115 17.286v-.572c0-.946.768-1.714 1.714-1.714h.572zm-1.355-5l1.07.596L12.07 19 11 18.404 15.93 10zm1.356 6.143h-.572a.571.571 0 00-.571.571v.572c0 .315.256.571.571.571h.572a.571.571 0 00.571-.571v-.572a.571.571 0 00-.571-.571zm-6-6.143c.946 0 1.714.768 1.714 1.714v.572c0 .946-.768 1.714-1.714 1.714h-.572A1.714 1.714 0 019 12.286v-.572C9 10.768 9.768 10 10.714 10h.572zm0 1.143h-.572a.571.571 0 00-.571.571v.572c0 .315.256.571.571.571h.572a.571.571 0 00.571-.571v-.572a.571.571 0 00-.571-.571z"
          fill="#000"
        />
      </g>
    </svg>
  );
};

export default PriceTag;
