import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { bodyNoScroll, getViewportWidth } from 'helpers/utilities';
import { appendModalToDom } from 'helpers/modal';
import theme from 'theme';
import { Overlay, Wrap, Dialog } from './Modal.styles';

const modalEl = appendModalToDom('kverneland-modal', { zIndex: 10001 });

const Modal = ({ onClose, children, ...props }) => {
  const [open, setOpen] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    if (getViewportWidth() >= theme.breakpoints.tablet) {
      setTimeout(() => setOpen(true), 100);
    }

    modalEl.style.display = 'block';

    bodyNoScroll.add();

    return () => {
      bodyNoScroll.remove();

      modalEl.style.display = 'none';
    };
  }, []);

  useEffect(() => {
    const modalEl = modalRef.current;
    if (modalEl) {
      // Prevent blurring of modal children
      modalEl.style.minHeight = `${2 * Math.round(modalEl.firstElementChild.offsetHeight / 2)}px`;
    }
  }, [children]);

  return createPortal(
    <>
      <Overlay open={open} onClick={onClose} />
      <Wrap>
        <Dialog ref={modalRef}>{children || <div></div>}</Dialog>
      </Wrap>
    </>,
    modalEl
  );
};

export default Modal;
