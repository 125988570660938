import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { GetIcon, Icon } from 'components/_icons';


export const Container = styled.div`
  ${({ theme }) => css`
    .iframe{
    min-height: 500px;
    height: 90vh;
  }
  .report-style-class {
    height: 600px;
    width: 100%;
  }
  .App {
    text-align: center;
  }
  
    margin-bottom: 50px;
    @media (min-width: ${theme.breakpoints.tablet}px) {
      
    }
    @media (min-width: ${theme.breakpoints.desktop}px) {
      
    }
  `}
`;