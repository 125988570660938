import React from 'react';

const Dashboard = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        d="M4.75 23.5a3.75 3.75 0 110 7.5 3.75 3.75 0 010-7.5zm11.25 0a3.75 3.75 0 110 7.5 3.75 3.75 0 010-7.5zm11.25 0a3.75 3.75 0 110 7.5 3.75 3.75 0 010-7.5zM4.75 12.25a3.75 3.75 0 110 7.5 3.75 3.75 0 010-7.5zm11.25 0a3.75 3.75 0 110 7.5 3.75 3.75 0 010-7.5zm11.25 0a3.75 3.75 0 110 7.5 3.75 3.75 0 010-7.5zM4.75 1a3.75 3.75 0 110 7.5 3.75 3.75 0 010-7.5zM16 1a3.75 3.75 0 110 7.5A3.75 3.75 0 0116 1zm11.25 0a3.75 3.75 0 110 7.5 3.75 3.75 0 010-7.5z"
        stroke="white"
        stroke-width="1.5px"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Dashboard;
