import React, { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import {
  ImageGalleryWrapper,
  Slide,
  Image,
  Caption,
  ChevronRight,
  ChevronLeft,
  ButtonLeft,
  ButtonRight,
  ImageWrapper,
} from './ImageGallery.styles.js';
import ReactHtmlParser from 'react-html-parser';

const formatImageArray = images => {
  return images
    .filter(item => item.original.src && item.thumbnail.src)
    .map((image, index) => {
      const { src: imageSrc, alt: imageAlt } = image?.original || {};
      const { src: thumbnailSrc, alt: thumbnailAlt } = image?.thumbnail || {};
      /* TODO: Add in the proper image urls when BE is ready */
      return {
        caption: image.caption,
        thumbnail: thumbnailSrc,
        original: imageSrc,
        originalAlt: imageAlt,
        thumbnailAlt: thumbnailAlt,
      };
    });
};

const ImageGalleryComponent = ({ images: rawImageData }) => {
  const [startIndex, setStartIndex] = useState(0);

  const hideChevronsAndThumbNails = rawImageData.length === 1;

  const images = formatImageArray(rawImageData);

  const renderSlide = item => {
    const { original, caption, originalAlt } = item;

    const onClickRight = () => {
      setStartIndex(oldIndex => (startIndex + 1 > images.length - 1 ? 0 : oldIndex + 1));
    };

    const onClickLeft = item => {
      setStartIndex(oldIndex => (startIndex - 1 < 0 ? images.length - 1 : oldIndex - 1));
    };

    return (
      <Slide>
        <ImageWrapper>
          <ButtonLeft hideChevronsAndThumbNails={hideChevronsAndThumbNails} onClick={onClickLeft}>
            <ChevronLeft name="chevron" />
          </ButtonLeft>
          <Image src={original} alt={originalAlt} />
          <ButtonRight hideChevronsAndThumbNails={hideChevronsAndThumbNails} onClick={onClickRight}>
            <ChevronRight name="chevron" />
          </ButtonRight>
        </ImageWrapper>
        <Caption>{ReactHtmlParser(caption)}</Caption>
      </Slide>
    );
  };

  return (
    <ImageGalleryWrapper>
      <ImageGallery
        items={images}
        renderItem={renderSlide}
        useTranslate3D={false}
        startIndex={startIndex}
        disableSwipe={true}
        slideDuration={0}
        showThumbnails={!hideChevronsAndThumbNails}
      />
    </ImageGalleryWrapper>
  );
};

ImageGallery.propTypes = {};

export default ImageGalleryComponent;
