import styled from 'styled-components';
import Button from 'components/_common/button';

export const ModalContentComponent = styled.section`
  ${({ theme }) => `
    width: 100%;
    padding: 6rem 3rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${theme.breakpoints.tablet}px) {
      width: 80vw;
      padding: 6rem 10rem 5rem;
    }

    @media (min-width: ${theme.breakpoints.tabletLarge}px) {
      width: 77rem;
    }
  `}
`;

export const ModalHeader = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;

    @media (min-width: ${theme.breakpoints.tablet}) {
      margin-bottom: 4rem;
      max-width: 32rem;
    }
  `}
`;

export const Line = styled.div`
  width: 11.5rem;
  height: 0.1rem;
  background-color: #333;
  margin: 0 3rem;
  flex: 1;
`;

export const Icon = styled.img`
  width: 4rem;
  height: 4rem;
`;

export const Heading = styled.h3`
  font-size: 24px;
  margin: 0 0 3rem;
`;

export const Body = styled.div`
  ${({ theme }) => `
    color: ${theme.colours.lightGrey};
    font-size: 1.6rem;
    margin-bottom: 5rem;
  `}
`;

export const ButtonCTA = styled(Button)`
  margin: 0;
`;
