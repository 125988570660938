import React from 'react';

const OnlineOrdering = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M25.899 29h-1l-.001-1.84-6-.001V29h-1v-2h.054a1.289 1.289 0 01-.631-.806L15 17.454H12.9v-1.293h2.164a1.3 1.3 0 011.194.96l1.641 6.183v-.416h7.875l.834-4.455h-8.708v-1.272h8.686a1.3 1.3 0 011.008.45c.25.29.355.676.285 1.052l-.834 4.454a1.285 1.285 0 01-1.281 1.043h-7.638l.454 1.708h7.32V29zm1.61-29v15.005h-1.25l-.001-10.004H1.25v22.508h14.38v1.25H0V0h27.51zM8.461 17c.487 0 .96.145 1.371.418a.37.37 0 01.1.521.39.39 0 01-.534.098 1.665 1.665 0 00-.937-.287c-.708 0-1.322.456-1.655 1.125h1.655a.38.38 0 01.385.375.38.38 0 01-.385.375h-1.89a2.606 2.606 0 00-.033.375c0 .128.015.253.033.375h1.506a.38.38 0 01.384.375.38.38 0 01-.384.375h-1.27c.332.669.946 1.125 1.654 1.125.336 0 .651-.097.937-.287a.391.391 0 01.535.098.37.37 0 01-.1.521A2.47 2.47 0 018.46 23c-1.126 0-2.091-.778-2.492-1.875h-.584A.38.38 0 015 20.75a.38.38 0 01.385-.375h.418c-.014-.125-.034-.247-.034-.375s.02-.25.034-.375h-.418A.38.38 0 015 19.25a.38.38 0 01.385-.375h.584C6.37 17.778 7.335 17 8.461 17zm0-9c.487 0 .96.145 1.371.418a.37.37 0 01.1.521.39.39 0 01-.534.098 1.665 1.665 0 00-.937-.287c-.708 0-1.322.456-1.655 1.125h1.655a.38.38 0 01.385.375.38.38 0 01-.385.375h-1.89a2.606 2.606 0 00-.033.375c0 .128.015.253.033.375h1.506a.38.38 0 01.384.375.38.38 0 01-.384.375h-1.27c.332.669.946 1.125 1.654 1.125.336 0 .651-.097.937-.287a.391.391 0 01.535.098.37.37 0 01-.1.521A2.47 2.47 0 018.46 14c-1.126 0-2.091-.778-2.492-1.875h-.584A.38.38 0 015 11.75a.38.38 0 01.385-.375h.418c-.014-.124-.034-.247-.034-.375s.02-.25.034-.375h-.418A.38.38 0 015 10.25a.38.38 0 01.385-.375h.584C6.37 8.778 7.335 8 8.461 8zm7.04 3.5v1.251H13v-1.25h2.5zM23.003 9v1.25H13V9h10.003zm3.256-7.75H1.25v2.501h25.008v-2.5zM3.75 1.876v1.25h-1.25v-1.25h1.25zm2.501 0v1.25h-1.25v-1.25h1.25z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default OnlineOrdering;
