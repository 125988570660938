import styled, { css } from 'styled-components';

export const NotificationHeaderComponent = styled.section`
  display: flex;
  flex-direction: row;
  padding: 23px 27px;
  border-bottom: 1px solid #efefef;
  margin-bottom: 8px;
`;

export const NotificationHeaderWrapper = styled.div`
  ${({ align }) => css`
    width: 100%;
    text-align: ${align ? align : 'center'};
  `}
`;

export const NotificationHeaderText = styled.p`
  ${({
    theme: {
      fonts: { NotoSansBold },
    },
    align,
  }) => css`
    width: 100%;
    text-align: ${align ? align : 'center'};
    font-size: 16px;
    font-family: ${NotoSansBold.value};
    font-weight: ${NotoSansBold.fontWeight};
    font-style: ${NotoSansBold.fontStyle};
    color: #333333;
    line-height: 28px;
    margin: 0;
  `}
`;

export const NotificationUnread = styled.span`
  ${({
    theme: {
      fonts: { NotoSansRegular },
    },
  }) => css`
    font-size: 12px;
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    background-color: #626162;
    color: #fff;
    line-height: 26px;
    letter-spacing: -0.48px;
    margin-left: 15px;
    padding: 2px 10px;
    border-radius: 10px;
  `}
`;
