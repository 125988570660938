import React from 'react';
import { LoadingSpinnerComponent } from './LoadingSpinner.styles.js';

const LoadingSpinner = props => {
  return (
    <>
      <LoadingSpinnerComponent place={props.place}></LoadingSpinnerComponent>
      <div></div>
    </>
  );
};

LoadingSpinner.propTypes = {};

export default LoadingSpinner;
