import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TilesComponent, IndvidualComponent } from './Tiles.styles.js';
import Tile from 'components/_common/tile/Tile.js';
import Popup from '../popup/index';

const Tiles = ({ tiles, Messages, brand, langFolder, pageID }) => {
  const [showPopup, setShowPopup] = useState(false);

  const unAuthorizedClick = () => {
    setShowPopup(true);
  }

  return (
    <>
      {
        tiles?.length > 1 ? (
          <TilesComponent count={tiles.length}>
            {tiles.map((tile, index) => (
              <Tile key={`${tile.label}-${index}`} {...tile} unAuthorizedClick={unAuthorizedClick} brand={brand} langFolder={langFolder} pageID={pageID}></Tile>
            ))}
          </TilesComponent>
        ) : (
          <IndvidualComponent count={tiles.length}>
            {tiles?.map((tile, index) => (
              <Tile key={`${tile.label}-${index}`} {...tile} unAuthorizedClick={unAuthorizedClick} brand={brand} langFolder={langFolder} pageID={pageID}></Tile>
            ))}
          </IndvidualComponent>
        )
      }
      <Popup
          handleNo={() => { setShowPopup(false); } }

          show={showPopup}
          headerText={Messages?.notauthorized?.header}
          detailText={Messages?.notauthorized?.message}
          closeBtnText={"X"}
      />
    </>
  )
};

Tiles.propTypes = {
  tiles: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Tiles;
