import React from 'react';

const SafetyRegulations = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M3.265 20.445v1.265c0 1.558 4.905 3.554 10.698 3.775l.529.015.525.005c6.239 0 11.753-2.143 11.753-3.795v-1.265h1.305v1.265c0 2.666-6.158 5.06-13.058 5.06-6.9 0-13.058-2.394-13.058-5.06v-1.265h1.306zm21.286-.58l.52 1.16c-1.946.818-5.606 1.318-10.054 1.318s-8.108-.5-10.053-1.317l.519-1.161c1.749.734 5.25 1.213 9.534 1.213 4.285 0 7.785-.479 9.534-1.213zM9.54 5.315c.43-.176.907.13.907.583v4.427H9.14V6.893c-3.64 1.829-5.876 5.166-5.876 9.125v2.53c0 .35-.293.632-.653.632H1.306v2.53H0v-3.162c0-.35.292-.633.653-.633h1.306v-1.897c0-4.804 2.942-8.804 7.58-10.703zm10.047.583c0-.453.477-.76.908-.583 4.639 1.9 7.58 5.899 7.58 10.703v1.897h1.306c.36 0 .653.283.653.633v3.162h-1.306v-2.53h-1.306a.643.643 0 01-.652-.632v-2.53c0-3.959-2.238-7.296-5.877-9.125v3.432h-1.306zM17.63 4c.36 0 .653.283.653.633v6.957h-1.306V5.265h-3.918v6.325h-1.306V4.633c0-.35.293-.633.653-.633z"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default SafetyRegulations;
