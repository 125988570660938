import styled from 'styled-components';

export const TilesComponent = styled.section`
  ${({ theme, count }) => `
    padding: 25px;
    // justify-content: center;

    // display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    // grid-auto-rows: 1fr;
    // grid-gap: 30px;
    // flex-wrap: wrap;
    // margin: 0 auto;
		// max-width: 1000px;

    display: flex;
    flex-wrap: wrap;
    width: 100%;


    @media (max-width: ${theme.breakpoints.tablet - 1}px) {
      &:before {
        content: '';
        width: 0;
        padding-bottom: 100%;
        grid-row: 1 / 1;
        grid-column: 1 / 1;
      }

      & > *:first-child {
        grid-row: 1 / 1;
        grid-column: 1 / 1;
      }

        
      display: block;
      // flex-direction: column;
      // justify-content: center;
      // align-items: center;
    }

    @media (min-width: ${theme.breakpoints.tablet}px) {
      padding: 50px 40px;
      // grid-auto-rows: 110px;
      // grid-template-columns:70%;
      // grid-gap: 40px;
    }

    @media (min-width: ${theme.breakpoints.desktop}px) {
      padding: 90px 10%;
      // grid-template-columns:50% 50%;
      
    }

    @media (min-width: ${theme.breakpoints.largeDesktop}px) {
      // grid-template-columns: ${count === 1 ? '50%' : 'repeat(3, minmax(50%, 50%))'};
      
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      max-width: none;
      margin: 0 auto;
      justify-content:center;
      a {
        max-width: none;
        width: calc(100% - 30px);
        margin: 1.5rem;
        height: 100%;
      }
    }

    @media (min-width: ${
      theme.breakpoints.mobileLarge
    }px) and (-ms-high-contrast: none), (-ms-high-contrast: active){
      a {
        width: calc(50% - 30px);
        padding: 2rem 4rem 2rem 3rem;
      }
    }

    @media (min-width: ${theme.breakpoints.tablet +
      1}px) and (-ms-high-contrast: none), (-ms-high-contrast: active){
      max-width: 80rem;
      a {
        max-width: 32rem;
        width: 100%;
        padding: 2rem 4rem 2rem 3rem;
        padding-bottom: 2rem;

      }
    }

    @media (min-width: ${
      theme.breakpoints.tabletLarge
    }px) and (-ms-high-contrast: none), (-ms-high-contrast: active){
      max-width: 140rem;
    }
  `}
`;

export const IndvidualComponent = styled.section`
  ${({ theme, count }) => `
    padding: 25px;
    display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    // grid-auto-rows: 1fr;
    // grid-gap: 30px;
    // flex-wrap: wrap;
    // justify-content: flex-start;
    // margin: 0 auto;
		// max-width: 1000px;

    
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @media (max-width: ${theme.breakpoints.tablet - 1}px) {
      &:before {
        content: '';
        width: 0;
        padding-bottom: 100%;
        grid-row: 1 / 1;
        grid-column: 1 / 1;
      }

      & > *:first-child {
        grid-row: 1 / 1;
        grid-column: 1 / 1;
      }
    }

    @media (min-width: ${theme.breakpoints.tablet}px) {
      padding: 50px 40px;
      // grid-auto-rows: 110px;
      // grid-template-columns: ${count === 1 ? '50%' : '50% 50%'};
      // grid-gap: 40px;
    }

    @media (min-width: ${theme.breakpoints.desktop}px) {
      padding: 90px 10%;
    }

    @media (min-width: ${theme.breakpoints.largeDesktop}px) {
      // grid-template-columns: ${count === 1 ? '50%' : 'repeat(3, minmax(50%, 50%))'};
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      max-width: none;
      margin: 0 auto;
      justify-content:center;
      a {
        max-width: none;
        width: calc(100% - 30px);
        margin: 1.5rem;
        height: 100%;
      }
    }

    @media (min-width: ${
      theme.breakpoints.mobileLarge
    }px) and (-ms-high-contrast: none), (-ms-high-contrast: active){
      a {
        width: calc(50% - 30px);
        padding: 2rem 4rem 2rem 3rem;
      }
    }

    @media (min-width: ${theme.breakpoints.tablet +
      1}px) and (-ms-high-contrast: none), (-ms-high-contrast: active){
      max-width: 80rem;
      a {
        max-width: 32rem;
        width: 100%;
        padding: 2rem 4rem 2rem 3rem;
        padding-bottom: 2rem;

      }
    }

    @media (min-width: ${
      theme.breakpoints.tabletLarge
    }px) and (-ms-high-contrast: none), (-ms-high-contrast: active){
      max-width: 140rem;
    }
  `}
`;
