import React from 'react';

const ServiceTraining = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M26.242 0v27.803h-11.12v-1.236h9.884v-4.325H23.77v3.09l-9.267-.001v4.196l-2.471-1.524-2.471 1.524-.001-4.196H6.471v-1.235l3.089-.001V22.86h4.943v1.235h8.032v-1.853H7.439a2.175 2.175 0 00-1.411.496l-.143.13c-.408.4-.641.946-.65 1.518a2.212 2.212 0 002.213 2.181h1.495v1.236H7.448A3.448 3.448 0 014 24.41l1.236.001-.001-.002H4l.002-.055.005-.155a3.41 3.41 0 01.008-.105H4V3.708A3.707 3.707 0 017.707 0h18.535zM13.268 24.096h-2.472v3.218l1.236-.762 1.236.762v-3.218zm11.738-22.86H7.707a2.471 2.471 0 00-2.471 2.471l-.001 18.084a3.403 3.403 0 012.213-.785h17.558V1.236zM7.943 2.47v17.3H6.707V2.47h1.236zM15.653 5c3.12 0 5.651 2.556 5.651 5.708 0 1.563-.621 2.98-1.629 4.01L22 17.066l-.727.734-2.386-2.41a5.588 5.588 0 01-3.235 1.027c-3.121 0-5.652-2.556-5.652-5.709C10 7.556 12.53 5 15.652 5zm0 1.038c-2.555 0-4.625 2.09-4.625 4.67s2.07 4.67 4.624 4.67 4.625-2.09 4.625-4.67-2.07-4.67-4.625-4.67z"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default ServiceTraining;
