import React from 'react';

const Vicon = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170 38.5">
      <g fill="none" fillRule="evenodd" transform="translate(-110 -32)">
        <path
          d="M163.92 37.465h5.183s-4.294 5.11-4.856 5.726c.38.685 1.847 3.793 2.428 5.004.58 1.21 2.47 5.638 4.122 6.74h-4.86s-1.565-.29-2.597-2.35c-1.033-2.057-2.43-4.788-2.592-5.492-.346.307-1.307 1.464-1.342 1.537v4.515s.07.94 1.104 1.79h-6.943s.98-.866.98-1.99V39.85s-.09-1.897-.997-2.35h6.778s-.922.324-.922 1.97v5.383s.125-.036.18-.109c.054-.071 3.88-4.507 4.261-5.302.38-.796.543-1.384.073-1.977"
          fill="#C5242B"
        />
        <path
          d="M166.294 42.955h6.004s2.536 5.71 2.843 6.47c.71-1.337 1.002-2.025 1.233-2.513.24-.488.782-1.482.855-1.699.072-.217.273-.577.236-1.083-.038-.506-.383-1.137-.49-1.175h4.243s-5.804 11.889-5.82 12.013c-.017.13-1.143.527-2.049-.25-.908-.776-1.234-1.23-1.668-2.005-.436-.778-3.538-7.283-3.736-7.68-.196-.397-.96-1.591-1.65-2.078M186.656 44.673c1.903 0 1.885 2.293 1.903 2.276h-3.843s.036-2.276 1.94-2.276zm1.634 7.913c-2.375 0-3.666-1.753-3.666-3.344h8.124s.51-6.722-6.146-6.722c-6.653 0-6.527 6.49-6.527 6.49s-.47 5.725 6.671 6.087c2.57.13 5.036-.901 5.094-1.103.092-.308.925-3.179.925-3.179s-2.101 1.77-4.475 1.77zM223.826 44.673c1.902 0 1.884 2.293 1.902 2.276h-3.842s.031-2.276 1.94-2.276zm1.63 7.913c-2.373 0-3.66-1.753-3.66-3.344h8.12s.506-6.722-6.146-6.722c-6.653 0-6.526 6.49-6.526 6.49s-.472 5.725 6.672 6.087c2.565.13 5.035-.901 5.093-1.103.093-.308.925-3.179.925-3.179s-2.102 1.77-4.479 1.77zM199.637 54.93h-6.362s.87-.74.87-1.48v-8.69s-.218-1.213-1.016-1.717h5.367v1.372s1.197-2.005 3.698-1.717v3.305s-1.54-.375-2.663.345c-.835.538-.835 1.53-.835 1.915v5.167s.018.814.941 1.5M202.501 54.93h6.546s-1.05-.776-1.05-1.697v-5.494s-.093-2.111 1.669-2.111c1.757 0 1.666 1.84 1.666 1.84v5.731s-.072 1.085-.96 1.736h6.563s-.962-.76-.962-1.61v-6.199s.237-4.518-4.604-4.518c-1.674 0-3.283.993-3.59 1.61V42.97h-5.278s.928.796.928 1.754v8.58s-.074.959-.928 1.625M235.937 47.75v5.494c0 .921 1.05 1.697 1.05 1.697h-6.545c.853-.668.927-1.624.927-1.624V39.241s-.292-1.517-1.128-1.805h5.696V47.75M238.803 46.173l1.176-3.073s2.012-.651 3.988-.543c1.976.109 3.61.651 4.188 1.571.582.922.872 2.08.872 2.856v4.264s-.166 1.534 1.523 1.736c-.617 1.244-.98 1.93-.98 1.93s-2.72.508-3.915-.757c-1.197-1.265-1.324-2.404-1.25-4.987-.672.18-2.756.723-2.651 2.257.109 1.538 1.944 1.573 2.377 1.538-.76 1.607-.851 1.843-.851 1.843s-5.512 1.749-6.185-3.237c-.446-3.326 5.254-4.103 4.84-4.008.962-.217 2.523-.688 2.431-1.374-.09-.687-.904-2.223-5.563-.016"
          fill="#C5242B"
        />

        <g fill="none" fillRule="evenodd" transform="translate(110 32.02)">
          <path
            d="M140.55 22.902h6.543s-1.05-.775-1.05-1.697v-5.492s-.091-2.113 1.667-2.113c1.757 0 1.667 1.842 1.667 1.842v5.728s-.07 1.083-.96 1.732h6.565s-.961-.757-.961-1.605V15.1s.234-4.517-4.607-4.517c-1.674 0-3.28.994-3.589 1.608v-1.247h-5.275s.924.797.924 1.754v8.582s-.07.956-.924 1.623M163.248 21.089l-.978 1.97s-3.592.868-5.93-2.296c-2.268-3.068-.756-7.465.909-8.67 1.595-1.159 2.392-1.5 3.862-1.464 1.468.037 2.718.634 2.864.776V7.249s-.362-1.589-1.142-1.824h5.656v15.683s.146 1.32 1.125 1.807h-5.606V15.18s-.07-1.88-1.738-1.88-2.3 2.115-2.3 3.487c0 1.374.379 3.867 3.278 4.3"
            fill="#C5242B"
            mask="url(#c)"
          />
          <path
            d="M25.512.029c-.737.014-1.84-.048-2.87.56-1.497.884-5.16 4.862-5.16 4.862h14.934l.016 15.446s4.19-3.986 5.077-5.474c.61-1.027.564-2.123.576-2.858.014-.752 0-12.539 0-12.539S26.265.016 25.512.03"
            fill="#007945"
            mask="url(#c)"
          />
          <path
            d="M21.436 7.503l7.697-.01-15.47 15.396L0 22.87s4.174-4.544 5.47-5c1.289-.456 1.998-.42 5.917-.424 2.319-2.344 10.05-9.944 10.05-9.944M30.61 16.567l.006-7.9-15.431 15.296v14.122s4.432-4.238 4.89-5.524c.456-1.287.536-2.07.42-5.899 2.353-2.311 10.116-10.095 10.116-10.095"
            fill="#007945"
            mask="url(#c)"
          />
        </g>
      </g>
    </svg>
  );
};

export default Vicon;
