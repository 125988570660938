import React from 'react';

const Brochure = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M19.007.023a.643.643 0 01.815.616v2.553a.641.641 0 01-.644.638.641.641 0 01-.645-.638V1.476L7.551 4.468h17.427c.323 0 .591.237.637.544l.007.095v20.127a.641.641 0 01-.644.638.641.641 0 01-.645-.638V5.745H6.29v22.978H22.4c.356 0 .644.286.644.639A.641.641 0 0122.4 30H5.644A.641.641 0 015 29.362V4.326a.64.64 0 01.473-.615zm-1.118 24.232c.356 0 .644.286.644.639a.641.641 0 01-.644.638h-5.156a.641.641 0 01-.644-.638c0-.353.288-.639.644-.639zm-2.553-14.219l5.68 2.367a.473.473 0 01.291.437v6.154a.473.473 0 01-.291.437l-5.68 2.366a.473.473 0 01-.364 0l-5.68-2.366A.473.473 0 019 18.994V12.84c0-.191.115-.363.291-.437l5.68-2.367a.473.473 0 01.365 0zm-5.39 3.987v4.655l4.734 1.972v-4.654l-4.734-1.973zm10.414 0l-4.734 1.973v4.654l4.734-1.972v-4.655zm-8.032-1.86l-2.192.914 5.018 2.09 2.091-.872-4.917-2.132zm2.826-1.177l-1.612.671 4.917 2.132 1.712-.712-5.017-2.09z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Brochure;
