import styled from 'styled-components';
import { externalStyles } from 'components/image-gallery/ImageGalleryExternalStyles.styles';
import { Icon } from 'components/_icons';

export const ImageGalleryWrapper = styled.section`
  ${({
    theme: {
      breakpoints: { tablet },
    },
  }) => `
    ${externalStyles}
    .image-gallery  {
      margin: 0 2.5rem 3rem;
      max-width: 81.9rem;
      @media (min-width: ${tablet}px) {
        margin: 0 auto 5rem;
        padding: 2.5rem
      }
    }
  `}
`;

export const Slide = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Image = styled.img`
  max-width: 76.9rem;
  width: 100%;
`;

export const Caption = styled.p`
  ${({
    theme: {
      colours: { darkerOffWhite, lightGrey },
      fonts: { NotoSansRegular },
      breakpoints: { mobileLarge },
    },
  }) => `
    text-align: left;
    line-height: 2.4rem;
    max-width: 76.9rem;
    white-space: normal;
    margin: 0;
    padding: 0.5rem 0.8rem;
    background-color: ${darkerOffWhite};
    color: ${lightGrey};
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    font-size: 1.2rem;
    font-weight: normal;
    line-height: 24px;

    @media (min-width: ${mobileLarge}px) {
      font-size: 1.4rem;
      padding: 1.5rem 3rem;
    }
  `}
`;

export const ImageWrapper = styled.div`
  position: relative;
`;

export const ChevronRight = styled(Icon)`
  ${({
    theme: {
      colours: { white },
    },
  }) => `
    width: 2rem;
    height: 2rem;
    transform: rotate(-360deg);
    fill: ${white};
  `}
`;

export const ChevronLeft = styled(Icon)`
  ${({
    theme: {
      colours: { white },
    },
  }) => `
    width: 2rem;
    height: 2rem;
    transform: rotate(180deg);
    fill: ${white};
  `}
`;

export const ButtonLeft = styled.button`
  ${({
    hideChevronsAndThumbNails,
    theme: {
      breakpoints: { mobileLarge },
    },
  }) => `
    display: ${hideChevronsAndThumbNails ? 'none' : 'flex'};
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(50% + 25px);
    transform: translateY(-50%);
    left: 3rem;
    border: 1px solid white;
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    background-color: rgba(0, 0, 0, 0.2);

    @media (min-width: ${mobileLarge}px) {
      top: 50%;
    }
  `}
`;

export const ButtonRight = styled.button`
  ${({
    hideChevronsAndThumbNails,
    theme: {
      breakpoints: { mobileLarge },
    },
  }) => `
    display: ${hideChevronsAndThumbNails ? 'none' : 'flex'};
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(50% + 25px);
    transform: translateY(-50%);
    right: 3rem;
    border: 1px solid white;
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    background-color: rgba(0, 0, 0, 0.2);

    @media (min-width: ${mobileLarge}px) {
      top: 50%;
    }
  `}
`;
