import React, { useContext, useEffect,useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';

import {
    Model,
    Container,
    Button,
    SubContainer,
    Img,
    DetilsContainer,
    Head,
    Message,
    ButtonsContainer
} from './popup.styles';


const Popup = (props) => {
    const {
        show, // boolean - visible/invisible
        headerText, // text
        detailText, // html / inner text
        showOkButton,
        handleYes,
        handleNo,
        closeBtnText,
        iconUrl
    } = { ...props };

    const sendYes = () => handleYes();

    const sendNo = () => handleNo();

    const ref = useRef(null);
    return (
    <Model show={show}>
        <Container ref={ref}>
            <SubContainer>
                {iconUrl && <Img src={iconUrl} />}
                <DetilsContainer>
                    <Head>{ReactHtmlParser(headerText)}</Head>
                    <Message dangerouslySetInnerHTML={{ __html: detailText }}></Message>
                </DetilsContainer>
                <ButtonsContainer>
                    <Button show={showOkButton} onClick={sendYes} primary={true}>YES</Button>
                    <Button show={true} onClick={sendNo} primary={false}>{closeBtnText}</Button>
                </ButtonsContainer>
           </SubContainer>
        </Container>
    </Model>
    );
};

export default Popup;
