import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import axios from 'axios';
import { getSiteEndpoint } from 'helpers/api';
import { Auth } from 'components/context/AuthContext';
import LoadingSpinner from 'components/_common/loading-spinner';


import {
     Container,
     HeaderComponent,
    TableWrapper, Header, TableContent, Row, Column, iframe
} from './pdf-viewer.styles';


const PDFViewer = ({ sourceUrl, brand, langFolder, pageID }) => {
    
    const [loading, setLoading] = useState(true);
    const { userToken } = useContext(Auth);

    return (
        <Container>
            <div className="App">
                <iframe width="70%" height="100vh" className='iframe' src={ sourceUrl } frameborder="0" allowfullscreen></iframe>
            </div>
        </Container>
    );
};

export default PDFViewer;

