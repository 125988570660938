export const brands = {
  default: {
    activeColour: '#232323',
    primary: 'rgba(51, 51, 51, 1.0)',
    accentColour: '#ffffff',
  },
  'Kverneland Group': {
    activeColour: '#232323',
    primary: 'rgba(51, 51, 51, 1.0)',
    accentColour: '#ffffff',
  },
  Kverneland: {
    activeColour: '#24542F',
    primary: '#347942',
    accentColour: '#FFFFFF',
  },
  Kubota: {
    activeColour: '#ff6600',
    primary: '#ff6600',
    accentColour: '#ffffff',
  },
  Vicon: {
    activeColour: '#112536',
    primary: '#18344F',
    accentColour: '#FFFFFF',
  },
};
const theme = {
  padding: {},
  margins: {},
  breakpoints: {
    mobileLarge: 375,
    mobileMax: 480,
    tablet: 768,
    tabletLarge: 1024,
    desktop: 1200,
    largeDesktop: 1920,
  },
  colours: {
    primary: 'rgb(51, 51, 51)',
    darkGrey: 'rgb(51, 51, 51)',
    lightGrey: 'rgb(106, 106, 106)',
    hairlineGrey: '#f2f2f2',
    lighterGrey: '#F9FAFB',
    red: '#d0021b',
    white: '#ffffff',
    offWhiteTable: '#F2F2F2',
    offWhite: 'rgb(249, 250, 251)',
    darkerOffWhite: 'rgb(242, 242, 242)',
    black: 'rgb(0, 0, 0)',
    borderGrey: 'rgb(150, 150, 150)',
    fileDownloadBorder: 'rgb(216, 216, 216)',
    accent: '#007b3d',
    unReadNotificationBackground: 'rgb(249, 250, 251)',
    mattBlack: 'rgb(80, 80, 80)',
    notificationBorder: 'rgb(236, 236, 236)',
  },
  fonts: {
    NotoSansRegular: {
      value: 'Noto Sans, Verdana, Helvetica Neue, sans-serif',
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
    NotoSansRegularItalic: {
      value: 'Noto Sans, Verdana, Helvetica Neue, sans-serif',
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
    NotoSansMedium: {
      value: 'Noto Sans, Verdana, Helvetica Neue, sans-serif',
      fontWeight: 500,
      fontStyle: 'normal',
    },
    NotoSansExtraBold: {
      value: 'Noto Sans ExtBd, Verdana, Helvetica Neue, sans-serif',
      fontWeight: '800',
      fontStyle: 'normal',
    },
    NotoSansExtraBoldItalic: {
      value: 'Noto Sans ExtBd, Verdana, Helvetica Neue, sans-serif',
      fontWeight: '800',
      fontStyle: 'italic',
    },
    NotoSansBold: {
      value: 'Noto Sans, Verdana, Helvetica Neue, sans-serif',
      fontWeight: 'bold',
      fontStyle: 'normal',
    },
    NotoSansBoldItalic: {
      value: 'Noto Sans, Verdana, Helvetica Neue, sans-serif',
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
    NotoSansSemiBold: {
      value: 'Noto Sans SemBd, Verdana, Helvetica Neue, sans-serif',
      fontWeight: '600',
      fontStyle: 'normal',
    },
    NotoSansSemiBoldItalic: {
      value: 'Noto Sans SemBd, Verdana, Helvetica Neue, sans-serif',
      fontWeight: '600',
      fontStyle: 'italic',
    },
  },
};

export default theme;
