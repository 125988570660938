import React from 'react';

const Chevron = ({ className }) => {
  return (
    <svg xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 10 16" className={className}>
      <g fillRule="evenodd" transform="translate(-10 -7)">
        <path id="a" d="M12.5 7.5l-1.762 1.762L16.462 15l-5.724 5.738L12.5 22.5 20 15z" />
      </g>
    </svg>
  );
};

export default Chevron;
