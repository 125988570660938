import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { Icon } from 'components/_icons';
import { Link } from 'react-router-dom';

export const InternalLinkComponent = styled(Link)`
  ${({
    theme: {
      colours,
      fonts: { NotoSansExtraBold },
    },
    dark,
  }) => css`
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${NotoSansExtraBold.value};
    font-style: ${NotoSansExtraBold.fontStyle};
    font-weight: ${NotoSansExtraBold.fontWeight};
    font-size: 1.4rem;
    background-color: ${dark ? colours.primary : colours.white};
    border: 1px solid ${colours.primary};
    color: ${dark ? colours.white : colours.primary};
    padding: 2.1rem 4rem 2rem;
    letter-spacing: 0.02px;
    text-transform: uppercase;
    margin-bottom: 2rem;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:disabled {
      cursor: default;
    }

    svg {
      path {
        transition: fill 0.2s ease-in-out;
      }
    }

    &:hover,
    &:focus {
      background-color: ${lighten(dark ? 0.1 : 0.2, dark ? colours.primary : colours.white)};
      border: 1px solid ${lighten(dark ? 0.1 : 0.2, colours.primary)};
      color: ${lighten(dark ? 0.1 : 0.2, dark ? colours.white : colours.primary)};

      svg {
        path {
          fill: ${lighten(dark ? 0.1 : 0.2, dark ? colours.white : colours.primary)};
        }
      }
    }
  `}
`;

export const LinkComponent = styled.button`
  ${({
    theme: {
      colours,
      fonts: { NotoSansExtraBold },
    },
    dark,
  }) => css`
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${NotoSansExtraBold.value};
    font-style: ${NotoSansExtraBold.fontStyle};
    font-weight: ${NotoSansExtraBold.fontWeight};
    font-size: 1.4rem;
    background-color: ${dark ? colours.primary : colours.white};
    border: 1px solid ${colours.primary};
    color: ${dark ? colours.white : colours.primary};
    padding: 2.1rem 4rem 2rem;
    letter-spacing: 0.02px;
    text-transform: uppercase;
    margin-bottom: 2rem;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:disabled {
      cursor: default;
    }

    svg {
      path {
        transition: fill 0.2s ease-in-out;
      }
    }

    &:hover,
    &:focus {
      background-color: ${lighten(dark ? 0.1 : 0.2, dark ? colours.primary : colours.white)};
      border: 1px solid ${lighten(dark ? 0.1 : 0.2, colours.primary)};
      color: ${lighten(dark ? 0.1 : 0.2, dark ? colours.white : colours.primary)};

      svg {
        path {
          fill: ${lighten(dark ? 0.1 : 0.2, dark ? colours.white : colours.primary)};
        }
      }
    }
  `}
`;

export const LinkIcon = styled(Icon)`
  ${({ theme: { colours }, dark }) => css`
    width: 2rem;
    height: 2rem;
    fill: ${dark ? colours.white : colours.primary};
    margin-right: 0.5rem;
  `}
`;

export const LinkIconSrc = styled.img`
  ${({ theme: { colours }, dark }) => css`
    width: 2rem;
    height: 2rem;
    fill: ${dark ? colours.white : colours.primary};
    margin-right: 0.5rem;
  `}
`;
