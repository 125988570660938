import styled from 'styled-components';

const CloseButton = styled.button`
  ${({
    theme: {
      colours,
      fonts: { NotoSansExtraBold },
      breakpoints: { tabletLarge, tablet },
    },
    dark,
    brand,
  }) => `
      position: absolute;
      top: 5px;
      right: 5px;
      width: 2.5rem;
      height: 2.5rem;
      font-family: ${NotoSansExtraBold.value};
      font-style: ${NotoSansExtraBold.fontStyle};
      font-weight: ${NotoSansExtraBold.fontWeight};
      font-size: 1.4rem;
      border-radius: 50%;
      border:none;
      color: ${dark ? colours.white : colours.primary};
      padding:7px
      cursor: pointer;

      &:hover, &:focus {
        cursor: pointer;
        color: ${colours.primary};
        background-color: lightgray;
        border-radius: 50%;
      }
      @media (max-width: ${tabletLarge}px) {
        display: none;
    `}
`;

export default CloseButton;
