import styled from 'styled-components';
import Link from 'components/_common/Link';

export const SignupComponent = styled.section`
  ${({
    theme: {
      breakpoints: { tablet },
    },
  }) => `
    max-height: 674px;
    max-width: 560px;
    padding: 3rem 2.5rem 3.1rem;
    @media (min-width: ${tablet}px) {
      padding: 17.5rem 6.2rem 17.5rem 11rem;
    }
  `}
`;

export const Title = styled.h1`
  ${({
    theme: {
      fonts: { NotoSansBold },
      breakpoints: { tablet },
    },
  }) => `
      font-family: ${NotoSansBold.value};
      font-style: ${NotoSansBold.fontStyle};
      font-weight: ${NotoSansBold.fontWeight};
      color: rgb(51, 51, 51);
      font-size: 2.4rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
      @media (min-width: ${tablet}px) {
        font-size: 4.8rem;
        line-height: 5.5rem;
        margin-bottom: 3rem;
      }
  `}
`;

export const Description = styled.p`
  ${({
    theme: {
      fonts: { NotoSansRegular },
      breakpoints: { tablet },
    },
  }) => `
      font-family: ${NotoSansRegular.value};
      font-style: ${NotoSansRegular.fontStyle};
      font-weight: ${NotoSansRegular.fontWeight};
      font-size: 1.4rem;
      color: rgb(106, 106, 106);
      line-height: 2.4rem;
      margin-bottom: 2rem;
      margin-top: 0;
      @media (min-width: ${tablet}px) {
        font-size: 1.6rem;
        margin-bottom: 3rem;
      }
  `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LinkCTA = styled(Link)`
  ${({
    theme: {
      breakpoints: { tablet },
    },
  }) => `
    width: 100%;
    max-width: none;
    padding-right: 1.5rem;
    padding-left: 1.5rem;

  `}
`;
