import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getSiteEndpoint } from 'helpers/api';
import { FormContext } from 'components/context/FormContext';
import Dropdown from 'components/_common/dropdown';
import {
  RequestAccessWrapper,
  Form,
  Field,
  Label,
  SubmitButton,
  Terms,
  Error,
  GridField,
} from 'components/request-access/RequestAccess.styles';
import ReactHtmlParser from 'react-html-parser';

const RequestAccess = ({
  accountID,
  additionalInfo,
  company,
  ctaLink,
  customerId,
  email,
  jobRole,
  postcode,
  location,
  firstName,
  lastName,
  phone,
  countryCode,
  locations,
  jobRoles,
  cookiesLink,
  privacyLink,
  labels = {},
  gatewayUrl,
  ...rest
}) => {
  const { custom_t_json: rawLocationValues = [] } = locations || {};
  const { custom_t_json: jobRoleValues = [] } = jobRoles || {};
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedRole, setSelectedRole] = useState([]);
  const [formSuccess, setFormSuccess] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [loadingForm, setLoadingForm] = useState(null);

  const { formDispatch } = useContext(FormContext);

  useEffect(() => {
    if (formValues) {
      setLoadingForm(true);
      axios({
        method: 'post',
        url: `${getSiteEndpoint()}/form/requestAccess`,
        data: formValues,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(function(response) {
          setFormSuccess(true);
          setLoadingForm(false);
          formDispatch({
            type: 'REQUEST_ACCESS_TOGGLE_SUCCESS_MODAL',
          });
        })
        .catch(function(error) {
          setFormSuccess(false);
          setLoadingForm(false);
          formDispatch({
            type: 'REQUEST_ACCESS_TOGGLE_ERROR_MODAL',
          });
        });
    }
  }, [formDispatch, formValues]);

  const locationValues = Object.values(locations)
    ?.filter(item => item.country)
    .map(item => item.country);

  let termsAndConditions = labels['request_terms_and_conditions'];

  termsAndConditions =
    termsAndConditions &&
    termsAndConditions.replace(
      '{0}',
      ` <a href={${privacyLink.url}} target={${privacyLink.target}} alt={${privacyLink.alt}}>
      ${privacyLink.title}
    </a> `
    );

  termsAndConditions =
    termsAndConditions &&
    termsAndConditions.replace(
      '{1}',
      ` <a href={${cookiesLink.url}} target={${cookiesLink.target}} alt={${cookiesLink.alt}}>
      ${cookiesLink.title}
    </a> `
    );

  const isRequiredAsterix = required => (required === 'yes' ? '*' : '');

  const schema = Yup.object().shape({
    location:
      location?.isRequired === 'yes' ? Yup.string().required(location?.requiredMessage) : Yup.string(),
    firstName:
      firstName?.isRequired === 'yes' ? Yup.string().required(firstName?.requiredMessage) : Yup.string(),
    lastName:
      lastName?.isRequired === 'yes' ? Yup.string().required(lastName?.requiredMessage) : Yup.string(),
    jobRole: jobRole?.isRequired === 'yes' ? Yup.string().required(jobRole?.requiredMessage) : Yup.string(),
    company: company?.isRequired === 'yes' ? Yup.string().required(company?.requiredMessage) : Yup.string(),
    postcode: postcode?.isRequired === 'yes' ? Yup.string().required(postcode?.requiredMessage) : Yup.string(),

    accountID: accountID?.isRequired === 'yes' ? Yup.string().required(accountID?.requiredMessage) : Yup.string(),
    additionalInfo: additionalInfo?.isRequired === 'yes' ? Yup.string().required(additionalInfo?.requiredMessage) : Yup.string(),
    
    emailAddress:
      email.isRequired === 'yes'
        ? Yup.string()
            .email(email.errorMessage)
            .required(email.requiredMessage)
        : Yup.string().email(email.errorMessage),
    phoneNumber:
      phone.isRequired === 'yes'
        ? Yup.number()
            .typeError(phone.errorMessage)
            .required(phone.requiredMessage)
        : Yup.number(),
    customerId:
      customerId?.isRequired === 'yes'
        ? Yup.number()
            .typeError(customerId?.errorMessage)
            .required(customerId?.requiredMessage)
        : Yup.number(),
  });

  if (formSuccess === false || formSuccess === true) {
    return <Redirect to={gatewayUrl.url} />;
  }

  return (
    <RequestAccessWrapper>
      <Formik
        initialValues={{
          location: '',
          firstName: '',
          lastName: '',
          jobRole: '',
          postCode:'',
          company: '',
          accountID: '',
          additionalInfo: '',
          emailAddress: '',
          phoneNumber: '',
          countryCode: '',
          customerId: '',
        }}
        validationSchema={schema}
        onSubmit={values => {
          // same shape as initial values
          const formData = new FormData();

          Object.keys(values).forEach(key => {
            if (key !== 'phoneNumber' && key !== 'countryCode') {
              formData.append(key, values[key]);
            }
          });
          formData.append('phoneNumber', `${values.countryCode}${values.phoneNumber}`);

          formData.append(
            'subject',
            `Partner Portal - Request access for ${values.firstName} ${values.lastName}`
          );

          setFormValues(formData);
        }}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            {location ? (
              <Label>
                {`${ReactHtmlParser(location.label)}${isRequiredAsterix(location.isRequired)}`}
                <Dropdown
                  selectedValue={selectedLocation}
                  handleChange={e => {
                    const {
                      target: { value },
                    } = e;
                    setSelectedLocation(value);
                    setFieldValue('location', value);
                  }}
                  selectOptions={locationValues}
                  placeholder={location.placeholder}
                />
                <Error error={errors.location && touched.location}>{errors.location}</Error>
              </Label>
            ) : null}

            {firstName ? (
              <Label>
                {`${ReactHtmlParser(firstName.label)}${isRequiredAsterix(firstName.isRequired)}`}
                <Field name="firstName" placeholder={firstName.placeholder} minLength={2} autocomplete="off" />
                <Error error={errors.firstName && touched.firstName}>{errors.firstName}</Error>
              </Label>
            ) : null}

            {lastName ? (
              <Label>
                {`${ReactHtmlParser(lastName.label)}${isRequiredAsterix(lastName.isRequired)}`}
                <Field name="lastName" placeholder={lastName.placeholder} minLength={2} autocomplete="off" />
                <Error error={errors.lastName && touched.lastName}>{errors.lastName}</Error>
              </Label>
            ) : null}

            {jobRole ? (
              <Label onClick={e=> e.preventDefault}>
                {`${ReactHtmlParser(jobRole.label)}${isRequiredAsterix(jobRole.isRequired)}`}
                <Dropdown
                  name={"jobRoles"}
                  ismultiple={true}
                  
                  selectedValue={selectedRole}
                  handleChange={e => {
                    var items = selectedRole;
                    if(e.target.checked) { items.push(e.target.value); }
                    else {
                      var itemIndex = items.indexOf(e.target.value);
                      if(itemIndex > -1) { items.splice(itemIndex, 1); }
                    }
                    
                    setSelectedRole(items);
                    setFieldValue('jobRole', items);
                  }}
                  selectOptions={Object.values(jobRoles)}
                  placeholder={jobRole.placeholder}
                />
                <Error error={errors.jobRole && touched.jobRole}>{errors.jobRole}</Error>
              </Label>
            ) : null}

            {company ? (
              <Label>
                {`${ReactHtmlParser(company.label)}${isRequiredAsterix(company.isRequired)}`}
                <Field name="company" placeholder={company.placeholder} minLength={2} autocomplete="off" />
                <Error error={errors.company && touched.company}>{errors.company}</Error>
              </Label>
            ) : null}

            {accountID ? (
              <Label>
                {`${ReactHtmlParser(accountID.label)}${isRequiredAsterix(accountID.isRequired)}`}
                <Field name="accountID" placeholder={accountID.placeholder} minLength={2} autocomplete="off" />
                <Error error={errors.accountID && touched.accountID}>{errors.accountID}</Error>
              </Label>
            ) : null}

            {email ? (
              <Label>
                {`${ReactHtmlParser(email.label)}${isRequiredAsterix(email.isRequired)}`}
                <Field name="emailAddress" placeholder={email.placeholder} autocomplete="off" />
                <Error error={errors.emailAddress && touched.emailAddress}>{errors.emailAddress}</Error>
              </Label>
            ) : null}

            {postcode ? (
              <Label>
                {`${ReactHtmlParser(postcode.label)}${isRequiredAsterix(postcode.isRequired)}`}
                <Field name="postCode" placeholder={postcode.placeholder} minLength={2} autocomplete="off" />
                <Error error={errors.postcode && touched.postcode}>{errors.postcode}</Error>
              </Label>
            ) : null}

            {phone ? (
              <Label>
                {`${ReactHtmlParser(phone.label)}${isRequiredAsterix(phone.isRequired)}`}
                <GridField>
                  {countryCode ? (
                    <Field name="countryCode" placeholder={countryCode.placeholder} maxLength={4} autocomplete="off" />
                  ) : null}
                  <Field name="phoneNumber" placeholder={phone.placeholder} maxLength={16} autocomplete="off" />
                </GridField>
                <Error error={errors.phoneNumber && touched.phoneNumber}>{errors.phoneNumber}</Error>
              </Label>
            ) : null}



            {customerId ? (
              <Label>
                {`${ReactHtmlParser(customerId.label)}${isRequiredAsterix(customerId.isRequired)}`}
                <Field name="customerId" placeholder={customerId.placeholder} autocomplete="off" />
                <Error error={errors.customerId && touched.customerId}>{errors.customerId}</Error>
              </Label>
            ) : null}


            {additionalInfo ? (
              <Label>
                {`${ReactHtmlParser(additionalInfo.label)}${isRequiredAsterix(additionalInfo.isRequired)}`}
                <Field component="textarea" name="additionalInfo" placeholder={additionalInfo.placeholder} rows="3" maxLength={300} autocomplete="off" />
                <Error error={errors.additionalInfo && touched.additionalInfo}>{errors.additionalInfo}</Error>
              </Label>
            ) : null}


            <Terms dangerouslySetInnerHTML={{ __html: termsAndConditions }} />

            <SubmitButton
              type="submit"
              linkText={ctaLink.title}
              iconName="lock"
              loading={loadingForm}
              loadingText={labels['loading_label']}
              dark
            />
          </Form>
        )}
      </Formik>
    </RequestAccessWrapper>
  );
};

export default RequestAccess;
