import styled from 'styled-components';

export const Overlay = styled.div`
  ${({ theme, open }) => `
    display: none;

    @media (min-width: ${theme.breakpoints.tablet}px) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.5);
      transition: opacity 0.2s ease-in;
      display: block;

      ${
        open
          ? `
              opacity: 1;
            `
          : ''
      }
    }
  `}
`;

export const Wrap = styled.div`
  ${({ theme }) => `
    display: inline-block;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background: #fff;
    overflow: scroll;

    @media (min-width: ${theme.breakpoints.tablet}px) {
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      width: auto;
      height: auto;
      overflow: auto;
    }
  `}
`;

export const Dialog = styled.div`
  ${({ theme }) => `
    background-color: white;
    z-index: 1000;
    height: 100%;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    @media (min-width: ${theme.breakpoints.tablet}px) {
      animation-name: fadeIn;
      animation-duration: 0.6s;
      animation-fill-mode: forwards;
      will-change: opacity;
      height: auto;
      opacity: 0;

    }
  `}
`;
