import styled from 'styled-components';
import { Icon } from 'components/_icons';
import NavigationLink from 'components/_common/navigation-link';

export const FooterWrapper = styled.footer`
  ${({
    theme: {
      breakpoints: { tablet },
    },
  }) => `
    padding: 2.9rem 3.2rem;
    display: flex;
    flex-direction: column;

    @media (min-width: ${tablet}px) {
      flex-direction: row;
      padding: 10rem 11rem;
    }
  `}
`;

export const FooterContent = styled.div`
  ${({
    theme: {
      breakpoints: { tablet },
    },
  }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: ${tablet}px) {
      flex-direction: row;
      justify-content: flex-start;
    }

  `}
`;

export const Column = styled.div`
  ${({
    theme: {
      breakpoints: { tablet },
    },
  }) => `
    display: flex;
    flex-direction: column;
    padding: 0;

    @media (min-width: ${tablet}px) {
      padding: 0 2.3rem 0 0;
    }
  `}
`;

export const LinkInner = styled.span`
  ${({
    theme: {
      colours: { black },
    },
  }) => `
    position: relative;
    text-align: center;
    &:after {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 0;
      right: 0;
      height: 1px;
      min-height: 1px;
      background-color: ${black};
      opacity: 0.3;
    }
  `}
`;

export const Link = styled(NavigationLink)`
  ${({
    theme: {
      fonts: { NotoSansMedium, NotoSansExtraBold },
      colours: { darkGrey },
      breakpoints: { tablet },
    },
  }) => `
    display: flex;
    justify-content: center;
    margin-bottom: 1.9rem;
    color: ${darkGrey};
    font-family: ${NotoSansExtraBold.value};
    font-weight: ${NotoSansExtraBold.fontWeight};
    font-style: ${NotoSansExtraBold.fontStyle};
    font-size: 1.3rem;
    letter-spacing: 0.02px;
    text-transform: uppercase;
    cursor: Pointer;
    position: relative;
    text-decoration: none;

    @media (min-width: ${tablet}px) {
      padding: 0;
      justify-content: flex-start;
      fopnt-size: 1.4rem
      font-family: ${NotoSansMedium.value};
      font-weight: ${NotoSansMedium.fontWeight};
      font-style: ${NotoSansMedium.fontStyle};
    }
  `}
`;
export const LogoLink = styled.a`
  ${({
    theme: {
      fonts: { NotoSansMedium, NotoSansExtraBold },
      colours: { darkGrey },
      breakpoints: { tablet },
    },
  }) => `
    display: flex;
    justify-content: center;
    margin-bottom: 1.9rem;
    color: ${darkGrey};
    font-family: ${NotoSansExtraBold.value};
    font-weight: ${NotoSansExtraBold.fontWeight};
    font-style: ${NotoSansExtraBold.fontStyle};
    font-size: 1.3rem;
    letter-spacing: 0.02px;
    text-transform: uppercase;
    cursor: Pointer;
    position: relative;
    text-decoration: none;

    @media (min-width: ${tablet}px) {
      padding: 0;
      justify-content: flex-start;
      fopnt-size: 1.4rem
      font-family: ${NotoSansMedium.value};
      font-weight: ${NotoSansMedium.fontWeight};
      font-style: ${NotoSansMedium.fontStyle};
    }
  `}
`;

export const AuthButton = styled.span`
  ${({
    theme: {
      fonts: { NotoSansMedium, NotoSansExtraBold },
      colours: { darkGrey },
      breakpoints: { tablet },
    },
  }) => `
    display: flex;
    justify-content: center;
    margin-bottom: 1.9rem;
    color: ${darkGrey};
    font-family: ${NotoSansExtraBold.value};
    font-weight: ${NotoSansExtraBold.fontWeight};
    font-style: ${NotoSansExtraBold.fontStyle};
    font-size: 1.3rem;
    letter-spacing: 0.02px;
    text-transform: uppercase;
    cursor: Pointer;
    position: relative;
    text-decoration: underline;

    @media (min-width: ${tablet}px) {
      padding: 0;
      justify-content: flex-start;
      fopnt-size: 1.4rem
      font-family: ${NotoSansMedium.value};
      font-weight: ${NotoSansMedium.fontWeight};
      font-style: ${NotoSansMedium.fontStyle};
    }
  `}
`;

export const CopyRight = styled.div`
  ${({
    theme: {
      fonts: { NotoSansRegular },
      colours: { lightGrey },
      breakpoints: { tablet },
    },
  }) => `
    display: flex;
    align-items: flex-end;
    margin-bottom: 1.4rem;
    color: ${lightGrey};
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    font-size: 1.2rem;
    line-height: 2.4rem;
    min-width: 13.9rem;
    justify-content: center;

    @media (min-width: ${tablet}px) {
      justify-content: flex-start;
      font-size: 1.6rem;
    }
  `}
`;

export const AsideContent = styled.div`
  ${({
    theme: {
      breakpoints: { tablet },
    },
  }) => `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 1.9rem;

    @media (min-width: ${tablet}px) {
      margin-left: auto;
      margin-right: 0;
    }
  `}
`;

export const Logo = styled.img`
  ${({
    theme: {
      fonts: { NotoSansRegular },
      colours: { darkGrey },
      breakpoints: { tablet },
    },
  }) => `
    width: 11rem;
    height: 5rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;

    @media (min-width: ${tablet}px) {
      margin-left: auto;
      margin-right: 0;
      margin-bottom: 0;
    }
  `}
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const SocialIcon = styled(Icon)`
  ${({
    theme: {
      colours: { darkGrey },
      breakpoints: { tablet },
    },
  }) => `
    width: 30px;
    height: 30px;
    margin-right: 2.5rem;
    margin-bottom: 0;
    fill: ${darkGrey};
  `}
`;
export const SocialIconImg = styled.img`
  ${({
    theme: {
      colours: { darkGrey },
      breakpoints: { tablet },
    },
  }) => `
    width: 30px;
    height: 30px;
    margin-bottom: 0;
    fill: ${darkGrey};
  `}
`;
export const SocialIconLink = styled.a`
  ${({
    theme: {
      fonts: { NotoSansRegular },
      colours: { lightGrey },
    },
  }) => `
    display: flex;
    align-items: flex-end;
    cursor: Pointer;
    margin-right: 2.5rem;
    &:last-child {
      ${Icon} {
        margin-right: 1rem;
      }
    }
  `}
`;
