import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import axios from 'axios';
import { getSiteEndpoint } from 'helpers/api';
import { Auth } from 'components/context/AuthContext';
import LoadingSpinner from 'components/_common/loading-spinner';
import qs from 'qs';

import Tile from 'components/_common/tile/Tile.js';

import ContactCard from 'components/_common/contact-card';

import {
     Container,
     HeaderComponent,
     TableWrapper, Header, TableContent, Row, Column,
     ContactCardsComponent
} from './portal-search-result.styles';
import { Kverneland } from 'components/_icons/Icon.stories';


const PortalSearchResult = ({ header, brand, langFolder, pageID }) => {
    const url = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const [loading, setLoading] = useState(true);
    const { userToken } = useContext(Auth);

    const [searchResult, setSearchResult] = useState([]); 
    const getSearchResult = (setSearchResult, token) => {
        return new Promise((resolve, reject) => {
            axios.get(`${getSiteEndpoint()}/Search/?query=${url.q}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(response => {
                if (response.status === 200 && response?.data) {
                    setSearchResult(response?.data);
                    setLoading(false);
                }
            })
            .catch(error => {
                if (error) { console.log(error); }
                setLoading(false);
            });
        });
    };

    useEffect(() => {
        getSearchResult(setSearchResult, userToken);
    }, [setSearchResult, userToken]);
    
    return (
        <Container>
            {url.q?
                <div className="searchResult">
                    <div className="search-header">
                        <h1>{ ReactHtmlParser(header) + " : " + url.q }</h1>
                    </div>

                    <div className="search-list">
                        {searchResult?.map((resultItem, indexSearch) => {
                            return (
                                <div className="search-item">
                                    <a href={resultItem.content?.url}>
                                        <h1>{ ReactHtmlParser(resultItem.content?.title)}</h1>
                                    </a>
                                    <div className="search-item-details">
                                        {resultItem?.content?.main?.components?.map((component, indexComponent) => {
                                            return (
                                                component.templateLabel == "Header Template" && component.header != resultItem.content.title?
                                                <SearchComponent_Header component={component} url={url}></SearchComponent_Header>

                                                :component.templateLabel == "Tile List Template"?
                                                <SearchComponent_Tiles component={component} url={url} brand={brand} langFolder={langFolder} pageID={pageID}></SearchComponent_Tiles>
                                                
                                                :component.templateLabel == "Contact Card Template"?
                                                <SearchComponent_Cards component={component} url={url}></SearchComponent_Cards>
                                                
                                                :null
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            :null
            }
        </Container>
    );
};

const SearchComponent_Header = ({ component, url }) => {
    return (
        component.header?.toLowerCase().indexOf(url.q.toLowerCase()) != -1
        ?
        <>
            <p>{component.header}</p>
        </>
        : null
    )
}

const SearchComponent_Tiles = ({ component, url, brand, langFolder, pageID }) => {
    return (
        component.tiles?.map((tile, indexTile) => {

            if(!tile.icon) { tile.icon = {src: '#'}; }
            return (tile.IsAuthorized && (
                tile.navigation?.alt?.toLowerCase().indexOf(url.q.toLowerCase()) != -1 ||
                tile.navigation?.url?.toLowerCase().indexOf(url.q.toLowerCase()) != -1 ||
                tile.label?.toLowerCase().indexOf(url.q.toLowerCase()) != -1
                )?
                <>
                    {
                        tile.label?
                        <a href={tile.navigation?.url}>{tile.label}</a>
                        :tile.navigation?.url.toLowerCase().indexOf('kverneland') != -1? <a href={tile.navigation?.url}>Kverneland</a>

                        :tile.navigation?.url.toLowerCase().indexOf('kubota') != -1? <a href={tile.navigation?.url}>Kubota</a>

                        :tile.navigation?.url.toLowerCase().indexOf('vicon') != -1? <a href={tile.navigation?.url}>Vicon</a>

                        :null
                    }
                    {/* <Tile key={`${tile.label}-${indexTile}`} {...tile} brand={brand} langFolder={langFolder} pageID={pageID}></Tile> */}
                    {/* <p>{tile.label}</p>
                    <p>{tile.navigation?.alt}</p>
                    <p>{tile.navigation?.url}</p> */}
                </>
                : null
            )
        })
    )
}

const SearchComponent_Cards = ({ component, url }) => {
    return (
        <ContactCardsComponent> {
            component.cards?.map((card, indexCard) => {
                if(!card.icon) { card.picture = {src: 'https://kvg-portal1-app-web-dev-we.azurewebsites.net/assets/contact-images/orderdesk-contacts/tanja.jpg'}; }
                return (
                    card.emailAddress?.toLowerCase().indexOf(url.q.toLowerCase()) != -1 ||
                    card.jobTitle?.toLowerCase().indexOf(url.q.toLowerCase()) != -1 ||
                    card.telephone?.toLowerCase().indexOf(url.q.toLowerCase()) != -1 ||
                    card.titleCompany?.toLowerCase().indexOf(url.q.toLowerCase()) != -1
                    ?
                    <>
                        {/* <ContactCard key={indexCard} {...card} /> */}
                        <h3>
                            {card.name}(<a href={'mailto: ' + card.emailAddress}>{card.emailAddress}</a>)
                        </h3>
                    </>
                    : null
                )
            })
        }</ContactCardsComponent>
    )
}


export default PortalSearchResult;
