import styled, { css } from 'styled-components';
import { Field as _Field, Form as _Form } from 'formik';
import Button from 'components/_common/button';
import { ButtonIcon } from 'components/_common/button/Button.styles';

export const RequestAccessWrapper = styled.div`
  ${({
    theme: {
      breakpoints: { tablet },
    },
  }) => css`
    max-width: 56rem;
    width: 100%;
    padding: 2rem 2.5rem 4.1rem;

    @media (min-width: ${tablet}px) {
      padding: 3.6rem 4.5rem 5rem 11rem;
    }
  `}
`;

export const Form = styled(_Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.div`
  ${({
    theme: {
      fonts: { NotoSansSemiBold },
      colours: { darkGrey },
      breakpoints: { tablet },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    color: ${darkGrey};
    font-family: ${NotoSansSemiBold.value};
    font-weight: ${NotoSansSemiBold.fontWeight};
    font-style: ${NotoSansSemiBold.fontStyle};
    font-size: 1.4rem;
    margin-bottom: 1rem;

    @media (min-width: ${tablet}) {
      font-size: 1.6rem;
    }
  `}
`;

export const GridField = styled.div`
  ${({
    theme: {
      breakpoints: { tablet },
    },
  }) => css`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 0 10px;
  `}
`;

export const Field = styled(_Field)`
  ${({
    theme: {
      fonts: { NotoSansRegular },
      colours: { borderGrey, lightGrey, darkGrey },
      breakpoints: { tablet },
    },
  }) => css`
    resize: none;
    border: 1px solid ${borderGrey};
    padding: 1.3rem 2rem;
    color: ${darkGrey};
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    font-size: 1.2rem;
    line-height: 2.4rem;
    margin-top: 1rem;
    width: 100%;

    &::placeholder {
      color: ${lightGrey};
    }

    @media (min-width: ${tablet}px) {
      font-size: 1.4rem;
      padding: 1.8rem 2rem;
    }
  `}
`;

export const SubmitButton = styled(Button)`
  width: 100%;
  padding: 1.4rem;
  cursor: pointer;
  ${ButtonIcon} {
    width: 3rem;
    height: 3rem;
  }
`;

export const Terms = styled.div`
  ${({
    theme: {
      colours: { lightGrey, darkGrey },
      fonts: { NotoSansRegular, NotoSansBold },
      breakpoints: { tablet },
    },
  }) => css`
    color: ${lightGrey};
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    font-size: 1.2rem;
    line-height: 2.4rem;
    margin-bottom: 2rem;

    p {
      margin: 0;
    }

    a {
      color: ${darkGrey};
      font-family: ${NotoSansBold.value};
      font-weight: ${NotoSansBold.fontWeight};
      font-style: ${NotoSansBold.fontStyle};
      font-size: 1.4rem;
      line-height: 2.4rem;
    }

    @media (min-width: ${tablet}) {
      font-size: 1.4rem;
    }
  `}
`;

export const Error = styled.p`
  ${({
    theme: {
      colours: { red, white },
      fonts: { NotoSansRegular },
    },
    error,
  }) => `
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    color: ${red};
    font-size: ${error ? '1.4rem' : 0};
    line-height: 1.8rem;
    height: max-content;
    margin-bottom: 0;
    margin-top: 0.4rem;
    display: ${error ? 'inline-block' : 'none'};
  `}
`;
