import React from 'react';

const TrainingManual = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M26.242 0v27.803h-11.12v-1.236h9.884v-4.325H23.77v3.09l-9.267-.001v4.196l-2.471-1.524-2.471 1.524-.001-4.196H6.471v-1.235l3.089-.001V22.86h4.943v1.235h8.032v-1.853H7.439a2.175 2.175 0 00-1.411.496l-.143.13c-.408.4-.641.946-.65 1.518a2.212 2.212 0 002.213 2.181h1.495v1.236H7.448A3.448 3.448 0 014 24.41l1.236.001-.001-.002H4l.002-.055.005-.155a3.41 3.41 0 01.008-.105H4V3.708A3.707 3.707 0 017.707 0h18.535zM13.268 24.096h-2.472v3.218l1.236-.762 1.236.762v-3.218zm11.738-22.86H7.707a2.471 2.471 0 00-2.471 2.471l-.001 18.084a3.403 3.403 0 012.213-.785h17.558l.001-8.978c-.024.1-.06.197-.109.292l-.378.748h-1.418v-1.085h.75l.079-.154a.285.285 0 00-.072-.351l-5.703-4.312a.542.542 0 00-.382-.073l-.096.009H9.952c-.033 0-.063.02-.091.074-.02.035-.012.079.002.09l6.25 4.6a.857.857 0 00.48.117h4.81a1.084 1.084 0 00-.225-.488l-2.562-1.862.409-.563 2.628 1.91.026.03c.31.358.466.824.433 1.271v2.78h-.695v-1.994h-1.432l.066.022-.881 2.653c-.347 1.033-2.331 1.035-4.53.304-2.206-.734-3.793-1.92-3.447-2.956l.875-2.652.503.166-3.368-2.477a1.164 1.164 0 01-.353-1.354l.056-.112a1.184 1.184 0 011.026-.644h7.702l.114-.013a1.67 1.67 0 011.021.269l5.752 4.345c.25.195.416.463.487.754l-.002-10.136zM7.943 2.47v17.3H6.707V2.47h1.236zm4.995 8.403l-.832 2.519c-.087.26 1.135 1.173 2.838 1.74 1.698.564 3.226.563 3.313.304l.786-2.37-2.554.001a1.918 1.918 0 01-.816-.211l-.16-.09-2.575-1.893z"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default TrainingManual;
