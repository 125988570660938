import React, { useState, useContext, useEffect } from 'react';
import {
  SideNavWrapper,
  SideNavContent,
  SideNavComponent,
  IconWrapper,
  SideNavIcon,
  SideNavRow,
  EndBlock,
  EndBlockGreyColumn,
  EndBlockWhiteColumn,
  HideChevron,
  TooltipText,
} from './SideNav.styles.js';
import SideNavSubHeading from './SideNavSubHeading';
import { GlobalContext } from 'components/context/GlobalContext';
import SubSideNav from './SubSideNav.js';
import SubSideNavChildLinks from './SubSideNavChildLinks.js';
import ReactHtmlParser from 'react-html-parser';
import { useHistory } from 'react-router-dom';

const SideNav = ({ parentLinks, brand }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(null);
  const [dataParentLinks, setDateParentLinks] = useState();
  const [dataLinks, setDateLinks] = useState([]);
  const [getActiveSubLink, setGetActiveSubLink] = useState();
  const history = useHistory();

  useEffect(() => {
    setDateParentLinks(parentLinks[activeTabIndex]);
  }, [activeTabIndex, parentLinks]);
  const {
    globalDispatch,
    globalState: { isOpenSideBar, showSidebar },
  } = useContext(GlobalContext);

  const toggleSidebar = value => {
    globalDispatch({
      type: 'TOGGLE_SIDEBAR',
    });
  };
  const toggleAppearSideBar = () => {
    globalDispatch({
      type: 'SHOW_SIDEBAR',
    });
  };

  const getSubChiled = title => {
    const subLinksChild = dataLinks?.at(-1)?.childSubLinks?.find(item => item.title === title);
    if (subLinksChild) {
      setDateLinks(prev => [...prev, { ...subLinksChild }]);
    }
  };
  const goBackMenu = () => {
    setDateLinks(prev => {
      const updatedDataLinks = [...prev];
      updatedDataLinks.pop();
      return [...updatedDataLinks];
    });
  };
  useEffect(() => {
    if (dataLinks.length === 0) {
      setGetActiveSubLink();
    }
  }, [dataLinks]);
  useEffect(() => {
    if (!isOpenSideBar || activeTabIndex) {
      setGetActiveSubLink();
      setDateLinks([]);
    }
  }, [activeTabIndex, isOpenSideBar]);

  useEffect(() => {
    if (parentLinks[activeTabIndex]?.childLinks) {
      const sublinks = parentLinks[activeTabIndex]?.childLinks.find(item => item.title === getActiveSubLink);
      setDateLinks([sublinks] ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabIndex, getActiveSubLink]);

  return (
    <SideNavWrapper showSidebar={showSidebar} open={isOpenSideBar}>
      <SideNavComponent open={isOpenSideBar} show={showSidebar}>
        {parentLinks?.map((link, tabIndex) => {
          const { id, childLinks, icon, title, url, isExternal, target } = link;
          const isActiveTab = tabIndex === activeTabIndex;
          return (
            <SideNavRow key={`${icon}-${tabIndex}`}>
              <IconWrapper
                onClick={() => {
                  setActiveTabIndex(isActiveTab ? null : tabIndex);
                  if (!childLinks || childLinks.length == 0) {
                    history.push(url);
                  }
                  if (!isActiveTab) {
                    globalDispatch({
                      type: 'OPEN_SIDEBAR',
                    });
                  } else {
                    globalDispatch({
                      type: 'CLOSE_SIDEBAR',
                    });
                    // If user clicks on active tab then,
                    // we want to hide the nav
                    toggleAppearSideBar();
                  }
                  if (!childLinks || childLinks.length == 0) {
                    globalDispatch({
                      type: 'CLOSE_SIDEBAR',
                    });
                    setActiveTabIndex(null);
                  }
                }}
                active={isActiveTab}
                brand={brand}
                stylePath={title}
              >
                <SideNavIcon name={icon} />
                <TooltipText>{ReactHtmlParser(title)}</TooltipText>
              </IconWrapper>
            </SideNavRow>
          );
        })}

        <EndBlock>
          <EndBlockGreyColumn brand={brand} />
          <EndBlockWhiteColumn open={isOpenSideBar} />
        </EndBlock>
      </SideNavComponent>
      <SubSideNav
        parentLinks={dataParentLinks}
        open={isOpenSideBar}
        getActiveSubLink={getActiveSubLink}
        setGetActiveSubLink={setGetActiveSubLink}
        setActiveTabIndex={setActiveTabIndex}
        goBackMenu={goBackMenu}
      />
      <SubSideNavChildLinks
        dataChild={dataLinks.at(-1)}
        getSubChiled={getSubChiled}
        setActiveTabIndex={setActiveTabIndex}
      />
    </SideNavWrapper>
  );
};

export default SideNav;
