import React from 'react';
import PropTypes from 'prop-types';
import { Crumb, ChevronRight, ChevronLeft } from 'components/breadcrumbs/Breadcrumb.styles';
import ReactHtmlParser from 'react-html-parser';

const Breadcrumb = ({ target, title, url, lastBreadcrumb, firstBreadcrumb, isExternal }) => {
  const linkProps = {};

  if (!lastBreadcrumb) {
    linkProps.linkHref = url;
    linkProps.target = target;
    linkProps.isExternal = isExternal;
  } else {
    linkProps.as = 'span';
  }
  return (
    <Crumb {...linkProps} lastBreadcrumb={lastBreadcrumb} firstBreadcrumb={firstBreadcrumb}>
      <ChevronLeft name="chevron" />
      {ReactHtmlParser(title)}
      <ChevronRight lastBreadcrumb={lastBreadcrumb} name="chevron" />
    </Crumb>
  );
};

Breadcrumb.propTypes = {
  breadcrumbs: PropTypes.array,
};

export default Breadcrumb;
