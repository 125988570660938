import styled from 'styled-components';
import { Icon } from 'components/_icons';

export const ContactCardWrapper = styled.div`
  ${({
    theme: {
      breakpoints: { mobileLarge, tabletLarge },
      colours: { offWhite, darkerOffWhite },
    },
  }) => `
    padding: 1.5rem;
    flex: 1 1 auto;
    background-color: ${offWhite};
    margin: 1rem auto;
    max-width: 50rem;
    width: 100%;
    border: 1px solid ${darkerOffWhite};

    @media(min-width: 1120px) {
      padding: 3rem 2.4rem 2.4rem 5rem;
      flex: 0.5 0.5 auto;
      max-width: calc(50% - 2rem);
      margin: 1rem 1rem;
    }
  `}
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Image = styled.div`
  ${({
    theme: {
      breakpoints: { tablet },
    },
    url,
  }) => `
    width: 4.9rem;
    height: 4.9rem;
    min-width: 4.9rem;
    min-height: 4.9rem;
    border-radius: 50%;
    background-image: url(${url});
    background-size: cover;
    background-position: center;

    @media(min-width: ${tablet}px) {
      width: 10.1rem;
      height: 10.1rem;
      min-width: 10.1rem;
      min-height: 10.1rem;
    }
  `}
`;

export const Details = styled.div`
  ${({
    theme: {
      breakpoints: { tablet },
    },
  }) => `
    padding: 0.2rem 0 0.2rem 1rem;

    @media(min-width: ${tablet}px) {
      padding: 0.5rem 0 0.6rem 4rem;
    }
  `}
`;

export const Person = styled.div`
  /* Person */
`;

export const Name = styled.div`
  ${({
    theme: {
      fonts: { NotoSansBold },
      colours: { darkGrey },
      breakpoints: { tablet },
    },
  }) => `
    color: ${darkGrey};
    font-family: ${NotoSansBold.value};
    font-weight: ${NotoSansBold.fontWeight};
    font-style: ${NotoSansBold.fontStyle};
    font-size: 1.8rem;
    line-height: 2.4rem;

    @media(min-width: ${tablet}px) {
      font-size: 2.4rem;
      line-height: 3.3rem;
    }
  `}
`;

export const Job = styled.div`
  ${({
    theme: {
      fonts: { NotoSansRegular },
      colours: { lightGrey },
      breakpoints: { tablet },
    },
  }) => `
    color: ${lightGrey};
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    font-size: 1.4rem;
    line-height: 2.4rem;

    @media(min-width: ${tablet}px) {
      font-size: 1.6rem;
      margin-bottom: 2.1rem;
    }
  `}
`;

export const DesktopContact = styled.div`
  ${({
    theme: {
      breakpoints: { tablet },
    },
  }) => `
    display: none;

    @media(min-width: ${tablet}px) {
      display: flex;
      flex-direction: column;
    }
 `}
`;

export const MobileContact = styled.div`
  ${({
    theme: {
      breakpoints: { tablet },
    },
  }) => `
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
 `}
`;

export const ContactRow = styled.div`
  ${({
    theme: {
      breakpoints: { tablet },
    },
  }) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;

    @media(min-width: ${tablet}px) {
      margin-bottom: 1rem;
    }
  `}
`;

export const Email = styled.div`
  ${({
    theme: {
      fonts: { NotoSansRegular },
      colours: { lightGrey },
      breakpoints: { tablet },
    },
  }) => `
    color: ${lightGrey};
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    font-size: 1.2rem;
    line-height: 2.4rem;

    @media(min-width: ${tablet}px) {
      font-size: 1.4rem;
    }
  `}
`;
export const EmailIcon = styled(Icon)`
  ${({
    theme: {
      colours: { primary },
      breakpoints: { tablet },
    },
  }) => `
    fill: ${primary};
    width: 2rem;
    height: 1.6rem;
    margin-right: 1.2rem;

    @media(min-width: ${tablet}px) {
      width: 2.2rem;
      height: 1.8rem;
    }
 `}
`;
export const Phone = styled.div`
  ${({
    theme: {
      fonts: { NotoSansRegular },
      colours: { lightGrey },
      breakpoints: { tablet },
    },
  }) => `
    color: ${lightGrey};
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    font-size: 1.2rem;
    line-height: 2.4rem;

    @media(min-width: ${tablet}px) {
      font-size: 1.4rem;
    }
  `}
`;
export const PhoneIcon = styled(Icon)`
  ${({
    theme: {
      colours: { primary },
      breakpoints: { tablet },
    },
  }) => `
    fill: ${primary};
    width: 2.1rem;
    height: 2.1rem;
    margin-right: 1.2rem;

    @media(min-width: ${tablet}px) {
      width: 2.4rem;
      height: 2.4rem;
    }
 `}
`;

export const JobTitle = styled.div`
  ${({
    theme: {
      fonts: { NotoSansRegular },
      colours: { lightGrey },
      breakpoints: { tablet },
    },
  }) => `
    color: ${lightGrey};
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    font-size: 1.4rem;
    line-height: 2.4rem;

    @media(min-width: ${tablet}px) {
      font-size: 1.6rem;
    }
  `}
`;
