import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { GetIcon, Icon } from 'components/_icons';

export const ContainerMultipleContact = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 40px;
    padding: 25px;
    max-width: 1000px;
    @media (min-width: ${theme.breakpoints.tablet}px) {
      
    }
    @media (min-width: ${theme.breakpoints.desktop}px) {
      
    }
  `}
`;
export const HeaderComponent = styled.h1`
  ${({
    theme: {
      fonts: { NotoSansRegularItalic },
      colours: { primary },
    },
    align,
  }) => css`
    font-family: ${NotoSansRegularItalic.value};
    font-weight: ${NotoSansRegularItalic.fontWeight};
    font-style: ${NotoSansRegularItalic.fontStyle};
    color: ${primary};
    padding-left: 20px;
    width: 100%;
    text-align: ${align ? align : 'left'};
  `}
`;

export const ContainerCards = styled.div`
  ${({ theme }) => css`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  margin-top: 10px
  `}
`;

export const TileComponent = styled.div`
  ${({ theme }) => css`
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    width: calc(50% - 40px);
    display: flex;
    margin: 5px;
    border-left: 5px solid ${theme.colours.primary};
    padding: 20px 40px 20px 40px;
    color: ${theme.colours.black};
    transition: all 0.2s ease-in-out;
    background-color: ${theme.colours.white};
    
    padding: 16px;

    border: 5px solid rgb(160, 170, 0);
    &:hover {
      background-color: rgb(160, 170, 0);
      color: ${theme.colours.primary};
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      cursor: pointer;
      svg {
        path {
          fill: ${theme.colours.primary};
        }
      }
    }
    @media (max-width: ${theme.breakpoints.tablet}px) {
      display: flex;
      width: 100%;
      padding: 10px 40px 10px 30px;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
    }
  `}
`;
export const DisableTileComponent = styled.div`
  ${({ theme }) => css`
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    width: calc(50% - 40px);
    display: flex;
    margin: 5px;
    padding: 20px 40px 20px 40px;
    color: ${theme.colours.black};
    transition: all 0.2s ease-in-out;
    background-color: ${theme.colours.white};
    
  padding: 16px;

    @media (max-width: ${theme.breakpoints.tablet}px) {
      display: flex;
      width: 100%;
      padding: 10px 40px 10px 30px;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
    }
  `}
`;

export const IconWrap = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin: 0 0 10px;
    width: 50px;
    height: 50px;
    @media (min-width: ${theme.breakpoints.mobileLarge}px) {
      margin-top: 5%;
    }

    @media (min-width: ${theme.breakpoints.tablet}px) {
      margin: 0 20px 0 0;
    }
  `}
`;

export const StyleIcons = styled(Icon)`
  ${({
    theme: {
      colours: { primary },
    },
  }) => css`
    fill: ${primary};
    width: 40px;
    height: 40px;
    cursor: pointer;
    > path {
      fill: ${primary};
    }
  `}
`;

export const TextWrap = styled.div`
  ${({ theme }) => css`
    
  `}
`;

export const Text = styled.h4`
  ${({ theme }) => css`
    font-size: 14px;
    flex: 1;
    margin: 0;
    word-break: break-word;
  `}
`;

export const TextSpan = styled.span`
  ${({
    theme: {
      fonts: { NotoSansRegular },
    },
  }) => css`
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    font-size: 12px;
    line-height: 1rem;
    margin-top: 8px;
    display: inline-block;
    width: 100%;
  `}
`;

export const Card = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    height: 100%;
  `}
`;
