import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'components/context/AuthContext';
import {
  FileDownloadComponent,
  DocumentName,
  LastUpdated,
  ViewOnline,
  DownloadButton,
  Bold,
  PdfIcon,
  FileDownloadContents,
} from './FileDownload.styles.js';
import axios from 'axios';
import { getSiteEndpoint } from 'helpers/api';
import ReactHtmlParser from 'react-html-parser';

var fileDownload = require('js-file-download');

const FileDownload = ({ name, file, labels, lastUpdated, isEncrypted }) => {
  const { download_cta_label, download_last_updated, download_view_online } = labels || {};
  const { userToken } = useContext(Auth);
  let fileArr = file.split('/');
  let fileName = fileArr[fileArr.length - 1];

  let fileExtArr = file.split('.');
  let fileExtenstion = fileExtArr[fileExtArr.length - 1];

  const getIcon = url => {
    const splitArray = url?.split('.');
    if (splitArray?.length > 0) {
      const fileFormat = splitArray[splitArray.length - 1];
      // const fileFormat = 'zip';

      // After discussing with client we had to
      // support
      switch (fileFormat) {
        case 'ppt':
        case 'pptx':
        case 'pptm':
          return 'powerPoint';
        case 'xls':
        case 'xlsx':
        case 'xlsm':
        case 'xlsb':
        case 'xlts':
          return 'excel';
        case 'doc':
        case 'docm':
        case 'docx':
        case 'dot':
          return 'word';
        case 'zip':
          return 'zip';
        case 'pdf':
          return 'pdf';
        default:
          return null;
      }
    }
  };
  
  const downloadLocalAsset = e => {
     e.preventDefault();
     axios
       .get(`${getSiteEndpoint()}/Files/key/${file}`, {
         headers: {
           Authorization: `Bearer ${userToken}`,
         },
         //responseType: 'blob',
       })
       .then(function(response) {
        window.open(`${getSiteEndpoint()}/Files/View/${response.data}`);
        //  //fileDownload(response.data, `${file}`);
        //  var contentType =  response.headers["content-type"];
      

        //  var blob = new Blob([response.data], { type: contentType });
        //  blob.title = file;
        //  blob.name = file;
        //  var url = window.URL.createObjectURL(blob, { oneTimeOnly: true, title: file });
    
        //  window.open(url, "_blank");
       })
       .catch(function(error) {
         console.log(error);
       });
  };

  useEffect(() => {
    getIcon(file);
  }, [file, fileExtenstion, name]);
  return (
    <FileDownloadComponent>
      <FileDownloadContents>
        <DocumentName>{ReactHtmlParser(name)}</DocumentName>
        <LastUpdated>
          {ReactHtmlParser(download_last_updated)}
          <Bold>{lastUpdated}</Bold>
        </LastUpdated>
      </FileDownloadContents>
      <DownloadButton
        linkText={download_cta_label}
        linkHref={isEncrypted ? `` : file}
        iconName="download"
        rel="noopener noreferrer"
        target="_blank"
        isExternal={true}
        dark
        download
        onClick={e => {
          if (isEncrypted) {
            downloadLocalAsset(e);
          }
        }}
      />
    </FileDownloadComponent>
  );
};

FileDownload.propTypes = {
  name: PropTypes.string,
  file: PropTypes.string,
  labels: PropTypes.shape({}),
  lastUpdated: PropTypes.string,
};

export default FileDownload;
