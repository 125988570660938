import React from 'react';

const Mail = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M27.624 3a2.35 2.35 0 011.73.762.85.85 0 01.151.15l.064.095c.017.029.03.058.043.087.245.381.388.838.388 1.328v18.805c0 1.325-1.044 2.404-2.344 2.422L2.376 27C1.064 27 0 25.916 0 24.578V5.422c0-.511.155-.985.42-1.376a.694.694 0 01.17-.222A2.356 2.356 0 012.375 3h25.248zm.791 2.761l-13.08 10.04a1 1 0 01-1.233-.011L1.584 5.8v18.778a.8.8 0 00.792.808l25.259-.352a.8.8 0 00.78-.807V5.761zm-1.146-1.147H2.676l12.058 9.622 12.535-9.622z"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default Mail;
