import React from 'react';

const PromotionalListing = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M24.922 0a2.694 2.694 0 012.694 2.694v26.27H8.083V2.694a1.347 1.347 0 10-2.694 0v13.472c0 .49-.132.95-.36 1.347h2.38v1.347H2.694l-.17-.005A2.694 2.694 0 010 16.165V6.063h3.368V7.41h-2.02v8.757a1.347 1.347 0 002.693 0V2.694A2.694 2.694 0 016.736 0zm0 1.347H9.07l.08.152c.18.36.28.766.28 1.195v24.922h16.84V2.694c0-.744-.604-1.347-1.348-1.347zM18.86 9.43v1.347h-7.41V9.43h7.41zm5.389-2.694v1.347H11.45V6.736h12.798zm0-2.695V5.39H11.45V4.04h12.798zm1.015 14.479h-1.368a.468.468 0 00-.456.48c0 .265.204.48.456.48h1.368a.468.468 0 00.456-.48.468.468 0 00-.456-.48zm-.636 2.952l-.9-.9a.45.45 0 00-.636.636l.9.9a.45.45 0 10.636-.636zm0-5.58a.45.45 0 00-.636 0l-.9.9a.45.45 0 10.636.636l.9-.9a.45.45 0 000-.636zM20.25 12c-.805 0-1.473.598-1.59 1.374l-.488.491c-.85.855-2.103 1.366-3.35 1.366h-3.215a1.61 1.61 0 00-1.515 1.077L10 20.615c.41.548.768.876 1.071.985v2.785c0 .89.721 1.615 1.608 1.615.886 0 1.607-.725 1.607-1.615v-2.693h.535c1.248 0 2.5.51 3.351 1.366l.489.49a1.612 1.612 0 001.589 1.375c.886 0 1.607-.725 1.607-1.615v-9.693c0-.89-.72-1.615-1.607-1.615zm-7.036 12.385c0 .297-.24.538-.535.538a.538.538 0 01-.536-.538v-2.693h1.071v2.693zm1.072-3.77h-2.679a.538.538 0 01-.536-.538v-3.23c0-.298.24-.54.536-.54h2.679v4.308zm4.357 1.413a5.84 5.84 0 00-3.29-1.388v-4.357a5.84 5.84 0 003.29-1.388v7.133zm2.143 1.28c0 .297-.24.538-.536.538a.538.538 0 01-.536-.538v-9.693c0-.297.24-.538.536-.538.295 0 .536.241.536.538v9.693z"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default PromotionalListing;
