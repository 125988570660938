import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import {
  NotificationWrapper,
  Logo,
  Content,
  TitleRow,
  Title,
  Time,
  Message,
  MobileChevronRight,
} from './Notification.styles.js';
import NavigationLink from 'components/_common/navigation-link';
import defaultIcon from './../../assets/icon_bell.png';
import { getNotification, getUnReadNotificationCount, markNotificationAsRead } from 'helpers/utilities.js';
import { Auth } from 'components/context/AuthContext.js';
import ReactHtmlParser from 'react-html-parser';

const Notification = ({
  icon,
  dateCreated,
  title,
  body,
  isRead,
  cta,
  notificationId,
  labels,
  closeSideBar = () => {},
}) => {
  const logo = icon?.src?.length > 0 ? icon?.src : defaultIcon;
  const { userToken, setNotificationCount, setNotifications } = useContext(Auth);
  const [elapsedDays, setElapsedDays] = useState();
  const { notifications_detail_cta_url } = labels || {};
  const time = dateCreated && dateCreated.split(' ')[0];
  const message = body?.length >= 21 ? `${body?.substring(0, 21)}...` : body;
  useEffect(() => {
    const dateToday = moment();
    const b = moment(time, 'DD/MM/YYYY');
    const res = dateToday.diff(b, 'days');
    setElapsedDays(res);
  }, [time]);
  return (
    <div
      onClick={() => {
        if (!isRead) {
          markNotificationAsRead(notificationId, null, userToken);
        }
        getUnReadNotificationCount(setNotificationCount, userToken);
        getNotification(setNotifications, userToken);
        closeSideBar();
      }}
    >
      <NavigationLink
        linkHref={`${notifications_detail_cta_url}?notificationId=${notificationId}`}
        target="_self"
        isExternal={false}
      >
        <NotificationWrapper read={isRead} className={isRead ? 'Read' : 'unRead'}>
          <Logo src={logo} />
          <Content>
            <TitleRow read={isRead}>
              <Title read={isRead}>{ReactHtmlParser(title)}</Title>
              <Time read={isRead}>
                {elapsedDays > 1 && time}
                {elapsedDays === 1 && 'Yesterday'}
                {elapsedDays === 0 && 'Today'}
              </Time>
            </TitleRow>
            <Message read={isRead}>{ReactHtmlParser(message)}</Message>
          </Content>
          <MobileChevronRight name="chevron" />
        </NotificationWrapper>
      </NavigationLink>
    </div>
  );
};

Notification.propTypes = {};

export default Notification;
