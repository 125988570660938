/* 
    Global Nav Mapper:

    This is to map the so called mini backend components like logo and links 
    into one component that the frontend can consume in One component 
    GlobalNav.

    TODO: Joe to review and confirm we stick with this or ask backend 
    to make into one entity 

    (Only applicable to Header and Footer regions for now)
*/

const GlobalNavMapper = region => {
  const { components = [] } = region;
  const templateLabelsConsumed = {
    'LogoList Template': 'logo',
    'Global Nav Template': 'links',
    'FavIcon Template': 'favicon',
    'Warning Header Template': 'warningHeader',
  };

  let mappedData = {};
  components.forEach(component =>
    templateLabelsConsumed[component.templateLabel]
      ? (mappedData[templateLabelsConsumed[component.templateLabel]] = component)
      : null
  );

  return mappedData;
};

export default GlobalNavMapper;
