import React from 'react';
import PropTypes from 'prop-types';
import { HeaderTitle } from './Header.styles.js';
import ReactHtmlParser from 'react-html-parser';

const Header = ({ header, align }) => {
  return <HeaderTitle align={align}>{ReactHtmlParser(header)}</HeaderTitle>;
};

Header.propTypes = {
  title: PropTypes.string,
};

export default Header;
