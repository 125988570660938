import styled, { css } from 'styled-components';

export const NotificationDetailComponent = styled.section`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 84px);
`;

export const NotificationPreviewContainer = styled.div`
  ${({
    theme: {
      breakpoints: { tablet },
    },
  }) => css`
    max-height: 73.3rem;
    overflow-y: auto;
    margin-right: 1.5rem;
    margin-left: 5rem;
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #efefef;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #707071;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #707071;
    }
    @media (min-width: ${tablet}px) {
      width: calc(100% - 35.7rem);
    }
  `}
`;

export const NotificationList = styled.div`
  ${({
    theme: {
      breakpoints: { tablet },
    },
  }) => css`
    display: none;
    flex-direction: column;
    overflow-y: scroll;
    height: 100%;
    width: 35.7rem;

    scrollbar-color: #efefef;
    scrollbar-width: 10px;
    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      background: #efefef;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #707071;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #707071;
    }
    @media (min-width: ${tablet}px) {
      display: flex;
    }
  `}
`;
