import styled, { css } from 'styled-components';
import { Icon } from 'components/_icons';

export const ContainerSubSideNav = styled.div`
  ${({
    theme: {
      fonts: { NotoSansRegular, NotoSansBold },
      colours: { primary },
      breakpoints: { tabletLarge },
    },
    bold,
    open,
    getActiveSubLink,
    parentLinks,
  }) => css`
    // position: absolute;
    // left: 80px;
    // top: 0;
    width: ${parentLinks ? 'auto' : '0'};
    height: 100%;
    max-width: 350px;
    background: ${!getActiveSubLink ? 'rgb(247, 249, 250)' : 'rgb(233, 233, 234)'};
    z-index: 999;
    padding: ${parentLinks ? '20px' : '0'};
    display: ${!open && 'none'};
    z-index: 10;
    > h1 {
      color: ${primary};
      font-size: 20px;
      width: 350px;
      // white-space: nowrap;
      a {
        text-decoration: none;
      }
    }
    > ul {
      list-style: none;

      padding: 0;
      li {
        //white-space: nowrap;
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        font-family: ${NotoSansRegular.value};
        font-weight: ${NotoSansRegular.fontWeight};
        font-style: ${NotoSansRegular.fontStyle};
        a {
          color: rgb(35, 35, 35);
          //white-space: nowrap;
          text-decoration: none;
        }
        > div {
          margin-left: 20px;
          cursor: pointer;
        }
        &:hover {
          a {
            color: ${primary};
          }
          > div {
            svg {
              fill: ${primary};
            }
          }
        }
        @media (max-width: ${tabletLarge}px) {
          white-space: normal;
          a {
            white-space: normal;
          }
        }
      }
    }
    @media (max-width: ${tabletLarge}px) {
      width: ${parentLinks ? 'calc(100vw - 80px)' : '0'};
      width: ${getActiveSubLink && 'auto'};
    }
  `}
`;
export const ContainerSubSideNavChildLinks = styled.div`
  ${({
    theme: {
      fonts: { NotoSansRegular, NotoSansBold },
      colours: { primary },
      breakpoints: { tabletLarge },
    },
    bold,
    open,
  }) => css`
    // position: absolute;
    // left: 129px;
    // top: 0;
    width: ${open ? 'auto' : 0};
    min-width: 300px;
    max-width: 350px;
    height: 100%;
    background: rgb(247, 249, 250);
    z-index: 2;
    padding: 20px;
    display: ${!open && 'none'};
    > h1 {
      color: ${primary};
      font-size: 20px;
      // white-space: nowrap;
      width: 350px;
      a {
        text-decoration: none;
      }
      @media (max-width: ${tabletLarge}px) {
        white-space: normal;
      }
    }
    > ul {
      list-style: none;

      padding: 0;
      li {
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        font-family: ${NotoSansRegular.value};
        font-weight: ${NotoSansRegular.fontWeight};
        font-style: ${NotoSansRegular.fontStyle};
        a {
          // white-space: nowrap;
          color: rgb(35, 35, 35);
          text-decoration: none;
        }
        > div {
          margin-left: 20px;
          cursor: pointer;
        }
        @media (max-width: ${tabletLarge}px) {
          white-space: normal;
          a {
            white-space: normal;
          }
        }
      }
    }
    @media (max-width: ${tabletLarge}px) {
      width: ${open ? 'calc(100vw - 129px)' : 0};
    }
  `}
`;

export const Chevron = styled(Icon)`
  ${({ isOpen }) => css`
    width: 1rem;
    height: 1rem;
    // transform: ${isOpen ? 'rotate(-90deg)' : 'rotate(90deg)'};
    transition: transform 400ms ease-in-out;
  `}
`;

export const ChevronBack = styled(Icon)`
  ${({
    theme: {
      colours: { primary },
    },
    isOpen,
  }) => css`
    fill: ${primary};
    width: 1rem;
    height: 1.5rem;
    transition: transform 400ms ease-in-out;
    transform: rotate(180deg);
    cursor: pointer;
  `}
`;

export const ContainerChevronBack = styled.div`
  ${({
    theme: {
      colours: { primary },
    },
    isOpen,
  }) => css`
    position: relative;
    &:hover {
      > div {
        visibility: visible;
      }
    }
  `}
`;

export const TooltipText = styled.div`
  ${({
    theme: {
      fonts: { NotoSansRegular },
    },
  }) => css`
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    position: absolute;
    z-index: 1000;
    bottom: 50%;
    left: 30px;
    transform: translatey(12px);
    font-size: 11px;
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    white-space: nowrap;
    &::before {
      content: '';
      display: inline-block;
      height: 10px;
      width: 10px;
      margin-top: -6px;
      position: absolute;
      -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: rotate(44deg);
      left: -3px;
      top: 50%;
      background: black;
    }
  `}
`;
