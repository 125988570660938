import React from 'react';

const Youtube = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 30 22"
      className={className}
    >
      <g fillRule="evenodd">
        <path
          d="M29.373 7.754a3.77 3.77 0 00-2.652-2.67C24.38 4.454 15 4.454 15 4.454s-9.381 0-11.72.63a3.77 3.77 0 00-2.653 2.67C0 10.108 0 15.02 0 15.02s0 4.913.627 7.268c.345 1.3 1.361 2.28 2.652 2.627 2.34.63 11.721.63 11.721.63s9.381 0 11.72-.63c1.292-.347 2.308-1.328 2.653-2.627.627-2.355.627-7.268.627-7.268s0-4.913-.627-7.267zM11.932 19.482V10.56l7.84 4.46-7.84 4.46z"
          id="a"
        />
      </g>
    </svg>
  );
};

export default Youtube;
