import React from 'react';

const MyOrders = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <g fill="none" fillRule="evenodd">
        <path
          d="M16.7 17.865l1.927-1.52L20.735 15h3.855l1.29 1.345L28 17.67l.796 4.384c-.804 1.998-1.474 3.26-2.008 3.786-1.417 1.398-4.016 1.517-4.95 1.756-1.461.374-3.174-.455-5.138-2.487v-7.243z"
          fill="#333"
          opacity=".503"
        />
        <path
          d="M22.48 13.973c3.903 0 7.066 3.195 7.066 7.136 0 2.067-.87 3.928-2.261 5.232L30 29.083l-.908.917-2.833-2.861a6.982 6.982 0 01-3.778 1.105c-3.903 0-7.066-3.195-7.066-7.135 0-3.941 3.163-7.136 7.066-7.136zm-9.359-12.91L25.325 6.9a.65.65 0 01.367.587v5.838h-1.285V9.16l-10.919 5.223V25.92l1.652-.792.55 1.172-2.568 1.233a.637.637 0 01-.55 0L.366 21.695A.65.65 0 010 21.109V7.487A.65.65 0 01.367 6.9l12.204-5.838a.637.637 0 01.55 0zm9.36 14.208c-3.193 0-5.781 2.613-5.781 5.838 0 3.224 2.588 5.838 5.78 5.838 3.193 0 5.781-2.614 5.781-5.838 0-3.225-2.588-5.838-5.78-5.838zM1.284 9.16v11.538l10.919 5.223V14.383L1.284 9.16zm18.627 9.354v5.19h-1.284v-5.19h1.284zm1.927 0v5.19h-1.284v-5.19h1.284zm2.57 0v5.19h-1.285v-5.19h1.284zm1.926 0v5.19H25.05v-5.19h1.284zM6.565 5.37l-5.1 2.441 11.381 5.445 5.1-2.441L6.565 5.37zm6.281-3.004l-4.78 2.286 11.381 5.445 4.78-2.286-11.381-5.445z"
          fill="#000"
        />
      </g>
    </svg>
  );
};

export default MyOrders;
