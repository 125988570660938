import React from 'react';

const Pdf = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 23 30">
      <g fillRule="nonzero">
        <path d="M12.942 12.73a2.122 2.122 0 00-.73-.396 4.302 4.302 0 00-.941-.091H9.873c-.243 0-.395.06-.516.152-.091.091-.152.273-.152.516v3.92c0 .182 0 .303.06.425.031.121.092.182.183.243.091.06.243.09.456.09H11.3c.243 0 .456-.03.669-.06.182-.03.364-.091.546-.152.183-.09.335-.182.456-.304.182-.182.334-.364.456-.577.121-.212.213-.455.273-.729.061-.273.091-.547.091-.88 0-.943-.273-1.672-.85-2.158zm-.79 3.766c-.091.061-.182.122-.274.152-.121.03-.212.06-.303.06h-1.246v-3.645h.73c.333 0 .607.03.85.122.243.06.425.243.577.516.152.274.243.669.243 1.185-.03.79-.213 1.306-.577 1.61z" />
        <path d="M22.603 5.985l-.03-.03v-.03L16.8.151l-.03-.03s-.03 0-.03-.03c0 0-.031 0-.031-.031C16.618 0 16.527 0 16.405 0H2.096C1.55 0 1.033.213.668.577l-.03.03A2.122 2.122 0 00.03 2.067v25.853c0 .577.243 1.094.608 1.458.365.365.911.608 1.458.608h18.501c.578 0 1.094-.243 1.459-.608.364-.364.607-.911.607-1.458V6.289a.899.899 0 00-.06-.304zm-5.62-3.798l3.432 3.433h-2.521c-.243 0-.486-.09-.638-.273a.947.947 0 01-.274-.638V2.187zm4.374 25.732a.76.76 0 01-.243.547.76.76 0 01-.547.243H2.097a.873.873 0 01-.548-.213.732.732 0 01-.212-.547V2.035a.76.76 0 01.243-.546l.03-.03c.152-.122.334-.213.517-.213h13.61v3.432c0 .608.243 1.155.668 1.58.395.395.972.638 1.58.638h3.372V27.92z" />
        <path d="M7.808 12.668c-.152-.152-.335-.243-.578-.303-.212-.061-.546-.092-.941-.092H4.89c-.243 0-.425.061-.516.152-.122.091-.152.274-.152.517v4.131c0 .213.06.365.152.486.09.122.243.152.395.152.152 0 .273-.06.395-.182a.773.773 0 00.151-.486v-1.519H6.32c.668 0 1.154-.152 1.489-.425.334-.274.516-.699.516-1.246 0-.243-.03-.486-.121-.699-.122-.182-.244-.364-.395-.486zm-.73 1.702a.772.772 0 01-.394.273 2.02 2.02 0 01-.638.091h-.73v-1.64h.73c.486 0 .79.09.972.243.152.152.212.334.212.577-.03.182-.06.334-.152.456zM15.403 12.213c-.183.03-.304.06-.395.121a.367.367 0 00-.213.213c-.06.091-.06.212-.06.395v4.101c0 .213.06.395.151.486.091.122.243.152.395.152a.64.64 0 00.395-.152.773.773 0 00.152-.486v-1.762h1.792c.152 0 .274-.03.365-.122.09-.06.121-.182.121-.303a.39.39 0 00-.121-.304.651.651 0 00-.365-.122h-1.792v-1.367h2.126c.183 0 .304-.03.395-.121a.39.39 0 00.122-.304.39.39 0 00-.122-.304c-.09-.091-.212-.121-.395-.121h-2.551z" />
      </g>
    </svg>
  );
};

export default Pdf;
