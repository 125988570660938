import React from 'react';
import PropTypes from 'prop-types';
import GlobalNav from 'components/global-nav';
import Footer from 'components/footer';
import { PageWrapper } from './Wrapper.styles.js';
import GlobalNavMapper from 'components/global-nav/GlobalNav.mapper';
import FooterMapper from 'components/footer/Footer.mapper';

const Wrapper = ({ header, footer, brand, children }) => {
  return (
    <PageWrapper>
      <GlobalNav {...GlobalNavMapper(header)} brand={brand} />
      {children}
      <Footer {...FooterMapper(footer)} />
    </PageWrapper>
  );
};

Wrapper.propTypes = {
  header: PropTypes.shape({}),
  footer: PropTypes.shape({}),
  children: PropTypes.node,
};

export default Wrapper;
