import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Title, Text, Content } from 'components/accordion/AccordionComponent.styles.js';
import { Icon } from 'components/_icons';
import ReactHtmlParser from 'react-html-parser';

const AccordionComponent = ({ title, text }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  return (
    <Accordion>
      <Title onClick={() => setIsAccordionOpen(oldState => !oldState)}>
        {ReactHtmlParser(title)}
        <Icon name={isAccordionOpen ? 'minus' : 'plus'}></Icon>
      </Title>
      <Content isAccordionOpen={isAccordionOpen}>
        <Text>{ReactHtmlParser(text)}</Text>
      </Content>
    </Accordion>
  );
};

AccordionComponent.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

export default AccordionComponent;
