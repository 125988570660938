import React from 'react';
import PropTypes from 'prop-types';
import { BreadcrumbsComponent } from 'components/breadcrumbs/Breadcrumbs.styles';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';

const Breadcrumbs = ({ breadcrumbs }) => {
  return (
    <BreadcrumbsComponent>
      {breadcrumbs?.map((crumb, index) => {
        const lastBreadcrumb = breadcrumbs.length - 1 === index;
        const firstBreadcrumb = index === 0;
        return (
          <Breadcrumb
            key={index}
            {...crumb}
            lastBreadcrumb={lastBreadcrumb}
            firstBreadcrumb={firstBreadcrumb}
          />
        );
      })}
    </BreadcrumbsComponent>
  );
};

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array,
};

export default Breadcrumbs;
