import { createGlobalStyle } from 'styled-components';
import { lighten } from 'polished';

import NotoSansBoldEot from 'assets/fonts/NotoSans-Bold.eot';
import NotoSansBoldTtf from 'assets/fonts/NotoSans-Bold.ttf';
import NotoSansBoldWoff2 from 'assets/fonts/NotoSans-Bold.woff2';
import NotoSansBoldWoff from 'assets/fonts/NotoSans-Bold.woff';

import NotoSansBoldItalicEot from 'assets/fonts/NotoSans-BoldItalic.eot';
import NotoSansBoldItalicTtf from 'assets/fonts/NotoSans-BoldItalic.ttf';
import NotoSansBoldItalicWoff2 from 'assets/fonts/NotoSans-BoldItalic.woff2';
import NotoSansBoldItalicWoff from 'assets/fonts/NotoSans-BoldItalic.woff';

import NotoSansSemiBoldEot from 'assets/fonts/NotoSans-SemiBold.eot';
import NotoSansSemiBoldTtf from 'assets/fonts/NotoSans-SemiBold.ttf';
import NotoSansSemiBoldWoff2 from 'assets/fonts/NotoSans-SemiBold.woff2';
import NotoSansSemiBoldWoff from 'assets/fonts/NotoSans-SemiBold.woff';

import NotoSansRegularEot from 'assets/fonts/NotoSans-Regular.eot';
import NotoSansRegularTtf from 'assets/fonts/NotoSans-Regular.ttf';
import NotoSansRegularWoff2 from 'assets/fonts/NotoSans-Regular.woff2';
import NotoSansRegularWoff from 'assets/fonts/NotoSans-Regular.woff';

import NotoSansExtraBoldItalicEot from 'assets/fonts/NotoSans-ExtraBoldItalic.eot';
import NotoSansExtraBoldItalicTtf from 'assets/fonts/NotoSans-ExtraBoldItalic.ttf';
import NotoSansExtraBoldItalicWoff2 from 'assets/fonts/NotoSans-ExtraBoldItalic.woff2';
import NotoSansExtraBoldItalicWoff from 'assets/fonts/NotoSans-ExtraBoldItalic.woff';

import NotoSansExtraBoldEot from 'assets/fonts/NotoSans-ExtraBold.eot';
import NotoSansExtraBoldTtf from 'assets/fonts/NotoSans-ExtraBold.ttf';
import NotoSansExtraBoldWoff2 from 'assets/fonts/NotoSans-ExtraBold.woff2';
import NotoSansExtraBoldWoff from 'assets/fonts/NotoSans-ExtraBold.woff';

import NotoSansMediumEot from 'assets/fonts/NotoSans-Medium.eot';
import NotoSansMediumTtf from 'assets/fonts/NotoSans-Medium.ttf';
import NotoSansMediumWoff2 from 'assets/fonts/NotoSans-Medium.woff2';
import NotoSansMediumWoff from 'assets/fonts/NotoSans-Medium.woff';

import NotoSansRegularItalicEot from 'assets/fonts/NotoSans-Regular-Italic.eot';
import NotoSansRegularItalicTtf from 'assets/fonts/NotoSans-Regular-Italic.ttf';
import NotoSansRegularItalicWoff2 from 'assets/fonts/NotoSans-Regular-Italic.woff2';
import NotoSansRegularItalicWoff from 'assets/fonts/NotoSans-Regular-Italic.woff';

import NotoSansSemiBoldItalicEot from 'assets/fonts/NotoSans-SemiBoldItalic.eot';
import NotoSansSemiBoldItalicTtf from 'assets/fonts/NotoSans-SemiBoldItalic.ttf';
import NotoSansSemiBoldItalicWoff2 from 'assets/fonts/NotoSans-SemiBoldItalic.woff2';
import NotoSansSemiBoldItalicWoff from 'assets/fonts/NotoSans-SemiBoldItalic.woff';

const GlobalStyle = createGlobalStyle`
  ${({ theme }) => `
    @font-face {
      font-family: 'Noto Sans';
      src: url('${NotoSansBoldEot}?#iefix') format('embedded-opentype');
      src:
        url(${NotoSansBoldTtf}) format('truetype'),
        url(${NotoSansBoldWoff2}) format('woff2'),
        url(${NotoSansBoldWoff}) format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Noto Sans';
      src: url('${NotoSansBoldItalicEot}?#iefix') format('embedded-opentype');
      src:
        url('${NotoSansBoldItalicTtf}') format('truetype'),
        url('${NotoSansBoldItalicWoff2}') format('woff2'),
        url('${NotoSansBoldItalicWoff}') format('woff');
      font-weight: bold;
      font-style: italic;
      font-display: swap;
    }

    @font-face {
      font-family: 'Noto Sans';
      src: url('${NotoSansMediumEot}?#iefix') format('embedded-opentype');
      src:
        url('${NotoSansMediumTtf}') format('truetype'),
        url('${NotoSansMediumWoff2}') format('woff2'),
        url('${NotoSansMediumWoff}') format('woff');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Noto Sans';
      src: url('${NotoSansSemiBoldEot}?#iefix') format('embedded-opentype');
      src:
        url('${NotoSansSemiBoldTtf}') format('truetype'),
        url('${NotoSansSemiBoldWoff2}') format('woff2'),
        url('${NotoSansSemiBoldWoff}') format('woff');
      font-weight: 600;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Noto Sans';
      src: url('${NotoSansRegularEot}?#iefix') format('embedded-opentype');
      src:
        url('${NotoSansRegularTtf}') format('truetype'),
        url('${NotoSansRegularWoff2}') format('woff2'),
        url('${NotoSansRegularWoff}') format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Noto Sans';
      src: url('${NotoSansExtraBoldItalicEot}?#iefix') format('embedded-opentype');
      src:
        url('${NotoSansExtraBoldItalicTtf}') format('trueType'),
        url('${NotoSansExtraBoldItalicWoff2}') format('woff2'),
        url('${NotoSansExtraBoldItalicWoff}') format('woff');
      font-weight: 800;
      font-style: italic;
      font-display: swap;
    }

    @font-face {
      font-family: 'Noto Sans';
      src: url('${NotoSansExtraBoldEot}?#iefix') format('embedded-opentype');
      src:
        url('${NotoSansExtraBoldTtf}') format('truetype'),
        url('${NotoSansExtraBoldWoff2}') format('woff2'),
        url('${NotoSansExtraBoldWoff}') format('woff');
      font-weight: 800;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Noto Sans';
      src: url('${NotoSansRegularItalicEot}?#iefix') format('embedded-opentype');
      src:
        url('${NotoSansRegularItalicTtf}') format('truetype'),
        url('${NotoSansRegularItalicWoff2}') format('woff2'),
        url('${NotoSansRegularItalicWoff}') format('woff');
      font-weight: normal;
      font-style: italic;
      font-display: swap;
    }

    @font-face {
      font-family: 'Noto Sans';
      src: url('${NotoSansSemiBoldItalicEot}?#iefix') format('embedded-opentype');
      src:
        url('${NotoSansSemiBoldItalicTtf}') format('truetype'),
        url('${NotoSansSemiBoldItalicWoff2}') format('woff2'),
        url('${NotoSansSemiBoldItalicWoff}') format('woff');
      font-weight: 600;
      font-style: italic;
      font-display: swap;
    }

    html {
      font-size: 62.5%;
    }

    body {
      font-family: 'Noto Sans', Verdana, 'Helvetica Neue', sans-serif;
      font-weight: normal;
      font-style: normal;
      background-color: #f9fafb;
      margin: 0;
      position: relative;
    }

    a {
      color: ${theme.colours.primary};
      letter-spacing: 0.02px;
      text-decoration: underline;

      &:hover, &:focus {
        color: ${lighten(0.2, theme.colours.primary)};
      }
    }

    * {
      box-sizing: border-box;
    }
  `}
`;

export default GlobalStyle;
