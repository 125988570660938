import React from 'react';

const SupportTicket = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M26.552 1l.096.002a3.132 3.132 0 013.112 3.132v1.253h-4.386V4.134h3.133a1.88 1.88 0 00-1.88-1.88h-.15l-.125.01a1.804 1.804 0 00-1.101.538 1.834 1.834 0 00-.503 1.413L24.747 25.4a3.001 3.001 0 01-.874 2.293 2.978 2.978 0 01-2.258.876H5.953c-1.506 0-3.221-1.402-3.728-2.937l-.271-.823h3.998V19.32L.009 13.385 0 13.137c-.024-.82.329-1.604.916-2.091l.009-.005a2.663 2.663 0 012.122-.942l.248.008 2.656 2.665V4.134a3.133 3.133 0 012.955-3.128l.178-.005h17.372a3.06 3.06 0 01.191 0zm-2.474 1.254H9.085a1.88 1.88 0 00-1.88 1.88v9.895l4.71 4.725a6.058 6.058 0 011.524 4.193l-.017.596-.596.012a6.086 6.086 0 01-4.215-1.58l-1.406-1.404v4.237h12.393l.12.475c.29 1.16 1.087 2.032 1.897 2.032h.036c.442.026.875-.118 1.214-.397l.122-.112c.352-.352.537-.838.508-1.371l.001-21.179a3.057 3.057 0 01.582-2.002zm-5.433 23.808H3.869l.1.122c.547.644 1.342 1.13 1.984 1.13h13.366a4.6 4.6 0 01-.674-1.252zM4.346 12.934l-1.507 1.507 6.634 6.625a4.818 4.818 0 002.69 1.208 4.77 4.77 0 00-1.16-2.662l-6.657-6.678zm-1.568-1.572a1.475 1.475 0 00-.93.526l-.086.082c-.275.229-.45.551-.497.9l.687.686 1.509-1.509-.683-.685zm13.631-.973v1.347H9v-1.347h7.41zm5.389-2.695v1.347H9V7.694h12.798zm0-2.694v1.347H9V5h12.798z"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default SupportTicket;
