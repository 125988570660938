import React from 'react';
import Signup from 'components/sign-up/Signup';
import Tiles from 'components/tiles';
import Header from 'components/Header';
import ContentBlock from 'components/content-block';
import ContactCards from 'components/contact-cards';
import Accordion from 'components/accordion';
import Form from 'components/form';
import EditProfile from 'components/edit-profile';
import NotFound from 'components/not-found';
import Breadcrumbs from 'components/breadcrumbs';
import Table from 'components/table';
import ImageGallery from 'components/image-gallery';
import Back from 'components/back';
import EventList from 'components/event-list';
import Image from 'components/_common/image';
import Modal from 'components/_common/modal';
import RequestAccess from 'components/request-access';
import FileDownloadList from 'components/file-download-list';
import FullWidthCTA from 'components/full-width-cta';
import NotificationPreviewList from 'components/notification-preview-list';
import NotificationDetail from 'components/notification-detail';
import OrderingTool from 'components/ordering-tool';
import MainImage from 'components/main-image';
import RichText from 'components/rich-text';
import ListLanguageFolder from 'components/list-language-folder';
import NotificationHeader from 'components/notification-header';
import MultipleContact from 'components/multiple-contact';
import FavoriteTiles from 'components/favorite-tiles'
import ViewProfile from 'components/view-profile'
import PowerBI from 'components/power-bi'
import PortalSearchResult from 'components/portal-search-result'
import VideoPlayer from 'components/video-player'
import PDFViewer from 'components/pdf-viewer'
import RequestAccessNew from 'components/request-accessnew';

const ComponentSwitch = ({ component, ...props }) => {
  if (!component) {
    return null;
  }
  const { templateLabel } = component;
  const components = {
    Signup: Signup,
    'Login Template': Signup,
    'Tile List Template': Tiles,
    'Header Template': Header,
    'Header With Text Template': ContentBlock,
    'Contact Card Template': ContactCards,
    'Accordion Template': Accordion,
    'Form Template': Form,
    'Edit Profile Template': EditProfile,
    'Error Template': NotFound,
    'Breadcrumbs Template': Breadcrumbs,
    'Table Template': Table,
    'Image Gallery Template': ImageGallery,
    'Event List Template': EventList,
    'Back Template': Back,
    'Image Template': Image,
    'Request Access Template': RequestAccess,
    'Request Accessnew Template': RequestAccessNew,
    'File Download List Template': FileDownloadList,
    'Call To Action Template': FullWidthCTA,
    'Notifications Preview Template': NotificationPreviewList,
    'Notifications List Template': NotificationDetail,
    'Notification Header Template': NotificationHeader,
    'Multiple Contact Template': MultipleContact,
    'Ordering Tool Template': OrderingTool,
    'Main Image Template': MainImage,
    'Rich Text Template': RichText,
    'List Language Folder Template': ListLanguageFolder,
    'Favorite Tiles Template': FavoriteTiles,
    'View Profile Template': ViewProfile,
    'Power BI Template': PowerBI,
    'Search Result Template': PortalSearchResult,
    'Video Player Template': VideoPlayer,
    'PDF Viewer Template': PDFViewer,
  };
  const Component = components[templateLabel];

  if (!Component) {
    return <p>No component found for {templateLabel}</p>;
  }
  
  return <Component {...{ ...component, ...props }}></Component>;
};

export default ComponentSwitch;
