import styled from 'styled-components';
import { Icon } from 'components/_icons';
import Link from 'components/_common/Link';

export const NotFoundComponent = styled.section`
  ${({
    theme: {
      breakpoints: { mobileLarge },
    },
  }) => `
    padding: 3rem 2.5rem 3.1rem;
    max-width: 63rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width:${mobileLarge}px) {
      padding: 5rem 2.5rem 10rem;

    }
  `}
`;

export const WarningIcon = styled(Icon)`
  ${({
    theme: {
      colours: { mattBlack },
      breakpoints: { mobileLarge },
    },
  }) => `
    fill: ${mattBlack};
    width: 11.2rem;
    height: 10rem;
    margin-bottom: 3.3rem;

    @media (min-width: ${mobileLarge}px) {
      width:16.8rem;
      height: 15rem;
      margin-bottom: 4.9rem;
    }
  `}
`;

export const Text = styled.p`
  ${({
    theme: {
      colours: { lightGrey },
      breakpoints: { mobileLarge },
      fonts: { NotoSansRegular },
    },
  }) => `
    color: ${lightGrey};
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3rem;

    @media (min-width: ${mobileLarge}px) {
      font-size: 1.6rem;
      margin-bottom: 5rem;
    }
  `}
`;

export const BackToDashboardLink = styled(Link)`
  ${({
    theme: {
      fonts: { NotoSansExtraBold },
    },
  }) => `
    font-family: ${NotoSansExtraBold.value};
    font-weight: ${NotoSansExtraBold.fontWeight};
    font-style: ${NotoSansExtraBold.fontStyle};
    font-size: 1.4rem;
    letter-spacing: 0.02px;
    text-transform: uppercase;

    svg {
      transform: rotate(-180deg);
    }
  `}
`;
