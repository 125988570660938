import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NotificationPreviewListComponent, BackToDashboardLink } from './NotificationPreviewList.styles.js';
import Notification from 'components/_common/notification';
import { getNotification, markNotificationAsRead } from 'helpers/utilities';
import { Auth } from 'components/context/AuthContext';
import getAuthVariables from 'helpers/authVariables.js';

const NotificationPreviewList = ({ cta, labels = {} }) => {
  const { url } = cta || {};
  const { userToken, notifications } = useContext(Auth);

  const markNotificationAsReadClickHandler = (notificationId, userId) => {
    markNotificationAsRead(notificationId, null, userToken);
  };

  return (
    <NotificationPreviewListComponent>
      {notifications?.map(notification => {
        return (
          <Notification
            {...notification}
            onClick={markNotificationAsReadClickHandler}
            notification={notification}
          />
        );
      })}
      <BackToDashboardLink
        linkText={labels.back_to_dashboard_cta_text || 'Back to dashboard'}
        linkHref={labels.dashboard_url || url}
        isExternal={false}
        target="_self"
        iconName="chevronLeft"
        dark
      />
    </NotificationPreviewListComponent>
  );
};

NotificationPreviewList.propTypes = {
  cta: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    alt: PropTypes.string,
    isExternal: PropTypes.bool,
  }),
};

export default NotificationPreviewList;
