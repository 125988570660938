import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from './theme.js';
import GlobalStyle from 'components/_utility/global-styles/GlobalStyles.js';
import PageLayout from 'components/_utility/page-layout/PageLayout';
import mapPageJSON from 'components/_utility/page-json-mapper/pageJSONMapper';

import { landingPageJSON } from 'components/page-json/LandingPage.data';
import { contactPageJSON } from 'components/page-json/ContactPage.data';
import Page from 'components/_utility/page/Page.js';
import { GlobalContextProvider } from 'components/context/GlobalContext';
import { FormContextProvider } from 'components/context/FormContext';
import AuthContextProvider from 'components/context/AuthContext';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from 'helpers/configuration';

function App() {
  const pca = new PublicClientApplication(msalConfig);
  return (
    <MsalProvider instance={pca}>
      <AuthContextProvider>
        <GlobalContextProvider>
          <FormContextProvider>
            <Router>
              <Switch>
                <Route path="*">
                  <Page />
                </Route>
              </Switch>
            </Router>
          </FormContextProvider>
        </GlobalContextProvider>
      </AuthContextProvider>
    </MsalProvider>
  );
}

export default App;
