import React from 'react';

const Delete = ({ className }) => {
  return (
    <svg version="1.1" x="0" y="0" viewBox="0 0 24 24" className={className}>
      <g>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m15.84 22.25h-7.68a3.05 3.05 0 0 1 -3-2.86l-.91-13.84a.76.76 0 0 1 .2-.55.77.77 0 0 1 .55-.25h14a.75.75 0 0 1 .75.8l-.87 13.84a3.05 3.05 0 0 1 -3.04 2.86zm-10-16 .77 13.05a1.55 1.55 0 0 0 1.55 1.45h7.68a1.56 1.56 0 0 0 1.55-1.45l.81-13z"
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m21 6.25h-18a.75.75 0 0 1 0-1.5h18a.75.75 0 0 1 0 1.5z"
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m15 6.25h-6a.76.76 0 0 1 -.75-.75v-1.8a2 2 0 0 1 1.95-1.95h3.6a2 2 0 0 1 1.95 2v1.75a.76.76 0 0 1 -.75.75zm-5.25-1.5h4.5v-1a.45.45 0 0 0 -.45-.45h-3.6a.45.45 0 0 0 -.45.45z"
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m15 18.25a.76.76 0 0 1 -.75-.75v-8a.75.75 0 0 1 1.5 0v8a.76.76 0 0 1 -.75.75z"
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m9 18.25a.76.76 0 0 1 -.75-.75v-8a.75.75 0 0 1 1.5 0v8a.76.76 0 0 1 -.75.75z"
        ></path>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m12 18.25a.76.76 0 0 1 -.75-.75v-8a.75.75 0 0 1 1.5 0v8a.76.76 0 0 1 -.75.75z"
        ></path>
      </g>
    </svg>
  );
};

export default Delete;
