import styled from 'styled-components';
import { Icon } from 'components/_icons';

export const BackComponent = styled.section`
  ${({
    theme: {
      breakpoints: { tablet },
    },
  }) => `
  padding: 1rem 1rem 1rem 1.8rem;
  @media (min-width: ${tablet}px){
    padding: 3rem 1rem 1rem 10rem;
  }
  `}
`;

export const BackButton = styled.button`
  ${({
    theme: {
      fonts: { NotoSansMedium },
      colours: { darkGrey },
    },
  }) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${darkGrey};
    font-size: 1.6rem;
    font-family: ${NotoSansMedium.value};
    font-weight: ${NotoSansMedium.fontWeight};
    line-height: 2.4rem;
    appearance: none;
    cursor: pointer;
    border: 0;
    background: transparent;
    text-transform: uppercase;
  `}
`;

export const Chevron = styled(Icon)`
  ${({
    theme: {
      colours: { darkGrey },
    },
  }) => `
    transform: rotate(180deg);
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 1rem;
    fill: ${darkGrey};
  `}
`;
