import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { GetIcon, Icon } from 'components/_icons';


export const ContainerFavoriteTiles = styled.div`
  ${({ theme }) => css`
    
    @media (min-width: ${theme.breakpoints.tablet}px) {
      
    }
    @media (min-width: ${theme.breakpoints.desktop}px) {
      
    }
  `}
`;

export const HeaderComponent = styled.h1`
  ${({
    theme: {
      fonts: { NotoSansRegularItalic },
      colours: { primary },
    },
    align,
  }) => css`
    font-family: ${NotoSansRegularItalic.value};
    font-style: normal;
    font-weight: bold;
    color: ${primary};
    width: 100%;
    padding: 50px 0px;
    text-align: ${align ? align : 'left'};
  `}
`;

export const NoTiles = styled.h1`
  ${({ }) => css`
    font-size: 14pt;
    text-align: center;
    color: #000;
    padding-top: 20px;
    width: 100%;
  `}
`;
