import { GlobalContext } from 'components/context/GlobalContext';
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Chevron, ContainerSubSideNavChildLinks } from './SubSideNav.styles';
import ReactHtmlParser from 'react-html-parser';
import CloseButton from './CloseButton';

export default function SubSideNavChildLinks(props) {
  const { dataChild, getSubChiled, setActiveTabIndex } = props;
  const { globalDispatch } = useContext(GlobalContext);
  return (
    <ContainerSubSideNavChildLinks open={dataChild}>
      {/* {dataChild && (
        <CloseButton
          onClick={() => {
            globalDispatch({
              type: 'CLOSE_SIDEBAR',
            });
            setActiveTabIndex(null);
          }}
        >
          X
        </CloseButton>
      )} */}
      <h1 style={{ whiteSpace: 'normal' }}>
        <Link to={dataChild?.url}>{ReactHtmlParser(dataChild?.title)}</Link>
      </h1>
      <ul>
        {dataChild?.childSubLinks &&
          dataChild?.childSubLinks?.map(item => {
            return (
              <li key={item.title}>
                {item.isExternal ? (
                  <a
                    style={{ whiteSpace: 'normal' }}
                    target={item.target}
                    href={item.url}
                    onClick={() => {
                      globalDispatch({
                        type: 'OPEN_SIDEBAR',
                      });
                    }}
                  >
                    {ReactHtmlParser(item.title)}
                  </a>
                ) : (
                  <Link
                    target={item.target}
                    to={item.url}
                    onClick={() => {
                      globalDispatch({
                        type: 'OPEN_SIDEBAR',
                      });
                    }}
                  >
                    {ReactHtmlParser(item.title)}
                  </Link>
                )}

                {item?.childSubLinks?.length > 0 && (
                  <div onClick={() => getSubChiled(item.title)}>
                    <Chevron name="chevron" />
                  </div>
                )}
              </li>
            );
          })}
      </ul>
    </ContainerSubSideNavChildLinks>
  );
}
