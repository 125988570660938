import React from 'react';

const Speech = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M26.923 1C28.623 1 30 2.378 30 4.077V21c0 1.7-1.378 3.077-3.077 3.077h-1.931l-3.779 4.857a1.538 1.538 0 01-2.751-.946v-3.911H3.077A3.077 3.077 0 010 21V4.077C0 2.377 1.378 1 3.077 1zm0 1.538H3.077c-.85 0-1.539.69-1.539 1.539V21c0 .85.69 1.538 1.539 1.538H19.23a.77.77 0 01.769.77v4.68l4.008-5.153a.77.77 0 01.607-.297h2.308c.85 0 1.539-.688 1.539-1.538V4.077c0-.85-.69-1.539-1.539-1.539zm-11.59 8.297v1.575H3.538v-1.575h11.795zm5.898-3.148V9.26H3.538V7.687h17.693zm0-3.149v1.575H3.538V4.538h17.693z"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default Speech;
