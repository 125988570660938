import React from 'react';

const ProductUpdates = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M20.018 1C21.662 1 23 2.346 23 4v18c0 1.654-1.338 3-2.982 3a2.992 2.992 0 01-2.949-2.552l-.906-.912C14.585 19.948 12.26 19 9.946 19h-.994v5c0 1.654-1.338 3-2.982 3-1.644 0-2.982-1.346-2.982-3v-5.172C2.424 18.627 1.761 18.018 1 17l.171-8c.41-1.164 1.515-2 2.81-2h5.965c2.315 0 4.639-.948 6.217-2.536l.906-.912A2.992 2.992 0 0120.02 1zM6.964 19H4.976v5c0 .551.446 1 .994 1a.998.998 0 00.994-1v-5zM20.018 3a.998.998 0 00-.994 1v18c0 .551.446 1 .994 1l.116-.007a1 1 0 00.878-.993V4c0-.551-.446-1-.994-1zm5.202 14.22a.75.75 0 011.06 0l1.5 1.5a.75.75 0 01-1.06 1.06l-1.5-1.5a.75.75 0 010-1.06zM17.036 6.377c-1.68 1.462-3.838 2.37-6.103 2.578v8.09c2.265.207 4.424 1.116 6.103 2.578zM8.952 9h-4.97a.998.998 0 00-.994 1v6c0 .551.446 1 .994 1h4.97V9zM29.2 13c.442 0 .8.448.8 1s-.358 1-.8 1h-2.4c-.442 0-.8-.448-.8-1s.358-1 .8-1zm-2.48-4.78a.75.75 0 011.06 1.06l-1.5 1.5a.75.75 0 01-1.06-1.06z"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default ProductUpdates;
