import { Auth } from 'components/context/AuthContext';
import { DeliveryStatus } from 'components/_icons/Icon.stories.js';
import { logoutApi } from 'helpers/utilities.js';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { NavIcon } from '../../global-nav/GlobalNav.styles.js';

export default function SearchBarNav({ SearchText }) {
  const searchParams = new URLSearchParams(document.location.search)

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = e => {
      if (!isOpen) {
        setIsOpen(true);
        document.getElementById('searchBar_Form').style.display = 'block';
      } else {
        setIsOpen(false);
        document.getElementById('searchBar_Form').style.display = 'none';
      }
  };
  return (
    <SearchBar>
      <form id="searchBar_Form" action="/Search">
        <input type="text" name="q" placeholder={SearchText} autoComplete="off" />
      </form>

      <a onClick={handleClick}>
        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.9536 14.9458L21 21M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </a>
    </SearchBar>
  );
}

const SearchBar = styled.div`${({
  theme: {
    breakpoints: { tablet, tabletLarge, mobileLarge, mobileMax },
  },
}) => `
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding: 12px;
  padding-right: 0;
  
  form{
    display: none;
    flex: 1 1 auto;
    width: 1%;

    input{
      height: min-content;
      border: 2px solid #000;
      border-radius: 5px;
    }
  }

  a{
    cursor: pointer;
  }
`}
`;