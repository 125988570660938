import styled, { css } from 'styled-components';
import { lighten } from 'polished';

export const RichTextComponent = styled.section`
  ${({ align }) => css`
    text-align: ${align ? align : 'left'};
  `}
`;

export const RichTextContent = styled.div`
  ${({
    theme: {
      colours: { darkGrey, white, primary },
      fonts: { NotoSansExtraBold, NotoSansBold, NotoSansRegular },
      breakpoints,
    },
    dark,
    align,
  }) => css`
    padding: 40px;

    > p {
      margin: 0;
      padding: 0 0 32.5px;
      font-size: 15px;
      line-height: 24px;
      font-family: ${NotoSansRegular.value};
      font-weight: ${NotoSansRegular.fontWeight};
      font-style: ${NotoSansRegular.fontStyle};
      text-align: ${align ? align : 'left'};
      >span{
        font-size: inherit !important;
      }
      @media (min-width: ${breakpoints.tablet}px) {
        font-size: 24px;
        >span{
        font-size: 14pt !important;
      }
      }
    }

    > p b {
      color: ${darkGrey};
      font-weight: bold;
    }

    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6 {
      font-family: ${NotoSansBold.value};
      font-weight: ${NotoSansBold.fontWeight};
      font-style: ${NotoSansBold.fontStyle};
      text-align: left;
      margin: 0 0 32.5px;
      padding: 0;
    }

    > h1 {
      font-size: 2.4rem;
    }

    > h2 {
      font-size: 24px;
      line-height: 24px;
      font-weight: normal;
    }

    > h3 {
      font-size: 2rem;
    }

    > h4 {
      font-size: 1.8rem;
    }

    > h5 {
      font-size: 1.6rem;
    }

    > h6 {
      font-size: 1.4rem;
    }

    > ul {
      color: ${darkGrey};
      margin: 0;
      padding: 0 2.9rem;
      /* max-width: 76.9rem; */
    }

    > ul li {
      font-size: 1.6rem;
      margin-bottom: 1rem;
      font-family: ${NotoSansRegular.value};
      font-weight: ${NotoSansRegular.fontWeight};
      font-style: ${NotoSansRegular.fontStyle};
      color: rgb(51, 51, 51);
    }

    > ol {
      color: ${darkGrey};
      margin: 0 auto 2.5rem;
      padding: 0 4.8rem;
      max-width: 76.9rem;
    }

    > ol li {
      font-size: 1.6rem;
      margin-bottom: 1rem;
    }

    > blockquote {
      color: ${darkGrey};
      margin: 0 auto 2.5rem;
      padding: 0 3rem;
      max-width: 76.9rem;
    }

    > div {
      color: ${darkGrey};
      margin-block: 2.5rem;
      display: block;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      font-size: 16px;
    }

    table tr {
      color: #757475;
      line-height: 24px;
    }

    table tr:first-child {
      /* color: ${darkGrey}; */
    }

    table tr:nth-child(even) {
      background-color: #f8f8f8;
    }

    table a {
      color: #3467c9;
      text-decoration: none;
    }

    table td {
      padding: 10px;
    }

    div.button-link {
      padding: 0;
      display: inline-flex;
      margin-top: 24px;

      > a {
        appearance: none;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: ${NotoSansExtraBold.value};
        font-style: ${NotoSansExtraBold.fontStyle};
        font-weight: ${NotoSansExtraBold.fontWeight};
        font-size: 1.4rem;
        background-color: ${dark ? white : primary};
        border: 1px solid ${primary};
        color: ${dark ? primary : white};
        letter-spacing: 0.02px;
        text-transform: uppercase;
        text-decoration: none;
        padding: 12px 24px;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        &:disabled {
          cursor: default;
        }

        &:hover,
        &:focus {
          background-color: ${lighten(dark ? 0.1 : 0.2, dark ? white : primary)};
          border: 1px solid ${lighten(dark ? 0.1 : 0.2, primary)};
          color: ${lighten(dark ? 0.1 : 0.2, dark ? primary : white)};
        }
      }
    }

    @media (min-width: ${breakpoints.tablet}px) {
      padding: 47px 135px 0 58px;

      table td {
        padding: 10px 30px;
      }
    }
  `}
`;
