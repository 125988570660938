import React from 'react';

const BrandGuideLines = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M21.624 8.958c1.024 0 1.854.83 1.854 1.853v4.44l3.8.782a2.802 2.802 0 012.378 2.81v3.707c0 .617-.118 1.108-.353 1.697-.186.463-.242.601-.259.672l-.006.042-.001.06h.619v4.325H19.153V25.02h.61c-.028-.84-.131-1.22-.432-1.626l-.304-.415a29.554 29.554 0 01-1.687-2.625l-1.301-2.602v-.146c0-1.237 1.983-2.007 2.957-1.06.257.248.519.513.775.779V10.81c0-1.024.83-1.853 1.853-1.853zm6.796 17.299h-8.032v1.853h8.032v-1.853zm-6.796-16.064a.618.618 0 00-.618.618v8.032H19.5l-.352-.38-.46-.48c-.185-.189-.37-.374-.552-.55-.098-.096-.301-.115-.539-.022a1.064 1.064 0 00-.27.155l1.103 2.206a28.304 28.304 0 001.894 2.886l.07.097c.44.63.575 1.196.606 2.266h6.803a1.991 1.991 0 01.152-.731l.185-.464.08-.204c.138-.377.201-.69.201-1.072v-3.718c.014-.784-.554-1.458-1.36-1.583l-4.818-.992v-5.446a.618.618 0 00-.618-.618zm-19.77-5.56v1.235h-.618v14.828h10.14l1.237 1.236h4.686v1.236h-5.198l-1.236-1.236H0v-17.3h1.853zM4.942 0l7.192 2.127c1.32.299 2.272 1.176 2.807 2.39.726-1.079 1.967-1.738 3.593-1.738h8.65v12.357h-1.236V4.015h-7.414c-1.978 0-3.09 1.223-3.09 3.089v12.357h.619v1.235h-2.966l-.077-.347a2.413 2.413 0 00-1.9-.888h-8.65V2.779h1.854v1.236h-.618v14.21h6.281l-5.045-1.49V0zm1.235 1.654V15.81l5.985 1.769c1.072.296 1.587.838 1.906 1.88h.141V7.105c0-1.972-.858-3.434-2.387-3.782L6.178 1.654zm23.478 2.979v11.12H28.42V5.869h-.618V4.633h1.854z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default BrandGuideLines;
