import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import defaultIcon from './../../../assets/icon_bell.png';

import moment from 'moment';
import {
  NotificationComponent,
  InnerWrapper,
  Details,
  Logo,
  Title,
  DesktopMessage,
  MobileMessage,
  Time,
} from './Notification.styles.js';
import NaviagtionLink from 'components/_common/navigation-link';
import { Auth } from 'components/context/AuthContext';
import ReactHtmlParser from 'react-html-parser';

const Notification = ({
  icon,
  title,
  body,
  dateCreated,
  isRead = false,
  onClick = () => {},
  userId,
  notificationId,
  cta,
  shortNotification = false,
  notification = {},
  setActiveNotification = () => {},
  activeNotification = {},
  history,
  labels,
  notificationReadStatus,
}) => {
  const { notifications_detail_cta_url } = labels || {};

  const logo = icon?.src?.length > 0 ? icon?.src : defaultIcon;

  const isEllipsisMessage = body.length >= 76 ? body.slice(76).concat('...') : body;

  const time = dateCreated && dateCreated.split(' ')[0];
  const dateParts = time && time.split('/');
  const formattedTime = moment(
    dateParts && `${dateParts[2]}/${dateParts[1]}/${dateParts[0]} ${dateCreated.split(' ')[1]}`
  ).fromNow();

  const [notificationIsRead, setNotificationIsRead] = useState(isRead);
  const isNotificationActive = activeNotification.notificationId === notification.notificationId;
  const [notificationTime, setNotificationTime] = useState(0);
  const [elapsedDays, setElapsedDays] = useState();
  useEffect(() => {
    const getToday = new Date();
    const formatToday = moment(getToday)
      .format('YYYY/MM/DD')
      .split('/');
    const arrayToday = formatToday.map(str => {
      return Number(str);
    });
    const formatArrayTime = dateParts.map(str => {
      return Number(str);
    });
    const timeCreated = formatArrayTime.reverse();
    var a = moment(arrayToday);
    var b = moment(timeCreated);
    setNotificationTime(a.diff(b, 'days'));
  }, [dateParts]);
  useEffect(() => {
    const dateToday = moment();
    const b = moment(time, 'DD/MM/YYYY');
    const res = dateToday.diff(b, 'days');
    setElapsedDays(res);
  }, [time]);
  return (
    <NotificationComponent
      read={notificationReadStatus}
      activeNotification={isNotificationActive}
      onClick={() => {
        setNotificationIsRead(true);
        if (!isRead) {
          onClick(notificationId, userId);
        }
        setActiveNotification(notification);
        if (shortNotification) {
          history.push(`?notificationId=${notification.notificationId}`);
        }
      }}
    >
      <NaviagtionLink
        linkHref={
          !shortNotification
            ? `${notifications_detail_cta_url}?notificationId=${notification.notificationId}`
            : ''
        }
        target="_self"
        isExternal={false}
      >
        <InnerWrapper>
          <Details>
            <Logo src={logo} />
            <Title read={notificationIsRead}>{ReactHtmlParser(title)}</Title>
            <DesktopMessage read={notificationIsRead} shortNotification={shortNotification}>
              {isEllipsisMessage}
            </DesktopMessage>
            <Time>
              {elapsedDays > 1 && time}
              {elapsedDays === 1 && 'Yesterday'}
              {elapsedDays === 0 && 'Today'}
            </Time>
          </Details>
          <MobileMessage read={notificationIsRead} shortNotification={shortNotification}>
            {isEllipsisMessage}
          </MobileMessage>
        </InnerWrapper>
      </NaviagtionLink>
    </NotificationComponent>
  );
};

Notification.propTypes = {};

export default withRouter(Notification);
