import styled from 'styled-components';
import { lighten } from 'polished';
import { Icon } from 'components/_icons';
import NavigationLink from 'components/_common/navigation-link';

export const Crumb = styled(NavigationLink)`
  ${({
    theme: {
      colours: { darkGrey, lightGrey },
      fonts: { NotoSansRegular, NotoSansMedium },
      breakpoints: { mobileMax },
    },
    lastBreadcrumb,
    firstBreadcrumb,
  }) => `
    display: ${firstBreadcrumb ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    margin: 1.5rem 0.5rem 1.5rem 3.1rem;
    color: ${darkGrey};
    font-family: ${NotoSansMedium.value};
    font-weight: ${NotoSansMedium.fontWeight};
    font-style: ${NotoSansMedium.fontStyle};
    font-size: 1.4rem;
    line-height: 1.9rem;
    letter-spacing: 0.02px;
    text-transform: uppercase;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: ${lighten(0.2, darkGrey)};
    }

    ${
      lastBreadcrumb
        ? `
        color: ${lightGrey};
        cursor: auto;
        font-family: ${NotoSansRegular.value};
        font-weight: ${NotoSansRegular.fontWeight};
        font-style: ${NotoSansRegular.fontStyle};
    `
        : ''
    }

    @media (min-width: ${mobileMax}px) {
      display: flex;
      margin: 1.5rem 4.1rem 1.5rem 0.5rem;
    }
  `}
`;

export const ChevronRight = styled(Icon)`
  ${({
    theme: {
      colours: { darkGrey },
      breakpoints: { mobileMax },
    },
    lastBreadcrumb,
    firstBreadcrumb,
  }) => `
    width: 1.4rem;
    height: 1.4rem;
    fill: ${darkGrey};
    position: absolute;
    right: -2.9rem;
    top: 0.3rem;
    display: none;
    @media (min-width: ${mobileMax}px) {
      display: flex;
      ${lastBreadcrumb ? 'display: none;' : ''}
    }
  `}
`;

export const ChevronLeft = styled(Icon)`
  ${({
    theme: {
      colours: { darkGrey },
      breakpoints: { mobileMax },
    },
    lastBreadcrumb,
  }) => `
    width: 1.4rem;
    height: 1.4rem;
    fill: ${darkGrey};
    position: absolute;
    left: -2.9rem;
    top: 0.3rem;
    transform: rotate(-180deg);

    @media (min-width: ${mobileMax}px) {
      display: none;
    }
  `}
`;
