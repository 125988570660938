import React from 'react';
import PropTypes from 'prop-types';
import { ExternalLink, InternalLink } from 'components/_common/navigation-link/NavigationLink.styles';

const Link = ({ linkText, linkHref, className, children, target = '_self', isExternal = false }) => {
  if (!isExternal) {
    return (
      <InternalLink to={linkHref || '#'} target={target} className={className} isExternal={isExternal}>
        {children || linkText}
      </InternalLink>
    );
  } else {
    return (
      <ExternalLink href={linkHref} target={target} className={className} isExternal={isExternal}>
        {children || linkText}
      </ExternalLink>
    );
  }
};

Link.propTypes = {
  as: PropTypes.string,
  linkText: PropTypes.string,
  linkHref: PropTypes.string,
  target: PropTypes.string,
  download: PropTypes.bool,
  iconName: PropTypes.string,
  dark: PropTypes.bool,
  className: PropTypes.string,
  isExternal: PropTypes.bool,
};

export default Link;
