import React from 'react';

const BusinessSupport = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M3.093 16.744v1.396h2.323c.328 0 .611.227.682.547a9.014 9.014 0 001.223 2.94.698.698 0 01-.093.871L5.58 24.147l1.971 1.971L9.2 24.469a.698.698 0 01.87-.093c.9.58 1.896.994 2.94 1.224.32.07.548.354.548.681v2.324h2.79V26.28c0-.327.229-.61.548-.68a9.014 9.014 0 002.94-1.225.698.698 0 01.871.093l1.649 1.649 1.971-1.971-1.648-1.649a.698.698 0 01-.094-.871c.58-.9.994-1.895 1.224-2.94a.698.698 0 01.682-.547h2.323v-1.396h1.395v2.093a.698.698 0 01-.697.698h-2.476a10.41 10.41 0 01-.984 2.363l1.755 1.755a.698.698 0 010 .987l-2.958 2.958a.698.698 0 01-.987 0l-1.755-1.755a10.41 10.41 0 01-2.363.983v2.476a.698.698 0 01-.697.698H12.86a.698.698 0 01-.697-.698v-2.476a10.41 10.41 0 01-2.363-.983l-1.756 1.755a.698.698 0 01-.986 0L4.1 24.64a.698.698 0 010-.987l1.755-1.755a10.41 10.41 0 01-.984-2.363H2.395a.698.698 0 01-.697-.698v-2.093h1.395zm6.977 0a4.884 4.884 0 009.767 0h1.396a6.28 6.28 0 11-12.559 0zM2.395 11.86v2.093h25.117V11.86h1.395v2.791a.698.698 0 01-.698.698H1.698A.698.698 0 011 14.65v-2.79h1.395zM9.033 1.483a.698.698 0 011.037.61v4.186H8.674V3.34l-.219.154c-2.598 1.85-3.967 4.374-3.967 7.67v1.395H3.093v-1.395c0-4.326 2.106-7.547 5.94-9.68zm10.804.61c0-.53.567-.866 1.032-.612 3.86 2.108 5.945 5.313 5.945 9.682v1.395h-1.395v-1.395c0-3.43-1.43-5.991-4.186-7.834v2.95h-1.396zM17.744 0c.386 0 .698.312.698.698v7.674h-1.395V1.395H12.86v6.977h-1.395V.698c0-.386.312-.698.698-.698z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default BusinessSupport;
