import React from 'react';

const Facebook = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 30 30"
      className={className}
    >
      <g transform="translate(7)" fillRule="evenodd">
        <path
          d="M4.76 30V16.582H.266V11.25H4.76V7.049C4.76 2.484 7.549 0 11.62 0c1.951 0 3.627.146 4.113.21v4.77H12.91c-2.215 0-2.642 1.055-2.642 2.596v3.674h4.998l-.686 5.332h-4.312V30"
          id="a"
        />
      </g>
    </svg>
  );
};

export default Facebook;
