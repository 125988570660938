import React from 'react';

const OnlineTraining = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M9.72 8a.64.64 0 01.49.226l2.987 3.538h4.152c.316 0 .584.23.629.538l.012.11c.036.453-.018.971-.24 1.48a2.502 2.502 0 01-.92 1.11L29 15v1H10.333v1.29H13a4 4 0 013.78 2.657l3.22 9.64-1.266.413-3.217-9.63A2.665 2.665 0 0013 18.607H9.667A.662.662 0 019 17.948V14h.181l-1.18-1.166.898-.887 2.356 2.326h3.551c1.02 0 1.538-.325 1.776-.875.054-.123.091-.252.114-.38H12.9a.64.64 0 01-.488-.226L9.423 9.255H7.178c-1.053 0-1.907.842-1.907 1.881v6.9c0 1.732 1.423 3.136 3.179 3.136h4.45c.232 0 .446.126.557.327l3.814 6.9-1.116.601-3.633-6.573H8.45c-2.458 0-4.45-1.966-4.45-4.39v-6.9C4 9.403 5.423 8 7.178 8zm-7.47 2v9.375a4.375 4.375 0 004.375 4.375H11V25H8v2.814l.61.686H11V30H8.333a.631.631 0 01-.471-.22L7 28.81l-.862.97a.631.631 0 01-.471.22H3v-1.5h2.39l1.139-1.28A.63.63 0 017 27L7 25h-.375A5.625 5.625 0 011 19.375V10h1.25zm27.1-5c.46 0 .773.46.603.882l-3.113 7.714a.649.649 0 01-.602.404h-7.264v-1.286H25.8l2.594-6.428H18.116l-1.912 4.738L15 10.547l2.075-5.143A.649.649 0 0117.677 5zM7.67 0a3.346 3.346 0 012.27.99A3.675 3.675 0 0111 3.525v.96A3.667 3.667 0 019.94 7.01 3.34 3.34 0 017.667 8h-.332a3.346 3.346 0 01-2.275-.99A3.675 3.675 0 014 4.473v-.96c.019-.956.4-1.864 1.06-2.524A3.346 3.346 0 017.33 0h.34zm-.01 1.334h-.32c-.522.02-1.02.24-1.401.62a2.297 2.297 0 00-.666 1.572v.934c.011.6.251 1.17.666 1.585.386.386.896.607 1.412.621h.31a2.103 2.103 0 001.4-.621c.415-.414.655-.985.666-1.572V3.54a2.304 2.304 0 00-.666-1.586c-.38-.38-.879-.6-1.4-.62z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default OnlineTraining;
