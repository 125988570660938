/* 
    Footer Mapper:

    This is to map the so called mini backend components like logo and links 
    into one component that the frontend can consume in One component 
    Footer.

    TODO: Joe to review and confirm we stick with this or ask backend 
    to make into one entity 

    (Only applicable to Header and Footer regions for now)
*/

const FooterMapper = region => {
  const { components = [] } = region;

  const templateLabelsConsumed = {
    'Footer Copyright Template': 'copyrightText',
    'Footer Nav Template': 'links',
    'Social Media Icons Template': 'socialIcons',
    'Footer Logo Template': 'logoLink',
  };

  let mappedData = {};

  components.forEach(component =>
    templateLabelsConsumed[component.templateLabel]
      ? (mappedData[templateLabelsConsumed[component.templateLabel]] = component)
      : null
  );

  return mappedData;
};

export default FooterMapper;
