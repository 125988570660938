import styled from 'styled-components';

export const MainImageComponent = styled.section`
  max-width: 76.9rem;
  margin: 0 auto;
  padding: 0 3rem;

  img {
    width: 100%;
  }
`;
