import React from 'react';
import PropTypes from 'prop-types';
import { NotFoundComponent, WarningIcon, Text, BackToDashboardLink } from './NotFound.styles.js';
import ReactHtmlParser from 'react-html-parser';

const NotFound = ({ body, cta: { url, target, title } }) => {
  return (
    <NotFoundComponent>
      <WarningIcon name="warning" />
      <Text>
        {body.split('\n').map((item, i) => (
           <p key={i}>{ReactHtmlParser(item)}</p>
        ))}
      </Text>
      <BackToDashboardLink linkHref={url} target={target} dark={true} iconName={'chevron'}>
        {ReactHtmlParser(title)}
      </BackToDashboardLink>
    </NotFoundComponent>
  );
};

NotFound.propTypes = {
  body: PropTypes.string,
  cta: PropTypes.shape({
    url: PropTypes.string,
    target: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default NotFound;
