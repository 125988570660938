import React, { useEffect, useContext } from 'react';
import { OrderingToolComponent } from './OrderingTool.styles.js';
import { Auth } from 'components/context/AuthContext';
import getAuthVariables from 'helpers/authVariables.js';

const OrderingTool = props => {
  const { getAccessToken } = useContext(Auth);
  const envVariables = getAuthVariables();

  useEffect(() => {
    const fetchToken = async () => {
      return await getAccessToken([envVariables.scope]);
    };
    const setupPage = async () => {
      const token = await fetchToken();

      window.baseUrl = 'https://www.kvgportal.com/kverneland-ordering-tool';
      loadScript('https://www.kvgportal.com/kverneland-ordering-tool/orderingtool.bundle.js', token);
    };
    setupPage();
  }, [envVariables.scope, getAccessToken]);

  const loadScript = (url, token) => {
    let script = document.createElement('script');
    script.src = url;

    if (script.readyState) {
      // only required for IE <9
      script.onreadystatechange = function() {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          window.cpqProvideToken(token);
        }
      };
    } else {
      //Others
      script.onload = function() {
        window.cpqProvideToken(token);
      };
    }
    document.head.appendChild(script); //or something of the likes
  };

  return (
    <OrderingToolComponent id="root-orderingtool">OrderingTool PlaceHolder Content</OrderingToolComponent>
  );
};

OrderingTool.propTypes = {};

export default OrderingTool;
