import React from 'react';

const PhysicalTraining = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M26.242 0v27.803h-11.12v-1.236h9.884v-4.325H23.77v3.09l-9.267-.001v4.196l-2.471-1.524-2.471 1.524-.001-4.196H6.471v-1.235l3.089-.001V22.86h4.943v1.235h8.032v-1.853H7.439a2.175 2.175 0 00-1.411.496l-.143.13c-.408.4-.641.946-.65 1.518a2.212 2.212 0 002.213 2.181h1.495v1.236H7.448A3.448 3.448 0 014 24.41l1.236.001-.001-.002H4l.002-.055.005-.155a3.41 3.41 0 01.008-.105H4V3.708A3.707 3.707 0 017.707 0h18.535zM13.268 24.096h-2.472v3.218l1.236-.762 1.236.762v-3.218zm11.738-22.86H7.707a2.471 2.471 0 00-2.471 2.471l-.001 18.084a3.403 3.403 0 012.213-.785h17.558V1.236zM8.943 2.47v17.3H7.707V2.47h1.236zm8.65 2.472c.34 0 .617.276.617.618v.962c.43.178.833.416 1.196.706l.854-.483a.618.618 0 01.84.23l1.235 2.137a.618.618 0 01-.23.846l-.853.486a4.88 4.88 0 01-.003 1.365l.85.486c.298.17.401.549.23.846l-1.236 2.137a.618.618 0 01-.843.227l-.857-.493c-.36.281-.758.51-1.183.68v.989a.618.618 0 01-.618.617h-2.47a.618.618 0 01-.619-.617v-.994a4.943 4.943 0 01-1.178-.67l-.86.499a.618.618 0 01-.844-.225l-1.236-2.138a.618.618 0 01.223-.842l.859-.503a5.125 5.125 0 01-.053-.692c.001-.226.019-.453.051-.677l-.855-.496a.618.618 0 01-.225-.844l1.236-2.137a.618.618 0 01.842-.227l.854.491c.3-.238.628-.44.975-.602l.211-.092v-.972c0-.342.277-.618.618-.618h2.471zm-.618 1.235h-1.236v.791a.618.618 0 01-.44.592 3.633 3.633 0 00-1.479.866.618.618 0 01-.737.091l-.701-.403-.619 1.07.702.407a.618.618 0 01.29.682 3.642 3.642 0 00-.105.842c.002.29.038.579.106.861a.618.618 0 01-.289.678l-.705.413.619 1.07.7-.407a.618.618 0 01.73.082c.423.392.93.68 1.483.842a.618.618 0 01.445.593v.816h1.236v-.81c0-.274.18-.515.444-.593a3.633 3.633 0 001.478-.85.618.618 0 01.733-.087l.702.403.616-1.066-.696-.396a.618.618 0 01-.294-.685 3.64 3.64 0 00.005-1.71.618.618 0 01.295-.68l.696-.397-.618-1.07-.704.397a.618.618 0 01-.733-.093 3.707 3.707 0 00-1.488-.88.618.618 0 01-.436-.59v-.779zm-.587 2.472a2.471 2.471 0 110 4.942 2.471 2.471 0 010-4.942zm0 1.235a1.236 1.236 0 100 2.472 1.236 1.236 0 000-2.472z"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default PhysicalTraining;
