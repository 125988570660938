import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { BackComponent, BackButton, Chevron } from './Back.styles.js';
import ReactHtmlParser from 'react-html-parser';

const Back = ({ label, icon }) => {
  let history = useHistory();
  const goToPreviousPath = () => {
    history.goBack();
  };
  return (
    <BackComponent>
      <BackButton onClick={goToPreviousPath}>
        <Chevron name={icon} />
        {ReactHtmlParser(label)}
      </BackButton>
    </BackComponent>
  );
};
Back.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default Back;
