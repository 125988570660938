import React from 'react';

const BespokeTruck = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M19.995 5v4.999h4.334a1.876 1.876 0 011.487.731l.1.136 1.946 3.509h.253a1.874 1.874 0 011.878 2.036v4.214c0 1.036-.84 1.875-1.874 1.875l-.688.001a3.126 3.126 0 01-6.123 0L19.37 22.5l-.001-.001-9.435.001a3.126 3.126 0 01-6.123 0H0V5h19.995zm4.374 15a1.875 1.875 0 100 3.75 1.875 1.875 0 000-3.75zM6.873 20a1.875 1.875 0 100 3.75 1.875 1.875 0 000-3.75zm19.518-4.375l-1.25.625h-4.52V15h4.226l1.25-.625h.336l-1.58-2.853a.625.625 0 00-.418-.264l-.103-.008-4.337-.001v10h1.313a3.126 3.126 0 016.123 0l.688.001c.345 0 .624-.28.624-.625v-4.244l.007-.141a.625.625 0 00-.631-.615H26.39zM18.746 6.25H1.25v15l2.562-.001a3.126 3.126 0 016.123 0l8.81.001v-15z"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default BespokeTruck;
