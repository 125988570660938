import React from 'react';

const User = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <g>
        <path
          d="M10 4.917a1.75 1.75 0 110 3.5 1.75 1.75 0 010-3.5zm0 7.5c2.475 0 5.083 1.216 5.083 1.75v.916H4.917v-.916c0-.534 2.608-1.75 5.083-1.75zm0-9.084A3.332 3.332 0 1010 10a3.332 3.332 0 100-6.667zm0 7.5c-2.225 0-6.667 1.117-6.667 3.334v2.5h13.334v-2.5c0-2.217-4.442-3.334-6.667-3.334z"
          id="b"
        />
      </g>
    </svg>
  );
};

export default User;
