import React from 'react';

const FavoritesRemove = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      
      version="1"
      viewBox="0 0 50 50"
    >
      <path
        d="M142 357C75 290 20 230 20 225c0-14 192-205 206-205 6 0 21 11 33 24l22 23 28-28c49-49 110-50 156-4 47 46 45 109-5 155l-29 27 24 23c23 22 25 30 25 120 0 61-4 100-12 108s-47 12-108 12h-95L142 357zm273 33c0-18-6-26-23-28-13-2-25 3-28 12-10 26 4 48 28 44 17-2 23-10 23-28zm36-219c20-20 29-39 29-61 0-43-47-90-90-90s-90 47-90 90 47 90 90 90c22 0 41-9 61-29z"
        transform="matrix(.1 0 0 -.1 0 50)"
      ></path>
      <path
        d="M350 142c0-4 5-13 12-20 8-8 8-15-2-27-17-20-5-32 15-15 12 10 18 10 30 0 20-17 32-5 15 15-10 12-10 18 0 30 17 20 5 32-15 15-12-10-19-10-27-2-14 14-28 16-28 4z"
        transform="matrix(.1 0 0 -.1 0 50)"
      ></path>
    </svg>
  );
};

export default FavoritesRemove;