import React from 'react';

const FindFinancialSolution = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M22.719 0c.389 0 .704.307.704.687l.001 15.062a6.871 6.871 0 012.773 5.53c0 1.878-.748 3.58-1.959 4.818l2.794 2.821-.873.882-2.868-2.895a6.715 6.715 0 01-3.886 1.233c-2.781 0-5.172-1.688-6.222-4.105H3.704A.696.696 0 013 23.345V.686C3 .308 3.315 0 3.704 0H22.72zm-3.314 15.666c-1.435 0-2.743.55-3.729 1.451v.165a6.019 6.019 0 00-1.41 2.056v-.2c-.102.249-.186.508-.252.774h.05c-.093.311-.161.633-.203.962a6.073 6.073 0 00-.013.404c0 3.1 2.488 5.612 5.557 5.612 3.069 0 5.557-2.512 5.557-5.612 0-3.099-2.488-5.612-5.557-5.612zm2.61-.722V1.374H4.408v21.285h8.342a6.955 6.955 0 01-.137-1.373H6.52a.696.696 0 01-.704-.687V3.433c0-.379.315-.686.704-.686h13.38c.39 0 .705.307.705.686v11.093c.49.089.961.23 1.409.418zm-8.045 2.22l-2.52.001v2.747h1.298a6.86 6.86 0 011.222-2.747zm-3.928.001H7.225v2.747h2.817v-2.747zm4.225-4.119H11.45v2.746h2.817v-2.746zm-4.225 0H7.225v2.746h2.817v-2.746zm9.155 0h-3.521v2.499a6.71 6.71 0 013.521-1.123v-1.376zm-9.155-4.12H7.225v2.746h2.817V8.926zm4.225 0H11.45v2.746h2.817V8.926zm4.93 0h-3.521v2.746h3.521V8.926zm0-4.806H7.226v3.433h11.973V4.12z"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default FindFinancialSolution;
