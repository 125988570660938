import styled, { css } from 'styled-components';
import { Icon } from 'components/_icons';

export const NotificationWrapper = styled.div`
  ${({
    read,
    theme: {
      colours: { darkerOffWhite, white, unReadNotificationBackground },
      breakpoints: { mobileMax },
    },
  }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.1rem 1.5rem;
    background-color: ${read ? unReadNotificationBackground : white};
    max-height: 87px;
    @media (min-width: ${mobileMax}px) {
      padding: 2.4rem 2.7rem 3rem 3rem;
      border-bottom: 1px solid #757475;
      &:hover {
        border-radius: 5px;
        background-color: #707071;

        > * {
          p,
          div {
            color: #fff;
          }
        }
      }
    }
    }
  `}
`;

export const Logo = styled.img`
  ${({
    theme: {
      breakpoints: { mobileMax },
    },
  }) => css`
    display: none;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    /* border: 1px solid #000; */
    /* @media (min-width: ${mobileMax}px) {
      display: block;
    } */
  `}
`;

export const Content = styled.div`
  ${({
    theme: {
      breakpoints: { mobileMax },
    },
  }) => css`
    width: 100%;
    display: flex;
    flex-direction: row-reverse;

    @media (min-width: ${mobileMax}px) {
      display: block;
    }
  `}
`;

export const TitleRow = styled.div`
  ${({
    theme: {
      fonts: { NotoSansRegular },
      colours: { black, darkGrey },
      breakpoints: { mobileMax },
    },
    read,
  }) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    ${!read
      ? css`
          &::before {
            content: '';
            display: inline-block;
            width: 10px;
            height: 10px;
            -moz-border-radius: 7.5px;
            -webkit-border-radius: 7.5px;
            border-radius: 7.5px;
            background-color: #d4021d;
            position: absolute;
            top: 8px;
            left: -20px;
          }
        `
      : ''}
  `}
`;

export const Title = styled.div`
  ${({
    theme: {
      fonts: { NotoSansRegular, NotoSansSemiBold },
      colours: { black, lightGrey },
      breakpoints: { mobileMax, tablet },
    },
    read,
  }) => css`
    font-family: ${NotoSansSemiBold.value};
    font-weight: ${NotoSansSemiBold.fontWeight};
    font-style: ${NotoSansSemiBold.fontStyle};
    color: ${black};
    margin-right: 1.3rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 0.8rem;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70%;
    
    ${
      read
        ? css`
            font-size: 1.4rem;
            font-family: ${NotoSansRegular.value};
            font-weight: ${NotoSansRegular.fontWeight};
            font-style: ${NotoSansRegular.fontStyle};
            color: ${lightGrey};
          `
        : ''
    }

    @media (min-width: ${mobileMax}px) {
      display: block;
      ${
        read
          ? `
        font-size: 1.5rem;
      `
          : ''
      }
    }
    @media (max-width: ${tablet}px) {
      display: none;
    }
  `}
`;

export const Time = styled.div`
  ${({
    read,
    theme: {
      fonts: { NotoSansRegular },
      colours: { lightGrey },
    },
  }) => css`
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-left: 1.4rem;
    color: ${lightGrey};
    font-family: ${NotoSansRegular.value};
    font-weight: ${read ? NotoSansRegular.fontWeight : 'normal'};
    font-style: ${NotoSansRegular.fontStyle};
    font-size: 1rem;
    line-height: 2.4rem;
    text-align: right;
    min-width: 9.7rem;
    justify-content: flex-end;
    margin-bottom: 0.8rem;
    width: 30%;

    display: inline-block;
  `}
`;

export const Message = styled.div`
  ${({
    read,
    theme: {
      fonts: { NotoSansRegular },
      colours: { lightGrey },
      breakpoints: { mobileMax },
    },
  }) => css`
    color: ${lightGrey};
    font-family: ${NotoSansRegular.value};
    font-weight: ${read ? 'normal' : NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    font-size: 1rem;
    margin-right: auto;
    margin-left: 0;

    @media (min-width: ${mobileMax}px) {
      margin-left: auto;
      margin-right: 0;
    }
  `}
`;

export const MobileChevronRight = styled(Icon)`
  ${({
    theme: {
      breakpoints: { mobileMax },
    },
  }) => css`
    width: 1.2rem;
    height: 1.2rem;
    line-height: 2.4rem;
    margin: 1.5rem;
    display: block;

    @media (min-width: ${mobileMax}px) {
      display: none;
    }
  `}
`;
