import React from 'react';

const ITService = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M26.259 4c1.036 0 1.875.84 1.875 1.876v15.629h1.876v1.876c0 1.727-1.4 3.127-3.126 3.127H3.126A3.126 3.126 0 010 23.38v-1.875l1.875-.001V5.875c0-.986.762-1.795 1.73-1.87L3.75 4zm-14.57 18.756H1.25v.625c0 1.036.84 1.876 1.876 1.876h23.758c1.036 0 1.876-.84 1.876-1.876v-.625H18.39l-1.25 1.25h-3.575l-1.875-1.25zM26.26 5.25H3.75a.625.625 0 00-.625.626v15.629h8.942l1.876 1.251h2.678l1.25-1.25 9.011-.001.001-15.63a.625.625 0 00-.533-.618l-.092-.007zm-8.128 8.128v.625c0 1.91-1.052 3.126-3.126 3.126h-3.126v3.126h-1.25V15.88h4.376c1.095 0 1.653-.414 1.82-1.25H7.503c-1.036 0-1.876.84-1.876 1.875v3.751h-1.25v-3.75c0-1.727 1.399-3.127 3.126-3.127H18.13zm6.877-5.627v11.88H13.13v-1.876h1.25v.625h9.379V9.002H14.38v3.75h-1.25v-5h11.878zM8.163 6.501c.703.04 1.36.362 1.824.892.463.53.694 1.224.642 1.878l-.002.664a2.62 2.62 0 01-2.464 2.817h-.07a2.62 2.62 0 01-1.824-.89 2.584 2.584 0 01-.642-1.879l.002-.664a2.62 2.62 0 012.463-2.817h.071zm-.035 1.252l-.117.014a1.37 1.37 0 00-.801.449c-.242.277-.363.64-.333 1.055l-.002.76a1.37 1.37 0 001.253 1.47 1.37 1.37 0 00.917-.463c.243-.277.363-.64.333-1.055l.002-.76a1.37 1.37 0 00-1.135-1.456l-.117-.014z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default ITService;
