import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { TileComponent, TileComponentClick, IconWrap, Icon, Text, TextLink, ActionIcon, Count, Icon2, HeaderDiv, Holder, FavButton, FavButtonIcon } from './Tile.styles.js';
import ReactHtmlParser from 'react-html-parser';

import axios from 'axios';
import { getSiteEndpoint } from 'helpers/api';
import { Auth } from 'components/context/AuthContext';
import LoadingSpinner from 'components/_common/loading-spinner';
var fileDownload = require('js-file-download');


const Tile = ({ icon: { src }, label, favoriteId, IsAuthorized, permissionsType, unAuthorizedClick, navigation, count, className, brand, langFolder, pageID }) => {
    const [loading, setLoading] = useState(false);
    const { userToken } = useContext(Auth);

    const [_favoriteId, SetfavoriteId] = useState(favoriteId);
    const self = {
        IsFavoritePage: window.location.pathname.startsWith('/my-favorite'),
        PageId: pageID,
        TileId: navigation.url ?? label,
        LangFolder: langFolder,
        TileData: JSON.stringify({
            label: label,
            icon: { src },
            navigation
        })
    }


    const RemoveFromFavourite = (event) => {
        event.preventDefault();
        if (_favoriteId) {
            if (self.IsFavoritePage) { setLoading(true); }

            if (self.TileId) {
                var config = {
                    method: 'Delete',
                    url: `${getSiteEndpoint()}/user/RemoveTile/${_favoriteId}`,
                    headers: {
                        'Authorization': `Bearer ${userToken}`,
                        'Content-Type': 'application/json',
                    }
                };
                axios(config)
                    .then(function (response) {
                        SetfavoriteId(null);
                        if (self.IsFavoritePage) {
                            window.location.reload();
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }
        else {
            alert("Tile Is Not In Favourite");
        }
    };

    const AddToFavourite = (event) => {
        event.preventDefault();
        if (!_favoriteId) {

            if (self.TileId) {
                var config = {
                    method: 'post',
                    url: `${getSiteEndpoint()}/user/AddTile`,
                    headers: {
                        'Authorization': `Bearer ${userToken}`,
                        'Content-Type': 'application/json',
                    },
                    data: JSON.stringify(self)
                };
                axios(config)
                    .then(function (response) {
                        if (response.status == 200 && response.data) { SetfavoriteId(response.data); }
                        console.log(JSON.stringify(response.data));
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }
        else {
            alert("Tile Is Already In Favourite");
        }
    };

    const downloadLocalAsset = (event, navigation) => {
        event.preventDefault();
        var file = navigation.url.split(new RegExp('local-assets(.*?)/')).pop();
        if(file)
        {
            axios
            .get(`${getSiteEndpoint()}/Files/key/${file}`, {
                headers: {
                Authorization: `Bearer ${userToken}`,
                },
                //responseType: 'blob',
            })
            .then(function(response) {
                window.open(`${getSiteEndpoint()}/Files/View/${response.data}`);
                //  //fileDownload(response.data, `${file}`);
                //  var contentType =  response.headers["content-type"];
            

                //  var blob = new Blob([response.data], { type: contentType });
                //  blob.title = file;
                //  blob.name = file;
                //  var url = window.URL.createObjectURL(blob, { oneTimeOnly: true, title: file });
            
                //  window.open(url, "_blank");
            })
            .catch(function(error) {
                console.log(error);
            });
        }
    };

    const linkProps = {};
    const actionIconProps = {
        name: 'chevron',
    };

    if(IsAuthorized == false) {
        linkProps.to = "#";
        linkProps.onClick = function (event) { unAuthorizedClick();  };

        if(permissionsType == "LicenceDisable"){
            actionIconProps.name = 'disablelock';
        }
        else{
            actionIconProps.name = '';
        }
    }
    else {
        IsAuthorized = true;
        if (navigation?.url.startsWith('http')) {
            linkProps.as = 'a';
            linkProps.href = navigation.url;
            linkProps.target = navigation.target;
            actionIconProps.name = 'launch';

            navigation.fullURL = navigation?.url;
        }
        else {
            if (navigation.url && navigation.url.indexOf('local-assets') != -1) {
                linkProps.onClick = function (event) { downloadLocalAsset(event, navigation) };
            }
            linkProps.to = navigation.url;
            actionIconProps.small = true;

            navigation.fullURL = window.location.origin + navigation?.url;
        }
    }
    
    return (
        <>
            <TileComponent className={ IsAuthorized? "" :"disabled" }>
                {IsAuthorized && <FavButton className={_favoriteId ? 'active' : ''} onClick={(event) => _favoriteId ? RemoveFromFavourite(event) : AddToFavourite(event)}>
                    {
                        _favoriteId ?
                            <FavButtonIcon name="FavoritesRemove" ></FavButtonIcon>
                            :
                            <FavButtonIcon name="FavoritesAdd" ></FavButtonIcon>
                    }
                </FavButton>}

                <TileComponentClick {...linkProps} className={className}>
                    <IconWrap className={label.length > 0 ? '' : 'max'}>
                        {label.length > 0 ? <Icon src={src}></Icon> : <Icon2 src={src}></Icon2>}
                        {count ? <Count>{ReactHtmlParser(count)}</Count> : null}
                    </IconWrap>
                    {label && <HeaderDiv><Text title={ReactHtmlParser(label)}>{ReactHtmlParser(label)}</Text></HeaderDiv>}
                    
                    {<ActionIcon {...actionIconProps}></ActionIcon>}
                </TileComponentClick>

                {self.IsFavoritePage && <TextLink href={navigation.url}>
                    <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 4.5H5.25V18.75H19.5V20.25H3.75V4.5ZM17.6515 8.25001L15.2196 5.81812L16.2803 4.75746L20.4851 8.96229L16.2803 13.1671L15.2196 12.1065L17.5761 9.75001L14.25 9.75C11.7647 9.75 9.75 11.7647 9.75 14.25V16.5H8.25V14.25C8.25 10.9363 10.9363 8.25 14.25 8.25L17.6515 8.25001Z" fill="#080341"/>
                    </svg>
                    <span className='tooltip'>
                        { ReactHtmlParser(navigation.url)}
                    </span>
                    <span className='tooltip-text'>{ ReactHtmlParser(navigation.fullURL)}</span>
                    
                </TextLink>}

            </TileComponent>


            {loading ? <LoadingSpinner /> : null}
        </>
    );
};

Tile.propTypes = {
    icon: PropTypes.shape({}).isRequired,
    label: PropTypes.string.isRequired,
    navigation: PropTypes.shape({}),
    count: PropTypes.number,
};

export default Tile;