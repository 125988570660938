import styled, { css } from 'styled-components';
import { Icon } from 'components/_icons';
import NavigationLink from 'components/_common/navigation-link';


export const WarningHeader = styled.div`
  position: relative;
  display: block;
  height: fit-content;
  background: #FFDCDF 0% 0% no-repeat padding-box;
  opacity: 1;
  
  svg.warning-header-icon{
    position: absolute;
    top: 17px;
    left: 21px;
  }

  a.warning-header-close{
    position: absolute;
    cursor: pointer;
    top: 24px;
    right: 21px;
  }

  span{
    position: relative;
    top: 11px;
    left: 67px;
    text-align: left;
    font: normal normal bold 16px/26px Helvetica Neue;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
  }

  div{
    position: relative;
    left: 67px;
    text-align: left;
    font: normal normal normal 14px/24px Helvetica Neue;
    letter-spacing: 0px;
    color: #333333;

    height: fit-content !important;
    display: flex;
    flex-direction: column;
  }
`;


export const GlobalNavBar = styled.nav`
  ${({
    theme: {
      breakpoints: { mobileLarge, tabletLarge, tablet, mobileMax },
    },
  }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.5rem 2.8rem 1.5rem 3rem;
    box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 99;

    
    @media (max-width: ${mobileMax}px){
      flex-flow: row wrap;
    }

    @media (min-width: ${tablet}px) {
      padding: 2.7rem 9.5rem 2.25rem 9rem;
    }
    @media (min-width: ${tabletLarge}px) {
      padding: 2.7rem 12.5rem 2.25rem 12rem;
    }
  `}
`;

export const LogoWrapper = styled.div`
  ${({
    theme: {
      breakpoints: { tabletLarge },
    },
  }) => `
    max-width: 13rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: ${tabletLarge}px) {
      margin-top: 1rem;
      margin-left: 0;
    }
  `}
`;

export const LinksWrapper = styled.div`
  ${({
    theme: {
      breakpoints: { tablet, tabletLarge, mobileLarge, mobileMax },
    },
  }) => `
    display: block;
    margin-right:10px;
    flex-direction: row;

    @media (max-width: ${mobileMax}px){
      display: flex;
      margin-right:0px;
    }

    @media (min-width: ${tablet}px) {
      display: flex;
    }
    @media (min-width: ${tabletLarge}px) {
      margin-left: auto;
    }
  `}
`;

export const Logo = styled.img`
  ${({
    brandName,
    theme: {
      breakpoints: { tabletLarge, mobileLarge },
    },
  }) => `
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0.5rem

      @media (min-width: ${tabletLarge}px) {
        margin-bottom: 1rem;
        margin-left: 0;
      }
  `}
`;


export const Link = styled(NavigationLink)`
  ${({
    theme: {
      fonts: { NotoSansBold },
      breakpoints: { tabletLarge },
    },
  }) =>
    `
      display: flex;
      align-items: center;
      color: rgb(106, 106, 106);
      font-family: ${NotoSansBold.value};
      font-weight: ${NotoSansBold.fontWeight};
      font-style: ${NotoSansBold.fontStyle};
      font-size: 1.4rem;
      font-weight: bold;
      // line-height: 2.4rem;
      // margin-right: 1.3rem;

      @media (min-width: ${tabletLarge}px) {
        font-size: 1.6rem;
      }
   `}
`;

export const LinkContainer = styled.div`
  ${({
    theme: {
      breakpoints: { tabletLarge },
    },
  }) => `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 1.3rem;

    &:last-child {
      margin-right: 0;
    }
    @media (min-width: ${tabletLarge}px) {
      margin-right: 4rem;
    }
  `}
`;

export const NavIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  fill: rgb(106, 106, 106);
`;

export const Hamburger = styled.div`
  ${({
    theme: {
      breakpoints: { tabletLarge },
    },
  }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 2rem;
    height: 2rem;

    @media (min-width: ${tabletLarge}px) {
      display: none;
    }
  `}
`;

export const Row = styled.div`
  margin-bottom: 3px;
  width: 100%;
  height: 3px;
  border-radius: 5px;
  background-color: rgb(106, 106, 106);
`;

export const Notifications = styled(Icon)`
  ${({
    theme: {
      breakpoints: { tablet },
    },
  }) => css`
    width: 2rem;
    height: 2rem;
    fill: rgb(106, 106, 106);
    display: none;
    @media (min-width: ${tablet}px) {
    }
  `}
`;
