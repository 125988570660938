import React from 'react';
import PropTypes from 'prop-types';
import AccordionComponent from 'components/accordion/AccordionComponent';
import { AccordionWrapper, Heading } from 'components/accordion/Accordion.styles.js';

const Accordion = ({ header, items }) => {
  return (
    <AccordionWrapper>
      <Heading>{header}</Heading>
      {items?.map((accordion, index) => {
        const { header: title, body } = accordion;
        return <AccordionComponent key={`${title}-${index}`} title={title} text={body} />;
      })}
    </AccordionWrapper>
  );
};

Accordion.propTypes = {
  accordions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};

export default Accordion;
