import styled from 'styled-components';

export const ContentBlockContainer = styled.section`
  ${({
    theme: {
      breakpoints: { tablet },
    },
    gatewayHeaderText,
  }) => `
    display: flex;
    flex-direction: column;
    padding: ${gatewayHeaderText ? '0 2.5rem 2.5rem;' : `1.5rem 1rem 1rem;`};

    ${gatewayHeaderText ? '' : 'max-width: 80rem;'}
    margin: 0 auto;
    @media (min-width: ${tablet}px) {
      padding: ${gatewayHeaderText ? '3.6rem 4.5rem 5rem 11rem' : `2.5rem 3rem 2rem 2.5rem`};
      margin: 0 auto;
    }
  `}
`;

export const Title = styled.h2`
  ${({
    theme: {
      colours: { darkGrey },
      breakpoints: { tablet },
      fonts: { NotoSansBold },
    },
  }) => `
    color: ${darkGrey};
    font-family: ${NotoSansBold.value};
    font-weight: ${NotoSansBold.fontWeight};
    font-style: ${NotoSansBold.fontStyle};
    font-size: 2rem;
    line-height: 2.8rem;
    text-align: center;
    margin: 0 0 1rem 0;
    @media (min-width: ${tablet}px) {
      font-size: 2.4rem;
      line-height: 3rem;
      text-align: left;
      margin: 0 0 1.5rem 0;

      [dir='rtl'] & {
        text-align: right;
      }
    }
  `}
`;

export const Description = styled.p`
  ${({
    theme: {
      colours: { lightGrey },
      breakpoints: { tablet },
      fonts: { NotoSansRegular },
    },
    gatewayHeaderText,
  }) => `
    color: ${lightGrey};
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle}
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: ${gatewayHeaderText ? 'left' : 'center'};
    margin: 0;
    padding: ${gatewayHeaderText ? '0' : '1rem'};

    @media (min-width: ${tablet}px) {
      font-size: 1.6rem;
      padding: ${gatewayHeaderText ? '0' : '2rem'};
    }
  `}
`;

export const GatewayHeader = styled.h1`
  ${({
    theme: {
      fonts: { NotoSansBold },
      breakpoints: { tablet },
    },
  }) => `
    font-famnily: ${NotoSansBold.value};
    font-weight: ${NotoSansBold.fontWeight};
    font-style: ${NotoSansBold.fontStyle};
    font-size: 2.4rem;
    line-height: 3.3rem;
    max-width: 56rem;
    padding: 0 2.5rem 0.5rem;

    @media (min-width: ${tablet}px) {
      padding: 1.6rem 4.5rem 2rem 11rem;
    }
  `}
`;

export const GatewayContentBlockContainer = styled.section`
  ${({
    theme: {
      fonts: { NotoSansRegular },
      breakpoints: { tablet },
    },
  }) => `
    font-famnily: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    font-size: 1.6rem;
    line-height: 2.4rem;
    max-width: 56rem;
    padding: 0 2.5rem 0.5rem;

    @media (min-width: ${tablet}px) {
      padding: 0rem 4.5rem 0rem 11rem;
    }
  `}
`;

export const GatewayDescription = styled.p`
  max-width: 36.6rem;
`;
