import React from 'react';

const Contact = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <g fillRule="evenodd">
        <path
          d="M13.498 18.159l-.411 5.341 3.512 2.2V30H5.26c-.053-1.592.12-2.787.518-3.587.399-.8 1.362-1.883 2.89-3.251L9 17l4.498 1.159z"
          fill="#333"
          opacity=".503"
        />
        <path
          d="M11.13 16.304c1.888 0 3.261 1.365 3.261 3.261v1.305c0 .92-.324 1.716-.873 2.29.55.141 1.09.326 1.58.546 1.574.709 2.554 1.729 2.554 3.033v2.609a.652.652 0 11-1.304 0v-2.609c0-.669-.629-1.322-1.786-1.844-1.053-.474-2.42-.765-3.432-.765-1.01 0-2.378.291-3.43.765-1.159.522-1.787 1.175-1.787 1.844v2.609a.652.652 0 01-1.304 0v-2.609c0-1.304.98-2.324 2.555-3.033.488-.22 1.03-.405 1.579-.547-.55-.573-.873-1.369-.873-2.29v-1.304c0-1.896 1.373-3.26 3.26-3.26zM24.826 0a2.609 2.609 0 012.609 2.609v14.348a2.609 2.609 0 01-2.609 2.608H23.19l-3.204 4.119a1.304 1.304 0 01-2.333-.802v-3.317h-1.304a.652.652 0 010-1.304h1.956c.36 0 .653.292.653.652v3.969l3.398-4.37a.652.652 0 01.515-.251h1.956c.72 0 1.304-.584 1.304-1.304V2.609c0-.72-.584-1.305-1.304-1.305H4.61c-.72 0-1.305.584-1.305 1.305v14.348c0 .72.584 1.304 1.305 1.304h1.304a.652.652 0 110 1.304H4.609A2.609 2.609 0 012 16.957V2.609A2.609 2.609 0 014.609 0zM11.13 17.609c-1.169 0-1.956.782-1.956 1.956v1.305c0 1.174.787 1.956 1.956 1.956 1.17 0 1.957-.782 1.957-1.956v-1.305c0-1.174-.787-1.956-1.957-1.956zM15 8.339v1.334H5V8.34h10zm5-2.67v1.335H5V5.669h15zM20 3v1.335H5V3h15z"
          fill="#000"
        />
      </g>
    </svg>
  );
};

export default Contact;
