import React from 'react';
import styled from 'styled-components';
import { Icon } from '.';

export default {
  component: Icon,
  title: 'Icons',
};

const Container = styled.div`
  font-size: 30px;

  svg {
    width: 100px;
    height: 100px;
    fill: black;

    g {
      fill: black;

      path {
        fill: black;
      }
    }
  }
`;

const BrandContainer = styled.div`
  font-size: 30px;

  svg {
    width: 100px;
    height: 100px;
  }
`;

export const orderOnline = () => (
  <Container>
    <Icon name="orderOnline" />
  </Container>
);
export const PriceTag = () => (
  <Container>
    <Icon name="priceTag" />
  </Container>
);

export const Brochures = () => (
  <Container>
    <Icon name="brochures" />
  </Container>
);

export const Charts = () => (
  <Container>
    <Icon name="charts" />
  </Container>
);

export const Contact = () => (
  <Container>
    <Icon name="contact" />
  </Container>
);

export const Machine = () => (
  <Container>
    <Icon name="machine" />
  </Container>
);

export const Training = () => (
  <Container>
    <Icon name="training" />
  </Container>
);

export const Truck = () => (
  <Container>
    <Icon name="truck" />
  </Container>
);

export const MyOrders = () => (
  <Container>
    <Icon name="myOrders" />
  </Container>
);

export const LinkedIn = () => (
  <Container>
    <Icon name="linkedin" />
  </Container>
);

export const Facebook = () => (
  <Container>
    <Icon name="facebook" />
  </Container>
);

export const Twitter = () => (
  <Container>
    <Icon name="twitter" />
  </Container>
);

export const Youtube = () => (
  <Container>
    <Icon name="youtube" />
  </Container>
);

export const BespokeCharts = () => (
  <Container>
    <Icon name="bespokeCharts" />
  </Container>
);

export const BrandGuideLines = () => (
  <Container>
    <Icon name="brandGuideLines" />
  </Container>
);

export const Brochure = () => (
  <Container>
    <Icon name="brochure" />
  </Container>
);

export const BusinessSupport = () => (
  <Container>
    <Icon name="businessSupport" />
  </Container>
);

export const Clock = () => (
  <Container>
    <Icon name="clock" />
  </Container>
);

export const BespokeContact = () => (
  <Container>
    <Icon name="bespokeContact" />
  </Container>
);

export const DeliveryStatus = () => (
  <Container>
    <Icon name="deliveryStatus" />
  </Container>
);

export const DigitalService = () => (
  <Container>
    <Icon name="digitalService" />
  </Container>
);

export const DistributionInfo = () => (
  <Container>
    <Icon name="distributionInfo" />
  </Container>
);

export const FarmCentre = () => (
  <Container>
    <Icon name="farmCentre" />
  </Container>
);

export const FindFinancialSolution = () => (
  <Container>
    <Icon name="findFinancialSolution" />
  </Container>
);

export const HandBook = () => (
  <Container>
    <Icon name="handBook" />
  </Container>
);

export const Info = () => (
  <Container>
    <Icon name="info" />
  </Container>
);

export const ITService = () => (
  <Container>
    <Icon name="iTService" />
  </Container>
);

export const LicensingShop = () => (
  <Container>
    <Icon name="licensingShop" />
  </Container>
);

export const Licensing = () => (
  <Container>
    <Icon name="licensing" />
  </Container>
);

export const LockShield = () => (
  <Container>
    <Icon name="lockShield" />
  </Container>
);

export const BespokeMachine = () => (
  <Container>
    <Icon name="bespokeMachine" />
  </Container>
);

export const Mail = () => (
  <Container>
    <Icon name="mail" />
  </Container>
);

export const Marketing = () => (
  <Container>
    <Icon name="marketing" />
  </Container>
);

export const MerchandiseStore = () => (
  <Container>
    <Icon name="merchandiseStore" />
  </Container>
);

export const BespokeMyOrders = () => (
  <Container>
    <Icon name="bespokeMyOrders" />
  </Container>
);

export const MyPartsOrders = () => (
  <Container>
    <Icon name="myPartsOrders" />
  </Container>
);

export const OnlineOrdering = () => (
  <Container>
    <Icon name="onlineOrdering" />
  </Container>
);

export const OnlineTraining = () => (
  <Container>
    <Icon name="onlineTraining" />
  </Container>
);

export const BespokeOrderOnline = () => (
  <Container>
    <Icon name="bespokeOrderOnline" />
  </Container>
);

export const PartsAvailability = () => (
  <Container>
    <Icon name="partsAvailability" />
  </Container>
);

export const PartsCatalogue = () => (
  <Container>
    <Icon name="partsCatalogue" />
  </Container>
);

export const PartsCalims = () => (
  <Container>
    <Icon name="partsCalims" />
  </Container>
);

export const PartsReplacementList = () => (
  <Container>
    <Icon name="partsReplacementList" />
  </Container>
);

export const PartsTrianing = () => (
  <Container>
    <Icon name="partsTrianing" />
  </Container>
);

export const Parts = () => (
  <Container>
    <Icon name="parts" />
  </Container>
);

export const Pen = () => (
  <Container>
    <Icon name="pen" />
  </Container>
);

export const PhysicalTraining = () => (
  <Container>
    <Icon name="physicalTraining" />
  </Container>
);

export const BespokePriceTag = () => (
  <Container>
    <Icon name="bespokePriceTag" />
  </Container>
);

export const ProductMarketing = () => (
  <Container>
    <Icon name="productMarketing" />
  </Container>
);

export const ProductService = () => (
  <Container>
    <Icon name="productService" />
  </Container>
);

export const ProductUpdates = () => (
  <Container>
    <Icon name="productUpdates" />
  </Container>
);

export const PromotionalListing = () => (
  <Container>
    <Icon name="promotionalListing" />
  </Container>
);

export const Repair = () => (
  <Container>
    <Icon name="repair" />
  </Container>
);

export const Returns = () => (
  <Container>
    <Icon name="returns" />
  </Container>
);

export const SafetyRegulations = () => (
  <Container>
    <Icon name="safetyRegulations" />
  </Container>
);

export const SalesAndOrders = () => (
  <Container>
    <Icon name="salesAndOrders" />
  </Container>
);

export const ServiceTraining = () => (
  <Container>
    <Icon name="serviceTraining" />
  </Container>
);

export const Service = () => (
  <Container>
    <Icon name="service" />
  </Container>
);

export const Speech = () => (
  <Container>
    <Icon name="speech" />
  </Container>
);

export const StockRecomendationList = () => (
  <Container>
    <Icon name="stockRecomendationList" />
  </Container>
);

export const SupportTicket = () => (
  <Container>
    <Icon name="supportTicket" />
  </Container>
);

export const TrainingManual = () => (
  <Container>
    <Icon name="trainingManual" />
  </Container>
);

export const BespokeTraining = () => (
  <Container>
    <Icon name="bespokeTraining" />
  </Container>
);

export const BespokeTruck = () => (
  <Container>
    <Icon name="bespokeTruck" />
  </Container>
);

export const BespokeUser = () => (
  <Container>
    <Icon name="bespokeUser" />
  </Container>
);

export const WarrantyPolicy = () => (
  <Container>
    <Icon name="warrantyPolicy" />
  </Container>
);

export const Vicon = () => (
  <BrandContainer>
    <Icon name="vicon" />
  </BrandContainer>
);

export const Kverneland = () => (
  <BrandContainer>
    <Icon name="kverneland" />
  </BrandContainer>
);
export const KVEGroup = () => (
  <BrandContainer>
    <Icon name="KVEGroup" />
  </BrandContainer>
);

export const Kubota = () => (
  <BrandContainer>
    <Icon name="kubota" />
  </BrandContainer>
);

export const User = () => (
  <Container>
    <Icon name="user" />
  </Container>
);

export const Notifications = () => (
  <Container>
    <Icon name="notifications" />
  </Container>
);

export const ExitToApp = () => (
  <Container>
    <Icon name="exitToApp" />
  </Container>
);

export const Chevron = () => (
  <Container>
    <Icon name="chevron" />
  </Container>
);

export const KVEGroupNoFill = () => (
  <Container>
    <Icon name="KVEGroupNoFill" />
  </Container>
);

export const Lock = () => (
  <Container>
    <Icon name="lock" />
  </Container>
);

export const Pin = () => (
  <Container>
    <Icon name="pin" />
  </Container>
);

export const Calendar = () => (
  <Container>
    <Icon name="calendar" />
  </Container>
);
export const Phone = () => (
  <Container>
    <Icon name="phone" />
  </Container>
);

export const Printer = () => (
  <Container>
    <Icon name="printer" />
  </Container>
);

export const Download = () => (
  <Container>
    <Icon name="download" />
  </Container>
);

export const Pdf = () => (
  <Container>
    <Icon name="pdf" />
  </Container>
);

export const Warning = () => (
  <Container>
    <Icon name="warning" />
  </Container>
);

export const Dashboard = () => (
  <Container>
    <Icon name="dashboard" />
  </Container>
);

export const Instagram = () => (
  <Container>
    <Icon name="instagram" />
  </Container>
);
