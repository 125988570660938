import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import {
  ContactCardWrapper,
  InnerWrapper,
  Image,
  Details,
  Person,
  Name,
  Job,
  DesktopContact,
  MobileContact,
  ContactRow,
  Email,
  EmailIcon,
  Phone,
  PhoneIcon,
  JobTitle,
} from './ContactCard.styles.js';

const ContactCard = props => {
  const {
    name,
    titleCompany,
    jobTitle,
    emailAddress,
    telephone,
    picture: { src, alt },
  } = props;
  return (
    <ContactCardWrapper>
      <InnerWrapper>
        <Image url={src} alt={alt} />
        <Details>
          <Person>
            <Name>{ReactHtmlParser(name)}</Name>
            {jobTitle && <JobTitle>{ReactHtmlParser(jobTitle)}</JobTitle>}
            {titleCompany && <Job>{ReactHtmlParser(titleCompany)}</Job>}
          </Person>
        </Details>
      </InnerWrapper>
      <MobileContact>
        {emailAddress && (
          <ContactRow>
            <EmailIcon name="mail" />
            <Email>
              <a href={"mailto:" + ReactHtmlParser(emailAddress)}>{ReactHtmlParser(emailAddress)}</a>
            </Email>
          </ContactRow>
        )}
        {telephone && (
          <ContactRow>
            <PhoneIcon name="phone" />
            <Phone>{ReactHtmlParser(telephone)}</Phone>
          </ContactRow>
        )}
      </MobileContact>
    </ContactCardWrapper>
  );
};

ContactCard.propTypes = {
  name: PropTypes.string,
  titleCompany: PropTypes.string,
  emailAddress: PropTypes.string,
  telephone: PropTypes.string,
  picture: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
};

export default ContactCard;
