import React from 'react';
import PropTypes from 'prop-types';
import { LinkComponent, InternalLinkComponent, LinkIcon, LinkIconSrc } from './Link.styles.js';
import { Icon } from '../tile/Tile.styles.js';
import ReactHtmlParser from 'react-html-parser';

const Link = ({
  as = 'a',
  linkText,
  linkHref,
  iconName = '',
  dark = false,
  className,
  children,
  onClick,
  target = '_self',
  download = false,
  isExternal = false,
  iconSrc = '',
}) => {
  if (!isExternal) {
    return (
      <InternalLinkComponent
        to={linkHref || '#'}
        target={target}
        dark={dark}
        download={download}
        className={className}
        onClick={onClick}
      >
        {iconName ? <LinkIcon name={iconName} dark={dark} /> : iconSrc ? <LinkIconSrc src={iconSrc} /> : null}
        {children || ReactHtmlParser(linkText)}
      </InternalLinkComponent>
    );
  } else {
    return (
      <LinkComponent
        as={as}
        href={linkHref}
        target={target}
        dark={dark}
        download={download}
        className={className}
        onClick={onClick}
      >
        {iconName ? <LinkIcon name={iconName} dark={dark} /> : iconSrc ? <LinkIconSrc src={iconSrc} /> : null}

        {children || ReactHtmlParser(linkText)}
      </LinkComponent>
    );
  }
};

Link.propTypes = {
  as: PropTypes.string,
  linkText: PropTypes.string,
  linkHref: PropTypes.string,
  target: PropTypes.string,
  download: PropTypes.bool,
  iconName: PropTypes.string,
  iconSrc: PropTypes.string,
  dark: PropTypes.bool,
  className: PropTypes.string,
};

export default Link;
