import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import IconPath from "../../assets/images/warn.png"

import {
    Card,
    ContainerCards,
    ContainerMultipleContact,
    DisableTileComponent,
    HeaderComponent,
    IconWrap,
    StyleIcons,
    Text,
    TextSpan,
    TextWrap,
    TileComponent,
} from './multiple-contact.styles';
import { getMultipleContact, getUserData, postMultipleContactId, CheckContactValid } from 'helpers/utilities';
import { Auth } from 'components/context/AuthContext';
import ReactHtmlParser from 'react-html-parser';
import Popup from '../popup/index';
import LoadingSpinner from 'components/_common/loading-spinner';

const MultipleContact = ({ header, align }) => {
    const dashboardLink = Cookies.get('dashboard');
    const { multipleContacData, setMultipleContacData, userToken } = useContext(Auth);
    const [showPopup, setShowPopup] = useState(false);
    const [showOK, setShowOk] = useState(true);
    const [popupTitle, setPopupTitle] = useState("");
    const [popupContent, setPopupContent] = useState("");
    const [firstPopup, setFirstPopup] = useState(true);
    const [closeBtnText, setCloseBtnText] = useState("No");
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [tempMultipleContacData, setTempMultipleContacData] = useState([]);
    const [userData, setUserData] = useState([]);

    const funMultipleContact = user => {
        if(user.langFolder) { Cookies.set("currentLang", user.langFolder.split('-').pop()); }
        Cookies.set('ActivUserName', user.linkedAccount.accountNumber);
        setCurrentUser(user);
        if (Cookies.get("IsFirstLogin") == "false") {
            setShowPopup(true);
            setPopupTitle("CLOSE ALL SESSIONS");
            setPopupContent("Have you closed all open sessions or applications in the partner portal?");
        } else {
            Cookies.set("IsFirstLogin",false)
            setLoading(true);
            postMultipleContactId(user.id, userToken, dashboardLink);
        }
    };
    useEffect(() => {
        getUserData(setUserData, setMultipleContacData, userToken);
    }, [setMultipleContacData, userToken]);


    // useEffect(() => {
    //     getMultipleContact(setMultipleContacData, userToken);
    // }, [setMultipleContacData, userToken]);

    useEffect(() => {
        Cookies.remove('currentLang');
        Cookies.remove('ActivUserName');
        if (document.querySelector("#NotificationsSideBar") != null) {
            document.querySelector("#NotificationsSideBar").style.display = 'none';
        }

        if (document.querySelector("#SideBarPane") != null) {
            document.querySelector("#SideBarPane").style.display = 'none';
        }

    }, []);

    useEffect(() => {
        setLoading(true);
        const tempMultipleContacDataHandler = async () => {
            let tempList = [];
            for (let i = 0; i < multipleContacData.length; i++) {

                multipleContacData[i].city = "";
                multipleContacData[i].postalCode = "";
                multipleContacData[i].disabled = true;
                try {
                    const contactResponse = await CheckContactValid(multipleContacData[i].id, userToken);
                    const contact = contactResponse?.data;
                    
                    multipleContacData[i].city = contact.city;
                    multipleContacData[i].postalCode = contact.postalCode;

                    multipleContacData[i].disabled = !contact?.isValid;
                }
                catch (err) {
                    console.log(err);
                }
                finally {
                    tempList.push(multipleContacData[i]);
                }
            }
            setTempMultipleContacData(tempList);
            setLoading(false);
        }
        tempMultipleContacDataHandler();
    }, [multipleContacData]);


    const handlePopupNo = () => {
        if (firstPopup == true) {
            setFirstPopup(false);
            setShowOk(false);
            setPopupContent("Please close all other sessions and try again");
            setCloseBtnText("Close");
        } else {
            setFirstPopup(true);
            setShowPopup(false);
            setShowOk(true);
            setPopupContent("Have you closed all open sessions or applications in the partner portal?");
            setCloseBtnText("NO");
        }
    }

    const handlePopupYes = () => {
        setShowPopup(false);
        // Cookies.remove("IsFirstLogin");
        setLoading(true);
        postMultipleContactId(currentUser.id, userToken, dashboardLink);
    }

    return (
        <>
            <ContainerMultipleContact>
                <HeaderComponent align={align}>{header}</HeaderComponent>
                <ContainerCards>
                    {tempMultipleContacData.map(item => {
                        return (
                            item.disabled ?
                            <DisableTileComponent key={item.id + 1}>
                                <Card>
                                    <IconWrap>
                                        <StyleIcons name="Building" />
                                    </IconWrap>
                                    <TextWrap>
                                        <Text>{ReactHtmlParser(item.linkedAccount.companyName)}</Text>
                                        <TextSpan>{ReactHtmlParser(item.linkedAccount.accountAddress1Country)}</TextSpan>
                                        <TextSpan>{ReactHtmlParser(item.linkedAccount.accountNumber)}</TextSpan>

                                        <TextSpan>{ReactHtmlParser(item.city)} - {ReactHtmlParser(item.postalCode)}</TextSpan>
                                    </TextWrap>
                                </Card>
                            </DisableTileComponent>
                            :
                            <TileComponent onClick={() => funMultipleContact(item)} key={item.id}>
                                <Card>
                                    <IconWrap>
                                        <StyleIcons name="Building" />
                                    </IconWrap>
                                    <TextWrap>
                                        <Text>{ReactHtmlParser(item.linkedAccount.companyName)}</Text>
                                        <TextSpan>{ReactHtmlParser(item.linkedAccount.accountAddress1Country)}</TextSpan>
                                        <TextSpan>{ReactHtmlParser(item.linkedAccount.accountNumber)}</TextSpan>

                                        <TextSpan>{ReactHtmlParser(item.city)} - {ReactHtmlParser(item.postalCode)}</TextSpan>
                                    </TextWrap>
                                </Card>
                            </TileComponent>

                        );
                    })}
                </ContainerCards>
            </ContainerMultipleContact>

            {/* Close All Sessions Popup Start */}
            <Popup
                handleYes={handlePopupYes}
                handleNo={handlePopupNo}
                show={showPopup}
                headerText={popupTitle}
                detailText={popupContent}
                showOkButton={showOK}
                closeBtnText={closeBtnText}
                iconUrl={IconPath}
            />
            {/* Close All Sessions Popup End  */}

            {/* Start Loader */}
            {loading ? <LoadingSpinner /> : null}
            {/* End Loader */}

        </>
    );
};

export default MultipleContact;
