import styled from 'styled-components';

export const TableWrapper = styled.section`
  ${({
    theme: {
      breakpoints: { tablet },
    },
  }) => `
    max-width: 81.9rem;
    margin: 0 2.5rem 3rem;

    @media (min-width: ${tablet}px) {
      margin: 0 auto 5rem;
      padding: 2.5rem
    }
  `}
`;
export const Header = styled.h2`
  ${({
    theme: {
      colours: { primary, black, white },
      fonts: { NotoSansBold },
      breakpoints: { tablet },
    },
  }) => `
    font-family: ${NotoSansBold.value};
    font-weight: ${NotoSansBold.fontWeight};
    font-style: ${NotoSansBold.fontStyle};
    background: ${primary};
    color: ${white};
    border-radius: 0;
    padding: 3rem 2rem;
    font-size: 1.8rem;
    margin: 0;

    @media (min-width:${tablet}px) {
      font-size: 2.4rem
    }
  `}
`;
export const TableContent = styled.div`
  ${({
    theme: {
      breakpoints: { tablet },
    },
    numberOfRows,
  }) => `
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
  `}
`;
export const Box = styled.div`
  ${({
    theme: {
      colours: { black, offWhiteTable, white, lightGrey },
      fonts: { NotoSansBold, NotoSansRegular },
      breakpoints: { tablet },
    },
    paleBackground,
    boldText,
    lightText,
  }) => `
    display: flex;
    flex-direction: column;
    background-color: ${paleBackground ? offWhiteTable : white};
    padding: 2rem;
    border-top: 1px solid #dfdfdf;
    border-left: 1px solid #dfdfdf;
    font-size: 1.2rem;
    line-height: 2.4rem;
    & > *{
      margin: 0;
    }

    ${
      boldText
        ? `
      font-family: ${NotoSansBold.value};
      font-weight: ${NotoSansBold.fontWeight};
      font-style: ${NotoSansBold.fontStyle};
      color: ${black};
    `
        : ''
    }

    ${
      lightText
        ? `
      font-family: ${NotoSansRegular.value};
      font-weight: ${NotoSansRegular.fontWeight};
      font-style: ${NotoSansRegular.fontStyle};
      color: ${lightGrey};
    `
        : ''
    }

    @media (min-width: ${tablet}px) {
      font-size: 1.6rem;
    }
  `}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FirstColumn = styled.div`
  ${({
    theme: {
      colours: { black, offWhiteTable, white, lightGrey },
      fonts: { NotoSansBold, NotoSansRegular },
      breakpoints: { tablet },
    },
    paleBackground,
    boldText,
    lightText,
  }) => `
    display: flex;
    flex-direction: column;
    background-color: ${paleBackground ? offWhiteTable : white};
    padding: 2rem;
    border-top: 1px solid #dfdfdf;
    border-left: 1px solid #dfdfdf;
    font-size: 1.2rem;
    line-height: 2.4rem;
    width: 50%;
    & > *{
      margin: 0;
    }

    ${
      boldText
        ? `
      font-family: ${NotoSansBold.value};
      font-weight: ${NotoSansBold.fontWeight};
      font-style: ${NotoSansBold.fontStyle};
      color: ${black};
    `
        : ''
    }

    ${
      lightText
        ? `
      font-family: ${NotoSansRegular.value};
      font-weight: ${NotoSansRegular.fontWeight};
      font-style: ${NotoSansRegular.fontStyle};
      color: ${lightGrey};
    `
        : ''
    }

    @media (min-width: ${tablet}px) {
      font-size: 1.6rem;
      width: 24%;
    }
  `}
`;
export const SecondColumn = styled.div`
  ${({
    theme: {
      colours: { black, offWhiteTable, white, lightGrey },
      fonts: { NotoSansBold, NotoSansRegular },
      breakpoints: { tablet },
    },
    paleBackground,
    boldText,
    lightText,
    singleRow,
  }) => `
    display: flex;
    flex-direction: column;
    background-color: ${paleBackground ? offWhiteTable : white};
    padding: 2rem;
    border-top: 1px solid #dfdfdf;
    border-left: 1px solid #dfdfdf;
    font-size: 1.2rem;
    line-height: 2.4rem;
    width: 50%;
    & .table-row {
      margin: 0 0 ${singleRow ? '0' : '1rem'};
    }

    ${
      boldText
        ? `
      font-family: ${NotoSansBold.value};
      font-weight: ${NotoSansBold.fontWeight};
      font-style: ${NotoSansBold.fontStyle};
      color: ${black};
    `
        : ''
    }

    ${
      lightText
        ? `
      font-family: ${NotoSansRegular.value};
      font-weight: ${NotoSansRegular.fontWeight};
      font-style: ${NotoSansRegular.fontStyle};
      color: ${lightGrey};
    `
        : ''
    }

    @media (min-width: ${tablet}px) {
      font-size: 1.6rem;
      width: 76%;

     & .table-row {
      margin: 0;
    }
    }
  `}
`;
