import React from 'react';
import FullWidth from 'components/page-layouts/full-width';
import Gateway from 'components/page-layouts/gateway';

const PageLayout = props => {
  const { pageLayout } = props;
  const layouts = {
    'Full Width': FullWidth,
    Gateway: Gateway,
  };

  const Layout = layouts[pageLayout] || FullWidth;

  return <Layout {...props} />;
};

export default PageLayout;
