import { getSiteEndpoint } from 'helpers/api';
import axios from 'axios';
import Cookies from 'js-cookie';

export const getViewportWidth = () => Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

export const getViewportHeight = () =>
    Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

export const bodyNoScroll = {
    scrollTop: 0,
    add(breakpoint) {
        this.breakpoint = breakpoint;

        if (!breakpoint || getViewportWidth() < breakpoint) {
            this.scrollTop = window.scrollY;
            document.documentElement.classList.add('lock-scroll');

            if (typeof window.bb !== 'undefined') {
                window.bb.header.headroom.destroy();
            }
        }
    },
    remove() {
        document.documentElement.classList.remove('lock-scroll');
        window.scrollTo(0, this.scrollTop);

        if (typeof window.bb !== 'undefined') {
            window.bb.header.init();
        }
    },
};

export const markNotificationAsRead = async (notificationId, cancel, token) =>
    await axios.get(`${getSiteEndpoint()}/notification/updateReadStatus`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            notificationId,
        },
    });

export const getUnReadNotificationCount = async (setCount, token) =>
    await axios
        .get(`${getSiteEndpoint()}/notification/unreadCount`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                test: 'hello',
            },
        })
        .then(response => {
            setCount(response?.data);
        })
        .catch(error => {
            if (error) {
                setCount(0);
            }
        });


export const postMultipleContactId = async (id, token, dashboardLink) => {
    const body = JSON.stringify({
        ContactId: id,
    });
    const config = {
        method: 'post',
        url: `${getSiteEndpoint()}/user/ActiveContact`,
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        data: JSON.stringify({ ContactID: id })
    };
    axios(config)
        .then(response => {
            console.log(response);
            if (response.status === 200) {
                Cookies.set('multiAccounts', 'multiAccounts', { expires: 365 });
                window.location.pathname = dashboardLink;
            }
        })
        .catch(error => {
            if (error) { console.log(error); }
            window.location.pathname = '/multiple-contact';
        });
};

export const deleteNotification = async (token, idNotification, setIsLoading, setNotifications) => {
    setIsLoading(true);
    await axios
        .delete(`${getSiteEndpoint()}/usernotification/delete?notificationId=${idNotification}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
            if (response) {
                getNotification(setNotifications, token, setIsLoading);
            }
        })
        .catch(error => { });
};

export const getNotification = async (
    setNotification,
    token,
    setNotifLoading,
    pageNotification,
    notificationPageSize,
    setPageNotification,
    setNotificationPageSize,
    setStopLoadMore
) =>
    await axios
        .get(`${getSiteEndpoint()}/notification/getMyNotification/?page=${pageNotification ?? 1}&pageSize=20`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
            if (setNotifLoading) {
                setNotifLoading(false);
                setNotification(prev => [...prev, ...response?.data]);
                setStopLoadMore(true);
                if (response?.data?.length === 20) {
                    setPageNotification(pageNotification + 1);
                } else if (response?.data?.length < 20) {
                    setPageNotification(1);
                    setStopLoadMore(false);
                }
            }
        })
        .catch(error => {
            if (error) {
                console.log(error);
                if (setNotifLoading) {
                    setNotifLoading(false);
                }
                setNotification([]);
            }
        });

export const newUser = function (token) {
    var config = {
        method: 'post',
        url: `${getSiteEndpoint()}/notification/newUser`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    axios(config).then(function (response) {
        console.log(JSON.stringify(response.data));
    });
};

export const logoutApi = function (token) {
    var config = {
        method: 'post',
        url: `${getSiteEndpoint()}/user/Logout`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    axios(config).then(function (response) {
        console.log(JSON.stringify(response.data));
    });
};
export const loginApi = function (token) {
    return new Promise((resolve, reject) => {
        var config = {
            method: 'post',
            url: `${getSiteEndpoint()}/user/Login`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        axios(config).then(function (response) {
            console.log(JSON.stringify(response.data));
            resolve(response);
        });
    });
};

export const getUserData = (setUserData, setMultipleContacData, token) => {
    return new Promise((resolve, reject) => {
        axios.get(`${getSiteEndpoint()}/user/Contacts`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                const { linkedContacts } = response?.data;
                setUserData(response?.data);
                setMultipleContacData(linkedContacts);
                resolve(linkedContacts);
            })
            .catch(error => {
                if (error) {
                    console.log(error);
                }
            });
    });
};

export const getActiveContactId = (token) => {
    return new Promise((resolve, reject) => {
        axios.get(`${getSiteEndpoint()}/user/ActiveContact`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => { resolve(response); })
            .catch(error => { resolve(null); });
    });
};


export const getMultipleContact = (setMultipleContacData, token) => {
    return new Promise((resolve, reject) => {
        axios.get(`${getSiteEndpoint()}/user/Contacts`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                const { linkedContacts } = response?.data;
                setMultipleContacData(linkedContacts);
                resolve(linkedContacts);
            })
            .catch(error => {
                if (error) {
                    console.log(error);
                }
            });
    });
};


export const CheckContactValid = (id, token) => {
    return new Promise((resolve, reject) => {
        const config = {
            method: 'get',
            url: `${getSiteEndpoint()}/user/Contact/${id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: null
        };
        axios(config).then(response => {
            console.log(response);
            if (response.status === 200) {
                resolve(response);
            }
        }).catch(error => {
            if (error) { console.log(error); }
            resolve(error);
        });
    });
};