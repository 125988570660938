import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  GlobalNavBar,
  Logo,
  LinksWrapper,
  Link,
  LinkContainer,
  NavIcon,
  Hamburger,
  Row,
  Notifications,
  LogoWrapper,
  WarningHeader
} from './GlobalNav.styles.js';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import { GlobalContext } from 'components/context/GlobalContext';
import NavigationLink from 'components/_common/navigation-link';
import ReactHtmlParser from 'react-html-parser';

import kvernelandGroupImg from 'components/global-nav/logos/Kverneland.jpg';
import kubotaImg from 'components/global-nav/logos/Kubota.png';
import viconImg from 'components/global-nav/logos/Vicon.jpg';
import HoverStyle from 'components/_common/navigation-link/HoverStyle.js';
import SearchBarNav from 'components/_common/navigation-link/SearchBarNav.js';
import { Auth } from 'components/context/AuthContext.js';
import { HeaderLink, UserLink, SearchBar } from 'components/_common/navigation-link/NavigationLink.styles.js';

const GlobalNav = ({ links: { links: { links } = {}, MyProfile, SearchText } = {}, logo, brand, favicon, warningHeader }) => {
  
  const { globalDispatch } = useContext(GlobalContext);
  const { userToken } = useContext(Auth);
  const [activeUser, setActiveUser] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const returnLogo = brand => {
    let brandLogo = logo?.Logos.filter(item => item.brand === brand)[0]
      ? logo?.Logos.filter(item => item.brand === brand)[0]?.image.src
      : logo?.Logos.filter(item => item.brand === 'Kverneland Group')[0]?.image.src;
    return brandLogo;
  };

  const returnFavicon = brand => {
    let icon = favicon?.Icons.filter(item => item.Brand === brand)[0]
      ? favicon?.Icons.filter(item => item.Brand === brand)[0]?.Image.src
      : favicon?.Icons.filter(item => item.Brand === 'Kverneland Group')[0]?.Image.src;
    return icon;
  };

  useEffect(() => {
    if (document.getElementById('favicon')) {
      let newFavicon = document.getElementById('favicon');
      newFavicon.href = returnFavicon(brand);
    }
    setActiveUser(Cookies.get('ActivUserName'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 const changeDealerHandler=()=>{
  // Cookies.set("IsFirstLogin",false)
  history.push('/multiple-contact')
  }

  return (
    <>
    { warningHeader && warningHeader.WarningShow && localStorage.getItem("PP_warningHeader_" + (new Date()).getDate()) != "hide" &&
    <WarningHeader>
      <svg class="warning-header-icon" xmlns="http://www.w3.org/2000/svg" width="30.154" height="28" viewBox="0 0 30.154 28">
        <path id="Icon_ionic-md-warning" data-name="Icon ionic-md-warning" d="M2.25,31.375H32.4l-15.077-28Zm16.692-4.308H15.712V23.837h3.231Zm0-5.385H15.712V15.221h3.231Z" transform="translate(-2.25 -3.375)" fill="#ff434a"></path>
      </svg>

      <span>{warningHeader.WarningLabel}</span>

        <div>
          {ReactHtmlParser(warningHeader.WarningMessage)}
        </div>

        <a class="warning-header-close" onClick={(e) => {
            e.preventDefault();
            localStorage.setItem("PP_warningHeader_" + (new Date()).getDate(), "hide");
            window.location.reload();
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16.672" height="16.672" viewBox="0 0 16.672 16.672"><path id="Icon_material-close" data-name="Icon material-close" d="M22.758,9.037,21.221,7.5l-6.092,6.092L9.037,7.5,7.5,9.037l6.092,6.092L7.5,21.221l1.537,1.537,6.092-6.092,6.092,6.092,1.537-1.537-6.092-6.092Z" transform="translate(-6.793 -6.793)" fill="#ff434a" stroke="#ff434a" stroke-width="1"></path></svg>
        </a>
    </WarningHeader>
    }

    <GlobalNavBar>
      {userToken && (
        <Hamburger
          onClick={() => {
            globalDispatch({
              type: 'SHOW_SIDEBAR',
            });
            globalDispatch({
              type: 'TOGGLE_SIDEBAR',
            });
          }}
        >
          <Row />
          <Row />
          <Row />
        </Hamburger>
      )}
      <LogoWrapper>
        <NavigationLink
          linkHref={Cookies.get('dashboard')}
          target={logo?.target}
          isExternal={logo?.isExternal}
        >
          <Logo src={returnLogo(brand)} alt={logo?.image?.alt} />
        </NavigationLink>
      </LogoWrapper>
      <LinksWrapper>
         {userToken && activeUser && location.pathname !== '/multiple-contact' && (
          <>
            {SearchText && <SearchBarNav SearchText={SearchText} /> }
            <HeaderLink id="userName">{activeUser}</HeaderLink>
          </>
        )}
        
        {userToken && location.pathname !== '/multiple-contact' && Cookies.get('ActivUserContactsCount') > 1 && (
          <HeaderLink onClick={changeDealerHandler}>Change Dealer</HeaderLink>
          // <HeaderLink onClick={() => history.push('/multiple-contact')}>Change Dealer</HeaderLink>
        )}
        <HoverStyle links={links} MyProfile={MyProfile} />
      </LinksWrapper>
      {userToken && <Notifications name="notifications" />}
    </GlobalNavBar>
    </>
  );
};

GlobalNav.propTypes = {
  links: PropTypes.shape({
    links: PropTypes.array,
  }),
  logo: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    }),
  }),
};

export default GlobalNav;
