import styled, { css } from 'styled-components';
import { Icon } from 'components/_icons';

export const SideNavWrapper = styled.div`
  ${({
    theme: {
      breakpoints: { tabletLarge },
    },
    showSidebar,
  }) => css`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: auto;
    /* overflow: hidden; */
    transition: all 200ms ease-in-out;
    position: relative;
  `}
`;

export const SideNavComponent = styled.nav`
  ${({
    theme: {
      colours: { primary },
      breakpoints: { mobileLarge, tabletLarge },
    },
    show,
    open,
  }) => css`
    display: ${open ? 'flex' : 'none'};
    flex-direction: column;
    width: 8rem;
    transition: width 400ms ease-in-out;
    @media (min-width: ${tabletLarge}px) {
      display: flex;
    }
  `}
`;

export const SideNavRow = styled.div`
  ${({ open }) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
  `}
`;

export const IconWrapper = styled.div`
  ${({
    theme: {
      colours: { primary, activeColour },
    },
    active,
    center,
    stylePath,
  }) => css`
    display: flex;
    width: 8rem;
    padding-top: ${center ? '0' : '1.8rem'};
    padding-bottom: ${center ? '0' : '1.8rem'};
    justify-content: center;
    align-items: ${center ? 'center' : 'flex-start'};
    min-height: 7.5rem;
    background-color: ${active ? 'rgb(247,249,250);' : primary};
    border-bottom: 1px solid rgb(150, 150, 150);
    cursor: pointer;
    position: relative;
    > svg {
      fill: ${active && primary};
      path {
        stroke: ${stylePath === 'Dashboard' && active && primary};
      }
    }
    &:hover {
      > div {
        visibility: visible;
      }
    }
  `}
`;

export const SideNavIcon = styled(Icon)`
  width: 40px;
  height: 54px;
  fill: white;
`;

export const TooltipText = styled.div`
  ${({
    theme: {
      fonts: { NotoSansRegular },
    },
  }) => css`
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    position: absolute;
    z-index: 11;
    top: 50%;
    transform: translateY(-50%);
    left: 90px;
    font-size: 11px;
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    white-space: nowrap;
    &::before {
      content: '';
      display: inline-block;
      height: 10px;
      width: 10px;
      margin-top: -6px;
      position: absolute;
      -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: rotate(44deg);
      left: -3px;
      top: 50%;
      background: black;
    }
  `}
`;

export const HideChevron = styled(Icon)`
  ${({
    theme: {
      colours: { white },
    },
  }) => css`
    width: 20px;
    height: 20px;
    fill: ${white};
    transition: transform 400ms ease-in-out;
  `}
`;

export const SideNavContent = styled.div`
  ${({
    theme: {
      colours: { white },
      breakpoints: { mobileMax },
    },
    active,
  }) => css`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: ${active ? '#F9FAFB' : white};
    transition: opacity 400ms ease-in-out;
    @media (min-width: ${mobileMax}px) {
      max-width: 21.5rem;
    }
  `}
`;

export const EndBlock = styled.div`
  display: flex;
  width: 100%;
  flex: 1 1 auto;
`;

export const EndBlockGreyColumn = styled.div`
  ${({
    theme: {
      colours: { primary },
    },
  }) => `
    background-color: ${primary};
    width: 8rem;
    min-height: 100%;
  `}
`;

export const EndBlockWhiteColumn = styled.div`
  ${({
    theme: {
      colours: { white },
    },
  }) => `
    background-color: white;
    flex: 1 1 auto;
    transition: opacity 400ms ease-in-out;
  `}
`;
