import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import axios from 'axios';
import { getSiteEndpoint } from 'helpers/api';
import { Auth } from 'components/context/AuthContext';
import LoadingSpinner from 'components/_common/loading-spinner';

import { models, Report, Embed, service, Page } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";


import {
     Container,
     HeaderComponent,
     TableWrapper, Header, TableContent, Row, Column
} from './power-bi.styles';


const PowerBI = ({ header, align, reportId, type, brand, langFolder, pageID }) => {
    
    const [loading, setLoading] = useState(true);
    const { userToken } = useContext(Auth);

    const [responseConfig, setResponseConfig] = useState({}); 
    const getResponseConfig = (setResponseConfig, token) => {
        return new Promise((resolve, reject) => {
            axios.get(`${getSiteEndpoint()}/powerbi/GetToken/${reportId}/${type}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(response => {
                if (response.status === 200 && response?.data) {
                    setResponseConfig(response?.data);
                    setLoading(false);
                }
            })
            .catch(error => {
                if (error) { console.log(error); }
                setLoading(false);
            });
        });
    };

    useEffect(() => {
        getResponseConfig(setResponseConfig, userToken);
    }, [setResponseConfig, userToken]);
    

    Object.byString = function(o, s) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}
    return (
        <Container>
            <div className="App">
                <header className="App-header">
                    <h1>{ ReactHtmlParser(header) }</h1>
                <PowerBIEmbed
                    embedConfig={{
                        
                        //hostname: "",
                        type: "report", // Supported types: report, dashboard, tile, visual and qna
                        id: responseConfig.reportId,
                        embedUrl: responseConfig.embedUrl,
                        accessToken: responseConfig.token,
                        tokenType: models.TokenType.Embed,
                        settings: {
                        panes: {
                            filters: {
                            expanded: false,
                            visible: false,
                            },
                            pageNavigation: {
                            visible: false,
                            },
                        },
                        background: models.BackgroundType.Transparent,
                        },
                    }}
                    eventHandlers={
                        new Map([
                        [
                            "loaded",
                            function () {
                            console.log("Report loaded");
                            },
                        ],
                        [
                            "rendered",
                            function () {
                            console.log("Report rendered");
                            },
                        ],
                        [
                            "error",
                        function (event) {
                            console.log(event.detail);
                            },
                        ],
                        ])
                    }
                    cssClassName={"report-style-class"}
                    />
                </header>
            </div>
        </Container>
    );
};

export default PowerBI;
