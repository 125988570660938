import getAuthVariables from 'helpers/authVariables';
const envVariables = getAuthVariables();

export const msalConfig = {
  auth: {
    authority: envVariables.authority,
    clientId: envVariables.clientId,
    redirectUri: envVariables.redirectUri,
    postLogoutRedirectUri: envVariables.redirectUri,
    scope: envVariables.scope,
    validateAuthority: false,
    navigateToLoginRequestUrl: false,
    policyEditProfile: envVariables.policyEditProfile,
    knownAuthorities: [envVariables.knownAuthorities],
    policyEditPassword: envVariables.policyEditPassword,
    policyEditPhoneNumber: envVariables.policyEditPhoneNumber,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
    secureCookies: false,
  },
  authenticationParameters: {
    scopes: ['openid', 'profile', envVariables.scope],
  },
  redirectUrl: envVariables.redirectUri + '/',
  policySignIn: 'B2C_1A_V1_SignIn_Partners',
  scopesApi: envVariables.scope,
  routeBase: '/',
};

export const loginRequest = {
  scopes: msalConfig.authenticationParameters.scopes,
};
