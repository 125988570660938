import styled from 'styled-components';

export const Title = styled.h3`
  ${({
    theme: {
      colours: { darkerOffWhite, offWhite, primary },
      fonts: { NotoSansBold },
      breakpoints: { tablet },
    },
  }) => `
    padding: 2rem;
    margin: 1rem 0 0;
    background-color: ${offWhite};
    border: 1px solid ${darkerOffWhite};
    color: ${primary};
    font-family: ${NotoSansBold.value};
    font-weight: ${NotoSansBold.fontWeight};
    font-style: ${NotoSansBold.fontStyle};
    font-size: 1.4rem;
    cursor: pointer;
    z-index: 1;
    position: relative;

    @media (min-width: ${tablet}px) {
      font-size: 1.8rem;
    }

    svg {
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      top: 2rem;
      right: 1.9rem;
    }
  `}
`;

export const Accordion = styled.section`
  /* accordion wrapper */
  &:first-of-type {
    ${Title} {
      margin-top: 0;
    }
  }
`;

export const Content = styled.div`
  ${({
    isAccordionOpen,
    theme: {
      colours: { darkerOffWhite },
    },
  }) => `
    // Max height trick for transitioning to height
    // 100%. So long as the max height is larger than
    // any possible text (2000px below)
    max-height: ${isAccordionOpen ? '200rem' : 0};
    overflow: hidden;
    transition: all 300ms ease-in-out;
    border: 1px solid ${darkerOffWhite};
    margin-top: -2px;
  `}
`;

export const Text = styled.p`
  ${({
    theme: {
      colours: { white, black },
      fonts: { NotoSansRegular },
      breakpoints: { tablet },
    },
    isAccordionOpen,
  }) => `
    display: block;
    box-sizing: border-box;
    padding: 1.9rem 2rem 1.9rem;
    background-color: ${white};
    color: ${black};
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin: 0;

    @media (min-width: ${tablet}px) {
      font-size: 1.6rem;
      padding: 1.8rem 2rem 1.8rem;
    }
  `}
`;
