import React from 'react';

const MyPartsOrders = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M7.865 9.219v1.154L5.13 11.416l9.962 3.795 9.96-3.795-2.588-.986V9.258l4.322 1.646c.212.081.353.285.353.512v13.69c0 .227-.14.43-.353.512l-11.5 4.38a.548.548 0 01-.39 0l-11.499-4.38a.548.548 0 01-.352-.512v-13.69c0-.227.14-.43.352-.512l3.677-1.388.792-.297zM4.14 12.21v12.518l10.404 3.964V16.174L4.139 12.21zm21.903 0l-10.404 3.964v12.517l10.405-3.963-.001-12.518zm-8.686 6.251l1.26.376-.314 1.05-.209-.063-.673 2.268 4.607 1.37.674-2.265-.463-.139.315-1.049 1.509.453-1.298 4.361-6.706-1.993 1.298-4.369zm-7.592.092l1.391 1.94a.548.548 0 01-.89.639l-.103-.145v1.38a.548.548 0 01-.458.541l-.09.008a.548.548 0 01-.547-.548v-2.11l-.257.157a.548.548 0 01-.694-.103l-.059-.08a.548.548 0 01.184-.752l1.523-.927zm-3.28-1.296l1.391 1.939a.548.548 0 01-.89.638l-.108-.152v1.59a.548.548 0 01-.459.541l-.089.007a.548.548 0 01-.547-.547L5.78 18.96l-.258.157a.548.548 0 01-.693-.105l-.059-.08a.548.548 0 01.185-.752l1.529-.924zm17.797-2.632l1.317 3.862-5.876 2.014-1.318-3.862 5.877-2.014zm-.682 1.391l-3.805 1.304.61 1.79 3.805-1.305-.61-1.789zM4.725 22.83a.548.548 0 01.71-.308l5.466 2.163a.548.548 0 01-.403 1.019L5.033 23.54a.548.548 0 01-.308-.711zm11.48-20.502l1.747.723c.183.076.276.28.214.468l-.27.806c.275.221.525.471.746.745l.806-.268a.377.377 0 01.468.213l.723 1.748a.377.377 0 01-.18.48l-.76.382c.037.348.037.7 0 1.049l.777.381a.377.377 0 01.182.482l-.724 1.749a.377.377 0 01-.467.213l-.807-.269c-.22.274-.47.524-.745.745l.27.807a.377.377 0 01-.215.468l-1.767.723a.377.377 0 01-.48-.18l-.38-.76a4.86 4.86 0 01-.877.015l-.175-.015-.363.755a.377.377 0 01-.484.184l-1.744-.723a.377.377 0 01-.213-.467l.27-.809c-.27-.22-.516-.47-.733-.742l-.805.268a.377.377 0 01-.467-.213l-.723-1.749a.377.377 0 01.18-.48l.759-.38a4.74 4.74 0 010-1.053l-.76-.38a.377.377 0 01-.18-.481l.724-1.748a.377.377 0 01.467-.213l.807.268c.221-.274.47-.524.745-.745l-
.269-.806a.377.377 0 01.213-.467l1.744-.724a.377.377 0 01.483.183l.37.758a4.939 4.939 0 011.045 0l.364-.756a.377.377 0 01.483-.185zm-2.784.832l-1.093.453.243.73a.377.377 0 01-.138.425 4.186 4.186 0 00-.964.964.377.377 0 01-.426.138l-.729-.243-.455 1.1.688.344a.377.377 0 01.203.401 3.986 3.986 0 000 1.356.377.377 0 01-.203.401l-.688.345.455 1.1.73-.244a.377.377 0 01.427.14c.26.372.581.697.949.963a.377.377 0 01.136.425l-.243.73 1.09.452.328-.681a.377.377 0 01.402-.208c.452.075.912.075 1.364 0a.377.377 0 01.399.203l.345.69 1.117-.458-.243-.728a.377.377 0 01.138-.426c.372-.266.698-.592.965-.964a.377.377 0 01.425-.138l.73.243.453-1.097-.703-.345a.377.377 0 01-.206-.4c.074-.45.074-.91 0-1.362a.377.377 0 01.203-.398l.689-.344-.455-1.1-.73.243a.377.377 0 01-.425-.138 4.186 4.186 0 00-.964-.964.377.377 0 01-.138-.425l.243-.73-1.095-.452-.328.68a.377.377 0 01-.4.21 4.186 4.186 0 00-1.362 0 .377.377 0 01-.4-.207l-.334-.684zm4.015 3.91a2.81 2.81 0 01-1.4 3.63l-.121.052-.103.04a2.815 2.815 0 111.624-3.721zm-3.306-.622a1.846 1.846 0 001.414 3.41l.097-.044a1.84 1.84 0 00.901-2.37 1.846 1.846 0 00-2.412-.996z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default MyPartsOrders;
