import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const initialState = {
  isOpenSideBar: false,
  showSidebar: false,
  isOpenNotificationSideBar: false,
  notificationsSideBarHeader: '',
};

const globalReducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_SIDEBAR':
      return {
        ...state,
        isOpenSideBar: !state.isOpenSideBar,
      };
    case 'OPEN_SIDEBAR':
      return {
        ...state,
        isOpenSideBar: true,
      };
    case 'CLOSE_SIDEBAR':
      return {
        ...state,
        isOpenSideBar: false,
      };
    case 'SHOW_SIDEBAR':
      return {
        ...state,
        showSidebar: !state.showSidebar,
      };
    case 'TOGGLE_NOTIFICATION_SIDEBAR':
      return {
        ...state,
        isOpenNotificationSideBar: !state.isOpenNotificationSideBar,
      };
    case 'OPEN_NOTIFICATION_SIDEBAR':
      return {
        ...state,
        isOpenNotificationSideBar: true,
      };
    case 'CLOSE_NOTIFICATION_SIDEBAR':
      return {
        ...state,
        isOpenNotificationSideBar: false,
      };
    case 'SHOW_NOTIFICATION_SIDEBAR':
      return {
        ...state,
        showNotificationSidebar: !state.showSidebar,
      };
    case 'NOTIFICATIONS_HEADER':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export const GlobalContext = createContext(initialState);

export const GlobalContextProvider = ({ children }) => {
  const [globalState, globalDispatch] = useReducer(globalReducer, initialState);
  return (
    <GlobalContext.Provider
      value={{
        globalState,
        globalDispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

GlobalContextProvider.propTypes = {
  children: PropTypes.node,
};
