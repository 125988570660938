import React from 'react';
import PropTypes from 'prop-types';
import {
  ContentBlockContainer,
  Title,
  Description,
  GatewayHeader,
  GatewayContentBlockContainer,
  GatewayDescription,
} from './ContentBlock.styles.js';
import Header from 'components/Header';
import ReactHtmlParser from 'react-html-parser';

const ContentBlock = ({ header, title, body, gatewayHeaderText = false }) => {
  if (gatewayHeaderText) {
    return (
      <>
        {header && <GatewayHeader header={header}>{ReactHtmlParser(header)}</GatewayHeader>}
        <GatewayContentBlockContainer>
          {body && <GatewayDescription>{ReactHtmlParser(body)}</GatewayDescription>}
        </GatewayContentBlockContainer>
      </>
    );
  }
  return (
    <>
      {header && <Header header={header}>{ReactHtmlParser(header)}</Header>}
      {title && <Title>{title}</Title>}
      <ContentBlockContainer>
        {body && <Description>{ReactHtmlParser(body)}</Description>}
      </ContentBlockContainer>
    </>
  );
};

ContentBlock.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default ContentBlock;
