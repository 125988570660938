import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  FooterWrapper,
  FooterContent,
  Column,
  Link,
  AuthButton,
  CopyRight,
  AsideContent,
  SocialIcons,
  SocialIcon,
  Logo,
  LinkInner,
  SocialIconLink,
  SocialIconImg,
  LogoLink,
} from './Footer.styles.js';
import theme from 'theme';
import { Auth } from 'components/context/AuthContext';
import ReactHtmlParser from 'react-html-parser';
const Footer = ({
  links: {
    links: { links: navLinks },
  },
  copyrightText: { value: copyrightText },
  socialIcons: { links: socialLinks },
  logoLink: { links: logoLink },
  labels = {},
}) => {
  const {
    colours: { primary },
  } = theme;

  const { langFolder } = useContext(Auth);

  return (
    <FooterWrapper>
      <FooterContent>
        <Column>
          {navLinks?.map((link, index) => {
            const { url, title, target, alt, isExternal } = link;
            return (
              <Link
                key={`${title}-${index}`}
                linkHref={url}
                target={target}
                alt={alt}
                isExternal={isExternal}
              >
                <LinkInner>{ReactHtmlParser(title)}</LinkInner>
              </Link>
            );
          })}
          <CopyRight>{`© ${ReactHtmlParser(copyrightText)}`}</CopyRight>
          <CopyRight>
            <span id="LanguageFolder">{ReactHtmlParser(langFolder)}</span>
          </CopyRight>
        </Column>
      </FooterContent>
      <AsideContent>
        {logoLink?.map((logo, logoIndex) => {
          const { icon, url, target, alt, iconImage } = logo;
          return (
            <LogoLink key={`${icon}-${logoIndex}`} href={url} target={target} alt={alt}>
              <Logo src={iconImage} alt={alt} />
            </LogoLink>
          );
        })}
        <SocialIcons>
          {socialLinks?.map((socialIcon, socialIconIndex) => {
            const { icon, url, target, alt, iconImage } = socialIcon;
            return (
              <SocialIconLink key={`${icon}-${socialIconIndex}`} href={url} target={target} alt={alt}>
                <SocialIconImg src={iconImage} alt={alt} />
              </SocialIconLink>
            );
          })}
        </SocialIcons>
      </AsideContent>
    </FooterWrapper>
  );
};

Footer.propTypes = {
  links: PropTypes.object,
  copyrightText: PropTypes.object,
  socialIcons: PropTypes.object,
  logo: PropTypes.object,
};

export default Footer;
