import styled, { css } from 'styled-components';
import { Icon } from 'components/_icons';
import Link from 'components/_common/Link';

const eventsPadding = `padding: 1.8rem`;
const eventsDisplaySass = 'display: flex; justify-content: center; align-items: center;';
const borderLeft = `border-left: 1px solid rgb(216, 216, 216);`;

export const EventsListWrapper = styled.div`
  ${({
    theme: {
      breakpoints: { tabletLarge },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 112.7rem;
    &:first-child {
      margin-top: 2rem;
    }
    @media (min-width: ${tabletLarge}px) {
      flex-direction: row;
    }
  `}
`;

export const DetailsWrapper = styled.div`
  ${({
    theme: {
      breakpoints: { tabletLarge },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(236, 236, 236);
    width: 100%;
    @media (min-width: ${tabletLarge}px) {
      flex-direction: row;
      flex: 0.84;
    }
  `}
`;

export const EventName = styled.h2`
  ${({
    theme: {
      colours: { black, offWhite },
      fonts: { NotoSansMedium },
      breakpoints: { tabletLarge },
    },
    noCTA,
  }) => css`
    ${eventsDisplaySass}
    justify-content: center;
    flex: 0.39;
    padding: 1.8rem 1.8rem 0.4rem 1.8rem;
    background-color: ${offWhite};
    margin: 0;
    color: ${black};
    font-family: ${NotoSansMedium.value};
    font-weight: ${NotoSansMedium.fontWeight};
    font-style: ${NotoSansMedium.fontStyle};
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: center;
    @media (min-width: ${tabletLarge}px) {
      justify-content: flex-start;
      ${eventsPadding};
      text-align: left;
      font-size: 1.6rem;
    }
  `}
`;

export const Date = styled.div`
  ${({
    theme: {
      fonts: { NotoSansRegular },
      breakpoints: { tabletLarge },
      colours: { offWhite, darkerOffWhite, black },
    },
  }) => css`
    ${eventsDisplaySass}
    flex: 0.225;
    color: black;
    background-color: ${offWhite};
    padding: 0.4rem 1.8rem 1.8rem 1.8rem;
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    font-size: 1.2rem;
    line-height: 2.4rem;
    text-align: left;
    .dropdown-container {
      position: relative;
      height: 3rem;
      display: flex;
      flex-direction: row;
      max-width: none;
      div:first-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0;
        border: none;
      }
    }
    @media (min-width: ${tabletLarge}px) {
      ${eventsPadding};
      ${borderLeft}
      background-color: ${darkerOffWhite};
      color: rgb(106, 106, 106);
      font-size: 1.4rem;
    }
  `}
`;

export const PlaceWrapper = styled.div`
  ${({
    theme: {
      breakpoints: { tabletLarge },
    },
  }) => css`
    display: flex;
    flex-direction: row;
    flex: 0.3325;
    border-top: 1px solid rgb(216, 216, 216);
    @media (min-width: ${tabletLarge}px) {
      flex: 0.4;
      border-top: none;
    }
  `}
`;

export const Location = styled.div`
  ${({
    theme: {
      fonts: { NotoSansRegular },
      breakpoints: { tabletLarge },
      colours: { darkerOffWhite },
    },
  }) => css`
    ${eventsDisplaySass}
    ${eventsPadding};
    background-color: ${darkerOffWhite};
    width: 50%;
    color: rgb(106, 106, 106);
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    font-size: 1.2rem;
    line-height: 2.4rem;
    @media (min-width: ${tabletLarge}px) {
      ${borderLeft}
      font-size: 1.4rem;
      justify-content: flex-start;
    }
  `}
`;

export const Country = styled.div`
  ${({
    theme: {
      fonts: { NotoSansRegular },
      breakpoints: { tabletLarge },
      colours: { darkerOffWhite },
    },
  }) => css`
    ${eventsDisplaySass}
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0.8rem 0.3rem 0.8rem 0.8rem;
    background-color: ${darkerOffWhite};
    width: 50%;
    color: rgb(106, 106, 106);
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    font-size: 1.4rem;
    line-height: 2.4rem;
    @media (min-width: ${tabletLarge}px) {
      ${borderLeft}
      justify-content: flex-start
    }
  `}
`;

export const Flag = styled.img`
  width: 2.4rem;
  height: 1.7rem;
  margin-right: 0.5rem;
`;

export const CountryName = styled.div`
  ${({
    theme: {
      fonts: { NotoSansRegular },
    },
  }) => css`
    color: rgb(106, 106, 106);
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    font-size: 1.4rem;
    line-height: 2.4rem;
  `}
`;

export const MoreInformation = styled(Link)`
  ${({
    theme: {
      fonts: { NotoSansRegular },
      breakpoints: { tabletLarge },
      colours: { darkerOffWhite, white },
    },
    noCTA,
  }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0.16;
    ${eventsPadding};
    background-color: ${noCTA ? white : darkerOffWhite};
    border-top: 1px solid rgb(216, 216, 216);
    margin-bottom: 0;
    outline: none;
    border: none;
    text-decoration: none;
    @media (min-width: ${tabletLarge}px) {
      ${noCTA
        ? ''
        : `border: 1px solid rgb(236, 236, 236);
      ${borderLeft}`}
    }
  `}
`;

export const MoreInformationText = styled.p`
  ${({
    theme: {
      fonts: { NotoSansMedium },
      colours: { black, darkGrey },
      breakpoints: { tabletLarge },
    },
  }) => css`
    color: ${darkGrey};
    font-family: ${NotoSansMedium.value};
    font-weight: ${NotoSansMedium.fontWeight};
    font-style: ${NotoSansMedium.fontStyle};
    font-size: 1.4rem;
    line-height: 2.2rem;
    margin: 0;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 0.1rem;
      background-color: ${black};
      bottom: -0.2rem;
      left: 0;
      right: 0;
      opacity: 0.3;
    }
    @media (min-width: ${tabletLarge}px) {
      &:after {
        display: none;
      }
    }
  `}
`;

export const Chevron = styled(Icon)`
  ${({
    theme: {
      colours: { primary },
      breakpoints: { tabletLarge },
    },
  }) => css`
    display: none;
    width: 1.1rem;
    height: 1.3rem;
    fill: ${primary};
    margin-left: 0.5rem;

    @media (min-width: ${tabletLarge}px) {
      display: block;
    }
  `}
`;

export const CalendarIcon = styled(Icon)`
  ${({
    theme: {
      colours: { primary },
    },
  }) => css`
    width: 2rem;
    height: 2.2rem;
    fill: ${primary};
    margin-right: 0.5rem;
  `}
`;

export const PinIcon = styled(Icon)`
  ${({
    theme: {
      colours: { primary },
    },
  }) => css`
    width: 1.4rem;
    height: 2rem;
    fill: ${primary};
    margin-right: 0.5rem;
  `}
`;

export const Dropdown = styled.div`
  ${({
    theme: {
      colours: { lightGrey },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    padding: 0.4rem;
    position: absolute;
    top: 100%;
    background-color: white;
    box-shadow: 0px 2px 15px -5px rgba(0, 0, 0, 0.4);
    width: 17.5rem;
    left: -3.2rem;
    z-index: 1;
    a {
      text-align: center;
      padding: 0.6rem;
      text-decoration: none;
      color: ${lightGrey};
      border-bottom: 1px solid rgb(216, 216, 216);
      &:last-child {
        border-bottom: none;
      }
    }
  `}
`;
