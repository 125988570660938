import styled from 'styled-components';
import { Icon } from 'components/_icons';

export const SideNavWrapper = styled.div`
  ${({
    theme: {
      breakpoints: { tabletLarge },
    },
    showSidebar,
  }) => `
    display: flex;
    flex-direction: row;
    height: 100%;
    width: ${showSidebar ? 'auto' : 0};
    overflow: hidden;
    transition: all 200ms ease-in-out;

    @media (min-width: ${tabletLarge}px) {
      width: auto;
    }
  `}
`;

export const SideNavComponent = styled.nav`
  ${({
    theme: {
      colours: { primary },
      breakpoints: { mobileLarge, tabletLarge },
    },
    open,
    show,
  }) => `
    display: flex;
    flex-direction: column;
    width: ${open ? '100vw' : '8rem'};
    max-width: ${open ? 'none' : '0'};
    ${open ? 'box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1)' : ''};
    transition: width 400ms ease-in-out;

    @media (min-width: ${mobileLarge + 1}px) {
      max-width: ${open ? '29rem' : '0'};
      width: ${open ? '100%' : '8rem'};
    }

    @media (min-width: ${tabletLarge}px) {
      max-width: ${open ? '29rem' : '8rem'};
    }
  `}
`;

export const SideNavRow = styled.div`
  ${({ open }) => `
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    width: 100%;
  `}
`;

export const IconWrapper = styled.div`
  ${({
    theme: {
      colours: { primary, activeColour },
    },
    active,
    center,
  }) => `
    display: flex;
    width: 8rem;
    padding-top: ${center ? '0' : '1.8rem'};
    padding-bottom: ${center ? '0' : '1.8rem'};
    justify-content: center;
    align-items: ${center ? 'center' : 'flex-start'};
    min-height: 7.5rem;
    background-color: ${active ? activeColour : primary};
    border-bottom: 1px solid rgb(150, 150, 150);
    cursor: pointer;
  `}
`;

export const SideNavIcon = styled(Icon)`
  width: 40px;
  height: 54px;
  fill: white;
`;

export const HideChevron = styled(Icon)`
  ${({
    theme: {
      colours: { white },
    },
    open,
  }) => `
    width: 20px;
    height: 20px;
    transform: rotate(${open ? '-180deg' : '0deg'});
    fill: ${white};
    transition: transform 400ms ease-in-out;
 `}
`;

export const SideNavContent = styled.div`
  ${({
    open,
    theme: {
      colours: { white },
      breakpoints: { mobileMax },
    },
    active,
  }) => `
    display: flex;
    flex-direction: column;
    width:  ${open ? 'calc(100% - 8rem)' : '0'};
    height:  ${open ? 'auto' : '5.4rem;'};
    overflow: hidden;
    background-color: ${active ? '#F9FAFB' : white};

    opacity: ${open ? '1' : '0'};
    transition: opacity 400ms ease-in-out;

    @media (min-width: ${mobileMax}px) {
      max-width: 21.5rem;
    }
  `}
`;

export const EndBlock = styled.div`
  display: flex;
  width: 100%;
  flex: 1 1 auto;
`;

export const EndBlockGreyColumn = styled.div`
  ${({
    theme: {
      colours: { primary },
    },
  }) => `
    background-color: ${primary};
    width: 8rem;
    min-height: 100%;
  `}
`;

export const EndBlockWhiteColumn = styled.div`
  ${({
    theme: {
      colours: { white },
    },
    open,
  }) => `
    background-color: white;
    flex: 1 1 auto;
    opacity: ${open ? '1' : '0'};
    transition: opacity 400ms ease-in-out;
  `}
`;
