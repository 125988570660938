import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  NotificationDetailComponent,
  NotificationList,
  NotificationPreviewContainer,
} from './NotificationDetail.styles.js';
import Notification from 'components/_common/notification';
import NotificationPreview from 'components/notifications-preview';
import { getUnReadNotificationCount, markNotificationAsRead } from 'helpers/utilities';
import { Auth } from 'components/context/AuthContext';
import getAuthVariables from 'helpers/authVariables.js';
import { getNotification } from 'helpers/utilities';
import LoadingSpinner from 'components/_common/loading-spinner/LoadingSpinner.js';
import { BtnLoadMore } from 'components/notifications-side-bar/NotificationsSideBar.styles.js';

const NotificationDetail = () => {
  const {
    notifications,
    setNotifications,
    userToken,
    setNotificationCount,
    notifLoading,
    setNotifLoading,
    pageNotification,
    notificationPageSize,
    setPageNotification,
    stopLoadMore,
    setStopLoadMore,
    setNotificationPageSize,
  } = useContext(Auth);
  const [activeNotification, setActiveNotification] = useState(undefined);
  const [queryString, setQueryString] = useState(undefined);
  const search = useLocation().search;
  const queryStrings = qs.parse(search);
  const source = axios.CancelToken.source();
  const envVariables = getAuthVariables();

  useEffect(() => {
    if (notifications) {
      if (Number(queryStrings['?notificationId'])) {
        setQueryString(Number(queryStrings['?notificationId']));
      }
      const notificationId = queryString;
      const not = notifications.find(notification => {
        return notification.notificationId === notificationId;
      });
      setActiveNotification(not);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStrings]);

  useEffect(() => {
    return () => {
      source.cancel('Component Unmounted');
    };
  }, [source]);

  const markNotificationAsReadClickHandler = (notificationId, userId, cancelToken) => {
    markNotificationAsRead(notificationId, cancelToken, userToken);
    getUnReadNotificationCount(setNotificationCount, userToken);
    getNotification(setNotifications, userToken);
  };
  const loadNewNOtification = () => {
    getNotification(
      setNotifications,
      userToken,
      setNotifLoading,
      pageNotification,
      notificationPageSize,
      setPageNotification,
      setNotificationPageSize,
      setStopLoadMore
    );
  };

  useEffect(() => {
    console.log(stopLoadMore);
  }, [stopLoadMore]);
  return (
    <NotificationDetailComponent>
      {notifLoading ? <LoadingSpinner /> : null}
      <NotificationList>
        {notifications?.map((notification, index) => {
          return (
            <Notification
              notificationReadStatus={notification.isRead}
              key={notification.guid}
              {...notification}
              notification={notification}
              shortNotification
              setActiveNotification={setActiveNotification}
              onClick={markNotificationAsReadClickHandler}
              cancelToken={source.token}
              activeNotification={activeNotification}
            />
          );
        })}
        {stopLoadMore && (
          <BtnLoadMore dark onClick={loadNewNOtification}>
            Load More
          </BtnLoadMore>
        )}
      </NotificationList>
      <NotificationPreviewContainer>
        <NotificationPreview {...activeNotification} />
      </NotificationPreviewContainer>
    </NotificationDetailComponent>
  );
};

export default NotificationDetail;
