import React from 'react';
import PropTypes from 'prop-types';
import { ContactCardsComponent } from './ContactCards.styles.js';
import ContactCard from 'components/_common/contact-card';

const ContactCards = ({ cards }) => {
  return (
    <ContactCardsComponent>
      {cards?.map((card, cardIndex) => (
        <ContactCard key={cardIndex} {...card} />
      ))}
    </ContactCardsComponent>
  );
};

ContactCards.propTypes = {
  cards: PropTypes.array,
};

export default ContactCards;
