const getAuthVariables = () => {
  const environment = document.querySelector('meta[name="environment_name"]')?.content || 'dev';

  const envs = {
    dev: {
      authority: process.env.REACT_APP_MSAL_AUTHORITY_DEV, // msalAuthority,
      clientId: process.env.REACT_APP_MSAL_CLIENT_ID_DEV, // clientId
      redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI_DEV, // msalRedirectUri,
      scope: process.env.REACT_APP_MSAL_SCOPE_DEV,
      policyEditProfile: process.env.REACT_APP_MSAL_POLICY_EDIT_PROFILE,
      policyEditPassword: process.env.REACT_APP_MSAL_POLICY_CHANGE_PASSWORD,
      policyEditPhoneNumber: process.env.REACT_APP_MSAL_POLICY_PROFILEEDIT_PHONENUMBER,
      knownAuthorities: process.env.REACT_APP_KNOWN_AUTHORITIES_DEV,
    },
    test: {
      authority: process.env.REACT_APP_MSAL_AUTHORITY_TEST, // msalAuthority,
      clientId: process.env.REACT_APP_MSAL_CLIENT_ID_TEST, // clientId
      redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI_TEST, // msalRedirectUri,
      scope: process.env.REACT_APP_MSAL_SCOPE_TEST,
      policyEditProfile: process.env.REACT_APP_MSAL_POLICY_EDIT_PROFILE,
      policyEditPassword: process.env.REACT_APP_MSAL_POLICY_CHANGE_PASSWORD,
      policyEditPhoneNumber: process.env.REACT_APP_MSAL_POLICY_PROFILEEDIT_PHONENUMBER,
      knownAuthorities: process.env.REACT_APP_KNOWN_AUTHORITIES_TEST,
    },
    staging: {
      authority: process.env.REACT_APP_MSAL_AUTHORITY_STAGING, // msalAuthority,
      clientId: process.env.REACT_APP_MSAL_CLIENT_ID_STAGING, // clientId
      redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI_STAGING, // msalRedirectUri,
      scope: process.env.REACT_APP_MSAL_SCOPE_STAGING,
      policyEditProfile: process.env.REACT_APP_MSAL_POLICY_EDIT_PROFILE,
      policyEditPassword: process.env.REACT_APP_MSAL_POLICY_CHANGE_PASSWORD,
      policyEditPhoneNumber: process.env.REACT_APP_MSAL_POLICY_PROFILEEDIT_PHONENUMBER,
      knownAuthorities: process.env.REACT_APP_KNOWN_AUTHORITIES_STAGING,
    },
    prod: {
      authority: process.env.REACT_APP_MSAL_AUTHORITY_PROD, // msalAuthority,
      clientId: process.env.REACT_APP_MSAL_CLIENT_ID_PROD, // clientId
      redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI_PROD, // msalRedirectUri,
      scope: process.env.REACT_APP_MSAL_SCOPE_PROD,
      policyEditProfile: process.env.REACT_APP_MSAL_POLICY_EDIT_PROFILE_PROD,
      policyEditPassword: process.env.REACT_APP_MSAL_POLICY_CHANGE_PASSWORD,
      policyEditPhoneNumber: process.env.REACT_APP_MSAL_POLICY_PROFILEEDIT_PHONENUMBER,
      knownAuthorities: process.env.REACT_APP_KNOWN_AUTHORITIES_PROD,
    },
    DevLocal : {
      authority: process.env.REACT_APP_MSAL_AUTHORITY_TEST, // msalAuthority,
      clientId: process.env.REACT_APP_MSAL_CLIENT_ID_TEST, // clientId
      redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI_TEST, // msalRedirectUri,
      scope: process.env.REACT_APP_MSAL_SCOPE_TEST,
      policyEditProfile: process.env.REACT_APP_MSAL_POLICY_EDIT_PROFILE,
      policyEditPassword: process.env.REACT_APP_MSAL_POLICY_CHANGE_PASSWORD,
      policyEditPhoneNumber: process.env.REACT_APP_MSAL_POLICY_PROFILEEDIT_PHONENUMBER,
      knownAuthorities: process.env.REACT_APP_KNOWN_AUTHORITIES_TEST,
    }
  };
  return envs[environment];
};

export default getAuthVariables;
