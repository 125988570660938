import React from 'react';

const BespokeOrderOnline = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M20.436 7.753c1.037 0 1.878.866 1.878 1.935v18.377c0 1.069-.84 1.935-1.878 1.935H10.103c-1.037 0-1.878-.866-1.878-1.935V9.688c0-1.069.84-1.935 1.878-1.935h10.333zm.939 19.345H9.164v.967c0 .535.42.968.94.968h10.332c.519 0 .94-.433.94-.968l-.001-.967zm-5.166.484v.967H14.33v-.967h1.879zm5.166-16.444H9.164V26.13h12.21V11.138zm-1.879 2.902c.26 0 .47.217.47.484v7.738c0 .267-.21.484-.47.484h-8.453a.477.477 0 01-.47-.484v-7.738c0-.267.21-.484.47-.484h8.453zm-6.106.967h-1.878v6.771h7.515v-6.77l-1.88-.001.001 1.451a.48.48 0 01-.385.476l-.084.008H13.86a.477.477 0 01-.47-.484l-.001-1.451zm5.167 5.32v.968h-.94v-.968h.94zm-1.409 0v.968h-.94v-.968h.94zm-.94-5.32H14.33v.968h1.879l-.001-.968zm4.228-6.287H10.103c-.518 0-.939.433-.939.968v.483h12.21l.001-.483c0-.535-.42-.968-.94-.968zM7.282 3.277c2.894-1.357 5.593-2.035 8.096-2.035 2.504 0 5.203.678 8.097 2.035a.53.53 0 00.714-.273.585.585 0 00-.289-.757C20.733.75 17.892 0 15.378 0c-2.513 0-5.354.75-8.521 2.247a.585.585 0 00-.289.757.53.53 0 00.714.273zm3.127 1.93c1.023-.58 2.68-.87 4.97-.87 2.29 0 3.946.29 4.969.87a.458.458 0 00.648-.22.664.664 0 00-.284-.837c-1.241-.704-3.02-1.055-5.334-1.055-2.314 0-4.092.351-5.333 1.055a.664.664 0 00-.284.836.458.458 0 00.648.22z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default BespokeOrderOnline;
