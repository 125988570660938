import React from 'react';
import PropTypes from 'prop-types';
import { ImageComponent } from './list-language-folder.styles.js';
import theme from 'theme.js';

const ListLanguageFolder = () => {
  return <></>;
};

export default ListLanguageFolder;
