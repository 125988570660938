import styled from 'styled-components';
import { Icon } from 'components/_icons';

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;


export const SelectMultiple = styled.div`
  ${({
    className,
    theme: {
      colours: { white, lightGrey, darkGrey, borderGrey },
      fonts: { NotoSansRegular },
      breakpoints: { tablet },
    },
    value,
  }) => `

    display: block;
    width: 100%;
    border: 1px #dadada solid;
    
    .placeholder-option {
      color: ${lightGrey};
      font-size: smaller;
    }

    label {
      display: block;
      position: relative;
      padding-left: 35px;
      margin: 10px 16px;
      cursor: pointer;
      
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      // font-size: 22px;
      input{
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      input:checked ~ .checkmark {
        background-color: #2196F3;
      }
      input:checked ~ .checkmark:after {
        display: block;
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #eee;
      }
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      .checkmark:after {
        left: 7px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    label:hover {
      // background-color: #1e90ff;
      input ~ .checkmark {
        background-color: #1e90ff;
      }
    }  
  `}
  `;

export const Select = styled.select`
  ${({
    className,
    theme: {
      colours: { white, lightGrey, darkGrey, borderGrey },
      fonts: { NotoSansRegular },
      breakpoints: { tablet },
    },
    value,
  }) => `
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-family: ${NotoSansRegular.value};
      font-weight: ${NotoSansRegular.fontWeight};
      font-style: ${NotoSansRegular.fontStyle};
      font-size: 1.4rem;
      line-height: 2.4rem;
      margin-top: 1rem;
      border: 1px solid ${borderGrey};
      padding: 1.3rem 5rem 1.3rem 2rem;
      text-align: left;
      background-color: rgba(0,0,0,0);
      color: ${darkGrey};
      min-height: 2.4rem;
      width: 100%;
      border-radius: 0;
      position: relative;
      z-index: 1;
      margin-bottom: 0;

     ${!value ? `color: ${lightGrey};` : ''};

      @media (min-width: ${tablet}px) {
        padding: 1.8rem 6rem 1.8rem 2rem;
        font-size: 1.6rem;
      }

      &.${className}{
        padding: 1.8rem 0rem 1.8rem 2rem;
      }
   `}
`;

export const Option = styled.option`
  ${({
    theme: {
      colours: { lightGrey },
    },
  }) => `
    &.placeholder-option {
      color: ${lightGrey};
      font-size: smaller;
    }
  `}
`;

export const ChevronWrapper = styled.div`
  ${({
    theme: {
      colours: { darkGrey },
      breakpoints: { tablet },
    },
  }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 1rem;
    bottom: 0;
    right: 0;
    z-index: 0;
    width: 5rem;
    background-color: ${darkGrey};
    border: none;
    outline: none;

    @media (min-width: ${tablet}px) {
      width: 6rem;
    }
  `}
`;

export const Chevron = styled(Icon)`
  ${({ isOpen }) => `
    width: 1.5rem;
    height: 1.5rem;
    fill: white;
    transform: ${isOpen ? 'rotate(-90deg)' : 'rotate(90deg)'};
    transition: transform 400ms ease-in-out;
  `}
`;
