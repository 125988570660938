import { Auth } from 'components/context/AuthContext';
import React, { useContext } from 'react';
import {
  NotificationHeaderComponent,
  NotificationHeaderText,
  NotificationHeaderWrapper,
  NotificationUnread,
} from './NotificationHeader.styles';
import ReactHtmlParser from 'react-html-parser';
const NotificationHeader = ({ header, align }) => {
  const { notificationCount } = useContext(Auth);

  return (
    <NotificationHeaderComponent>
      <NotificationHeaderWrapper align={align}>
        <NotificationHeaderText align={align}>
          {ReactHtmlParser(header)}
          <NotificationUnread>{notificationCount} unread</NotificationUnread>
        </NotificationHeaderText>
      </NotificationHeaderWrapper>
    </NotificationHeaderComponent>
  );
};

export default NotificationHeader;
