import React, { createContext, useEffect, useState } from 'react';
import getAuthVariables from 'helpers/authVariables';
import Cookies from 'js-cookie';
import axios from 'axios';
import qs from 'qs';
import { useIsAuthenticated } from '@azure/msal-react';
import {
    getMultipleContact,
    postMultipleContactId,
    getNotification,
    getUnReadNotificationCount,
    loginApi,
    logoutApi,
    newUser,
} from 'helpers/utilities';
// MSAL Integration
import { InteractionStatus } from '@azure/msal-browser';
import { getSiteEndpoint } from 'helpers/api';
import { useMsal } from '@azure/msal-react';
import { msalConfig, loginRequest } from 'helpers/configuration';
import { useHistory } from 'react-router-dom';

export const Auth = createContext();

const AuthContextProvider = ({ children }) => {
    const [error, setError] = useState(null);
    const [lang, setLang] = useState(null);
    const [langFolder, setLangFolder] = useState(null);
    const { instance, inProgress, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [userToken, setUserToken] = useState(null);

    const [notifications, setNotifications] = useState([]);
    const [notificationCount, setNotificationCount] = useState(0);
    const [notifLoading, setNotifLoading] = useState(true);
    const [multipleContacData, setMultipleContacData] = useState([]);
    const [pageNotification, setPageNotification] = useState(1);
    const [notificationPageSize, setNotificationPageSize] = useState(20);
    const [stopLoadMore, setStopLoadMore] = useState(false);
    const [page, setPage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [slugValue, setSlugValue] = useState('');
    const url = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const dashboardLink = Cookies.get('dashboard');
    const multiAccounts = Cookies.get('multiAccounts');
    const history = useHistory();

    const [user, setUser] = useState({
        hasAccessToken: false,
        accessToken: '',
        signIn: lang => {
            sessionStorage.clear();
            instance.loginRedirect(loginRequest).catch(e => {
                console.error(e);
            });
        },
        editProfile: lang => {
            sessionStorage.clear();
            instance.loginRedirect({
                ...loginRequest,
                extraQueryParameters: {
                    p: msalConfig.auth.policyEditProfile,
                },
            });
        },
        logout: () => {
            sessionStorage.clear();
            Cookies.remove('ActivUserName');
            Cookies.remove('ActivUserContactsCount');
            instance.logoutRedirect().catch(e => {
                console.error(e);
            });
        },
        editPhoneNumber: () => {
            instance.loginRedirect({
                ...loginRequest,
                extraQueryParameters: { p: msalConfig.auth.policyEditPhoneNumber },
            });
        },
        editPassword: () => {
            instance.loginRedirect({
                ...loginRequest,
                extraQueryParameters: { p: msalConfig.auth.policyEditPassword },
            });
        },
    });

    var timeoutInMiliseconds = 3600000;
    var timeoutId;
    function startTimer() {
        // window.setTimeout returns an Id that can be used to start and stop a timer
        timeoutId = window.setTimeout(doInactive, timeoutInMiliseconds);
    }
    function resetTimer() {
        window.clearTimeout(timeoutId);
        startTimer();
    }
    function doInactive() {
        if (isAuthenticated) {
            user.logout();
        }
    }
    function setupTimers() {
        document.addEventListener('mousemove', resetTimer, false);
        document.addEventListener('mousedown', resetTimer, false);
        document.addEventListener('keypress', resetTimer, false);
        document.addEventListener('touchmove', resetTimer, false);
        startTimer();
    }
    setupTimers();

    var customText = {
        ru: {
            alertBigPrivacy:
                'Этот сайт использует кукис и позволяет вам контролировать сервисы которые вы хотите активировать',
            denyAll: 'Запретить использование cookies',
            mandatoryTitle: 'Основные файлы cookie',
        },
        en: {
            alertBigPrivacy:
                'We are using cookies on this website to offer you a better browsing experience.&nbsp;Find out more here',
            denyAll: 'Essential Cookies only',
            mandatoryTitle: 'Essential Cookies',
        },
        fr: {
            alertBigPrivacy:
                'Ce site utilise des cookies et vous donne le contrôle sur ceux que vous souhaitez activer',
            denyAll: 'Tout refuser',
            mandatoryTitle: 'Cookies essentiels',
        },
        de: {
            alertBigPrivacy:
                "Diese Webseite verwendet 'Cookies' um Inhalte und Anzeigen zu personalisieren und zu analysieren. Bestimmen Sie, welche Dienste benutzt werden dürfen",
            denyAll: 'Verbiete alle Cookies',
            mandatoryTitle: 'Wesentliche Cookies',
        },
        hu: {
            alertBigPrivacy:
                'A weboldalunk sütik segítségével az Ön által kifejezetten óhajtott szolgáltatásokat biztosítani tudja - részletek',
            denyAll: 'Elutasítom',
            mandatoryTitle: 'nélkülözhetetlen sütiket',
        },
        no: {
            alertBigPrivacy:
                'Dette nettstedet bruker informasjonskapsler og gir deg kontroll over hva du vil aktivere',
            denyAll: 'Nekt alle informasjonskapsler',
            mandatoryTitle: 'Viktige informasjonskapsler',
        },

        da: {
            alertBigPrivacy:
                'Vi anvender cookies for at sikre dig den bedste oplevelse af vores hjemmeside – læs mere her',
            denyAll: 'Afvis alle cookies',
            mandatoryTitle: 'Væsentlige cookies',
        },
        es: {
            alertBigPrivacy: 'Este sitio web usa cookies y te permite controlar las que deseas activar',
            denyAll: 'Denegar todas las cookies',
            mandatoryTitle: 'Galletas Esenciales',
        },
        it: {
            alertBigPrivacy:
                "Questo sito utilizza i cookie per assicurarti la migliore esperienza di navigazione. Proseguendo la visita acconsenti all'utilizzo dei cookie da parte nostra. LEGGI DI PIU ",
            denyAll: 'Rifiuta tutti i cookie',
            mandatoryTitle: 'Cookie essenziali',
        },
        nl: {
            alertBigPrivacy:
                'Gebruik van cookiesOm de website zo optimaal mogelijk vorm te geven en voortdurend te kunnen verbeteren, maakt Kverneland Group gebruik van cookies. Lees meer',
            denyAll: 'Weiger alle cookies',
            mandatoryTitle: 'Essentiële cookies',
        },
        pl: {
            alertBigPrivacy: 'This site uses cookies and gives you control over what you want to activate',
            denyAll: 'Deny all cookies',
            mandatoryTitle: 'Essential Cookies',
        },
        sv: {
            alertBigPrivacy: 'Denna sida använder cookies och ger dig kontroll över vad du vill aktivera',
            denyAll: 'Neka ala čookieš',
            mandatoryTitle: 'Nevyhnutné súbory cookie',
        },
    };

    useEffect(() => {
        if (lang) {
            Cookies.set('currentLang', lang);
        }
    }, [lang]);

    useEffect(() => {
        let _lang = Cookies.get('currentLang');
        if (!_lang) {
            _lang = 'en';
        }

        if (typeof window.tarteaucitronCustomText !== 'undefined') {
            window.tarteaucitronCustomText = customText[_lang];
        }
        window.tarteaucitronForceLanguage = _lang;
        if (typeof window.tarteaucitron !== 'undefined') {
            window.tarteaucitron.init({
                privacyUrl:
                    'https://ien.kverneland.com/About-Kverneland/Privacy-and-Cookie-information' /* Privacy policy url */,

                hashtag: '#tarteaucitron' /* Open the panel with this hashtag */,
                cookieName: 'PartnerPortal_tarteaucitron' /* Cookie name */,

                orientation: 'middle' /* Banner position (top - bottom) */,

                groupServices: false /* Group services by category */,

                showAlertSmall: true /* Show the small banner on bottom right */,
                cookieslist: false /* Show the cookie list */,

                closePopup: false /* Show a close X on the banner */,

                showIcon: false /* Show cookie icon to manage cookies */,
                //"iconSrc": "", /* Optionnal: URL or base64 encoded image */
                iconPosition: 'BottomRight' /* BottomRight, BottomLeft, TopRight and TopLeft */,

                adblocker: false /* Show a Warning if an adblocker is detected */,

                DenyAllCta: true /* Show the deny all button */,
                AcceptAllCta: true /* Show the accept all button when highPrivacy on */,
                highPrivacy: true /* HIGHLY RECOMMANDED Disable auto consent */,

                handleBrowserDNTRequest: false /* If Do Not Track == 1, disallow all */,

                removeCredit: true /* Remove credit link */,
                moreInfoLink: true /* Show more info link */,

                useExternalCss: false /* If false, the tarteaucitron.css file will be loaded */,
                useExternalJs: false /* If false, the tarteaucitron.js file will be loaded */,

                //   "cookieDomain": "kverneland.com", /* Shared cookie for multisite */

                readmoreLink:
                    'https://ien.kverneland.com/About-Kverneland/Privacy-and-Cookie-information' /* Change the default readmore link */,

                mandatory: true /* Show a message about mandatory cookies */,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getAccessToken();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instance, accounts, inProgress, slugValue]);
    const getNotificationsData = async token => {
        await getUnReadNotificationCount(setNotificationCount, token);
        if (notifications.length === notificationPageSize || notifications.length === 0) {
            getNotification(
                setNotifications,
                token,
                setNotifLoading,
                pageNotification,
                notificationPageSize,
                setPageNotification,
                setNotificationPageSize,
                setStopLoadMore,
                stopLoadMore
            );
        }
        // newUser(token);
    };

    const fetchData = async (token, slug) => {
        setLoading(true);
        try {
            const response = await axios.get(`${getSiteEndpoint()}/page${slug}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response) {
                const { data: pageRes } = response;
                setPage(pageRes);
                setLangFolder(pageRes?.langFolder);
                setLang(pageRes?.langFolder.split('-')[1]);
                setLoading(false);
            }
        } catch (error) {
            if (error && error.response) {
                const {
                    response: { data: pageRes },
                } = error;
                setPage(pageRes);
                setLangFolder(pageRes?.langFolder);
                setLang(pageRes?.langFolder?.split('-')[1]);
                setLoading(false);
            }
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps

    const getAccessToken = () => {
        if (accounts.length > 0) {
            instance.setActiveAccount(accounts[0]);
        }

        if (instance && inProgress === InteractionStatus.None) {
            const accessTokenRequest = {
                ...loginRequest,
                account: accounts[0],
            };
            instance
                .acquireTokenSilent(accessTokenRequest)
                .then(async accessTokenResponse => {
                    let accessToken = accessTokenResponse.accessToken;
                    setUserToken(accessToken);
                    getNotificationsData(accessToken);
                    if (accessToken && dashboardLink && slugValue === '/') {

                        var activeUserName = Cookies.get('ActivUserName');


                        Cookies.set('IsFirstLogin', true);
                        loginApi(accessToken).then(res => {
                            if (res.status != 200) {
                                user.logout();
                                logoutApi(accessToken);
                            }
                        });
                        await getMultipleContact(setMultipleContacData, accessToken).then(res => {
                            if (res?.length > 1) {

                                Cookies.set('ActivUserContactsCount', res?.length);
                                try {
                                    if (activeUserName && activeUserName != "") {

                                        var currentUser = res.find(o => o.linkedAccount?.accountNumber == activeUserName);
                                        if (currentUser) {
                                            Cookies.set("IsFirstLogin", false);
                                            Cookies.set('ActivUserName', currentUser.linkedAccount.accountNumber);
                                            if (currentUser.langFolder) { Cookies.set("currentLang", currentUser.langFolder.split('-').pop()); }

                                            postMultipleContactId(currentUser.id, accessToken, dashboardLink);
                                        }
                                        else {
                                            window.location.pathname = '/multiple-contact';
                                        }
                                    }
                                    else {
                                        window.location.pathname = '/multiple-contact';
                                    }
                                }
                                catch { window.location.pathname = '/multiple-contact'; }

                            } else {
                                if (res[0].langFolder) {
                                    Cookies.set('currentLang', res[0].langFolder.split('-').pop());
                                }
                                Cookies.set('ActivUserName', res[0].linkedAccount.accountNumber);
                                Cookies.set('ActivUserContactsCount', 1);
                                window.location.pathname = dashboardLink;
                            }
                        });
                    } else {
                        if (slugValue) {
                            fetchData(accessToken, slugValue);
                        }
                    }
                })
                .catch(e => {
                    console.log(e);
                    if (slugValue) {
                        fetchData(undefined, slugValue);
                    }
                });
        }
    };

    return (
        <Auth.Provider
            value={{
                handleLogin: () => user.signIn(),
                handleLogout: () => user.logout(),
                getAccessToken: scopes => getAccessToken(scopes),
                editProfile: () => user.editProfile(),
                setLang,
                setLangFolder,
                langFolder,
                editPhoneNumber: () => user.editPhoneNumber(),
                editPassword: () => user.editPassword(),
                userToken,
                setUserToken,
                notifications,
                pageNotification,
                setPageNotification,
                stopLoadMore,
                setStopLoadMore,
                notificationPageSize,
                setNotificationPageSize,
                setNotifications,
                notificationCount,
                setNotificationCount,
                notifLoading,
                setNotifLoading,
                setSlugValue,
                page,
                loading,
                setMultipleContacData,
                multipleContacData,
                accounts,
            }}
        >
            {children}
        </Auth.Provider>
    );
};
export default AuthContextProvider;
