import styled from 'styled-components';

export const BreadcrumbsComponent = styled.section`
  ${({
    theme: {
      breakpoints: { tablet, tabletLarge },
    },
  }) => `
    display: flex;
    flex-direction: row;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    overflow: auto;

    @media (min-width: ${tablet}px) {
      padding-left: 8.2rem;
      padding-right: 9.5rem;
    }

    @media (min-width: ${tabletLarge}px) {
      padding-left: 4.2rem;
    }
  `}
`;
