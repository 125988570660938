import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import axios from 'axios';
import { getSiteEndpoint } from 'helpers/api';
import { Auth } from 'components/context/AuthContext';
import LoadingSpinner from 'components/_common/loading-spinner';


import {
    Container,
    HeaderComponent,
    TableWrapper, Header, TableContent, Row, Column, iframe
} from './video-player.styles';


const VideoPlayer = ({ sourceUrl, sourceType, brand, langFolder, pageID }) => {

    const [loading, setLoading] = useState(true);
    const { userToken } = useContext(Auth);

    return (
        <Container>
            {sourceType == "youtube" ?
                <div className="App">
                    <iframe width="70%" className='iframe' src={"https://www.youtube.com/embed/" + sourceUrl} frameborder="0" allowfullscreen></iframe>
                </div>

                : sourceType == "internal" ?
                    <div className="App">
                        <video width="70%" controls="controls">
                            <source src={sourceUrl} type="video/mp4" />
                            Your browser does not support HTML video.
                        </video>
                    </div>
                    : sourceType == "external" ?
                        <div className="App">
                            <video width="70%" controls="controls">
                                <source src={sourceUrl} type="video/mp4" />
                                Your browser does not support HTML video.
                            </video>
                        </div>

                        : null
            }
        </Container>
    );
};

export default VideoPlayer;
