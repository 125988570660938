import React from 'react';

const Clock = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M15.5 3C22.392 3 28 8.607 28 15.5 28 22.392 22.392 28 15.5 28c-2.552 0-5.007-.764-7.1-2.21a12.487 12.487 0 01-4.52-5.674.551.551 0 111.025-.408 11.387 11.387 0 004.123 5.174 11.33 11.33 0 006.472 2.015c6.284 0 11.397-5.113 11.397-11.397S21.784 4.103 15.5 4.103 4.103 9.216 4.103 15.5A.551.551 0 113 15.5C3 8.607 8.607 3 15.5 3zm.417 3.333a.55.55 0 110 1.101c-4.677 0-8.483 3.806-8.483 8.483 0 4.677 3.806 8.482 8.483 8.482 4.677 0 8.482-3.805 8.482-8.482a8.458 8.458 0 00-3.106-6.561.55.55 0 11.699-.851 9.555 9.555 0 013.508 7.412c0 5.284-4.3 9.583-9.583 9.583-5.285 0-9.584-4.299-9.584-9.583 0-5.285 4.3-9.584 9.584-9.584zm-.726 2.5c.29 0 .524.254.524.567v6.16l2.131 2.306a.6.6 0 010 .801.503.503 0 01-.37.166.504.504 0 01-.37-.166l-2.286-2.47a.591.591 0 01-.153-.401V9.4c0-.313.234-.567.524-.567z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Clock;
