import React from 'react';

const Pen = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M25.49 0c.958 0 1.877.381 2.553 1.058l.898.898a3.61 3.61 0 010 5.108l-2.346 2.345 1.253 1.117c.681.605 1.086 1.41 1.145 2.26L29 13c0 .928-.414 1.818-1.151 2.473l-5.864 5.224a1.266 1.266 0 01-1.644 0 .958.958 0 010-1.465l5.864-5.224c.3-.267.47-.63.47-1.008s-.17-.74-.47-1.008l-1.16-1.033-13.138 13.14A21.977 21.977 0 011.441 29.94l-.127.03a1.07 1.07 0 01-1.286-1.282 21.973 21.973 0 015.874-10.597L22.936 1.059A3.61 3.61 0 0125.49 0zM10.825 16.193l-3.41 3.411a19.834 19.834 0 00-4.758 7.649l-.045.135.135-.046c2.872-.975 5.49-2.6 7.648-4.756l3.411-3.412-2.981-2.981zm9.47-9.468l-8.102 8.1 2.981 2.981 8.271-8.272-3.104-2.766a1.115 1.115 0 01-.047-.043zm5.195-4.586c-.39 0-.765.155-1.041.432L21.83 5.188c.054.034.106.072.154.115l3.01 2.681 2.435-2.433a1.47 1.47 0 000-2.082l-.9-.899a1.47 1.47 0 00-1.04-.431z"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default Pen;
