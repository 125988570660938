import React from 'react';
import PropTypes from 'prop-types';
import { MainImageComponent } from './MainImage.styles.js';
import Image from 'components/_common/image';

const MainImage = props => {
  return (
    <MainImageComponent>
      <Image {...props} />
    </MainImageComponent>
  );
};

MainImage.propTypes = {};

export default MainImage;
