import styled, { css } from 'styled-components';
import { Icon } from 'components/_icons';

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  position: relative;

  @media (min-width: 992px) {
    min-height: calc(100vh - 192px);
  }
`;
export const SideBarPane = styled.div`
  /* SideBarPane */
`;

export const MainPaneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;


export const MainPaneFull = styled.div`
  ${({
    theme: {
      breakpoints: { mobileMax },
    },
  }) => css`
    width: 100%;
    height: 100%;
    @media (min-width: ${mobileMax}px) {
      height: 100%;
    }
  `}
`;

export const MainPane = styled.div`
  ${({
    theme: {
      breakpoints: { mobileMax },
    },
  }) => css`
    width: 100%;
    height: 100%;
    @media (min-width: ${mobileMax}px) {
      padding-right: 8rem;
      height: 100%;
    }
  `}
`;

export const NotificationsSideBarPane = styled.div`
  ${({
    isOpenNotificationSideBar,
    theme: {
      breakpoints: { mobileMax },
    },
  }) => `
    display: none;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: ${isOpenNotificationSideBar ? '37rem' : '8rem'};
    width: 100%;

    @media (min-width: ${mobileMax}px) {
      display: block;
    }
  `}
`;

export const MobileHeader = styled.div`
  ${({
    theme: {
      colours: { primary },
      fonts: { NotoSansBold },
      breakpoints: { mobileMax },
    },
  }) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 3rem;
    padding: 2.2rem 3.4rem 0 2.5rem;
    margin: 0 auto;
    font-family: ${NotoSansBold.value};
    font-weight: ${NotoSansBold.fontWeight};
    font-style: ${NotoSansBold.fontStyle};
    font-size: 1.8rem;
    line-height: 3rem;

    @media (min-width: ${mobileMax}px) {
      display: none;
    }
  `}
`;

export const ChevronDown = styled(Icon)`
  ${({
    theme: {
      colours: { primary },
    },
  }) => `
    max-width: 1.2rem;
    width: 100%;
    height: 1.2rem;
    min-width: 1.2rem;
    transform: rotate(90deg);
    fill: ${primary};
    margin-left: 1.5rem;
  `}
`;

export const MobileNotificationsWrapper = styled.div`
  ${({
    isOpenNotificationSideBar,
    theme: {
      breakpoints: { mobileMax },
    },
  }) => `
    max-height: ${isOpenNotificationSideBar ? '1000rem' : '0'};
    transition: max-height 400ms ease-in-out;

    @media (min-width: ${mobileMax}px) {
      display: none;
    }
  `}
`;
