const mxWidthForSlider = `76.9rem`;

export const externalStyles = `

// SVG ICON STYLES
  .image-gallery-icon {
   display: none !important;
  }


  .image-gallery-using-mouse {
    .image-gallery-icon {
      &:focus {
        outline: none;
      }
    }
  }


  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
   display: none;

    .image-gallery-svg {
      height: 36px;
      width: 36px;
    }


    @media (max-width: 768px) {
      padding: 15px;

      .image-gallery-svg {
        height: 24px;
        width: 24px;
      }
    }


    @media (max-width: 480px) {
      padding: 10px;

      .image-gallery-svg {
        height: 16px;
        width: 16px;
      }
    }
  }


  .image-gallery-fullscreen-button {
    display: none !important;
  }


  .image-gallery-play-button {
    display: none !important;
  }


  .image-gallery-left-nav,
  .image-gallery-right-nav {
    display: none !important;
  }

  // End of Icon styles

  .image-gallery {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: relative;
    max-width: ${mxWidthForSlider};

    &.fullscreen-modal {
      background: #000;
      bottom: 0;
      height: 100%;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 5;

      .image-gallery-content {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .image-gallery-content {
    position: relative;
    line-height: 0;
    top: 0;

    &.fullscreen {
      background: #000;
    }

    .image-gallery-slide .image-gallery-image {
      max-height: calc(100vh - 80px); // 80 px for the thumbnail space
    }

    &.left,
    &.right {
      .image-gallery-slide .image-gallery-image {
        max-height: 100vh;
      }
    }
  }


  .image-gallery-slide-wrapper {
    position: relative;

    &.left,
    &.right {
      display: inline-block;
      width: calc(100% - 110px); // 100px + 10px for margin

      @media (max-width: 768px) {
        width: calc(100% - 87px); // 81px + 6px for margin
      }
    }

    &.image-gallery-rtl {
      direction: rtl;
    }

    &.image-gallery-slide {
      transition: none;
      transform: none;
    }
  }

  .image-gallery-slides {
    line-height: 0;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    text-align: center;
  }

  .image-gallery-slide {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &:focus {
      border: none;
      outline: none;
    }

    &.center {
      position: relative;
    }

    .image-gallery-image {
      width: 100%;
      object-fit: contain;
    }


    .image-gallery-description {
      background: rgba(0, 0, 0, 0.4);
      bottom: 70px;
      color: #fff;
      left: 0;
      line-height: 1;
      padding: 10px 20px;
      position: absolute;
      white-space: normal;

      @media (max-width: 768px) {
        bottom: 45px;
        font-size: 0.8em;
        padding: 8px 15px;
      }
    }
  }


  .image-gallery-bullets {
    bottom: 20px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 80%;
    z-index: 4;

    .image-gallery-bullets-container {
      margin: 0;
      padding: 0;
      text-align: center;
    }


    .image-gallery-bullet {
      appearance: none;
      background-color: transparent;
      border: 1px solid #fff;
      border-radius: 50%;
      box-shadow: 0 1px 0 lighten(#000, 10%);
      cursor: pointer;
      display: inline-block;
      margin: 0 5px;
      outline: none;
      padding: 5px;
      transition: background 0.2s ease-out;

      @media (max-width: 768px) {
        margin: 0 3px;
        padding: 3px;
      }

      @media (max-width: 480px) {
        padding: 2.7px;
      }

      &:focus,
      &:hover {
        background: #337ab7;
        transform: scale(1.1);
      }

      &.active {
        background: #fff;
      }
    }
  }


  .image-gallery-thumbnails-wrapper {
    position: relative;

    &.thumbnails-wrapper-rtl {
      direction: rtl;
    }

    &.left,
    &.right {
      display: inline-block;
      vertical-align: top;
      width: 100px;

      @media (max-width: 768px) {
        width: 81px; // 75px + 6px for border
      }


      .image-gallery-thumbnails {
        height: 100%;
        width: 100%;
        left: 0;
        padding: 0;
        position: absolute;
        top: 0;

        .image-gallery-thumbnail {
          display: block;
          margin-right: 0;
          padding: 0;

          + .image-gallery-thumbnail {
            margin-left: 0;
            margin-top: 2px;
          }
        }
      }
    }

    &.left,
    &.right {
      margin: 0 5px;

      @media (max-width: 768px) {
        margin: 0 3px;
      }
    }
  }

  .image-gallery-thumbnails {
    overflow: hidden;
    padding: 5px 0;

    @media (max-width: 768px) {
      padding: 3px 0;
    }

    .image-gallery-thumbnails-container {
      cursor: pointer;
      text-align: left;
      transition: transform 0.45s ease-out;
      white-space: nowrap;
    }
  }


  .image-gallery-thumbnail {
    display: inline-block;
    border: 4px solid transparent;
    transition: border 0.3s ease-out;
    width: 100px;
    background: transparent;
    padding: 0;

    @media (max-width: 768px) {
      border: 3px solid transparent;
      width: 81px;
    }

    + .image-gallery-thumbnail {
      margin-left: 2px;
    }

    .image-gallery-thumbnail-inner {
      position: relative;
    }


    .image-gallery-thumbnail-image {
      vertical-align: middle;
      width: 100%;
      line-height: 0;
    }

    &.active,
    &:hover,
    &:focus {
      outline: none;
      border: 4px solid #337ab7;

      @media (max-width: 768px) {
        border: 3px solid #337ab7;
      }
    }
  }


  .image-gallery-thumbnail-label {
    box-sizing: border-box;
    color: white;
    font-size: 1em;
    left: 0;
    line-height: 1em;
    padding: 5%;
    position: absolute;
    top: 50%;
    text-shadow: 1px 1px 0 black;
    transform: translateY(-50%); 
    white-space: normal;
    width: 100%;

    @media (max-width: 768px) {
      font-size: 0.8em;
      line-height: 0.8em;
    }
  }

  .image-gallery-index {
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 4;

    @media (max-width: 768px) {
      font-size: 0.8em;
      padding: 5px 10px;
    }
  }
`;
