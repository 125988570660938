import styled, { css } from 'styled-components';
import Link from 'components/_common/Link';
import { Icon } from 'components/_icons';

export const NotificationsPreviewWrapper = styled.div`
  ${({
    theme: {
      colours: { primary },
      breakpoints: { tablet, tabletLarge },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    padding: 3.3rem 2.5rem 3.2rem;
    width: 100%;
    min-height: 100%;

    @media (min-width: ${tablet}px) {
      padding: 2rem;
    }

    hr{
      border: 1px solid ${primary};
      width: 100%;
      margin: 15px 0px;
    }
  `}
`;
export const ContainerWrapperIcon = styled.div`
  ${({
    theme: {
      breakpoints: { tablet, tabletLarge },
    },
  }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  `}
`;

export const ContainerIcons = styled.div`
float: right;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-bottom: 1px solid #efefef;
  margin-bottom: 11px;
  padding-bottom: 11px;
  justify-content: space-between;
  align-items: center;
`;

export const SubTitleContainer = styled.div`
  border-bottom: 1px solid #efefef;
  margin-bottom: 60px;
  padding-bottom: 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Print = styled.div`
  /* Print container */
`;

export const PrinterIcon = styled(Icon)`
  ${({
    theme: {
      colours: { primary },
    },
  }) => css`
    fill: ${primary};
    width: 2.4rem;
    height: 2.4rem;
    margin-left: 1rem;
  `}
`;
export const StyleIcons = styled(Icon)`
  ${({
    theme: {
      colours: { primary },
    },
  }) => css`
    fill: ${primary};
    width: 2.4rem;
    height: 2.4rem;
    margin-left: 1rem;
    cursor: pointer;
    > path {
      fill: ${primary};
    }
  `}
`;

export const Title = styled.h2`
  ${({
    theme: {
      colours: { darkGrey },
      breakpoints: { tablet },
      fonts: { NotoSansRegular },
    },
  }) => css`
    color: ${darkGrey};
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    font-size: 1.5rem;
    line-height: 2.4rem;
    margin: 0;

    @media (min-width: ${tablet}px) {
      font-size: 2.4rem;
      line-height: 3.3rem;
    }
  `}
`;

export const TimeSince = styled.p`
  ${({
    theme: {
      colours: { lightGrey },
      breakpoints: { tablet },
      fonts: { NotoSansRegular },
    },
  }) => css`
    color: ${lightGrey};
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    font-size: 1rem;
    line-height: 2.4rem;

    @media (min-width: ${tablet}px) {
      font-size: 1.4rem;
    }
  `}
`;

export const Text = styled.p`
  ${({
    theme: {
      colours: { lightGrey },
      breakpoints: { tablet },
      fonts: { NotoSansRegular },
    },
  }) => css`
    color: ${lightGrey};
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin: 0;

    @media (min-width: ${tablet}px) {
      font-size: 1.6rem;
    }
  `}
`;

export const LinkCTA = styled(Link)`
  width: fit-content;
  padding: 2.1rem 2rem;
`;
