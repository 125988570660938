import styled from 'styled-components';
import Image from 'components/_common/image';

export const GatewayComponent = styled.section`
  ${({ theme }) => `
    @media (min-width: ${theme.breakpoints.tabletLarge}px) {
      display: flex;
      flex-direction: row-reverse;
    }
  `}
`;

export const ImagePane = styled.div`
  ${({ theme }) => `
    height: 0;
    padding-top: 56.25%;
    position: relative;
    @media (min-width: ${theme.breakpoints.tabletLarge}px) {
      flex: 1;
      height: auto;
      padding: 0;
    }
  `}
`;

export const BackgroundImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

export const ContentPane = styled.div`
  ${({ theme }) => `
    @media (min-width: ${theme.breakpoints.tabletLarge}px) {
      width: 560px;
      min-width: 560px;
    }
  `}
`;
