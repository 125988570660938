import React from 'react';

const Zip = ({ className }) => {
  return (
    <svg version="1.1" className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <g>
        <g>
          <path
            d="M47.987,21.938c-0.006-0.091-0.023-0.178-0.053-0.264c-0.011-0.032-0.019-0.063-0.033-0.094
			c-0.048-0.104-0.109-0.202-0.193-0.285c-0.001-0.001-0.001-0.001-0.001-0.001L42,15.586V10c0-0.022-0.011-0.041-0.013-0.063
			c-0.006-0.088-0.023-0.173-0.051-0.257c-0.011-0.032-0.019-0.063-0.034-0.094c-0.049-0.106-0.11-0.207-0.196-0.293l-9-9
			c-0.086-0.086-0.187-0.148-0.294-0.196c-0.03-0.014-0.06-0.022-0.091-0.032c-0.085-0.03-0.172-0.047-0.263-0.052
			C32.039,0.01,32.021,0,32,0H7C6.448,0,6,0.448,6,1v14.586l-5.707,5.707c0,0-0.001,0.001-0.002,0.002
			c-0.084,0.084-0.144,0.182-0.192,0.285c-0.014,0.031-0.022,0.062-0.033,0.094c-0.03,0.086-0.048,0.173-0.053,0.264
			C0.011,21.96,0,21.978,0,22v19c0,0.552,0.448,1,1,1h5v5c0,0.552,0.448,1,1,1h34c0.552,0,1-0.448,1-1v-5h5c0.552,0,1-0.448,1-1V22
			C48,21.978,47.989,21.96,47.987,21.938z M44.586,21H42v-2.586L44.586,21z M38.586,9H33V3.414L38.586,9z M8,2h23v8
			c0,0.552,0.448,1,1,1h8v5v5H8v-5V2z M6,18.414V21H3.414L6,18.414z M40,46H8v-4h32V46z M46,40H2V23h5h34h5V40z"
          />
          <polygon
            points="14.582,27.766 18.356,27.766 14.31,36.317 14.31,38 20.6,38 20.6,36.164 16.571,36.164 20.6,27.613 20.6,25.964 
			14.582,25.964 		"
          />
          <rect x="22.436" y="25.964" width="2.04" height="12.036" />
          <path
            d="M32.542,26.72c-0.323-0.277-0.688-0.473-1.097-0.586c-0.408-0.113-0.805-0.17-1.19-0.17h-3.332V38h2.006v-4.828h1.428
			c0.419,0,0.827-0.074,1.224-0.221c0.397-0.147,0.748-0.374,1.054-0.68c0.306-0.306,0.553-0.688,0.739-1.148
			c0.187-0.459,0.28-0.994,0.28-1.606c0-0.68-0.105-1.247-0.314-1.7C33.132,27.364,32.866,26.998,32.542,26.72z M31.259,31.005
			c-0.306,0.334-0.697,0.501-1.173,0.501H28.93v-3.825h1.156c0.476,0,0.867,0.147,1.173,0.442c0.306,0.295,0.459,0.765,0.459,1.411
			C31.718,30.18,31.565,30.67,31.259,31.005z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Zip;
