import React from 'react';

const BespokeUser = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M15.5 16.25l.295.002C21.515 16.336 28 18.834 28 22.5V30h-1.25v-7.5c0-1.49-1.852-2.855-4.375-3.78v7.271l1.875 1.875V30H23v-1.616l-1.875-1.875v-8.197a20.95 20.95 0 00-1.875-.445v8.383h-7.5v-8.383c-.64.121-1.27.271-1.875.445v8.197L8 28.384V30H6.75v-2.134l1.875-1.875V18.72c-2.523.926-4.375 2.29-4.375 3.78V30H3v-7.5c0-3.73 6.711-6.25 12.5-6.25zm0 1.25c-.819 0-1.662.058-2.5.167V25h5v-7.333a19.555 19.555 0 00-2.5-.167zm-5-9.375v.625c0 2.542 2.684 5.625 5 5.625s5-3.083 5-5.625v-.625h1.25v.625c0 3.196-3.202 6.875-6.25 6.875S9.25 11.946 9.25 8.75v-.625h1.25zM15.5 0c4.484 0 6.25 1.94 6.25 5.625l-.001.624 3.751.001V7.5l-15.001-.001.001.001H9.25V5.625C9.25 1.94 11.016 0 15.5 0zm0 1.25c-3.766 0-5 1.355-5 4.375l-.001.624h10l.001-.624c0-2.948-1.175-4.31-4.735-4.373z"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default BespokeUser;
