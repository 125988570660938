import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import {
  NotificationsPreviewWrapper,
  TitleContainer,
  Title,
  Print,
  PrinterIcon,
  TimeSince,
  Text,
  LinkCTA,
  SubTitleContainer,
  ContainerWrapperIcon,
  ContainerIcons,
  StyleIcons,
} from './NotificationsPreview.styles.js';
import { deleteNotification, getNotification } from 'helpers/utilities.js';
import { Auth } from 'components/context/AuthContext.js';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import moment from 'moment';
import LoadingSpinner from 'components/_common/loading-spinner/LoadingSpinner.js';
import ReactHtmlParser from 'react-html-parser';

const NotificationsPreview = ({ title, dateCreated, body, cta, className, sender, pageId }) => {
  const { notifications, userToken, setNotifications } = useContext(Auth);
  const [activeNotification, setActiveNotification] = useState(undefined);
  const [queryString, setQueryString] = useState(undefined);
  const [listOfNotificationId, setListOfNotificationId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const search = useLocation().search;
  const queryStrings = qs.parse(search);
  let history = useHistory();

  const notificationRef = useRef(null);
  const { url, title: ctaText, target, isExternal } = cta || {};
  const time = dateCreated && dateCreated.split(' ')[0];
  const dateParts = time && time.split('/');
  const datePartsWithOutslash = time;
  const formattedTime =
    time && moment(`${dateParts[2]}/${dateParts[1]}/${dateParts[0]} ${dateCreated.split(' ')[1]}`).fromNow();

  useEffect(() => {
    if (notifications) {
      if (Number(queryStrings['?notificationId'])) {
        setQueryString(Number(queryStrings['?notificationId']));
      }
      const notificationId = queryString;
      setActiveNotification(notificationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStrings]);

  const removeNotification = () => {
    const idIndex = listOfNotificationId.findIndex(item => item === activeNotification);
    const oldListOfNotificationId = [...listOfNotificationId];
    if (oldListOfNotificationId.length === 1) {
      deleteNotification(userToken, activeNotification, setIsLoading, setNotifications);
      history.push('/dashboard');
    } else if (oldListOfNotificationId.length - 1 > idIndex) {
      deleteNotification(userToken, activeNotification, setIsLoading, setNotifications);
      history.push(
        `/dashboard-pages/notification-list?notificationId=${oldListOfNotificationId[idIndex + 1]}`
      );
    } else if (oldListOfNotificationId.length - 1 === idIndex) {
      deleteNotification(userToken, activeNotification, setIsLoading, setNotifications);
      history.push(
        `/dashboard-pages/notification-list?notificationId=${oldListOfNotificationId[idIndex - 1]}`
      );
    }
  };

  const [notificationTime, setNotificationTime] = useState(0);
  useEffect(() => {
    if (dateParts) {
      const getToday = new Date();
      const formatToday = moment(getToday)
        .format('YYYY/MM/DD')
        .split('/');
      const arrayToday = formatToday.map(str => {
        return Number(str);
      });
      const formatArrayTime = dateParts.map(str => {
        return Number(str);
      });
      const timeCreated = formatArrayTime.reverse();
      var a = moment(arrayToday);
      var b = moment(timeCreated);
      setNotificationTime(a.diff(b, 'days'));
    }
  }, [dateParts]);

  useEffect(() => {
    if (notifications) {
      const notificationsIdArray = notifications.map(id => id.notificationId);
      setListOfNotificationId(notificationsIdArray);
    }
  }, [notifications]);

  return (
    <>
    {title?
    <NotificationsPreviewWrapper ref={notificationRef} className={className}>
      {isLoading && <LoadingSpinner />}
      <TitleContainer>
        <Title>{ReactHtmlParser(title)}</Title>
        <TimeSince>{notificationTime > 2 ? datePartsWithOutslash : formattedTime}</TimeSince>
      </TitleContainer>
      <SubTitleContainer>
        <Text>{ReactHtmlParser(sender)}</Text>
        <ReactToPrint content={() => notificationRef.current}>
          <ContainerWrapperIcon>
            <ContainerIcons>
              <a href="/dashboard">
                <StyleIcons name="CircleArrow" />
              </a>
            </ContainerIcons>
            <PrintContextConsumer>
              {({ handlePrint }) => (
                <Print onClick={handlePrint}>
                  <PrinterIcon name="printer" />
                </Print>
              )}
            </PrintContextConsumer>
            <ContainerIcons onClick={removeNotification}>
              <StyleIcons name="Delete" />
            </ContainerIcons>
          </ContainerWrapperIcon>
        </ReactToPrint>
      </SubTitleContainer>
      {body && <Text>{ReactHtmlParser(body.replace(/\n/g, "<br />"))}</Text> }

      {url && url != '#' &&
      <>
      <hr /><LinkCTA linkText={ctaText? ctaText : '...'} linkHref={url} target={target} isExternal={isExternal} dark />
      </>
      }
    </NotificationsPreviewWrapper>
    :
  
    <ContainerIcons>
      <a href="/dashboard">
        <StyleIcons name="CircleArrow" />
      </a>
    </ContainerIcons>
    }
    </>
  );
};

NotificationsPreview.propTypes = {
  title: PropTypes.string,
  timeSince: PropTypes.string,
  text: PropTypes.string,
  cta: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
    target: PropTypes.string,
    isExternal: PropTypes.bool,
  }),
};

export default NotificationsPreview;
