import React from 'react';

const FarmCentre = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path
        d="M8.858 8.393c3.176-3.22 8.529-4.334 16.025-2.534.648.156.86.971.371 1.423-1.332 1.23-2.236 2.61-3.038 4.45l-.15.348-.148.363-.85 2.22c-.752 1.933-1.298 2.992-2.196 4.081-2.457 2.98-7.927 2.763-10.738.642a21.032 21.032 0 00-2.51 5.093.834.834 0 11-1.581-.527c1.367-4.102 3.823-7.558 7.089-10.483a34.107 34.107 0 014.563-3.423 46.856 46.856 0 011.808-1.086l1.228-.7a.834.834 0 01.846 1.437l-1.7.973c-.445.26-.862.513-1.29.784a32.463 32.463 0 00-4.343 3.256 25.937 25.937 0 00-3.132 3.327c2.129 1.64 6.644 1.866 8.474-.353.76-.922 1.244-1.864 1.94-3.654l.848-2.216.079-.194.158-.376.244-.551c.619-1.354 1.314-2.497 2.198-3.527l-.385-.073C16.69 6 12.53 7.045 10.051 9.558a10.186 10.186 0 00-2.867 7.15.834.834 0 11-1.667.011 11.857 11.857 0 013.341-8.326z"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default FarmCentre;
