import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  EditProfileComponent,
  Rows,
  Row,
  FieldName,
  FieldValue,
  EditProfileLink,
} from './EditProfile.styles.js';
import Modal from 'components/_common/modal/Modal.js';
import ModalContent from 'components/_common/modal-content/index.js';
import ReactHtmlParser from 'react-html-parser';

const EditProfile = ({
  /* TODO: Swap for profile and remove default values when UMS linked up */
  user = {
    firstName: 'Richard',
    lastName: 'Thompson',
    role: 'developer',
    company: 'Dept',
    country: 'United Kingdom',
    email: 'test@test.com',
  },
  labels,
  modal,
  ...rest
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const {
    profile_first_name: firstNameLabel,
    profile_surname: lastNameLabel,
    profile_user_role: userRoleLabel,
    profile_company: companyLabel,
    profile_country: countryLabel,
    profile_email: emailLabel,
    profile_password: passwordLabel,
    profile_cta: editProfileLabel,
    editProfileInfoContent,
    closeLabel,
  } = labels || {};

  const { modalBody, modalCtaLabel, modalHeader, modalIcon } = modal || {};

  const { src: iconSrc } = modalIcon || {};

  return (
    <>
      <EditProfileComponent>
        <Rows>
          <Row>
            <FieldName>{ReactHtmlParser(firstNameLabel)}</FieldName>
            <FieldValue>{ReactHtmlParser(user.firstName)}</FieldValue>
          </Row>
          <Row>
            <FieldName>{ReactHtmlParser(lastNameLabel)}</FieldName>
            <FieldValue>{ReactHtmlParser(user.lastName)}</FieldValue>
          </Row>
          <Row>
            <FieldName>{ReactHtmlParser(userRoleLabel)}</FieldName>
            <FieldValue>{ReactHtmlParser(user.role)}</FieldValue>
          </Row>
          <Row>
            <FieldName>{ReactHtmlParser(companyLabel)}</FieldName>
            <FieldValue>{ReactHtmlParser(user.company)}</FieldValue>
          </Row>
          <Row>
            <FieldName>{ReactHtmlParser(countryLabel)}</FieldName>
            <FieldValue>{ReactHtmlParser(user.country)}</FieldValue>
          </Row>
          <Row>
            <FieldName>{ReactHtmlParser(emailLabel)}</FieldName>
            <FieldValue>{ReactHtmlParser(user.email)}</FieldValue>
          </Row>
          <Row>
            <FieldName>{ReactHtmlParser(passwordLabel)}</FieldName>
            <FieldValue>**********</FieldValue>
          </Row>
        </Rows>
        <EditProfileLink dark onClick={() => setModalOpen(true)}>
          {ReactHtmlParser(editProfileLabel)}
        </EditProfileLink>
      </EditProfileComponent>
      {modalOpen ? (
        <Modal onClose={() => setModalOpen(false)}>
          <ModalContent
            icon={iconSrc}
            title={modalHeader}
            buttonText={modalCtaLabel}
            buttonAction={() => setModalOpen(false)}
          >
            {modalBody}
          </ModalContent>
        </Modal>
      ) : null}
    </>
  );
};

EditProfile.propTypes = {
  /* TODO: Uncomment when UMS system hooked up */
  // profile: PropTypes.shape({}).isRequired,
  labels: PropTypes.shape({}).isRequired,
};

export default EditProfile;
