import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const initialState = {
  isOpenFormSuccessModal: null,
  isOpenFormErrorModal: null,
};

const FormReducer = (state, action) => {
  switch (action.type) {
    case 'REQUEST_ACCESS_TOGGLE_SUCCESS_MODAL':
      return {
        ...state,
        isOpenFormSuccessModal: !state.isOpenFormSuccessModal,
      };
    case 'REQUEST_ACCESS_TOGGLE_ERROR_MODAL':
      return {
        ...state,
        isOpenFormErrorModal: !state.isOpenFormErrorModal,
      };
    default:
      return {
        ...state,
      };
  }
};

export const FormContext = createContext(initialState);

export const FormContextProvider = ({ children }) => {
  const [formState, formDispatch] = useReducer(FormReducer, initialState);
  return (
    <FormContext.Provider
      value={{
        formState,
        formDispatch,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

FormContextProvider.propTypes = {
  children: PropTypes.node,
};
