import styled, { css } from 'styled-components';

export const NotificationComponent = styled.section`
  ${({
    theme: {
      colours: { notificationBorder, white, offWhite, primary },
    },
    read,
    activeNotification,
  }) => css`
    // border-bottom: 1px solid ${notificationBorder};
    background-color: ${read ? offWhite : white};
    cursor: pointer;
    // border-right: ${activeNotification ? `2px solid ${primary}` : 'none'};
    flex-shrink: 0;
    a{
      >div{
        transition: all 0.2s ease-in-out;
        border-radius: ${activeNotification ? `5px` : 'none'};
        background-color: ${activeNotification ? `#707071` : 'none'};
        border-bottom: 1px solid #757475;
        padding: 2.4rem 2.7rem 3rem 3rem;
        margin: 0 9px 0  9px;
        border-bottom: 1px solid #757475;
        >div{
        position: relative;
        ${
          !read
            ? css`
                &::before {
                  content: '';
                  display: inline-block;
                  width: 10px;
                  height: 10px;
                  -moz-border-radius: 7.5px;
                  -webkit-border-radius: 7.5px;
                  border-radius: 7.5px;
                  background-color: #d4021d;
                  position: absolute;
                  top: 8px;
                  left: -20px;
                }
              `
            : ''
        }
          >p{
            color:${activeNotification ? `#fff` : 'none'};
            margin: 0;
            margin-bottom: 0.8rem;
          }
        }
        >p{
          color:${activeNotification ? `#fff` : 'none'};
          margin: 0;
          display: none;
        }
        &:hover{
          border-radius: 5px;
          background-color: #707071;
          >p{
            color:#fff
          }
          >div{
            >p{
              color:#fff
            }
          }
      }
    }
  `}
`;

export const InnerWrapper = styled.div`
  margin: 0 auto;
  max-width: 105rem;
  padding: 2.5rem;
`;

export const Details = styled.div`
  ${({
    theme: {
      breakpoints: { mobileMax },
    },
  }) => css`
    display: grid;
    justify-content: start;
    align-items: center;
    justify-items: stretch;
    grid-template-columns: 1fr auto;
  `}
`;
export const Logo = styled.img`
  width: 3.3rem;
  height: 3rem;
  border-radius: 50%;
  margin-right: 2.3rem;
  display: none;
`;
export const Title = styled.p`
  ${({
    theme: {
      breakpoints: { mobileMax },
      colours: { black, lightGrey },
      fonts: { NotoSansSemiBold, NotoSansRegular },
    },
    read,
  }) => css`
    font-family: ${NotoSansSemiBold.value};
    font-weight: ${NotoSansSemiBold.fontWeight};
    font-style: ${NotoSansSemiBold.fontStyle};
    color: ${black};
    margin-right: 1.3rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${
      read
        ? css`
            font-size: 1.4rem;
            font-family: ${NotoSansRegular.value};
            font-weight: ${NotoSansRegular.fontWeight};
            font-style: ${NotoSansRegular.fontStyle};
            color: ${lightGrey};
          `
        : ''
    }

    @media (min-width: ${mobileMax}px) {
      ${
        read
          ? `
        font-size: 1.5rem;
      `
          : ''
      }
    }
  `}
`;
export const DesktopMessage = styled.p`
  ${({
    theme: {
      breakpoints: { tablet },
      colours: { black, lightGrey },
      fonts: { NotoSansMedium },
    },
    shortNotification,
  }) => css`
    display: none;
    font-family: ${NotoSansMedium.value};
    font-weight: ${NotoSansMedium.fontWeight};
    font-style: ${NotoSansMedium.fontStyle};
    color: ${lightGrey};
    flex: 1;
    margin-right: 6.1rem;
    font-size: 1.6rem;
    line-height: 2.4rem;

    @media (min-width: ${tablet}px) {
      display: ${shortNotification ? 'none' : 'flex'};
    }
  `}
`;

export const MobileMessage = styled.p`
  ${({
    theme: {
      breakpoints: { tablet, mobileMax },
      colours: { lightGrey },
      fonts: { NotoSansRegular, NotoSansSemiBold },
    },
    shortNotification,
    read,
  }) => css`
    font-family: ${NotoSansSemiBold.value};
    font-weight: ${NotoSansSemiBold.fontWeight};
    font-style: ${NotoSansSemiBold.fontStyle};
    color: ${lightGrey};
    flex: 1;
    margin-left: 0;
    margin-right: 0;
    font-size: 1.6rem;
    line-height: 2.4rem;

    ${
      read
        ? css`
            font-family: ${NotoSansRegular.value};
            font-weight: ${NotoSansRegular.fontWeight};
            font-style: ${NotoSansRegular.fontStyle};
            margin-top: 0;
          `
        : ''
    }

    @media (min-width: ${mobileMax}px) {
      ${
        read
          ? css`
              font-size: 1rem;
            `
          : ''
      }
    }

    @media (min-width: ${tablet}px) {
      display: ${shortNotification ? 'flex' : 'none'};
    }

  `}
`;
export const Time = styled.p`
  ${({
    theme: {
      breakpoints: { mobileMax },
      colours: { black, lightGrey },
      fonts: { NotoSansRegular },
    },
  }) => css`
    display: inline-block;
    font-family: ${NotoSansRegular.value};
    font-weight: ${NotoSansRegular.fontWeight};
    font-style: ${NotoSansRegular.fontStyle};
    color: ${lightGrey};
    font-size: 10px;
    line-height: 2.4rem;
  `}
`;
