import React, { useContext, useEffect } from 'react';
import {
  Main,
  SideBarPane,
  MainPaneWrapper,
  MainPane, MainPaneFull,
  NotificationsSideBarPane,
  MobileNotificationsWrapper,
  MobileHeader,
  ChevronDown,
} from './FullWidth.styles.js';
import Wrapper from 'components/_utility/page-layout/wrapper';
import SideNav from 'components/side-nav';
import NotificationsSideBar from 'components/notifications-side-bar';
import ComponentSwitch from 'components/_utility/component-switch';
import { GlobalContext } from 'components/context/GlobalContext';
import {
  ContentNotificationsIconWrapper,
  Notifications,
} from 'components/notifications-side-bar/NotificationsSideBar.styles';
import SideNavMobile from 'components/side-nav-mobile/SideNav.js';
import useMediaQuery from 'helpers/useMediaQuery.js';

const FullWidth = ({ header, sidebar, notificationsSideBar, main: { components }, footer, brand, langFolder, pageID }) => {
  const {
    globalDispatch,
    globalState: { isOpenNotificationSideBar, notificationsSideBarHeader },
  } = useContext(GlobalContext);

  const toggleSidebar = value => {
    globalDispatch({
      type: 'TOGGLE_NOTIFICATION_SIDEBAR',
    });
  };

  const notificationCount = notificationsSideBarHeader.split(' ')[0];
  const matches = useMediaQuery('(max-width: 1023px)');

  return (
    <Wrapper header={header} footer={footer} brand={brand}>
      <Main className="main-section">
        
        {sidebar && Object.keys(sidebar).length > 0 ? (
          <SideBarPane id="SideBarPane">
            <SideNav {...sidebar} brand={brand} />
          </SideBarPane>
        )
        : null}

        <MainPaneWrapper>
          <MobileHeader onClick={toggleSidebar}>
            <ContentNotificationsIconWrapper notificationCount={notificationCount}>
              <Notifications name="notifications" />
            </ContentNotificationsIconWrapper>
            <>{` ${notificationsSideBarHeader}`}</>
            <ChevronDown name="chevron" />
          </MobileHeader>

          {Object.keys(notificationsSideBar).length > 0 ? (
            <MobileNotificationsWrapper isOpenNotificationSideBar={isOpenNotificationSideBar}>
              <NotificationsSideBar {...notificationsSideBar} mobile />
            </MobileNotificationsWrapper>
          ) : null}

          {notificationsSideBar && Object.keys(notificationsSideBar).length > 0 ? (
            <MainPane>
              {components?.map((component, componentIndex) => {
                return <ComponentSwitch key={componentIndex} component={component} brand={brand} langFolder={langFolder} pageID={pageID} />;
              })}
            </MainPane>)

            :(
            <MainPaneFull>
              {components?.map((component, componentIndex) => {
                return <ComponentSwitch key={componentIndex} component={component} brand={brand} langFolder={langFolder} pageID={pageID} />;
              })}
            </MainPaneFull>)
          }

        </MainPaneWrapper>
        {Object.keys(notificationsSideBar).length > 0 ? (
          <NotificationsSideBarPane id="NotificationsSideBar"  isOpenNotificationSideBar={isOpenNotificationSideBar}>
            <NotificationsSideBar {...notificationsSideBar} />
          </NotificationsSideBarPane>
        ) : null}
      </Main>
    </Wrapper>
  );
};

FullWidth.propTypes = {};

export default FullWidth;
