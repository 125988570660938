import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const InternalLink = styled(Link)`
  text-decoration: none;
`;

export const ExternalLink = styled.a`
  text-decoration: none;
`;


export const HeaderLink = styled.span`
  ${({
    theme: {
      breakpoints: { tablet, tabletLarge, mobileLarge, mobileMax },
    },
  }) => `
    color: #333;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    padding: 12px;
    border-right: 2px #333333 solid;
    
    &: hover {
      background-color: #f2f2f2;
    }

    &#userName{
      cursor: default;
      &: hover {
        background-color: transparent;
      }
    }

    @media (max-width: ${mobileMax}px){
      font-size: 14px;
      padding: 5px;
    }
  `}
`;


export const UserLink = styled.span`
  ${({
    theme: {
      breakpoints: { tablet },
    },
  }) => `
  color: rgb(106, 106, 106);
  font-size: 13px;
  line-height: 26px;
  font-weight: bold;
  margin-right: 35px;
  @media (max-width: ${tablet}px) {
    display: none;
  }
  `}
`;
