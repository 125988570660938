import styled from 'styled-components';

export const AccordionWrapper = styled.section`
  max-width: 76.9rem;
  width: 100%;
  margin: 0 auto 5rem;
`;

export const Heading = styled.h2`
  ${({
    theme: {
      fonts: { NotoSansBold },
      colours: { darkGrey },
      breakpoints: { tablet },
    },
  }) => `
    color: ${darkGrey};
    font-family: ${NotoSansBold.value};
    font-weight: ${NotoSansBold.fontWeight};
    font-style: ${NotoSansBold.fontStyle};
    font-size: 1.8rem;
    text-align: center;

    @media (min-width: ${tablet}px) {
      font-size: 2.4rem;
    }
  `}
`;
