import React from 'react';
import PropTypes from 'prop-types';
import FileDownload from 'components/file-download';
import {
  FileDownloadListComponent,
  FileDownloadLists,
  FilesComponent,
  Header,
} from './FileDownloadList.styles.js';
import ReactHtmlParser from 'react-html-parser';

const FileDownloadList = ({ downloads, labels }) => {
  return (
    <FileDownloadListComponent>
      <FileDownloadLists>
        {downloads?.map(list => {
          const { files, header } = list;
          return (
            <FilesComponent>
              {header && <Header>{ReactHtmlParser(header)}</Header>}
              {files?.map((file, index) => {
                return <FileDownload key={`${file.file}-${index}`} {...file} labels={labels} />;
              })}
            </FilesComponent>
          );
        })}
      </FileDownloadLists>
    </FileDownloadListComponent>
  );
};

FileDownloadList.propTypes = {
  downloads: PropTypes.array,
  labels: PropTypes.object,
};

export default FileDownloadList;
