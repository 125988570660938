import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { GetIcon, Icon } from 'components/_icons';


export const ContactCardsComponent = styled.section`
  ${({
    theme: {
      breakpoints: { tabletLarge },
    },
  }) => `
    
  `}
`;


export const Container = styled.div`
  ${({ theme }) => css`
  .searchResult {
    padding: 25px;
    text-align: left;
   
    div{
      float: none !important;
      padding-right: 0 !important;
    }
  }
  .search-item-details
  {
    padding-left: 10px;
    border-left: 2px solid;

    a {
      margin-left: 10px;
    }
  }

  @media (min-width: ${theme.breakpoints.desktop}px) {
    padding: 90px 10%;
  }
    margin-bottom: 50px;
    @media (min-width: ${theme.breakpoints.tablet}px) {
      
    }
    @media (min-width: ${theme.breakpoints.desktop}px) {
      
    }
  `}
`;